<template>
	<div>
		<table class="altbl noborderv market-table" id='stations'>
			<thead>
				<tr>
					<td v-for="col in cols" :key="col.title" class="headingtext" @click="sortColumn(col.fldid)" data-f-bold="true">
						<span>{{col.title}}</span>
					</td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="rowitem in rows" :key="rowitem.id">
					<td>
						<span>
							{{rowitem.name}}
							<InfoIcon v-on:clicked="showMarketSummary(rowitem)" title="Click to view market information and recent survey results in popup"></InfoIcon>
						</span>
					</td>
					<td><p>{{store.markets.find(item => item.id === rowitem.mktid).name}}</p></td>
					<td>
						<span class="hidden">{{ucfirst(rowitem.netgroupown)}}</span>
						<div style="width: 40px; height: 40px;">
							<StationLogo :stnob="rowitem" ltype="own"></StationLogo>
						</div>
					</td>
					<td data-t="n" class="ctr"><p style="text-align: right;">{{numdisplay(rowitem.cume10)}}</p></td>
					<td data-t="n" class="ctr"><p style="text-align: right;">{{numdisplay(rowitem.cume2554)}}</p></td>
				</tr>
			</tbody>
		</table>
		<div style="margin: 10px 0;" class="primary-btn" @click="exportToExcel('stations','Stations')">Export</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import InfoIcon from "@/components/arnmarkets/InfoIcon";
import StationLogo from "@/components/arnmarkets/StationLogo";
import TableToExcel from "@linways/table-to-excel";
export default {
	name: "StationsTable",
	components: {
		StationLogo,
		InfoIcon
	},
	props: {
		filtertext: String,
		inrows: Array,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			// table stuff
			sortColName: null,
			sortColOrder: 'desc',
			cols: [
				{fldid: 'name', title: 'Station Name', sortable: true},
				{fldid: 'mktid', title: 'Market', sortable: true},
				{fldid: 'netgroupown', title: 'Owner', sortable: true},
				{fldid: 'cume10', title: 'ARN Rep 10+ Cume', sortable: true, thClass: 'text-right', sortDirection: 'desc'},
				{fldid: 'cume2554', title: 'ARN Rep 25-54 Cume', sortable: true, thClass: 'text-right', sortDirection: 'desc'},
			],
			//end table stuff
		}
	},
	computed: {
		//table stuff
		rows() {
			return this.tblPrepareFilteredRows(this.inrows, this.filtertext, this.sortColName, this.sortColOrder)
		},
		//end table stuff
	},
	methods: {
		exportToExcel(tblid, filename = 'export') {
			if(this.excelfilename && this.excelfilename.length > 0) {
				filename = this.excelfilename;
			}
			let t = document.getElementById(tblid)
			let table2 = t.cloneNode(true)
			table2.innerHTML = table2.innerHTML.replace(/,/g,'')
			TableToExcel.convert(table2, {
				name: filename+".xlsx",
				sheet: {
					name: filename
				}
			});
		},
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		//end table stuff
		showMarketSummary(stnob) {
			this.$emit('showsum', this.store.markets.find(item => item.id === stnob.mktid))
		}
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>

</style>