<template>
	<div class="holder">
		<img :src="srcurl" />
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "TradeMarketingChannelTile",
	components: {},
	props: {
		title: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		srcurl() {
			if(this.title != "") {
				return '/public-files/trade-marketing/channel-logos/'+this.title+'.jpg';
			}
			return "";
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.holder {
	width: 100%;
	height: 100%;
}
.holder img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
</style>