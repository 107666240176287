<template>
	<div v-if="!userHasPerm" style="padding-top: 30px;">
		<p>You do not have permission to view this page.  If you think this is an error please contact us.</p>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "PermissionChecker",
	components: {},
	props: {
		allowedperms: Array,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		userHasPerm() {
			if(this.userHasPermission(this.allowedperms)) return true;
			return false;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>