<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">
			<div style="padding-top: 30px; display: flex">
				<div class="large-tile-button" @click="$router.push('/brand-tracking/market-dashboard')">
					<div class="tile-inside">
						<div class="tile-icon-hold">
							<i class="mdi mdi-trending-up"></i>
						</div>
						<p>Brand Tracking</p>
					</div>
				</div>
				<div class="large-tile-button" @click="$router.push('/marketing/share-of-voice')">
					<div class="tile-inside">
						<div class="tile-icon-hold">
							<i class="mdi mdi-bullhorn"></i>
						</div>
						<p>Share Of<br/>Voice</p>
					</div>
				</div>
				<div class="large-tile-button" @click="$router.push('/trade-marketing')">
					<div class="tile-inside">
						<div class="tile-icon-hold">
							<i class="mdi mdi-account"></i>
						</div>
						<p>Trade<br/>Marketing</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import PermissionChecker from "@/components/PermissionChecker";
export default {
	name: "MarketingPage",
	components: {
		PermissionChecker
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			viewPerms: ['elt','connections'],
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {
		document.title = 'ARN Marketing';
	}
}
</script>

<style scoped>

</style>