<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)" class="chart-rows">
			<PodListenerWarn></PodListenerWarn>
			<ChartHolderDash
					:charttitle="'Total Listeners by month'"
					:source="chartsSource.monthly_podcast_listeners" :addclasses="'half'">
				<template v-slot:default><ApexLineMonthlyTrend :chartdata="apexdata.monthly_podcast_listeners" filename="MonthlyListeners" :fetching="getChartsBtnWorking"></ApexLineMonthlyTrend></template>
			</ChartHolderDash>

			<ChartHolderDash
					:charttitle="'Total Downloads by month'"
					:source="chartsSource.monthly_podcast_downloads" :addclasses="'half'" :height="400">
				<template v-slot:default><ApexLineMonthlyTrend :chartdata="apexdata.monthly_podcast_downloads" filename="MonthlyDownloads" :fetching="getChartsBtnWorking"></ApexLineMonthlyTrend></template>
			</ChartHolderDash>

<!--			<ChartHolderDash-->
<!--					:charttitle="'Total Listeners by month by publisher (NOT FOR PUBLICATION)'"-->
<!--					:source="chartsSource.monthly_podcast_listeners_pub" :addclasses="'half'">-->
<!--				<template v-slot:default><ApexLineMonthlyTrendMulti :chartdata="apexdata.monthly_podcast_listeners_pub" filename="MonthlyListenersPublisher" :fetching="getChartsBtnWorking"></ApexLineMonthlyTrendMulti></template>-->
<!--			</ChartHolderDash>-->

<!--			<ChartHolderDash-->
<!--					:charttitle="'Total Downloads by month by publisher (NOT FOR PUBLICATION)'"-->
<!--					:source="chartsSource.monthly_podcast_downloads_pub" :addclasses="'half'">-->
<!--				<template v-slot:default><ApexLineMonthlyTrendMulti :chartdata="apexdata.monthly_podcast_downloads_pub" filename="MonthlyDownloadsPublisher" :fetching="getChartsBtnWorking"></ApexLineMonthlyTrendMulti></template>-->
<!--			</ChartHolderDash>-->

<!--			<ChartHolderDash-->
<!--					:charttitle="'Downloads by hour of day (Megaphone)'"-->
<!--					:source="chartsSource.hour_downloads" :addclasses="'half'">-->
<!--				<apexchart v-if="apexdata.hour_downloads" width="99%" height="100%" type="line"-->
<!--					:options="chartoptionsHourlyLine" :series="apexdata.hour_downloads"></apexchart>-->
<!--				<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>-->
<!--			</ChartHolderDash>-->

			<ChartHolderDash
					:charttitle="'Show Listeners Trending'"
					:source="chartsSource.podcast_uniques_show_trends">
				<template v-slot:default>
					<ApexBarTrendCategories :chartdata="apexdata.podcast_uniques_show_trends" filename="MonthlyShowListeners" :fetching="getChartsBtnWorking"></ApexBarTrendCategories>
				</template>
			</ChartHolderDash>

			<ChartHolderDash
					:charttitle="'Podcast Summary - Australian Downloads/Listeners'"
					:source="chartsSource.podcastshowmthtbl" :height="580">
				<div class="b-table-holder">
					<ALTable tblclasses="noborderv" :excel="true" excelfilename="Top ARN Podcasts"
						:cols="[
							{title: 'Podcast/Show', sortable: true, fldid: 'cat'},
							{title: 'Current Listeners', sortable: true, fldid: 'mthlist', numtype: 'number'},
							{title: 'Prev Listeners', sortable: true, fldid: 'mthprvlist', numtype: 'number'},
							{title: 'MoM Change Listeners', sortable: true, fldid: 'pctdifflist', numtype: 'pct'},
							{title: 'Current Downloads', sortable: true, fldid: 'mthdl', numtype: 'number'},
							{title: 'Prev Downloads', sortable: true, fldid: 'mthprvdl', numtype: 'number'},
							{title: 'MoM Change Downloads', sortable: true, fldid: 'pctdiff', numtype: 'pct'},
						]"
						:rowdata="chartsData.podcastshowmthtbl"
					></ALTable>
				</div>
				<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
			</ChartHolderDash>

			<ChartHolderDash
				:charttitle="'Listeners by Publisher'"
				:source="chartsSource.monthly_podcast_listeners_pub">
				<template v-slot:default>
					<ApexBarTrendCategories :chartdata="apexdata.monthly_podcast_listeners_pub" filename="MonthlyPublisherListeners" :fetching="getChartsBtnWorking"></ApexBarTrendCategories>
				</template>
			</ChartHolderDash>

			<ChartHolderDash
				:charttitle="'Downloads by Publisher'"
				:source="chartsSource.monthly_podcast_downloads_pub">
				<template v-slot:default>
					<ApexBarTrendCategories :chartdata="apexdata.monthly_podcast_downloads_pub" filename="MonthlyPublisherDownloads" :fetching="getChartsBtnWorking"></ApexBarTrendCategories>
				</template>
			</ChartHolderDash>

			<ChartHolderDash
					:charttitle="'Downloads by State'"
					:source="chartsSource.monthly_downloads_by_state" :addclasses="'half'">
				<template v-slot:default>
					<ApexPie :chartdata="apexdata.monthly_downloads_by_state" :labels="apexlabels.monthly_downloads_by_state" filename="DownloadsByState" :fetching="getChartsBtnWorking"></ApexPie>
				</template>
			</ChartHolderDash>

			<ChartHolderDash
					:charttitle="'NSW Downloads by month'"
					:source="chartsSource.monthly_downloads_by_state_trend_NSW" :addclasses="'half'">
				<template v-slot:default>
					<ApexBarTrendDateMonthlySingleSeries :chartdata="apexdata.monthly_downloads_by_state_trend_NSW" filename="NSW_Downloads" :fetching="getChartsBtnWorking"></ApexBarTrendDateMonthlySingleSeries>
				</template>
			</ChartHolderDash>

			<ChartHolderDash
					:charttitle="'VIC Downloads by month'"
					:source="chartsSource.monthly_downloads_by_state_trend_VIC" :addclasses="'half'">
				<template v-slot:default>
					<ApexBarTrendDateMonthlySingleSeries :chartdata="apexdata.monthly_downloads_by_state_trend_VIC" filename="VIC_Downloads" :fetching="getChartsBtnWorking"></ApexBarTrendDateMonthlySingleSeries>
				</template>
			</ChartHolderDash>

			<ChartHolderDash
					:charttitle="'QLD Downloads by month'"
					:source="chartsSource.monthly_downloads_by_state_trend_QLD" :addclasses="'half'">
				<template v-slot:default>
					<ApexBarTrendDateMonthlySingleSeries :chartdata="apexdata.monthly_downloads_by_state_trend_QLD" filename="QLD_Downloads" :fetching="getChartsBtnWorking"></ApexBarTrendDateMonthlySingleSeries>
				</template>
			</ChartHolderDash>

			<ChartHolderDash
					:charttitle="'SA Downloads by month'"
					:source="chartsSource.monthly_downloads_by_state_trend_SA" :addclasses="'half'">
				<template v-slot:default>
					<ApexBarTrendDateMonthlySingleSeries :chartdata="apexdata.monthly_downloads_by_state_trend_SA" filename="SA_Downloads" :fetching="getChartsBtnWorking"></ApexBarTrendDateMonthlySingleSeries>
				</template>
			</ChartHolderDash>

			<ChartHolderDash
					:charttitle="'WA Downloads by month'"
					:source="chartsSource.monthly_downloads_by_state_trend_WA" :addclasses="'half'">
				<template v-slot:default>
					<ApexBarTrendDateMonthlySingleSeries :chartdata="apexdata.monthly_downloads_by_state_trend_WA" filename="WA_Downloads" :fetching="getChartsBtnWorking"></ApexBarTrendDateMonthlySingleSeries>
				</template>
			</ChartHolderDash>

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import ApexBarTrendDateMonthlySingleSeries from "@/components/charts/ApexBarTrendDateMonthlySingleSeries";
import ApexBarTrendCategories from "@/components/charts/ApexBarTrendCategories";
import ApexPie from "@/components/charts/ApexPie";
import ApexLineMonthlyTrend from "@/components/charts/ApexLineMonthlyTrend";
import PodListenerWarn from "@/components/PodListenerWarn";
import PermissionChecker from "@/components/PermissionChecker";
import ALTable from "@/components/ALTable";

export default {
	name: 'PodcastDashboard',
	components: {
		ALTable,
		PermissionChecker,
		PodListenerWarn,
		ApexLineMonthlyTrend,
		ApexPie,
		ApexBarTrendCategories,
		ApexBarTrendDateMonthlySingleSeries,
		ChartLoaderInsert,
		ChartHolderDash,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','content-metro','connections','podcast'],
			chartId: 0,
			chartsData: {},
			chartsSource: [],
			getChartsBtnWorking: false,

			apexdata: {},
			apexlabels: {},

			chartoptionsHourlyLine: {
				chart: {
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					},
					type: 'line',
				},
				fill: {

				},
				xaxis: {
					type: "category",
					categories: [],
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 0,
					min: 0,
					forceNiceScale: true,
					labels: {
						formatter: (value) => {
							if(value === null) return null
							return value.toLocaleString() + "%"
						},
					},
				},
				colors: [],
				stroke: {
					curve: "smooth",
					width: 2.5,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 0
				},
				tooltip: {
					x: {
						formatter: (value) => { return 'Hour '+value },
					}
				},
			}
		}
	},
	computed: {

	},
	methods: {
		getCharts() {
			this.getChartsBtnWorking = true
			let self = this
			let targ = "/api/podcast-charts.php"
			axios.get(targ).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					if(ret.apexdata) self.apexdata = ret.apexdata
					if(ret.apexlabels) self.apexlabels = ret.apexlabels
					if (ret.chartsData != null) self.chartsData = ret.chartsData
					if (ret.chartsSource != null) self.chartsSource = ret.chartsSource
					if (ret.h != null) {
						for (var key in ret.h) { //this updates any that exist, adds new if needed, but does not delete existing
							self.slotprops[key] = ret.h[key];
						}
					}
					//custom callback functions here if required (using pageaction as filter)
					//handle click-through table links for On-Demand Radio shows
					let ct = 0
					for(let s of self.chartsData.podcastshowmthtbl) {
						s.cat = '<a href="/podcasts/on-demand-radio?showid='+s.showid+'" class="link-text">'+s.cat+'</a>'
						self.chartsData.podcastshowmthtbl[ct] = s
						ct++
					}
				}
				self.getChartsBtnWorking = false;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {},
	props: {

	},
	mounted() {
		this.getCharts();
		document.title = "Podcast Dashboard";
		this.chartoptionsHourlyLine.colors = this.store.chartColourGroupARNFirst
	},
	created() {

	},
}
</script>

<style scoped>

</style>
