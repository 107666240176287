<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0" width="99%" height="100%" :type="mytype"
			:options="chartoptions" :series="chartdata"></apexchart>
		<ChartLoaderInsert v-if="fetching"></ChartLoaderInsert>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
export default {
	name: "ApexLineWeeklyTrend",
	components: {
		ChartLoaderInsert
	},
	props: {
		fetching: Boolean,
		categories: Array,
		chartdata: Array,
		filename: String,
		cadence: String,
		forcelinetype: Boolean,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			mytype: 'area',
			chartoptions: {
				chart: {
					id: this.filename,
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					},
					type: 'area',
				},
				fill: {
					gradient: {
						opacityFrom: 0.4,
						opacityTo: 0.2,
					},
				},
				xaxis: {
					type: "datetime",
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 0,
					min: 0,
					forceNiceScale: true,
					labels: {
						formatter: (value) => {
							if(value === null) return null
							return value.toLocaleString()
						},
					},
				},
				colors: [],
				stroke: {
					curve: "straight",
					width: 2,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
				tooltip: {
					x: {
						format: 'dd MMMM yyyy',
					}
				},
			},
		}
	},
	computed: {

	},
	methods: {
		setChartCadence() {
			let mths = ['monthly','m','mth','month'];
			let wks = ['weekly','w','wk','week'];
			if(mths.includes(this.cadence)) this.chartoptions.tooltip.x.format = 'MMMM yyyy';
			else if(wks.includes(this.cadence)) {
				this.chartoptions.tooltip.x.formatter = function(value, series) {
					if(series.w) {
						// console.log(series);
						return series.w.config.series[series.seriesIndex].data[series.dataPointIndex].x;
					}
				}
				this.chartoptions.tooltip.x.format = 'dd MMMM yyyy';
			}
			else this.chartoptions.tooltip.x.format = 'dd MMMM yyyy';
		},
		// updateCategories() { //need to do this manually as data is nested
		// 	var oldxaxis = this.chartoptions.xaxis;
		// 	oldxaxis.categories = this.categories;
		// 	this.chartoptions = {...this.chartoptions, ...{
		// 			xaxis: oldxaxis
		// 		}};
		// },
	},
	watch: {
		cadence() {
			this.setChartCadence();
		},
		categories() {
			// this.updateCategories();
		},
	},
	created() {
		if(this.forcelinetype && this.forcelinetype === true) {
			this.chartoptions.fill = {};
			this.mytype = 'line';
			this.chartoptions.chart.type = 'line';
		}
	},
	mounted() {
		this.setChartCadence();
		this.chartoptions.colors = this.store.chartColourGroupARNFirst
		// this.updateCategories();
	}
}
</script>

<style scoped>

</style>