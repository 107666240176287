<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>

		<div v-if="userHasPermission(viewPerms) && metaReceived" class="top-selections">
			<div>
				<p class="above-select-label">Lookup Type</p>
				<select class="select-styled" v-model="lookupType">
					<option value="station">Station</option>
					<option value="segment">Segment</option>
				</select>
			</div>
			<div v-if="lookupType === 'station'">
				<p class="above-select-label">Station</p>
				<div style="width: 300px; display: flex; align-items: center; justify-content: flex-end;">
					<Autocompleter :searchlist="stations" :incomingval="chosenStation"
						idfldname="id" textfldname="name" v-on:update="chosenStation = $event['id']"
					></Autocompleter>
				</div>
			</div>
			<div v-if="lookupType === 'segment'">
				<p class="above-select-label">Segment</p>
				<select class="select-styled" v-model="chosenSegment">
					<option v-for="opt in segments" :key="opt.id" :value="opt.id">{{opt.name}}</option>
				</select>
			</div>
			<div>
				<p class="above-select-label">Statistic Type</p>
				<select class="select-styled" v-model="chosenStatistic">
					<option v-for="opt in statisticOptions" :key="opt.id" :value="opt.id">{{opt.name}}</option>
				</select>
			</div>
			<div>
				<p class="above-select-label">Reporting Period</p>
				<select class="select-styled" v-model="chosenPeriod">
					<option v-for="opt in periods" :key="opt.id" :value="opt.id">{{opt.name}}</option>
				</select>
			</div>
			<div>
				<p class="above-select-label">Period Start</p>
				<select class="select-styled" v-model="startdayselect">
					<option v-for="opt in startdayselects" :key="opt" :value="opt">{{opt}}</option>
				</select>
			</div>
			<div>
				<p class="above-select-label">Period End</p>
				<select class="select-styled" v-model="enddayselect">
					<option v-for="opt in enddayselects" :key="opt" :value="opt">{{opt}}</option>
				</select>
			</div>
			<div style="display: flex; align-items: flex-end;">
				<span class="primary-btn" @click="getChartData">Fetch</span>
			</div>
		</div>

		<div class="chart-rows">
			<ChartHolderDash
					:charttitle="chartsTitle"
					:source="chartsSource" :height="600">
				<template v-slot:default>
					<ApexLineWeeklyTrend :forcelinetype="false" :chartdata="chartsData" :cadence="chosenPeriod" filename="Streaming" :fetching="getChartsBtnWorking"></ApexLineWeeklyTrend>
				</template>
			</ChartHolderDash>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ApexLineWeeklyTrend from "@/components/charts/ApexLineWeeklyTrend";
import PermissionChecker from "@/components/PermissionChecker";
import Autocompleter from "@/components/Autocompleter";

export default {
	name: 'IHRChartExplorer',
	components: {
		Autocompleter,
		PermissionChecker,
		ApexLineWeeklyTrend,
		ChartHolderDash,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','content-metro','connections','iheart'],
			metaReceived: false,
			getChartsBtnWorking: false,
			chartsData: [],
			chartsSource: '',
			chartsTitle: '',
			tablehtml: '',

			stations: [],
			segments: [],
			monthstarts: [],
			monthends: [],
			weekstarts: [],
			weekends: [],

			chart: {},

			statisticOptions: [
				{id: 'cume', name: 'Cume'},
				{id: 'uniques', name: 'Uniques'},
				{id: 'tlh', name: 'TLH'},
				{id: 'streamstart', name: 'Streams Started'},
				{id: 'visits', name: 'Visits'},
			],

			chosenStation: 2399324738, //KIIS1065
			chosenSegment: 8,
			chosenStatistic: 'tlh',
			chosenPeriod: 'monthly',
			lookupType: 'station',
			startdayselect: '',
			enddayselect: '',
			groupStationsTogether: true,

			defaultWeekStart: '',
			defaultWeekEnd: '',
			defaultMonthStart: '',
			defaultMonthEnd: '',

			menu: false,
			menu2: false,
		}
	},
	computed: {
		periods() {
			let periods = [{id: 'monthly', name: 'Monthly'}]
			if(this.lookupType === 'segment') {
				periods.push({id: 'weekly', name: 'Weekly'})
			}
			return periods
		},
		startdayselects() {
			if(this.chosenPeriod === 'monthly') return this.monthstarts;
			else return this.weekstarts;
		},
		enddayselects() {
			if(this.chosenPeriod === 'monthly') return this.monthends;
			else return this.weekends;
		},
	},
	methods: {
		getChartData() {
			this.getChartsBtnWorking = true;
			let str = "statistic="+this.chosenStatistic+"&station="+this.chosenStation+"&segment="+this.chosenSegment+"&lookupType="+this.lookupType;
			str += "&period="+this.chosenPeriod+"&startdate=" + this.startdayselect + "&enddate=" + this.enddayselect;
			let gt = 1;
			if(this.groupStationsTogether === false) gt = 0;
			str += '&grouptogether='+gt;
			let self = this;
			let targ = "/api/iheart/get-explorer-data.php?"+str;
			this.chartsData = [];
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.chartsData != null) {
						self.chartsData = ret.chartsData;
					}
					if (ret.chartsSource != null) {
						self.chartsSource = ret.chartsSource;
					}
					if (ret.chartsTitle != null) {
						self.chartsTitle = ret.chartsTitle;
					}
					if (ret.table != null) {
						self.tablehtml = ret.table;
					}
				}
				self.getChartsBtnWorking = false;
			}).catch(function (error) {
				console.log(error);
			});
		},
		getMeta() {
			var self = this;
			var targ = "/api/iheart/get-meta.php";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.appinfo != null) {
						var a = ret.appinfo;
						self.stations = a['ihrStations'];
						self.segments = a['ihrSegments'];
						self.monthstarts = a['streamingMonthStarts'];
						self.monthends = a['streamingMonthEnds'];
						self.weekstarts = a['streamingWeekStarts'];
						self.weekends = a['streamingWeekEnds'];
						self.startdayselect = a['streamingDefaultMonthStart'];
						self.enddayselect = a['streamingDefaultMonthEnd'];
						self.defaultWeekStart = a['streamingDefaultWeekStart'];
						self.defaultWeekEnd = a['streamingDefaultWeekEnd'];
						self.defaultMonthStart = a['streamingDefaultMonthStart'];
						self.defaultMonthEnd = a['streamingDefaultMonthEnd'];
						self.metaReceived = true
						self.getChartData()
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		chosenPeriod() {
			if(this.chosenPeriod === 'weekly') {
				this.startdayselect = this.defaultWeekStart;
				this.enddayselect = this.defaultWeekEnd;
			}
			else if(this.chosenPeriod === 'monthly') {
				this.startdayselect = this.defaultMonthStart;
				this.enddayselect = this.defaultMonthEnd;
			}
			this.getChartData()
		},
		lookupType() {
			if(this.lookupType === 'station') {
				this.chosenPeriod = 'monthly'
			}
		},
		chosenStation() {
			this.getChartData()
		},
		chosenSegment() {
			this.getChartData()
		},
		chosenStatistic() {
			this.getChartData()
		},
	},
	mounted() {

	},
	created() {
		this.getMeta();
		document.title = "ARN iHeartRadio Chart Explorer";
	},
}
</script>

<style scoped>
.top-selections {
	display: flex;
	justify-content: flex-start;
	gap: 15px;
}

@media screen and (max-width: 1450px) {
	.top-selections {
		flex-wrap: wrap;
	}
}
</style>
