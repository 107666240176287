<template>
	<div v-if="collatedMarketData.length > 0" style="width: 100%; height: 100%;">
		<ApexCropCircles :chartdata="chartdataCropCircle" :chartcolors="chartcolors" :fetching="fetchingcharts"></ApexCropCircles>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ApexCropCircles from "@/components/arnmarkets/ApexCropCircles.vue";

export default {
	name: "CropCircles",
	components: {
		ApexCropCircles

	},
	props: {
		source: String, //gfk or xtra
		indata: Array,
		stnids: Array,
		fetchingcharts: Boolean,
		mktob: Object,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			collatedMarketData: [], //xtra data

			chartdataCropCircle: [
				{name: 'Station', data: [{x: 0, y: 0, z: 0}]}, //x pct fem, y age
			],
			chartcolors: [],
		}
	},
	computed: {},
	methods: {
		loadData() {
			this.collatedMarketData = this.indata;
			if(this.collatedMarketData.length > 0) {
				this.prepareMFSplits();
			}
		},
		prepareMFSplits() {
			let demos = [50,49]; //female, male
			let stnobs = []; //for crop circles
			let rawFems = {}; //for crop circles
			let totcumes = {}; //for crop circles
			let pctFems = {}; //for crop circles
			let avgAges = {}; //for crop circles
			let latestSurvOb = this.store.surveys.find(item => item.id === this.mktob.latestSurveyId);
			for(let d=0; d<demos.length; d++) {
				for (let s = 0; s < this.stnids.length; s++) {
					let stid = this.stnids[s];
					let stnob = this.store.stations.find(item => item.id === stid);
					let ob = this.collatedMarketData.find(item => item.demoId === demos[d] && item.daypartId === 1 && item.statistic === 'cume' && item.survcode === latestSurvOb.id);
					if(ob) {
						let cell = ob.data.find(item => item.stid === stid)
						if(cell) {
							if (d === 0) { //crop circle working
								stnobs.push(stnob);
								rawFems[stid] = cell.val;
							}
							else if (d === 1) {
								totcumes[stid] = (rawFems[s] + cell.val) * 100;
								pctFems[stid] = Math.round((rawFems[stid] / (rawFems[stid] + cell.val)) * 100);
								let ageob = this.collatedMarketData.find(item => item.demoId === 48 && item.daypartId === 1 && item.statistic === 'avgAge' && item.survcode === latestSurvOb.id);
								if(ageob) {
									let agecell = ageob.data.find(item => item.stid === stid)
									if(agecell) {
										avgAges[stid] = agecell.val
									}
								}
							}
						}
					}
				}
			}

			let mycolors = [];
			//now prepare crop circle info
			let cropdata = [];
			this.store.defaultColorCount = 0
			let largestcume = Math.max.apply(null, totcumes);
			for(let st of stnobs) {
				let stid = st.id;
				let zsize = 50;
				if(totcumes[stid] && totcumes[stid] > 0) {
					zsize = Math.round((totcumes[stid] / largestcume) * 50);
				}
				let item = {
					name: st.name,
					data: [{ x: pctFems[stid], y: avgAges[stid], z: zsize }],
				};
				mycolors.push(this.getChartColorForStation(st, mycolors));
				cropdata.push(item);
			}
			this.chartcolors = mycolors;
			this.chartdataCropCircle = cropdata;
		},
	},
	watch: {
		indata() {
			this.loadData();
		}
	},
	mounted() {
		this.loadData();
	}
}
</script>

<style scoped>

</style>