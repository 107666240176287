<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">
			<div class="chart-rows">
				<ChartHolderDash charttitle="Playlists Cume by day" :height="420" :source="chartsSource.a_cumeByDay" :addclasses="'half'">
					<template v-slot:default>
						<ApexLineTrend :chartdata="chartsData.a_cumeByDay" :fetching="getChartsBtnWorking"></ApexLineTrend>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Playlists Stream Starts by day" :height="420" :source="chartsSource.a_ssByDay" :addclasses="'half'">
					<template v-slot:default>
						<ApexLineTrend :chartdata="chartsData.a_ssByDay" :fetching="getChartsBtnWorking"></ApexLineTrend>
					</template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash charttitle="Playlists TLH by day" :height="420" :source="chartsSource.a_tlhByDay" :addclasses="'half'">
					<template v-slot:default>
						<ApexLineTrend :chartdata="chartsData.a_tlhByDay" :fetching="getChartsBtnWorking"></ApexLineTrend>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Playlists Cume by Type (by week)" :height="420" :source="chartsSource.b_cumeTypeWeek" addclasses="half">
					<template v-slot:default>
						<ApexLineTrendMulti :chartdata="chartsData.b_cumeTypeWeek" :fetching="getChartsBtnWorking"></ApexLineTrendMulti>
					</template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash charttitle="Playlists Stream Starts by Type (by week)" :height="420" :source="chartsSource.b_ssTypeWeek" addclasses="half">
					<template v-slot:default>
						<ApexLineTrendMulti :chartdata="chartsData.b_ssTypeWeek" :fetching="getChartsBtnWorking"></ApexLineTrendMulti>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Playlists TLH by Type (by week)" :height="420" :source="chartsSource.b_tlhTypeWeek" addclasses="half">
					<template v-slot:default>
						<ApexLineTrendMulti :chartdata="chartsData.b_tlhTypeWeek" :fetching="getChartsBtnWorking"></ApexLineTrendMulti>
					</template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash charttitle="Content Type Compare (cume by day)" :height="420" :source="chartsSource.c_cumeAssetDay" addclasses="">
					<template v-slot:default>
						<ApexLineTrendMulti :chartdata="chartsData.c_cumeAssetDay" :fetching="getChartsBtnWorking"></ApexLineTrendMulti>
					</template>
				</ChartHolderDash>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from 'axios'
import PermissionChecker from "@/components/PermissionChecker";
import ChartHolderDash from "@/components/ChartHolderDash";
import ApexLineTrend from "@/components/charts/ApexLineTrend.vue";
import ApexLineTrendMulti from "@/components/charts/ApexLineTrendMulti.vue";
export default {
	name: "IHRPlaylistDash",
	components: {
		ApexLineTrendMulti,
		ApexLineTrend,
		PermissionChecker,
		ChartHolderDash,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			viewPerms: ['elt','connections','iheart'],
			getChartsBtnWorking: true,

			chartsData: [],
			chartsSource: [],

		}
	},
	computed: {

	},
	methods: {
		getData() {
			let self = this;
			let targ = "/api/iheart/get-playlists.php";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					if (ret.chartsData) self.chartsData = ret.chartsData
					if (ret.chartsSource) self.chartsSource = ret.chartsSource
					self.getChartsBtnWorking = false
				}
			}).catch(function (error) {
				console.log(error);
			});
		},

	},
	watch: {},
	mounted() {
		document.title = 'iHeartRadio Playlists Dashboard'
		this.getData()
	}
}
</script>

<style scoped>

</style>