<template>
	<div class="podlistenerwarn">
		<p>The "Listeners" metric cannot be added across lines.  This is a unique figure per show (like Cume for GFK data), so please only use published Listeners numbers rather than adding them together.</p>
	</div>
</template>

<script>

export default {
	name: "PodListenerWarn",
	components: {},
	props: {},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.podlistenerwarn {
	margin: -10px 0 20px;
	width: 100%;
	padding: 20px 30px;
	border-radius: 10px;
	background-color: #A398CE;
	color: #FFF;
}
</style>