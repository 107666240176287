<template>
	<div>
		<div class="main-info">
			<div class="third">
				<div class="info"><span class="lbl">Client Name</span><input v-model="campaign.client_name" /></div>
				<div class="info"><span class="lbl">Campaign Name</span><input v-model="campaign.campaign_name" /></div>
			</div>
			<div class="third">
				<div class="info">
					<span class="lbl">Start Date</span>
					<date-picker class="dpick" v-model:value="campaign.camp_start_date" valueType="format" format="YYYY-MM-DD"></date-picker>
				</div>
				<div class="info">
					<span class="lbl">End Date</span>
					<date-picker class="dpick" v-model:value="campaign.camp_end_date" valueType="format" format="YYYY-MM-DD"></date-picker>
				</div>
			</div>
			<div class="third">
				<div class="info"><span class="lbl">Budget Estimate</span><input type="number" v-model="campaign.budget_estimate" /></div>
				<div class="info"><span class="lbl">Target Demographic</span><input v-model="campaign.demographic_text" /></div>
			</div>
		</div>
		<div class="grand-totals">
			<div class="third">
				<div class="info">
					<span class="lbl bold">Total Spots</span>
					<span>{{numdisplay(campaign.totalSpots)}}</span>
				</div>
			</div>
			<div class="third">
				<div class="info">
					<span class="lbl bold">Total Value</span>
					<span>${{numdisplay(campaign.totalValue,2)}}</span>
				</div>
			</div>
			<div class="third">
				<div class="info">
					<span class="lbl bold">Total Cost</span>
					<span>${{numdisplay(campaign.totalCost,2)}}</span>
				</div>
			</div>
		</div>

		<SalesCampaignStationItem v-for="(c, ind) in campaign.station_rows" :key="ind"
			:stations="stations" :rates="rates" :commercialdayparts="commercialDayparts"
			:stationindex="ind"
		></SalesCampaignStationItem>

		<button class="primary-btn" @click="addNewStationRow">Add Station</button>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import SalesCampaignStationItem from "@/components/sales-campaigns/SalesCampaignStationItem.vue";
import DatePicker from 'vue-datepicker-next'

export default {
	name: "SalesCampaignPlanner",
	components: {
		SalesCampaignStationItem,
		DatePicker,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			isFetching: false,

			stations: [],
			stationAliasMaps: [],
			dayparts: [],
			commercialDayparts: [],
			rates: [],

		}
	},
	computed: {
		campaign: {
			get() {
				return this.store.sales_campaign
			},
			set(newval) {
				this.store.sales_campaign = newval
			}
		},
	},
	methods: {
		addNewStationRow() {
			this.store.sales_campaign.station_rows.push({
				stationOb: null,
				airtimeRows: [],
				addValueRows: [],
				otherRows: [],
				totalSpots: null,
				totalValue: null,
				totalCost: null,
			})
		},
		// fetchData() {
		// 	let self = this;
		// 	let targ = "/api/sales-tools/geo-lookup.php";
		// 	this.isFetching = true
		// 	axios.post(targ, JSON.stringify({
		// 		reportType: self.reportType,
		// 		pasteData: self.pastedata,
		// 		selectedLapname: self.selectedLapname
		// 	})).then(function (response) {
		// 		let ret = response.data; //response.data is returned info
		// 		if (ret.tbldata) self.tbldata = ret.tbldata
		// 		else self.tbldata = []
		// 		self.isFetching = false
		// 		self.pageview = 'results'
		// 	}).catch(function () {
		// 		self.isFetching = false
		// 	});
		// },
		getMeta() {
			let self = this;
			let targ = "/api/sales-campaign-planner/get-initial.php";
			this.isFetching = true
			axios.post(targ, JSON.stringify({

			})).then(function (response) {
				let ret = response.data
				self.stations = []
				if (ret.stations) self.stations = ret.stations
				if (ret.stationAliasMaps) self.stationAliasMaps = ret.stationAliasMaps
				if (ret.commercialDayparts) self.commercialDayparts = ret.commercialDayparts
				if (ret.rates) self.rates = ret.rates

				self.isFetching = false
			}).catch(function () {
				self.isFetching = false
			});
		},
	},
	watch: {
		campaign: {
			handler() {
				//total campaign spots
				let total = 0
				for(let sr of this.store.sales_campaign.station_rows) {
					total += sr.totalSpots
				}
				this.campaign.totalSpots = total
				//total campaign value
				total = 0
				for(let sr of this.store.sales_campaign.station_rows) {
					total += sr.totalValue
				}
				this.campaign.totalValue = total
				//total campaign cost
				total = 0
				for(let sr of this.store.sales_campaign.station_rows) {
					total += sr.totalCost
				}
				this.campaign.totalCost = total
			},
			deep: true
		}
	},
	mounted() {
		document.title = 'Campaign Planner'
		this.getMeta()
		if(this.store.sales_campaign.station_rows.length === 0) {
			this.addNewStationRow()
		}
	}
}
</script>

<style scoped>
.main-info, .grand-totals {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
}
.third {
	width: 30%;
}
.info {
	padding: 5px 0;
}
.info .lbl {
	display: inline-block;
	width: 150px;
	margin-bottom: 5px;
}
.info input {
	width: 200px;
	padding: 8px 10px 9px;
	border: 1px solid #CCC;
}
.info input:hover, .info input:focus, .info input:active {
	border: 1px solid #409aff;
}
.info .dpick {
	width: 200px;
}
.primary-btn {
	padding: 10px 20px;
	background-color: #4E39A3;
	color: white;
	border: none;
	cursor: pointer;
	border-radius: 10px;
}
</style>