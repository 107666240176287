<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">
			<h2>Studies</h2>
			<br/>
			<div v-for="st in this.brandstudies" :key="st.id">
				<a class="link-text" style="line-height: 1.5;" @click="$router.push('/brand-tracking/study-summary/?studyid='+st.id)">{{st.title}} ({{st.nicedate}})</a>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import PermissionChecker from "@/components/PermissionChecker";

export default {
	name: 'BrandTrackingStudyList',
	components: {
		PermissionChecker

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','connections','content-metro'],
			updating: false,
			brandstudies: [],
		}
	},
	computed: {

	},
	methods: {
		getStudyList() {
			this.updating = true;
			var self = this;
			var targ = "/api/brand-tracking-get-study-list.php";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					if (ret.brandstudies != null) {
						self.brandstudies = ret.brandstudies;
					}
				}
				setTimeout(function() {
					self.updating = false;
				}.bind(self), 1000);
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {

	},
	created() {
		document.title = "ARN Brand Tracking Studies";
		this.getStudyList();
	},
}
</script>

<style scoped>

</style>
