<template>
	<div class="view-holder">
		<MarketSummary v-if="mktob" :mktob="mktob" :notpopup="true"></MarketSummary>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import MarketSummary from "@/components/arnmarkets/MarketSummary";

export default {
	name: "MarketSingle",
	components: {
		MarketSummary,
	},
	props: {

	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			mktob: null,
		}
	},
	computed: {

	},
	methods: {
		preparePage(param_marketname) {
			this.mktob = this.store.markets.find(item => this.convertToUrlName(item.name) === param_marketname);
			document.title = this.mktob.name;
		},
	},
	watch: {

	},
	mounted() {
		let urlparams = this.$route.params;
		if(urlparams.marketname) {
			if(this.store.markets.length > 0) {
				this.preparePage(urlparams.marketname);
			}
			else { //probably an F5 - waiting on the fetch for stations/market obs
				setTimeout(function() {
					this.preparePage(urlparams.marketname);
				}.bind(this), 1000);
			}
		}
	}
}
</script>
<style>

</style>
<style scoped>
.view-holder {
	margin: 0 20px;
	position: relative;
}
</style>