<template>
	<div>
		<div class="chart-rows">
			<ChartHolderDash
					:charttitle="'Total Website Pageviews by month'"
					:source="chartsSource.monthly_pageviews" :addclasses="'half'">
				<template v-slot:default><ApexLineMonthlyTrend :chartdata="apexdata.monthly_pageviews" filename="MonthlyPageviews" :fetching="getChartsBtnWorking"></ApexLineMonthlyTrend></template>
			</ChartHolderDash>

			<ChartHolderDash
					:charttitle="'Total Website Unique Users by month'"
					:source="chartsSource.monthly_uniques" :addclasses="'half'" :height="400">
				<template v-slot:default><ApexLineMonthlyTrend :chartdata="apexdata.monthly_uniques" filename="MonthlyWebUsers" :fetching="getChartsBtnWorking"></ApexLineMonthlyTrend></template>
			</ChartHolderDash>

			<ChartHolderDash
					:charttitle="'Total Facebook Page Impressions by month'"
					:source="chartsSource.monthly_fb_impressions" :addclasses="'half'">
				<template v-slot:default><ApexLineMonthlyTrend :chartdata="apexdata.monthly_fb_impressions" filename="MonthlyFBImpressions" :fetching="getChartsBtnWorking"></ApexLineMonthlyTrend></template>
			</ChartHolderDash>

			<ChartHolderDash
					:charttitle="'Total Facebook Engagement by month'"
					:source="chartsSource.monthly_fb_engagement" :addclasses="'half'" :height="400">
				<template v-slot:default><ApexLineMonthlyTrend :chartdata="apexdata.monthly_fb_engagement" filename="MonthlyFBEngagements" :fetching="getChartsBtnWorking"></ApexLineMonthlyTrend></template>
			</ChartHolderDash>

			<ChartHolderDash
				:charttitle="'Total Facebook Video Views by month'"
				:source="chartsSource.monthly_fb_videoviews" :addclasses="'half'" :height="400">
				<template v-slot:default><ApexLineMonthlyTrend :chartdata="apexdata.monthly_fb_videoviews" filename="MonthlyFBVideoViews" :fetching="getChartsBtnWorking"></ApexLineMonthlyTrend></template>
			</ChartHolderDash>

			<ChartHolderDash
				:charttitle="'Total Instagram Video Views by month'"
				:source="chartsSource.monthly_insta_videoviews" :addclasses="'half'" :height="400">
				<template v-slot:default><ApexLineMonthlyTrend :chartdata="apexdata.monthly_insta_videoviews" filename="MonthlyInstaVideoViews" :fetching="getChartsBtnWorking"></ApexLineMonthlyTrend></template>
			</ChartHolderDash>

			<ChartHolderDash
				:charttitle="'Total TikTok Video Views by month'"
				:source="chartsSource.monthly_tiktok_videoviews" :addclasses="'half'" :height="400">
				<template v-slot:default><ApexLineMonthlyTrend :chartdata="apexdata.monthly_tiktok_videoviews" filename="MonthlyTTVideoViews" :fetching="getChartsBtnWorking"></ApexLineMonthlyTrend></template>
			</ChartHolderDash>

			<ChartHolderDash
				:charttitle="'Total Twitter Video Views by month'"
				:source="chartsSource.monthly_twitter_videoviews" :addclasses="'half'" :height="400">
				<template v-slot:default><ApexLineMonthlyTrend :chartdata="apexdata.monthly_twitter_videoviews" filename="MonthlyTwitterVideoViews" :fetching="getChartsBtnWorking"></ApexLineMonthlyTrend></template>
			</ChartHolderDash>

			<ChartHolderDash
					:charttitle="'Web Traffic by site/asset'"
					:source="chartsSource.monthly_table" :height="500">
				<div class="b-table-holder">
					<ALTable tblclasses="noborderv" :excel="true" excelfilename="Web Traffic"
						:cols="[
							{title: 'Page', sortable: true, fldid: 'cat'},
							{title: 'Current Pageviews', sortable: true, fldid: 'mthdl', align: 'right', numtype: 'number'},
							{title: 'Prev Pageviews', sortable: true, fldid: 'mthprvdl', align: 'right', numtype: 'number'},
							{title: 'MoM Change Pageviews', sortable: true, fldid: 'pctdiff', align: 'right', numtype: 'pct'},
							{title: 'Current Users', sortable: true, fldid: 'mthlist', align: 'right', numtype: 'number'},
							{title: 'Prev Users', sortable: true, fldid: 'mthprvlist', align: 'right', numtype: 'number'},
							{title: 'MoM Change Users', sortable: true, fldid: 'pctdifflist', align: 'right', numtype: 'pct'},
						]"
						:rowdata="chartsData.monthly_table"
					></ALTable>
				</div>
				<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
			</ChartHolderDash>

			<ChartHolderDash
					:charttitle="'Facebook Traffic by site/asset'"
					:source="chartsSource.monthly_fbtable" :height="900">
				<div class="b-table-holder">
					<ALTable tblclasses="noborderv" :excel="true" excelfilename="Facebook"
						:cols="[
							{title: 'Station/Show', sortable: true, fldid: 'cat'},
							{title: 'Current Impressions', sortable: true, fldid: 'mthdl', align: 'right', numtype: 'number'},
							{title: 'Prev Impressions', sortable: true, fldid: 'mthprvdl', align: 'right', numtype: 'number'},
							{title: 'MoM Change Impressions', sortable: true, fldid: 'pctdiff', align: 'right', numtype: 'pct'},
							{title: 'Current Engagement', sortable: true, fldid: 'mthlist', align: 'right', numtype: 'number'},
							{title: 'Prev Engagement', sortable: true, fldid: 'mthprvlist', align: 'right', numtype: 'number'},
							{title: 'MoM Change Engagement', sortable: true, fldid: 'pctdifflist', align: 'right', numtype: 'pct'},
						]"
						:rowdata="chartsData.monthly_fbtable"
					></ALTable>
				</div>
				<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
			</ChartHolderDash>

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ApexLineMonthlyTrend from "@/components/charts/ApexLineMonthlyTrend";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import ALTable from "@/components/ALTable";

export default {
	name: 'WebMetroDashboard',
	components: {
		ALTable,
		ChartLoaderInsert,
		ApexLineMonthlyTrend,
		ChartHolderDash,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			chartId: 0,
			chartsData: {},
			chartsSource: [],
			getChartsBtnWorking: false,

			apexdata: {},
			apexlabels: {},

		}
	},
	computed: {

	},
	methods: {
		getCharts() {
			this.getChartsBtnWorking = true;
			let self = this;
			let targ = "/api/web-dashboard.php?group=metro";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					if(ret.apexdata) {
						self.apexdata = ret.apexdata;
					}
					if(ret.apexlabels) {
						self.apexlabels = ret.apexlabels;
					}
					if (ret.chartsData != null) {
						self.chartsData = ret.chartsData;
					}
					if (ret.chartsSource != null) {
						self.chartsSource = ret.chartsSource;
					}
					if (ret.h != null) {
						for (var key in ret.h) { //this updates any that exist, adds new if needed, but does not delete existing
							self.slotprops[key] = ret.h[key];
						}
					}
					//custom callback functions here if required (using pageaction as filter)
				}
				self.getChartsBtnWorking = false;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {},
	props: {

	},
	mounted() {
		this.getCharts();
		document.title = "Metro Web Dashboard";
	},
	created() {

	},
}
</script>

<style scoped>

</style>
