<template>
	<div>
		<div style="display: flex; justify-content: flex-start; gap: 15px;">
			<div>
				<select class="select-styled" v-model="chosenMarket">
					<option v-for="opt in markets" :key="opt.id" :value="opt.id">{{opt.name}}</option>
				</select>
			</div>
			<div>
				<select class="select-styled" v-model="chosenDaypart">
					<option v-for="opt in dropdownDayparts" :key="opt.id" :value="opt.id">{{opt.name}}</option>
				</select>
			</div>
			<div>
				<select class="select-styled" v-model="chosenDemographic">
					<option v-for="opt in demographics" :key="opt.id" :value="opt.id">{{opt.name}}</option>
				</select>
			</div>
			<div>
				<select class="select-styled" v-model="chosenStatistic">
					<option v-for="opt in statistics" :key="opt" :value="opt">{{opt}}</option>
				</select>
			</div>
			<div>
				<select class="select-styled" v-model="chosenFirstSurvey">
					<option v-for="opt in surveysNewToOld" :key="opt" :value="opt">{{opt}}</option>
				</select>
			</div>
			<div>
				<select class="select-styled" v-model="chosenLastSurvey">
					<option v-for="opt in surveysNewToOld" :key="opt" :value="opt">{{opt}}</option>
				</select>
			</div>
<!--			<div><span class="primary-btn" @click="getChartData">Fetch</span></div>-->
		</div>

		<div style="margin: 20px 0 20px;">
			<div style="margin-bottom: 10px; display: flex; justify-content: space-between;">
				<div v-if="chosenMarket === 1" data-marketname="Perth">
					<span class="netshortcut" @click="chosenStations = perthDefaultStations">Commercial</span>
					<span class="netshortcut" @click="chosenStations = [1,2,3,575,5,6,7,8,9,10,11]">All Non DAB</span>
					<span class="netshortcut" @click="chosenStations = [5,1991]">ARN Only</span>
				</div>
				<div v-if="chosenMarket === 2" data-marketname="Adelaide">
					<span class="netshortcut" @click="chosenStations = adelaideDefaultStations">Commercial</span>
					<span class="netshortcut" @click="chosenStations = [43,44,45,46,47,48,227,49,50,51,52,53]">All Non DAB</span>
					<span class="netshortcut" @click="chosenStations = [45,227,1993]">ARN Only</span>
				</div>
				<div v-if="chosenMarket === 3" data-marketname="Brisbane">
					<span class="netshortcut" @click="chosenStations = brisbaneDefaultStations">Commercial All</span>
					<span class="netshortcut" @click="chosenStations = [83,1467,85,547,87,88,89,90,91,92,93,243,224]">All Non DAB</span>
					<span class="netshortcut" @click="chosenStations = [87,85,1995]">ARN Only</span>
				</div>
				<div v-if="chosenMarket === 4" data-marketname="Melbourne">
					<span class="netshortcut" @click="chosenStations = melbourneDefaultStations">Commercial FMs</span>
					<span class="netshortcut" @click="chosenStations = [244,127,128,129,130,131,245,132,134,135,238]">Commercial All</span>
					<span class="netshortcut" @click="chosenStations = [127,128,129,130,244,238,245,132,134,135,136,137,138,139,140]">All Non DAB</span>
					<span class="netshortcut" @click="chosenStations = [245,132,2000]">ARN Only</span>
				</div>
				<div v-if="chosenMarket === 5" data-marketname="Sydney">
					<span class="netshortcut" @click="chosenStations = sydneyDefaultStations">Commercial FMs</span>
					<span class="netshortcut" @click="chosenStations = [247,177,178,536,182,241,184,185,186,239,612]">Commercial All</span>
					<span class="netshortcut" @click="chosenStations = [247,177,178,536,182,241,184,185,186,187,188,189,190,191,239,612]">All Non DAB</span>
					<span class="netshortcut" @click="chosenStations = [241,186,2003]">ARN Only</span>
				</div>
				<div v-if="chosenMarket === 21">
					<span class="netshortcut" @click="chosenStations = smbapDefaultStations">Networks excl DAB</span>
					<span class="netshortcut" @click="chosenStations = [485,486,487,558,1475,1473]">Networks incl DAB</span>
					<span class="netshortcut" @click="chosenStations = [476,478,479,480,481,490]">Sub Networks</span>
				</div>
				<div v-if="chosenMarket === 23" data-marketname="Gold Coast">
					<span class="netshortcut" @click="chosenStations = goldcoastDefaultStations">Commercial FMs</span>
					<span class="netshortcut" @click="chosenStations = [1517,1516,1512,1505,1507,1508,1509,1513,1518,1515,1502,1503,1504]">All</span>
					<span class="netshortcut" @click="chosenStations = [1497,1498,1499]">Networks</span>
				</div>
				<div v-if="chosenMarket === 24" data-marketname="Canberra">
					<span class="netshortcut" @click="chosenStations = canberraDefaultStations">Commercial Stations</span>
					<span class="netshortcut" @click="chosenStations = [1535,1526,1523,1524,1527,1528,1529,1530,1531]">All</span>
					<span class="netshortcut" @click="chosenStations = [1521,1522]">Networks</span>
				</div>
				<div v-if="chosenMarket === 25" data-marketname="Newcastle">
					<span class="netshortcut" @click="chosenStations = newcastleDefaultStations">Commercial Stations</span>
					<span class="netshortcut" @click="chosenStations = [1538,1540,1548,1536,1541,1542,1543,1544,1545]">All</span>
					<span class="netshortcut" @click="chosenStations = [1549,1550]">Networks</span>
				</div>
				<div>
					<span class="netshortcut" @click="selectAllSurveys">All Surveys</span>
				</div>
			</div>
			<div v-for="st in stationsInMarket" :key="st.id" style="display: inline-block; margin-bottom: 8px;">
				<input style="margin-right: 5px;" type="checkbox" v-model="chosenStations" :id="'stn'+st.stid" :value="st.stid" />
				<label style="margin-right: 15px;" :for="'stn'+st.stid">{{st.niceName}}</label>
			</div>
			<span class="netshortcut" @click="showStationSelectOverlay = true">More</span>

			<div v-if="showStationSelectOverlay" class="loader-overlay">
				<div class="stationselect-popup whitebg">
					<div class="popup-topbar">
						<span class="boldme">Choose Stations</span>
						<span class="closer" @click="showStationSelectOverlay = false"><i class="mdi mdi-close"></i></span>
					</div>
					<div style="width: 100%;" class="popup-insides">
						<div v-for="st in allStationsInMarket" :key="st.id" style="display: inline-block; margin-bottom: 8px;">
							<input style="margin-right: 5px;" type="checkbox" v-model="chosenStations" :id="'stn'+st.stid" :value="st.stid" />
							<label style="margin-right: 15px;" :for="'stn'+st.stid">{{st.niceName}}</label>
						</div>
					</div>
				</div>
			</div>

		</div>

		<div class="chart-rows">
			<ChartHolderDash
					:charttitle="chartsTitle.ratings"
					:source="chartsSource.ratings" :height="500">
				<template v-slot:default>
					<apexchart v-if="chartdata.length > 0" width="99%" height="100%" type="line"
						:options="chartoptions" :series="chartdata"></apexchart>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</template>
			</ChartHolderDash>
		</div>

		<div class="gfktable">
			<div id="table_div">
				<div id="table_hold">
					<div class="table-container">
						<table>
							<thead>
								<tr>
									<td class="stnfix"></td>
									<td class="stnpad"></td>
									<td v-for="s in mycatnames" :key="s">{{s}}</td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="cd in chartdata" :title="cd.name" :key="cd.stationob.stid">
									<td class="stnfix">{{cd.name}}</td>
									<td class="stnpad"></td>
									<td v-for="(val, index) in cd.data" :key="index">{{tblCellNumDisplay(val.y)}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="highlight-toggles">
					<span onclick="highlightByRow();" class="link-text" style="margin-right: 5px; font-size: 12px;">Highlight By Row</span>
					<span onclick="highlightByCol();" class="link-text" style="margin-right: 5px; font-size: 12px;">Highlight By Column</span>
					<span onclick="clearHighlights();" class="link-text" style="font-size: 12px;">Clear Highlights</span>
					<span v-if="chosenMarket !== 21" @click="showMarketSummary" class="link-text" style="font-size: 12px; margin-left: 40px;">Market Summary Popup</span>
				</div>
			</div>
		</div>

		<div v-if="showSummaryView" class="loader-overlay">
			<div class="market-summary-view">
				<MarketSummary :aspopup="true" :mktob="summaryMarketOb" v-on:close="showSummaryView = false"></MarketSummary>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import MarketSummary from "@/components/arnmarkets/MarketSummary";

export default {
	name: 'RatingsCharts',
	components: {
		MarketSummary,
		ChartLoaderInsert,
		ChartHolderDash,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			getChartsBtnWorking: false,
			showSummaryView: false,
			summaryMarketOb: null,
			chartsSource: [],
			chartsTitle: [],

			markets: [],
			demographics: [],
			dayparts: [],
			stations: [],
			statistics: ['Share','Cume','TSL','AvgAud'],
			surveylist: [],
			surveylistgc: [],
			surveylistcbr: [],
			surveylistnew: [],

			perthDefaultStations: [5,3,6,575,2,1,1991],
			adelaideDefaultStations: [45,46,47,48,227,43,1993],
			brisbaneDefaultStations: [87,88,547,224,85,83,1467,1995],
			melbourneDefaultStations: [245,132,244,134,135,238,2000],
			sydneyDefaultStations: [241,186,185,184,239,247,2003],
			goldcoastDefaultStations: [1517,1516,1512],
			canberraDefaultStations: [1535,1526,1523,1524],
			newcastleDefaultStations: [1538,1540,1548,1536],
			smbapDefaultStations: [482,483,484,559,1473],

			chosenMarket: 21,
			chosenDemographic: 34,
			chosenDaypart: 1,
			chosenStations: [482,483,484,559,1473],
			chosenStatistic: 'Share',
			chosenFirstSurvey: "",
			chosenLastSurvey: "",

			chartoptions: {
				chart: {
					id: "ratings",
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					}
				},
				xaxis: {
					type: "category",
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 1,
					min: 0,
				},
				colors: [],
				stroke: {
					curve: "straight",
					width: 3,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
			},
			chartdata: [],
			mysurveylist: [],
			mycatnames: [],

			showStationSelectOverlay: false,
		}
	},
	computed: {
		stationsInMarket() {
			return this.stations.filter(
				item =>
					item.mktid === this.chosenMarket &&
					(
						(item.donotlist === 0 && item.isamfm === 1) || [1991,1993,1995,2000,2003].includes(item.stid) //edge/cada
					)
			);
		},
		allStationsInMarket() {
			return this.stations.filter(item => item.mktid === this.chosenMarket && item.donotlist === 0);
		},
		surveysNewToOld() {
			if(this.chosenMarket === 23) return this.surveylistgc.slice().reverse();
			else if(this.chosenMarket === 24) return this.surveylistcbr.slice().reverse();
			else if(this.chosenMarket === 25) return this.surveylistnew.slice().reverse();
			return this.surveylist.slice().reverse();
		},
		dropdownDayparts() {
			if(this.chosenMarket === 23) return this.dayparts;
			else {
				let excludes = [23,24,25,26];
				return this.dayparts.filter(item => !excludes.includes(item.id));
			}
		},
	},
	methods: {
		tblCellNumDisplay(rawval) {
			if(this.chosenStatistic === 'TSL') return this.numdisplay(rawval, 2)
			else if(this.chosenStatistic === 'Share') return this.numdisplay(rawval, 1)
			return rawval
		},
		getChartData() {
			if(this.getChartsBtnWorking === false && this.chosenFirstSurvey !== "" && this.chosenLastSurvey !== "" && this.chosenStations.length > 0) {
				this.getChartsBtnWorking = true;
				let firstsurvarr = this.chosenFirstSurvey.split(" ");
				let firstsurv = "y" + firstsurvarr[1] + firstsurvarr[0];
				let lastsurvarr = this.chosenLastSurvey.split(" ");
				let lastsurv = "y" + lastsurvarr[1] + lastsurvarr[0];
				let self = this;
				this.doGetGfkData(this.chosenMarket, this.chosenDemographic, this.chosenStatistic, this.chosenDaypart, this.chosenStations, firstsurv, lastsurv).then(function(ret) {
					if (ret.error === 1) {
						console.log(ret);
					}
					else {
						if (ret.chartsSource != null) self.chartsSource = ret.chartsSource;
						if (ret.chartsTitle != null) self.chartsTitle = ret.chartsTitle;
						if (ret.newcatnames != null) self.mycatnames = ret.newcatnames;
						if (ret.newdatawithkeys != null) {
							for(let stnarr of ret.newdatawithkeys) { //remove any surveys that only have zeroes from start of array
								let firstValueForStationFound = false;
								for(let survrec of stnarr.data) {
									if(survrec.y === 0 && firstValueForStationFound === false) {
										survrec.y = null;
									}
									else {
										firstValueForStationFound = true;
									}
								}
							}
							self.chartdata = ret.newdatawithkeys;
						}
					}
					self.getChartsBtnWorking = false;
				});
			}
		},
		getMeta() {
			let self = this;
			let targ = "https://gfk.arnconnect.com.au/post/arnstats/get-meta.php";
			axios.get(targ).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					if(ret.dayparts !== null) {
						self.dayparts = ret.dayparts;
						if(self.chosenDaypart === null) {
							self.chosenDaypart = self.dayparts[0];
						}
					}
					if(ret.markets !== null) {
						self.markets = ret.markets;
						if(self.chosenMarket === null) {
							self.chosenMarket = self.markets[0];
						}
					}
					if(ret.demographics !== null) {
						self.demographics = ret.demographics;
						if(self.chosenDemographic === null) {
							self.chosenDemographic = self.demographics[0];
						}
					}
					if(ret.stations !== null) {
						self.stations = ret.stations;
					}
					if (ret.surveys != null) {
						self.surveylist = ret.surveys;
					}
					if(ret.surveysgc !== null) {
						self.surveylistgc = ret.surveysgc;
						self.surveylistcbr = ret.surveyscbr;
						self.surveylistnew = ret.surveysnew;
					}
					if(self.chosenFirstSurvey === "") {
						self.updateSurveyChoices();
					}
				}
				self.getChartsBtnWorking = false;
				self.getChartData();
			}).catch(function (error) {
				console.log(error);
			});
		},
		updateColours() {
			let outcols = [];
			for(let i=0; i<this.chartdata.length; i++) {
				let gfkstnob = this.chartdata[i].stationob;
				let stnob = this.store.stations.find(item => item.alportid === gfkstnob.stid);
				if(stnob) {
					outcols.push(this.getChartColorForStation(stnob, outcols));
				}
				else { //use the GFK station lookup
					outcols.push(this.getChartColorForStation(gfkstnob, outcols));
				}
			}
			this.chartoptions.colors = outcols;
			this.chartoptions = {...this.chartoptions, ...{
				colors: outcols
			}};
		},
		selectAllSurveys() {
			if(this.chosenMarket === 23) {
				this.chosenFirstSurvey = this.surveylistgc[0];
				this.chosenLastSurvey = this.surveylistgc[this.surveylistgc.length-1];
			}
			else if(this.chosenMarket === 24) {
				this.chosenFirstSurvey = this.surveylistcbr[0];
				this.chosenLastSurvey = this.surveylistcbr[this.surveylistcbr.length-1];
			}
			else if(this.chosenMarket === 25) {
				this.chosenFirstSurvey = this.surveylistnew[0];
				this.chosenLastSurvey = this.surveylistnew[this.surveylistnew.length-1];
			}
			else { //metro standard
				this.chosenFirstSurvey = this.surveylist[0];
				this.chosenLastSurvey = this.surveysNewToOld[0];
			}
			this.getChartData();
		},
		updateSurveyChoices() { //for metro
			if(this.chosenMarket === 23) {
				this.chosenFirstSurvey = this.surveylistgc[0];
				this.chosenLastSurvey = this.surveylistgc[this.surveylistgc.length-1];
			}
			else if(this.chosenMarket === 24) {
				this.chosenFirstSurvey = this.surveylistcbr[0];
				this.chosenLastSurvey = this.surveylistcbr[this.surveylistcbr.length-1];
			}
			else if(this.chosenMarket === 25) {
				this.chosenFirstSurvey = this.surveylistnew[0];
				this.chosenLastSurvey = this.surveylistnew[this.surveylistnew.length-1];
			}
			else { //metros
				let nsurvs = this.surveylist.length;
				let firstind = nsurvs - 20;
				this.chosenFirstSurvey = this.surveylist[firstind];
				let lastind = nsurvs - 1;
				this.chosenLastSurvey = this.surveylist[lastind];
			}
		},
		showMarketSummary() {
			this.summaryMarketOb = this.store.markets.find(item => item.alportid === this.chosenMarket);
			if(this.summaryMarketOb) {
				this.showSummaryView = true;
			}
		},
	},
	watch: {
		chosenStatistic() {
			// let mydec = 1;
			// if(this.chosenStatistic === 'Cume') mydec = 0;
			// else if(this.chosenStatistic === 'TSL') mydec = 2;
			// else if(this.chosenStatistic === 'Share') mydec = 1;
			// let yopts = this.chartoptions.yaxis;
			// yopts.decimalsInFloat = mydec;
			// this.chartoptions = {...this.chartoptions, ...{
			// 	yaxis: yopts
			// }};
			this.getChartData();
		},
		chosenStations() {
			this.getChartData();
		},
		chosenDaypart() {
			this.getChartData();
		},
		chosenDemographic() {
			this.getChartData();
		},
		chosenFirstSurvey() {
			this.getChartData();
		},
		chosenLastSurvey() {
			this.getChartData();
		},
		chartdata() {
			this.updateColours();
		},
		chosenMarket() {
			if(this.chosenMarket === 1) this.chosenStations = this.perthDefaultStations;
			if(this.chosenMarket === 2) this.chosenStations = this.adelaideDefaultStations;
			if(this.chosenMarket === 3) this.chosenStations = this.brisbaneDefaultStations;
			if(this.chosenMarket === 4) this.chosenStations = this.melbourneDefaultStations;
			if(this.chosenMarket === 5) this.chosenStations = this.sydneyDefaultStations;
			if(this.chosenMarket === 21) this.chosenStations = this.smbapDefaultStations;
			if(this.chosenMarket === 23) this.chosenStations = this.goldcoastDefaultStations;
			if(this.chosenMarket === 24) this.chosenStations = this.canberraDefaultStations;
			if(this.chosenMarket === 25) this.chosenStations = this.newcastleDefaultStations;
			this.updateSurveyChoices();
			this.getChartData();
		},
	},
	props: {

	},
	mounted() {

	},
	created() {
		this.getMeta();
		this.getChartData();
		document.title = "ARN Ratings Charts";
	},
}
</script>

<style scoped>
.netshortcut {
	display: inline-block;
	border: 1px solid #DDD;
	background: #EAEAEA;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	margin: 0 10px 10px 0;
	padding: 7px 10px;
	border-radius: 5px;
}
.netshortcut:hover {
	background-color: #DDD;
}
.market-summary-view {
	width: 90%;
	height: 90%;
	position: fixed;
	top: 5%;
	left: 5%;
}
.loader-overlay {

}
.stationselect-popup {
	width: 70%;
	height: 500px;
	margin: 100px auto;
	position: relative;
}
.stationselect-popup.whitebg {
	background-color: #FFF;
	overflow-y: auto;
}
.popup-topbar {
	position: absolute;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: rgb(239, 239, 239);
	border-bottom: 1px solid rgb(204, 204, 204);
	color: #000;
	padding: 0 20px;
	width: 100%;
	height: 40px;
}
.closer {
	font-size: 18px;
	cursor: pointer;
}
.closer:hover i {
	color: #888;
}
.popup-insides {
	width: 100%;
	padding: 20px;
	height: calc(100% - 40px);
	margin-top: 40px;
	overflow-y: auto;
}
</style>
