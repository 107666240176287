<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">

			<div style="margin-top: 10px; padding: 0 0 10px;">
				<input type="checkbox" v-model="commasInNumbers" id="comnum" /> <label for="comnum" style="margin-right: 20px; font-size: 11px;">Show commas in numbers</label>
				<input type="checkbox" v-model="hideLY" id="hideLYcheck" /> <label for="hideLYcheck" style="margin-right: 20px; font-size: 11px;">Hide last year</label>
				<span class="netshortcut" @click="exportToExcel('national_kpi', 'IHR_Nat_KPI')">Export</span>
			</div>

			<div v-if="chartsData['cume']">
				<table class="altbl" id="national_kpi" :class="{hideLY : hideLY}">
					<thead>
						<tr>
							<td class="headingtext"></td>
							<td class="headingtext rt" v-for="(t, ind) in chartsData['cume'][0]['data']" :key="ind" data-f-bold="true">{{cleanMonth(t.x)}}</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(grpitem, ind) in groupData" :key="ind">
							<td data-f-bold="true">{{grpitem.title}}</td>
							<td v-for="(item, ind) in grpitem.data" :key="ind" class="rt"
								:class="{under : grpitem.isVar && ((grpitem.key !== 'regpct' && item < 100 && item > 0) || (grpitem.key === 'regpct' && item < 0)), over : grpitem.isVar && ((grpitem.key !== 'regpct' && item >= 100) || (grpitem.key === 'regpct' && item > 0))}"
							>{{formatNumbers(item)}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import PermissionChecker from "@/components/PermissionChecker";
import TableToExcel from "@linways/table-to-excel";

export default {
	name: 'IHRNationalKPITable',
	components: {
		PermissionChecker,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','content-metro','connections','iheart'],

			getChartsBtnWorking: false,

			chartsData: {},

			months: [],
			commasInNumbers: true,
			hideLY: true,

		}
	},
	computed: {
		groupData() {
			let loopItems = [
				{key: 'cume', title: 'IHR Cume'},
				{key: 'regpct', title: 'IHR Registered Cume'},
				{key: 'tlh', title: 'IHR TLH'},
				{key: 'cume_metro', title: 'Metro Stations Cume'},
				{key: 'cume_reg', title: 'Regional Stations Cume'},
			];
			let outdata = []
			let loopInd = 0;
			for(let li of loopItems) {
				let arr = this.chartsData[li.key] //eg chartsData['cume']
				let actuals = []
				let targets = []
				let vartargs = []
				let blanks = []
				for(let mthitem of arr[0].data) {
					actuals.push(parseInt(mthitem.y))
				}
				let ind = 0
				if(arr[1] && arr[1].data) {
					for (let mthitem of arr[1].data) {
						targets.push(parseInt(mthitem.y))
						if (!isNaN(actuals[ind]) && !isNaN(targets[ind]) && targets[ind] !== 0) {
							if (li.key === 'regpct') { //registered cume pct - already in pct
								vartargs.push(parseInt(actuals[ind] - parseInt(mthitem.y)))
							}
							else vartargs.push(parseInt(actuals[ind] / parseInt(mthitem.y) * 100))
						}
						else vartargs.push(null)
						if (!actuals[ind]) actuals[ind] = null //fill this if needed
						blanks[ind] = null
						ind++
					}
				}
				outdata.push({title: li.title+' (Target)', key: li.key, data: targets, isVar: false})
				outdata.push({title: li.title+' (Actual)', key: li.key,  data: actuals, isVar: false})
				outdata.push({title: 'Variance to Target %', key: li.key,  data: vartargs, isVar: true})
				loopInd++
				if(loopInd < loopItems.length) outdata.push({title: '', data: blanks, isVar: false})
			}
			return outdata
		},
	},
	methods: {
		cleanMonth(ymd) {
			let arr = ymd.split('-')
			return this.getMonthName(arr[1]) + ' ' + arr[0]
		},
		exportToExcel(tblid, filename = 'export') {
			TableToExcel.convert(document.getElementById(tblid), {
				name: filename+".xlsx",
				sheet: {
					name: 'Sheet 1'
				}
			});
		},
		getMyVal(arr, stnid, mth) {
			let ob = arr.find(item => item.stnid === stnid);
			if(ob) {
				let obb = ob.data.find(item => item.x === mth);
				if(obb) {
					return obb.y;
				}
			}
			return 0;
		},
		formatNumbers(num) {
			if(num != undefined) {
				num = parseInt(num)
				if (this.commasInNumbers === true) {
					return num.toLocaleString();
				}
			}
			return num;
		},
		getData() {
			this.getChartsBtnWorking = true;
			let targ = "/api/iheart/get-dashboard.php";
			let self = this;
			this.chartsData = [];
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.chartsData != null) self.chartsData = ret.chartsData;
				}
				self.getChartsBtnWorking = false;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {

	},
	mounted() {

	},
	created() {
		this.getData();
		document.title = "IHR National KPI Tables";
	},
}
</script>

<style scoped>
.netshortcut {
	display: inline-block;
	border: 1px solid #DDD;
	background: #EAEAEA;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	margin: 0 10px 10px 0;
	padding: 6px 8px;
	border-radius: 5px;
	font-size: 11px;
}
.netshortcut:hover {
	background-color: #DDD;
}
table.hideLY tr td:nth-child(n+2):nth-child(-n+13) {
	display: none;
}
td.under {
	background-color: rgba(255,0,0,0.3);
}
td.over {
	background-color: rgba(0,255,0,0.3);
}
</style>
