<template>
	<div class="campaign-station-item">
		<div class="chooser">
			<div>
				Choose Station
				<select v-model="chosenStation" class="select-styled">
					<option v-for="opt in stations" :key="opt.aquira_name" :value="opt">{{opt.aquira_name}}</option>
				</select>
			</div>
			<div class="remover">
				<span @click="store.sales_campaign.station_rows.splice(stationindex,1)">
					<i class="mdi mdi-close"></i>
				</span>
			</div>
		</div>

		<div v-if="chosenStation" class="section-holder">
			<div class="section-head">
				<div class="section-head-inside">
					<div class="cell wider">Daypart</div>
					<div class="cell wider">Day</div>
					<div class="cell">Hour Placement</div>
					<div class="cell">Spot Length (s)</div>
					<div class="cell">Spot Value</div>
					<div class="cell">Discount %</div>
					<div class="cell">Spot Cost</div>
					<div class="cell">Num Spots</div>
					<div class="cell">Total Value</div>
					<div class="cell">Total Cost</div>
					<div class="cell"></div>
				</div>
				<div class="section-label">Airtime</div>
			</div>

			<div class="section-body">
				<div class="section-body-inside">
					<SalesCampaignStandardAirtimeRow v-for="ind in store.sales_campaign.station_rows[stationindex].airtimeRows.length" :key="ind"
						:chosenstation="chosenStation" :stations="stations"
						:ratesforstation="ratesForStation" :commercialdayparts="commercialdayparts"
						:rowindex="ind-1" :stationindex="stationindex"
					></SalesCampaignStandardAirtimeRow>
					<div @click="addNewAirtimeRow" class="add-row">Add Row</div>
					<div class="section-label">Added Value</div>
				</div>
			</div>

			<div class="section-body">
				<div class="section-body-inside">
					<SalesCampaignAddedValueRow v-for="ind in store.sales_campaign.station_rows[stationindex].addValueRows.length" :key="ind"
						:chosenstation="chosenStation" :stations="stations"
						:ratesforstation="ratesForStation" :commercialdayparts="commercialdayparts"
						:rowindex="ind-1" :stationindex="stationindex"
					></SalesCampaignAddedValueRow>
					<div @click="addNewAddValueRow" class="add-row">Add Row</div>
					<div class="section-label">Other</div>
				</div>
			</div>
			<div class="section-body">
				<div class="section-body-inside">
					<SalesCampaignOtherRow v-for="ind in store.sales_campaign.station_rows[stationindex].otherRows.length" :key="ind"
						:chosenstation="chosenStation" :stations="stations"
						:ratesforstation="ratesForStation"
						:rowindex="ind-1" :stationindex="stationindex"
					></SalesCampaignOtherRow>
					<div @click="addNewOtherRow" class="add-row">Add Row</div>
				</div>
			</div>
			<div class="section-body">
				<div class="section-body-inside">
					<div class="airtimerow" style="border-bottom: none">
						<div class="cell wider norightborder bold" style="text-align: left; font-size: 12px; text-transform: uppercase; display: inline-block; padding: 10px;">TOTAL</div>
						<div class="cell wider norightborder"></div>
						<div class="cell norightborder"></div>
						<div class="cell norightborder"></div>
						<div class="cell norightborder"></div>
						<div class="cell norightborder"></div>
						<div class="cell"></div>
						<div class="cell bold">{{numdisplay(stationRow.totalSpots)}}</div>
						<div class="cell bold">{{numdisplay(stationRow.totalValue,2)}}</div>
						<div class="cell bold">{{numdisplay(stationRow.totalCost,2)}}</div>
						<div class="cell"></div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import SalesCampaignStandardAirtimeRow from "@/components/sales-campaigns/SalesCampaignStandardAirtimeRow.vue";
import SalesCampaignAddedValueRow from "@/components/sales-campaigns/SalesCampaignAddedValueRow.vue";
import SalesCampaignOtherRow from "@/components/sales-campaigns/SalesCampaignOtherRow.vue";
export default {
	name: 'SalesCampaignStationItem',
	components: {
		SalesCampaignOtherRow,
		SalesCampaignStandardAirtimeRow,
		SalesCampaignAddedValueRow,

	},
	props: {
		stations: {
			type: Array,
			default: () => []
		},
		rates: {
			type: Object,
			default: () => {}
		},
		commercialdayparts: {
			type: Array,
			default: () => []
		},
		stationindex: {
			type: Number,
			default: 0
		},
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

		}
	},
	computed: {
		chosenStation: {
			get() {
				return this.store.sales_campaign.station_rows[this.stationindex].stationOb
			},
			set(newval) {
				this.store.sales_campaign.station_rows[this.stationindex].stationOb = newval
			}
		},
		aliases() {
			if (this.chosenStation) {
				if(this.rates[this.chosenStation.aquira_name]) {
					return this.rates[this.chosenStation]
				}
				else {
					return this.chosenStation.aliases
				}
			}
			return null
		},
		ratesForStation() {
			if (this.chosenStation) {
				if(this.rates[this.chosenStation.aquira_name]) {
					return this.rates[this.chosenStation.aquira_name]
				}
				else {
					for(let alias of this.chosenStation.aliases) {
						if(this.rates[alias]) {
							return this.rates[alias]
						}
					}
				}
			}
			return {}
		},
		stationRow: {
			get() {
				if(this.store.sales_campaign.station_rows[this.stationindex]) {
					return this.store.sales_campaign.station_rows[this.stationindex]
				}
				return null
			},
			set(newval) {
				this.store.sales_campaign.station_rows[this.stationindex] = newval
			}
		},
	},
	methods: {
		addNewAirtimeRow() {
			store.sales_campaign.station_rows[this.stationindex].airtimeRows.push({
				daypartName: null,
				weekdayset: null,
				hourplacement: null,
				spotlength: 30,
				discountPct: 0,
				nSpots: null,
				spotValue: null,
				spotCost: null,
				totalSpotsValue: null,
				totalSpotsCost: null
			})
		},
		addNewAddValueRow() {
			store.sales_campaign.station_rows[this.stationindex].addValueRows.push({
				daypartName: null,
				weekdayset: null,
				hourplacement: null,
				spotlength: 30,
				discountPct: 100,
				nSpots: null,
				spotValue: null,
				spotCost: null,
				totalSpotsValue: null,
				totalSpotsCost: null
			})
		},
		addNewOtherRow() {
			store.sales_campaign.station_rows[this.stationindex].otherRows.push({
				itemType: null,
				daypartName: null,
				weekdayset: null,
				hourplacement: null,
				spotlength: 30,
				discountPct: 0,
				nSpots: null,
				spotValue: null,
				spotCost: null,
				totalSpotsValue: null,
				totalSpotsCost: null
			})
		}
	},
	watch: {
		stationRow: {
			handler() {
				//get total spots
				let total = 0
				for(let row of this.store.sales_campaign.station_rows[this.stationindex].airtimeRows) {
					if(row.nSpots) total += row.nSpots
				}
				for(let row of this.store.sales_campaign.station_rows[this.stationindex].addValueRows) {
					if(row.nSpots) total += row.nSpots
				}
				for(let row of this.store.sales_campaign.station_rows[this.stationindex].otherRows) {
					if(row.nSpots) total += row.nSpots
				}
				this.store.sales_campaign.station_rows[this.stationindex].totalSpots = total
				//total value
				total = 0
				for(let row of this.store.sales_campaign.station_rows[this.stationindex].airtimeRows) {
					if(row.totalSpotsValue) total += row.totalSpotsValue
				}
				for(let row of this.store.sales_campaign.station_rows[this.stationindex].addValueRows) {
					if(row.totalSpotsValue) total += row.totalSpotsValue
				}
				for(let row of this.store.sales_campaign.station_rows[this.stationindex].otherRows) {
					if(row.totalSpotsValue) total += row.totalSpotsValue
				}
				this.store.sales_campaign.station_rows[this.stationindex].totalValue = total
				//total cost
				total = 0
				for(let row of this.store.sales_campaign.station_rows[this.stationindex].airtimeRows) {
					if(row.totalSpotsCost) total += row.totalSpotsCost
				}
				for(let row of this.store.sales_campaign.station_rows[this.stationindex].addValueRows) {
					if(row.totalSpotsCost) total += row.totalSpotsCost
				}
				for(let row of this.store.sales_campaign.station_rows[this.stationindex].otherRows) {
					if(row.totalSpotsCost) total += row.totalSpotsCost
				}
				this.store.sales_campaign.station_rows[this.stationindex].totalCost = total
			},
			deep: true
		}
	},
	mounted() {
		if(store.sales_campaign.station_rows[this.stationindex].airtimeRows.length === 0) {
			this.addNewAirtimeRow()
		}
		if(store.sales_campaign.station_rows[this.stationindex].addValueRows.length === 0) {
			this.addNewAddValueRow()
		}
		if(store.sales_campaign.station_rows[this.stationindex].otherRows.length === 0) {
			this.addNewOtherRow()
		}
	},
	created() {

	},
}
</script>

<style scoped>
.campaign-station-item {
	margin: 20px 0;
	padding: 20px;
	border: 1px solid #CCC;
	background-color: #FFF;
}
.select-styled {
	width: 200px;
	margin-left: 20px;
	padding: 5px 5px;
}
.chooser {
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
}

</style>
