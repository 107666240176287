<template>
	<div class="view-holder">
		<div v-if="store.user.id === 1 || store.user.id === 2">
			<input type="text" class="filterbox" v-model="store.natCumeDateToUse" />
			<div class="primary-btn" style="display: inline; margin-left: 10px; padding: 8px 20px 7px; font-size: 12px;" @click="getAudologyNationalCumes()">Update</div>
		</div>
		<br/>
		<div>
			<p style="font-size:16px; line-height: 1.5;" title="Excludes Canberra FM">
				<span class="bold">Total 10+ Owned Cume: </span>{{numdisplay(totownedcume)}}
			</p>
			<p style="font-size:16px; line-height: 1.5;" title="Includes 6IX, Ace Metro, Super Newcastle, ARN Combo Gold Coast, Capital Canberra (not Canberra FM)">
				<span class="bold">Total 10+ Repped Cume: </span>{{numdisplay(totreppedcume)}}
			</p>
		</div>
		<div class="filterbox-hold" style="margin-top: 20px; margin-bottom: 20px;">
			<select v-if="pageview === 'markets'" v-model="listviewchooser" style="padding: 5px; margin-right: 10px; border: 1px solid #DDD;">
				<option value="all">All Markets</option>
				<option value="surveyed">Surveyed Markets</option>
				<option value="arnmarkets">ARN Owned Markets</option>
				<option value="arnrepmarkets">ARN Repped Markets</option>
			</select>
			<div v-if="pageview === 'markets'" style="display: inline;">
				<input id="inclnoncom" type="checkbox" v-model="includeNonCompetitive" style="margin-right: 5px;" />
				<label for="inclnoncom" style="font-size: 14px; margin-right: 10px;">Include Non-Competitive</label>
			</div>
			<select v-if="pageview === 'stations'" v-model="stnlistviewchooser" style="padding: 5px; margin-right: 10px; border: 1px solid #DDD;">
				<option value="arnowned">ARN Owned Stations</option>
				<option value="arnrepped">ARN Repped Stations</option>
			</select>
			<input type="text" placeholder="Filter by name..." v-model="filterText" class="filterbox" />
		</div>

		<div style="margin-bottom: 20px;">
			If you wish to search by Postcode, Suburb, or LGA,
			<router-link to="/sales-tools/geo-lookup" class="link-text">click here to use the Sales Geo Tools</router-link>.
		</div>

		<div v-if="pageview === 'markets'" class="checkbox-list-hold">
			<MarketsTable :filtertext="filterText" :inrows="filteredMarkets" v-on:showsum="showMarketSummary($event)"></MarketsTable>
		</div>

		<div v-if="pageview === 'stations'" class="checkbox-list-hold">
			<StationsTable :filtertext="filterText" :inrows="filteredStations" v-on:showsum="showMarketSummary($event)"></StationsTable>
		</div>

		<div v-if="showSummaryView" class="loader-overlay">
			<div class="market-summary-view">
				<MarketSummary :aspopup="true" :mktob="summaryMarketOb" v-on:close="showSummaryView = false"></MarketSummary>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import MarketSummary from "@/components/arnmarkets/MarketSummary";
import MarketsTable from "@/components/arnmarkets/MarketsTable";
import StationsTable from "@/components/arnmarkets/StationsTable";

export default {
	name: "MarketsPage",
	components: {
		StationsTable,
		MarketsTable,
		MarketSummary,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			filterText: '',

			summaryMarketOb: null,
			showSummaryView: false,
			includeNonCompetitive: true,

			listviewchooser: 'arnmarkets',
			stnlistviewchooser: 'arnowned',
			pageview: null,

			freqcachedata: [],
		}
	},
	computed: {
		totownedcume() {
			if(this.store.natCumesLoaded === true) {
				let c = this.store.totcumes.owned10;
				let t = c.metro + c.gfkreg + c.xtrareg;
				return t;
			}
			return '';
		},
		totreppedcume() {
			if(this.store.natCumesLoaded === true) {
				let c = this.store.totcumes.repped10;
				let t = c.metro + c.gfkreg + c.xtrareg;
				return t;
			}
			return '';
		},
		filteredMarkets() {
			let temp = [];
			if(this.listviewchooser === 'all') temp = this.store.markets;
			else if(this.listviewchooser === 'surveyed') temp = this.store.markets.filter(item => item.surveyed === true);
			else if(this.listviewchooser === 'arnmarkets') temp = this.store.markets.filter(item => item.ownership.owns.includes('arn'));
			else if(this.listviewchooser === 'arnrepmarkets') temp = this.store.markets.filter(item => item.ownership.reps.includes('arn'));
			if(this.includeNonCompetitive === false) temp = temp.filter(item => item.isCompetitive !== 0)
			if(this.filterText.length > 0) { //either already selected or matching text
				return temp.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
			return temp;
		},
		mktids() {
			let temp = [];
			for(let i=0; i<this.store.markets.length; i++) {
				temp.push(parseInt(this.store.markets[i].id));
			}
			return temp;
		},
		filteredStations() {
			let temp = [];
			temp = this.store.stations.filter(item => this.mktids.includes(item.mktid) && item.repnetgroup === 'arn' && item.outsidemkt === false);
			if(this.stnlistviewchooser === 'arnowned') temp = temp.filter(item => item.netgroupown === 'arn');
			if(this.filterText.length > 0) { //either already selected or matching text
				return temp.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
			return temp;
		},
	},
	methods: {
		showMarketSummary(mktob) {
			this.summaryMarketOb = mktob;
			this.showSummaryView = true;
		},
		toggleSection() {
			if(this.$router.currentRoute.value.path === '/arn-markets/stations') {
				document.title = "ARN Broadcast Stations";
				this.pageview = 'stations';
			}
			else {
				document.title = "ARN Broadcast Markets";
				this.pageview = 'markets';
			}
		},
	},
	watch: {
		$route(){
			this.toggleSection();
		},
		// 'store.initialObjectsReceived'() {
		// 	if(this.store.initialObjectsReceived === true && this.store.totcumes.owned10.metro === 0) {
		// 		this.getFreqNationalCumes()
		// 		this.getGFKNationalCumes()
		// 	}
		// },
	},
	mounted() {
		this.toggleSection();
		// if(this.store.initialObjectsReceived === true && this.store.totcumes.owned10.metro === 0) {
		// 	this.getFreqNationalCumes()
		// 	this.getGFKNationalCumes()
		// }
	}
}
</script>
<style>
table.market-table tr {
	transition: background-color 0.2s ease-in-out;
}
table.market-table tbody tr:hover {
	background-color: #EFEFEF;
}
table.market-table td {
	vertical-align: middle;
	padding: 2px 5px;
}
th.text-right {
	text-align: right !important;
}
</style>
<style scoped>
.view-holder {
	margin: 0 20px;
}
.market-table {
	max-width: 1200px;
}
.market-summary-view {
	width: 90%;
	height: 90%;
	position: fixed;
	top: 5%;
	left: 5%;
}
.filterbox {
	border: 1px solid #DDD;
	padding: 6px;
	width: 250px;
}
.link-text {
	color: inherit;
	text-decoration: none;
}
</style>