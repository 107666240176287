<template>
	<div class="bgpage" style="padding-top: 50px; min-height: 100vh;">
		<h1>{{errorPageHeading}}</h1>
		<div v-html="errorPageText"></div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Error',
	components: {},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			errorId: 0,
			heading: '',
			content: '',
		}
	},
	computed: {
		transformed() {
			return {
				template: '<div>' + this.content + '</div>',
				props: this.$options.props
			}
		}
	},
	methods: {},
	watch: {},
	props: {
		errorPageHeading: String,
		errorPageText: String,
	},
	mounted() {

	},
	created() {
		let urlparams = this.$route.query;
		this.heading = "Error";
		this.content = "Ooops - there seemed to be a problem.  Please try what you were doing again.";
		if (urlparams.errorId !== undefined) {
			this.errorId = parseInt(urlparams.errorId);
			// if(this.errorId === 404) {
			// 	this.heading = "404 Not Found";
			// 	this.content = "Sorry, but we couldn't find the page you're looking for.";
			// }
		}
		document.title = this.heading;
	},
}
</script>

<style scoped>

</style>
