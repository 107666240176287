<template>
	<div :class="{magentaToggle : showMagenta}">
		<PodcastCRAMenu></PodcastCRAMenu>
		<div style="padding: 20px 20px; max-width: 1480px; margin: 0 auto;">
			<p style="font-size: 40px; color: #553AA9;">
				{{headline}}
				<span v-if="store.user.id === 1" style="margin-left: 10px; font-size: 10px; cursor: pointer" @click="togglaMagenta">Magenta</span>
			</p>
		</div>
		<div style="display: flex; flex-wrap: wrap; max-width: 1480px; margin: 0 auto;">
			<div v-for="s in rankInfo" :key="s.rank" style="position: relative; width: 124px; height: 124px; border: 1px solid #888; margin: 20px;">
				<div style="display: inline-flex; position: absolute; right: -18px; margin-top: -18px; align-items: center; justify-content: center; background-color: #FFF; color: #000; height: 36px; width: 36px; border-radius: 18px;">#{{s.rank}}</div>
				<div style="cursor:pointer;" @click="jumpToPodcastShowDetail(s.showid)" >
					<img :src="s.showObj.showIconUrl" style="width:122px; height:122px; object-fit: cover;" />
				</div>
			</div>
		</div>
		<div style="padding: 50px 0;">
			<p v-for="miss in missing" :key="miss.showid">{{miss.showid}}: {{miss.title}}</p>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import PodcastCRAMenu from "@/components/PodcastCRAMenu.vue";

export default {
	name: 'DashPodcastShowDetail',
	components: {
		PodcastCRAMenu

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			getChartsBtnWorking: false,
			headline: '',
			headlineSource: '',
			rankInfo: [],
			missing: [],
			showMagenta: false,
		}
	},
	computed: {

	},
	methods: {
		togglaMagenta() {
			if(this.showMagenta === true) this.showMagenta = false
			else this.showMagenta = true
		},
		jumpToPodcastShowDetail(showid) {
			if(this.userHasPermission(['elt','connections','content-metro','podcast-admin'])) {
				this.$router.push("/podcasts/show-detail?showid=" + showid);
			}
		},
		getInfo() {
			this.getChartsBtnWorking = true;
			var self = this;
			var targ = "/api/podcast-show-visual.php";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					if (ret.rankInfo != null) {
						self.rankInfo = ret.rankInfo;
					}
					if (ret.missing != null) {
						self.missing = ret.missing;
					}
					// if (ret.chartsOptions != null) {
					// 	self.chartsOptions = ret.chartsOptions;
					// }
					// if (ret.chartsTitle != null) {
					// 	self.chartsTitle = ret.chartsTitle;
					// }
					if (ret.headline != null) { self.headline = ret.headline; }
					if (ret.headlineSource != null) { self.headlineSource = ret.headlineSource; }
				}
				self.getChartsBtnWorking = false;
				setTimeout(function() {
					self.updating = false;
				}.bind(self), 1000);
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {

	},
	created() {
		let urlparams = this.$route.query;
		if(urlparams.showid !== undefined) {
			this.showIdFromGet = urlparams.showid;
		}
		this.getInfo();
		document.title = "ARN Podcasts Visual Rankings";
	},
}
</script>

<style scoped>
.magentaToggle {
	background-color: magenta;
}
</style>
