<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">
			<ALTable tblclasses="noborderv" :excel="true" excelfilename="PodcastShows"
				:searchbox="true" :pagination="100" :rowdata="showstabledata"
				:cols="[
					{title: 'Name', sortable: true, fldid: 'name'},
					{title: 'Category', sortable: true, fldid: 'category'},
					// {title: 'Network', sortable: true, fldid: 'network'},
					{title: 'In Bundle', sortable: true, fldid: 'appears_in_bundle'},
					{title: 'Mth Listeners', sortable: true, fldid: 'listeners', align: 'right', numtype: 'number'},
					{title: 'Mth Downloads', sortable: true, fldid: 'downloads', align: 'right', numtype: 'number'},
				]"
			>
			</ALTable>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import PermissionChecker from "@/components/PermissionChecker";
import ALTable from "@/components/ALTable.vue";

export default {
	name: 'PodcastShowListOmny',
	components: {
		ALTable,
		PermissionChecker,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','connections','content-metro','podcast-admin'],

			shows: [],
			showstabledata: [],
			latestdata: {},
			headline: '',
			headlineSource: '',
			updating: false,

		}
	},
	computed: {

	},
	methods: {
		getValBySlug(slug, key) {
			let ret = ''
			if (this.latestdata[slug]) {
				ret = this.latestdata[slug][key]
			}
			return ret
		},
		getData() {
			this.getOmnyData()
		},
		getOmnyData() {
			if (!this.updating) {
				this.updating = true
				let self = this
				let targ = "/api/podcast/omny-shows-list.php"
				axios.get(targ).then(function (response) {
					let ret = response.data; //response.data is returned info
					self.shows = ret.shows
					self.prepareStatsLookupRequest()
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		prepareStatsLookupRequest() {
			let showsToLookup = []
			for (let i = 0; i < this.shows.length; i++) {
				let show = this.shows[i]
				showsToLookup.push({slug: show.slug, triton_prog_id: show.triton_prog_id, name: show.name})
			}
			let self = this
			let targ = "/api/podcast/podcast-latest-metrics.php"
			axios.post(targ, {showslookup: showsToLookup}).then(function (response) {
				let ret = response.data; //response.data is returned info
				self.latestdata = ret.latestdata
				let sh = []
				for(let s of self.shows) {
					let row = {
						id: s.id,
						name: '<a class="link-text" href="/podcasts/shows/'+s.slug+'">'+s.name+'</a>',
						category: s.category,
						network: s.network,
						appears_in_bundle: self.trueToYes(s.appears_in_bundle),
						listeners: self.getValBySlug(s.slug, 'listeners'),
						downloads: self.getValBySlug(s.slug, 'downloads'),
					}
					sh.push(row)
				}
				self.showstabledata = sh
				self.updating = false
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {

	},
	mounted() {

	},
	created() {
		document.title = "Podcast Shows"
		this.getData()
	},
}
</script>

<style scoped>

</style>
