<template>
	<div>
		<div>
			<div style="margin-top: 20px;">
				<div v-for="r in latestVals" :key="r.name" style="display: flex;">
					<div>
						<p style="font-size: 22px; font-weight: bold;">{{r.name}}: {{numdisplay(r.value * 1000)}}</p>
						<p style="font-size: 12px; color: #BBB;" v-if="r.source && r.source.length > 0">{{r.source}}</p>
					</div>
				</div>
			</div>
			<div class="chart-rows">
				<ChartHolderDash
						charttitle="Streaming Weekly Unique Listeners" :height="420"
						:source="streamChartsSource" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="streamApexDataUniques.streamstntrnd" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash
						charttitle="Streaming Weekly Total Hours (000s)" :height="420"
						:source="streamChartsSource" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="streamApexData.streamstntrnd" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash
						charttitle="Streaming Monthly Unique Listeners" :height="420"
						:source="streamChartsSource" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="streamApexDataUniquesMth.streamstntrnd" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash
						charttitle="Streaming Monthly Total Hours (000s)" :height="420"
						:source="streamChartsSource" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="streamApexDataMth.streamstntrnd" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash
						charttitle="Website Weekly Sessions" :height="420"
						:source="webChartsSource.w_sessions" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="webApexData.w_sessions" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash
						charttitle="Website Weekly Users" :height="420"
						:source="webChartsSource.w_users" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="webApexData.w_users" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash
						charttitle="Website Monthly Sessions" :height="420"
						:source="webChartsSourceMth.m_sessions" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="webApexDataMth.m_sessions" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash
						charttitle="Website Monthly Users" :height="420"
						:source="webChartsSourceMth.m_users" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="webApexDataMth.m_users" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash
						charttitle="Facebook Weekly Impressions" :height="420"
						:source="fbChartsSource.w_impressions" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="fbApexData.w_impressions" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash
						charttitle="Facebook Monthly Impressions" :height="420"
						:source="fbChartsSourceMth.m_impressions" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="fbApexDataMth.m_impressions" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash
						charttitle="Instagram Weekly Impressions" :height="420"
						:source="instaChartsSource.w_impressions" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="instaApexData.w_impressions" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="10+ Cume DAB Perth" :height="420" source="GFK Metro Radio Ratings" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="gfkData[0]" :fetching="getGFKFetching"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from 'axios'
import ChartHolderDash from "@/components/ChartHolderDash";
import ApexLineTrend from "@/components/charts/ApexLineTrend";
export default {
	name: "KIXDashboard",
	components: {
		ChartHolderDash,
		ApexLineTrend,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			viewPerms: [],
			streamChartsSource: 'Triton',
			streamApexData: {},
			streamApexDataUniques: {},
			streamApexDataMth: {},
			streamApexDataUniquesMth: {},
			fetchedStreams: 0,
			liveListenNowNum: 0,
			latest_time_utc: null,

			webApexData: {},
			webChartsSource: {},
			webApexDataMth: {},
			webChartsSourceMth: {},

			fbApexData: {},
			fbChartsSource: {},
			fbApexDataMth: {},
			fbChartsSourceMth: {},

			instaApexData: {},
			instaChartsSource: {},

			gfkData: [],
			fetchedGfks: 0,

			chartsSource: [],
			apexdata: [],
			fetchedLocal: 0,

			latestCMV: null,
		}
	},
	computed: {
		endOfLastMonth() {
			let date = new Date();
			date.setDate(0); //remember month index starts at 0 so need to +1 below to get 9=sep eg
			return date.getFullYear().toString() + '-' + (date.getMonth()+1).toString() + '-' + date.getDate().toString()
		},
		endOfLastWeek() {
			let date = new Date();
			let currDayOfWeek = date.getDay(); //1=Mon, 2=Tue etc
			if(currDayOfWeek < 3) { //we probably don't have last week's figures yet (prior to Wed), roll back one week
				date.setDate(date.getDate() - 7 - date.getDay());
			}
			else {
				date.setDate(date.getDate() - date.getDay());
			}
			return date.getFullYear().toString() + '-' + (date.getMonth()+1).toString() + '-' + date.getDate().toString()
		},
		getChartsBtnWorking() {
			return false;
		},
		getGFKFetching() {
			if(this.fetchedGfks >= 0) return false;
			return true;
		},
		getPodcastFetching() {
			if(this.fetchedLocal >= 3) return false;
			return true;
		},
		latestVals() {
			let r = []
			//if(this.latestCMV) r.push({name: 'CMV Weekly Reach', value: this.latestCMV.reach, source: this.latestCMV.survey})
			return r
		},
	},
	methods: {
		prepare() {
			this.getStreamingData('cume', 'KIXCOUNTRY', 'w', 'streamApexDataUniques');
			this.getStreamingData('tlh', 'KIXCOUNTRY', 'w', 'streamApexData');
			this.getStreamingData('cume', 'KIXCOUNTRY', 'm', 'streamApexDataUniquesMth');
			this.getStreamingData('tlh', 'KIXCOUNTRY', 'm', 'streamApexDataMth');

			this.getGFKDataAud(0,'cume',48,1,['g_901017'], 114); //per

			this.getFBData('w');
			this.getFBData('m');
			this.getInstaData();

			this.getCMVData()
		},
		getCMVData() {
			let self = this;
			let targ = "/api/cmv/get-latest.php?station=KIX&demo="+encodeURIComponent("14+");
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					self.latestCMV = ret.cmv
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getStreamingData(rpttypeid, streamstnid, cadence, targobname) {
			let endday = this.endOfLastWeek;
			let startday = "2022-01-03";
			if(cadence === 'm') { //monthly cume
				startday = "2022-01-01";
				endday = this.endOfLastMonth;
			}
			let str = "statistic="+rpttypeid+"&stncode="+streamstnid+"&daypart=overall";
			str += "&ptype="+cadence+"&grouptogether=0";
			str += "&startdate=" + startday + "&enddate=" + endday + "&dist=all";
			let self = this;
			let targ = "/api/streaming-get-gb.php?"+str;
			axios.get(targ).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
					return [];
				}
				else {
					self.fetchedStreams++;
					self[targobname] = ret.chartsData;
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getWebData(cadencekey) {
			let self = this;
			let targ = "/api/web-get-analytics.php?site=kix_country&period="+cadencekey+"&filter=all";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.webdata != null) {
						if(cadencekey === 'w') {
							self.webApexData = ret.webdata.apexdata;
							self.webChartsSource = ret.webdata.chartsSource;
						}
						else if(cadencekey === 'm') {
							self.webApexDataMth = ret.webdata.apexdata;
							self.webChartsSourceMth = ret.webdata.chartsSource;
						}
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getFBData(cadencekey) {
			let self = this;
			let fbpageid = '128139877311592';
			let targ = "/api/fb-get-analytics.php?pageid="+fbpageid+"&period="+cadencekey+"&metric=impressions";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.fbdata != null) {
						if(cadencekey === 'w') {
							self.fbApexData = ret.fbdata.apexdata;
							self.fbChartsSource = ret.fbdata.chartsSource;
						}
						else if(cadencekey === 'm') {
							self.fbApexDataMth = ret.fbdata.apexdata;
							self.fbChartsSourceMth = ret.fbdata.chartsSource;
						}
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getInstaData() {
			let self = this;
			let fbpageid = '17841400143095960';
			let targ = "/api/insta-get-analytics.php?pageid="+fbpageid+"&period=w&metric=impressions";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.fbdata != null) {
						self.instaApexData = ret.fbdata.apexdata;
						self.instaChartsSource = ret.fbdata.chartsSource;
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getGFKDataAud(indexid, statistic,demoid,daypart,stns,gfkmktid) {
			let self = this
			let sets = []
			let type = 'standard'
			let surveylistobs = this.getSurveyObsForMarket(gfkmktid)
			let surveylistIds = this.getSurveyIdsFromSurveyObs(surveylistobs)
			surveylistIds = surveylistIds.slice(0, 24)
			if(gfkmktid === 900) type = 'smbap'
			sets.push({
				type: type,
				dayparts: [daypart],
				demos: [demoid],
				statistics: [statistic],
				surveylist: surveylistIds,
				stationIds: stns,
				market: gfkmktid,
				audioTypeIds: [0],
			})
			this.doGetCacheDataAudology(sets).then(function(ret) {
				if(ret.error === 1) console.log(ret)
				else {
					self.gfkData[indexid] = self.prepareLineFromAudData(sets[0], ret.returnedData)
				}
				self.fetchedGfks++;
			})
		},
	},
	watch: {},
	mounted() {
		document.title = 'KIX Country Dashboard';
		this.prepare();
		this.getWebData('w');
		this.getWebData('m');
	}
}
</script>

<style scoped>

</style>