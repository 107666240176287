<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">
			<br/>
			<h2>{{this.study.title}}</h2>
			<br/>
			<div v-for="qg in study.results" :key="qg.name">
				<div v-if="qg.type.length > 0 && qg.layout.length > 0" style="margin-bottom: 10px; padding: 10px;">
					<BrandTrackChartSingle :qgroup="qg"></BrandTrackChartSingle>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import {store} from '@/store.js'
// import {globalMixin} from "@/mixins"
import axios from "axios";
import BrandTrackChartSingle from "@/components/BrandTrackChartSingle";
import PermissionChecker from "@/components/PermissionChecker";

export default {
	name: 'BrandTrackingStudySummary',
	components: {
		PermissionChecker,
		BrandTrackChartSingle
	},
	// mixins: [globalMixin],
	data() {
		return {
			// store: store,
			viewPerms: ['elt','connections','content-metro'],
			study: {},
			studyidFromGet: 0,
		}
	},
	computed: {
		livestudyid() {
			if(this.study.id != undefined) {
				return this.study.id;
			}
			else return this.studyidFromGet;
		},
	},
	methods: {
		getQuestionResults() {
			this.updating = true;
			var self = this;
			var studid = this.study.id;
			if(this.livestudyid > 0) {
				studid = this.livestudyid;
			}
			if(studid > 0) {
				var targ = "/api/brand-tracking-get-study-details.php?studyid=" + studid;
				axios.get(targ).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.error === 1) {
						console.log(ret);
					}
					else {
						if (ret.dataset != null) {
							self.study = ret.dataset;
							document.title = "Brand Tracking - "+ret.dataset.title;
						}
					}
					setTimeout(function () {
						self.updating = false;
					}.bind(self), 1000);
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {

	},
	created() {
		let urlparams = this.$route.query;
		if(urlparams.studyid !== undefined) {
			this.studyidFromGet = urlparams.studyid;
		}
		document.title = "ARN Brand Tracking Study";
		this.getQuestionResults();
	},
}
</script>

<style scoped>
.popout {
	position: fixed;
	top: 100px;
	right: 100px;
	height: 600px;
	width: 800px;
	background: #EFEFEF
}
</style>
