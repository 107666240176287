<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<PodcastCRAMenu></PodcastCRAMenu>
		<div v-if="userHasPermission(viewPerms)" class="chart-rows">
			<div style="margin-bottom: 20px;">
				<span class="link-btn" @click="viewSelection = 'arn'" style="margin-right: 5px;" :class="{'active' : viewSelection === 'arn'}">ARN/iHeart</span>
				<span class="link-btn" @click="viewSelection = 'allranker'" :class="{'active' : viewSelection === 'allranker'}">Ranker</span>
			</div>
			<ChartHolderDash v-if="viewSelection === 'arn'"
					charttitle="Top Movers This Month (more than 10% change, greater than 30k downloads)"
					:source="chartsSource.downloadMoves" :height="3500">
				<div class="b-table-holder">
					<ALTable tblclasses="noborderv" :excel="true" excelfilename="Top Podcast Movers"
						:cols="[
							{title: 'Show', sortable: true, fldid: 'showname'},
							{title: 'Lstn Pct Change', sortable: true, fldid: 'pctChangeList', numtype: 'pctbubble'},
							{title: 'Lstn Curr', sortable: true, fldid: 'listcurr', numtype: 'number'},
							{title: 'Lstn Prev', sortable: true, fldid: 'listprev', numtype: 'number'},
							{title: 'DL Pct Change', sortable: true, fldid: 'pctChange', numtype: 'pctbubble'},
							{title: 'DL Curr', sortable: true, fldid: 'dlcurr', numtype: 'number'},
							{title: 'DL Prev', sortable: true, fldid: 'dlprev', numtype: 'number'},
						]"
						:rowdata="chartsData.downloadMoves"
					></ALTable>
				</div>
				<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
			</ChartHolderDash>
			<ChartHolderDash v-if="viewSelection === 'allranker'"
				charttitle="Top Movers This Month (more than 10% change, greater than 30k downloads)"
				:source="chartsSource.rankerMoves" :height="5000">
				<div class="b-table-holder">
					<ALTable tblclasses="noborderv" :excel="true" excelfilename="Top Podcast Movers"
						:cols="[
							{title: 'Show', sortable: true, fldid: 'showname'},
							{title: 'Publisher', sortable: true, fldid: 'publisher', filter: true},
							{title: 'Sales', sortable: true, fldid: 'sales', filter: true},
							{title: 'Lstn Pct Change', sortable: true, fldid: 'pctChangeList', numtype: 'pctbubble'},
							{title: 'Lstn Curr', sortable: true, fldid: 'listcurr', numtype: 'number'},
							{title: 'Lstn Prev', sortable: true, fldid: 'listprev', numtype: 'number'},
							{title: 'DL Pct Change', sortable: true, fldid: 'pctChange', numtype: 'pctbubble'},
							{title: 'DL Curr', sortable: true, fldid: 'dlcurr', numtype: 'number'},
							{title: 'DL Prev', sortable: true, fldid: 'dlprev', numtype: 'number'},
						]"
						:rowdata="chartsData.rankerMoves"
					></ALTable>
				</div>
				<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
			</ChartHolderDash>

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import PermissionChecker from "@/components/PermissionChecker";
import ALTable from "@/components/ALTable";
import PodcastCRAMenu from "@/components/PodcastCRAMenu.vue";

export default {
	name: 'DashPodcastMovers',
	components: {
		PodcastCRAMenu,
		ALTable,
		PermissionChecker,
		ChartLoaderInsert,
		ChartHolderDash,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','content-metro','connections','podcast'],
			chartId: 0,
			chartsData: {},
			chartsSource: [],
			getChartsBtnWorking: false,
			viewSelection: 'arn', //arn or allranker
		}
	},
	computed: {

	},
	methods: {
		getCharts() {
			this.getChartsBtnWorking = true;
			var self = this;
			var targ = "/api/podcast-movers.php";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					if (ret.chartsData != null) {
						self.chartsData = ret.chartsData;
					}
					if (ret.chartsSource != null) {
						self.chartsSource = ret.chartsSource;
					}
					if (ret.h != null) {
						for (var key in ret.h) { //this updates any that exist, adds new if needed, but does not delete existing
							self.slotprops[key] = ret.h[key];
						}
					}
					//custom callback functions here if required (using pageaction as filter)
				}
				self.getChartsBtnWorking = false;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {},
	props: {

	},
	mounted() {

	},
	created() {
		this.getCharts();
		document.title = "Top Movers";
	},
}
</script>

<style scoped>
.link-btn {
	border: 1px solid #DDD;
}
</style>
