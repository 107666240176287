<template>
	<div style="margin-top: 10px; padding: 20px 0 20px; display: flex; align-items: center;">
		<div style="font-size: 16px; font-weight: bold; margin-right: 30px;">{{title}}</div>
		<div style="display: flex; align-items: center;">
			<input type="checkbox" v-model="commasInNumbers" id="comnum" /> <label for="comnum" style="margin-right: 20px; font-size: 11px;">Show commas in numbers</label>
			<span class="netshortcut" @click="exportToExcel(tblid, 'IHR_Regional_KPI')">Export</span>
		</div>
	</div>
	<table class="altbl" :id="tblid">
		<thead>
		<tr>
			<td class="headingtext"></td>
			<td class="headingtext rt" v-for="t in months" :key="t" data-f-bold="true">{{cleanMonth(t)}}</td>
		</tr>
		</thead>
		<tbody>
		<tr v-for="(grpitem, ind) in groupData" :key="ind">
			<td data-f-bold="true">{{grpitem.title}}</td>
			<td v-for="(item, ind) in grpitem.data" :key="ind" class="rt"
				:class="{is_target : item.type === 'target'}"
			>{{formatNumbers(item.value)}}</td>
		</tr>
		</tbody>
	</table>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import TableToExcel from "@linways/table-to-excel";

export default {
	name: "IHRRegionalTable",
	components: {},
	props: {
		months: Array,
		summary_data: Array,
		title: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			commasInNumbers: true,
		}
	},
	computed: {
		tblid() {
			return 'ihr_regional_'+this.title
		},
		groupData() {
			let blankrowdata = []
			for(let i=0; i<this.summary_data[0].display[0].data.length; i++) {
				blankrowdata.push({value: null, type: ''})
			}
			let blankrow = {title: ' ', data: blankrowdata}
			let outdata = []
			for(let li of this.summary_data) {
				let conf = li.conf
				for (let darr of li.display) {
					let met = darr.metric
					if(met === 'cume') met = 'Cume'
					else if(met === 'tlh') met = 'TLH'
					else if(met === 'regpct') met = 'Reg%'
					let data = darr.data
					outdata.push({title: conf.title + ' ' + met, data: data})
				}
				outdata.push(blankrow)
			}
			return outdata
		},
	},
	methods: {
		cleanMonth(ymd) {
			let arr = ymd.split('-')
			return this.getMonthName(arr[1]) + ' ' + arr[0]
		},
		exportToExcel(tblid, filename = 'export') {
			TableToExcel.convert(document.getElementById(tblid), {
				name: filename+".xlsx",
				sheet: {
					name: 'Sheet 1'
				}
			});
		},
		formatNumbers(num) {
			if(num != undefined) {
				num = parseInt(num)
				if (this.commasInNumbers === true) {
					return num.toLocaleString();
				}
			}
			return num;
		},

	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.netshortcut {
	display: inline-block;
	border: 1px solid #DDD;
	background: #EAEAEA;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	margin: 0 10px 0 0;
	padding: 6px 8px;
	border-radius: 5px;
	font-size: 11px;
}
.netshortcut:hover {
	background-color: #DDD;
}
table.hideLY tr td:nth-child(n+2):nth-child(-n+13) {
	display: none;
}
td.under {
	background-color: rgba(255,0,0,0.3);
}
td.over {
	background-color: rgba(0,255,0,0.3);
}
td.is_target {
	background-color: rgba(0,0,0,0.1);
}
</style>