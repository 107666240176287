<template>
	<div>
		<p class="headingtext">
			<span class="bold">Indexing: </span>is a comparison of a specific podcast audience compared to the average Australian Podcast Listener.
			<br/>
			<span class="bold">Example: </span>if an index for Podcast “X” is 147, we would explain it in this way: "Podcast X’s listeners are 47% more likely to holiday domestically in the next year than the average Australian podcast listener aged 18+."
		</p>
		<div>
			<PodcastDemosIndexes></PodcastDemosIndexes>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import PodcastDemosIndexes from "@/components/bits/PodcastDemosIndexes.vue";

export default {
	name: 'PodcastIndexing',
	components: {
		PodcastDemosIndexes,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {},
	watch: {},
	props: {

	},
	mounted() {

	},
	created() {
		document.title = "Audience Profile Indexes"
	},
}
</script>

<style scoped>

</style>
