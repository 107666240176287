<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0" width="99%" height="100%" type="bar"
			:options="chartoptions" :series="chartdata"></apexchart>
		<ChartLoaderInsert v-if="fetching"></ChartLoaderInsert>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
export default {
	name: "ApexBarTrendCategories",
	components: {
		ChartLoaderInsert
	},
	props: {
		fetching: Boolean,
		categories: Array,
		chartdata: Array,
		filename: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			chartoptions: {
				chart: {
					type: 'bar',
					height: 350
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: '80%',
						endingShape: 'rounded'
					},
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					show: true,
					width: 2,
					colors: ['transparent'],
				},
				legend: {
					markers: {
						fillColors: [],
					}
				},
				xaxis: {
					categories: [],
					labels: {
						rotate: 0,
						rotateAlways: false,
						hideOverlappingLabels: false,
					},
				},
				yaxis: {
					decimalsInFloat: 0,
					min: 0,
					forceNiceScale: true,
					labels: {
						formatter: (value) => {
							if(value === null) return null
							return value.toLocaleString()
						},
					},
				},
				fill: {
					opacity: 1,
					colors: [],
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return val.toLocaleString()
						}
					}
				}
			},
		}
	},
	computed: {},
	methods: {
		// updateCategories() { //need to do this manually as data is nested
		// 	var oldxaxis = this.chartoptions.xaxis;
		// 	oldxaxis.categories = this.categories;
		// 	this.chartoptions = {...this.chartoptions, ...{
		// 			xaxis: oldxaxis
		// 		}};
		// },
	},
	watch: {
		categories() {

		},
	},
	mounted() {
		this.chartoptions.legend.markers = this.store.chartColourGroupGradient
		this.chartoptions.fill.colors = this.store.chartColourGroupGradient
	}
}
</script>

<style scoped>

</style>