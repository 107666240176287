<template>
	<div>
		<table class="altbl noborderv market-table" id="markets">
			<thead>
				<tr>
					<td v-for="col in cols" :key="col.title" class="headingtext" @click="sortColumn(col.fldid)" data-f-bold="true">
						<span>{{col.title}}</span>
					</td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="rowitem in rows" :key="rowitem.id">
					<td>
						<span>
							<router-link class="link-text" :to="'/arn-markets/'+convertToUrlName(rowitem.name)">{{rowitem.name}}</router-link>
							<InfoIcon v-on:clicked="showMarketSummary(rowitem)" title="Click to view market information and recent survey results in popup"></InfoIcon>
						</span>
					</td>
					<td>{{rowitem.state}}</td>
					<td data-t="n"><p style="text-align: right;">{{numdisplay(rowitem.indpop)}}</p></td>
					<td data-t="n"><p style="text-align: right;">{{intToYes(rowitem.isCompetitive)}}</p></td>
					<td>
						<div v-for="o in rowitem.ownership.owns" :key="o" class="item-logo">
							<img v-if="!['rsn','sky','tab'].includes(o)" :src="store.logosRoot+'station-logos/'+o+'.png?v=2'" :style="'width:50px;height:40px;'" />
						</div>
					</td>
					<td>
						<div v-if="rowitem.isGfk === false">
							<div v-for="o in rowitem.ownership.reps" :key="o" class="item-logo">
								<img v-if="!['rsn','sky','tab'].includes(o)" :src="store.logosRoot+'station-logos/'+o+'.png?v=2'" :style="'width:50px;height:40px;'" />
							</div>
						</div>
					</td>
					<td class="ctr" data-t="n"><p style="text-align: right;">{{numdisplay(rowitem.cumeown10)}}</p></td>
					<td class="ctr" data-t="n"><p style="text-align: right;">{{numdisplay(rowitem.cume10)}}</p></td>
					<td class="ctr" data-t="n"><p style="text-align: right;">{{numdisplay(rowitem.cume2554)}}</p></td>
				</tr>
			</tbody>
		</table>
		<div style="margin: 10px 0;" class="primary-btn" @click="exportToExcel('markets','Markets')">Export</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import InfoIcon from "@/components/arnmarkets/InfoIcon";
import TableToExcel from "@linways/table-to-excel";
export default {
	name: "MarketsTable",
	components: {
		InfoIcon
	},
	props: {
		filtertext: String,
		inrows: Array,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			// table stuff
			sortColName: null,
			sortColOrder: 'desc',
			cols: [
				{fldid: 'name', title: 'Market Name', sortable: true},
				{fldid: 'state', title: 'State', sortable: true},
				{fldid: 'indpop', title: '10+ Pop', sortable: true, thClass: 'text-right', sortDirection: 'desc'},
				{fldid: 'isCompetitive', title: 'Competitive', sortable: true},
				{fldid: '', title: 'Ownership', sortable: true},
				{fldid: '', title: 'Representation', sortable: true},
				{fldid: 'cumeown10', title: 'ARN Own 10+ Cume', sortable: true, thClass: 'text-right', sortDirection: 'desc'},
				{fldid: 'cume10', title: 'ARN Rep 10+ Cume', sortable: true, thClass: 'text-right', sortDirection: 'desc'},
				{fldid: 'cume2554', title: 'ARN Rep 25-54 Cume', sortable: true, thClass: 'text-right', sortDirection: 'desc'},
			],
			//end table stuff
		}
	},
	computed: {
		//table stuff
		rows() {
			return this.tblPrepareFilteredRows(this.inrows, this.filtertext, this.sortColName, this.sortColOrder)
		},
		//end table stuff
	},
	methods: {
		exportToExcel(tblid, filename = 'export') {
			if(this.excelfilename && this.excelfilename.length > 0) {
				filename = this.excelfilename;
			}
			let t = document.getElementById(tblid)
			let table2 = t.cloneNode(true)
			table2.innerHTML = table2.innerHTML.replace(/,/g,'')
			TableToExcel.convert(table2, {
				name: filename+".xlsx",
				sheet: {
					name: filename
				}
			});
		},
		//table stuff
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		//end table stuff
		showMarketSummary(mktob) {
			this.$emit('showsum', mktob)
		}
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.item-logo {
	display: inline-block;
}
.item-logo img {
	width: 50px;
	height: 40px;
	object-fit: contain;
	margin-right: 5px;
}
</style>