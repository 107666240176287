<template>
	<div>
		<router-link to="/podcasts/podcast-ranker" class="link-btn" style="margin-right: 5px;" :class="{'active' : activeRoutePage === '/podcasts/podcast-ranker'}">
			<p>Show Ranker</p>
		</router-link>
		<router-link to="/podcasts/podcast-ranker-publishers" class="link-btn" style="margin-right: 5px;" :class="{'active' : activeRoutePage === '/podcasts/podcast-ranker-publishers'}">
			<p>Publishers</p>
		</router-link>
		<router-link to="/podcasts/show-visual-ranker" class="link-btn" style="margin-right: 5px;" :class="{'active' : activeRoutePage === '/podcasts/show-visual-ranker'}">
			<p>Visual Ranker</p>
		</router-link>
		<router-link to="/podcasts/top-movers" class="link-btn" style="margin-right: 5px;" :class="{'active' : activeRoutePage === '/podcasts/top-movers'}">
			<p>Top Movers</p>
		</router-link>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: 'PodcastCRAMenu',
	components: {

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			activeRoutePage: '',

		}
	},
	computed: {

	},
	methods: {},
	watch: {},
	props: {

	},
	mounted() {

	},
	created() {
		this.activeRoutePage = this.$route.path
	},
}
</script>

<style scoped>
.link-btn {
	border: 1px solid #DDD;
	padding: 8px 12px;
}
</style>
