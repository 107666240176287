<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">
			<div>
				<router-link to="/trade-marketing">
					<span class="primary-btn">Back</span>
				</router-link>
			</div>
			<div style="padding-top: 30px; display: flex; flex-wrap: wrap; justify-content: space-between;">
				<TradeMarketingChannelInfo :channeldata="adnews"></TradeMarketingChannelInfo>
				<TradeMarketingChannelInfo :channeldata="mumbrella"></TradeMarketingChannelInfo>
				<TradeMarketingChannelInfo :channeldata="mediaweek"></TradeMarketingChannelInfo>
				<TradeMarketingChannelInfo :channeldata="mediai"></TradeMarketingChannelInfo>
				<TradeMarketingChannelInfo :channeldata="bandt"></TradeMarketingChannelInfo>
				<TradeMarketingChannelInfo :channeldata="radiotoday"></TradeMarketingChannelInfo>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

import TradeMarketingChannelInfo from "@/components/TradeMarketingChannelInfo";
import PermissionChecker from "@/components/PermissionChecker";
export default {
	name: "TradeMarketingChannels",
	components: {PermissionChecker, TradeMarketingChannelInfo},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			viewPerms: ['elt','connections'],
			adnews: {
				name: 'adnews',
				title: 'AdNews',
				mediakiturl: '/public-files/trade-marketing/mediakits/adnews.pdf',
				newslettersub: '17,000',
				mnthwebsess: '',
				mthwebusers: '212,000',
				mthwebviews: '450,000',
				socialfollowers: '95,000',
				magazinesub: '5k dist, 25k readers',
			},
			mumbrella: {
				name: 'mumbrella',
				title: 'Mumbrella',
				mediakiturl: '/public-files/trade-marketing/mediakits/mumbrella.pdf',
				newslettersub: '54,400',
				mnthwebsess: '',
				mthwebusers: '',
				mthwebviews: '863,000',
				socialfollowers: '65,000',
				magazinesub: '',
			},
			mediaweek: {
				name: 'mediaweek',
				title: 'MediaWeek',
				mediakiturl: '/public-files/trade-marketing/mediakits/mediaweek.pdf',
				newslettersub: '85,000',
				mnthwebsess: '1,500,000',
				mthwebusers: '1,000,000',
				mthwebviews: '',
				socialfollowers: '30,7000',
				magazinesub: '',
			},
			mediai: {
				name: 'mediai',
				title: 'MediaI',
				mediakiturl: '/public-files/trade-marketing/mediakits/mediai.pdf',
				newslettersub: '',
				mnthwebsess: '',
				mthwebusers: '',
				mthwebviews: '',
				socialfollowers: '',
				magazinesub: '',
			},
			bandt: {
				name: 'bandt',
				title: 'B&T',
				mediakiturl: 'https://misfitsmedia.shorthandstories.com/bandt/mediakit/index.html',
				newslettersub: '34,500',
				mnthwebsess: '345,000',
				mthwebusers: '320,000',
				mthwebviews: '560,000',
				socialfollowers: '',
				magazinesub: '',
			},
			radiotoday: {
				name: 'radiotoday',
				title: 'Radio Today',
				mediakiturl: '/public-files/trade-marketing/mediakits/radiotoday.pdf',
				newslettersub: '10,000',
				mnthwebsess: '172,000',
				mthwebusers: '120,000',
				mthwebviews: '260,000',
				socialfollowers: '18,500',
				magazinesub: '',
			},
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>