<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0" width="99%" height="100%" type="bar"
			:options="chartoptions" :series="chartdata"></apexchart>
		<ChartLoaderInsert v-if="fetching"></ChartLoaderInsert>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
export default {
	name: "ApexBarTrend",
	components: {
		ChartLoaderInsert
	},
	props: {
		fetching: Boolean,
		categories: Array,
		chartdata: Array,
		filename: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			chartoptions: {
				chart: {
					id: this.filename,
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					},
					type: 'bar',
				},
				plotOptions: {
					bar: {
						horizontal: false,
						endingShape: 'rounded',
						columnWidth: '80%',
					},
				},
				xaxis: {
					type: "category",
					labels: {
						show: true,
						hideOverlappingLabels: false
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 1,
					min: 0,
					forceNiceScale: true,
				},
				colors: [
					'rgb(103, 183, 220)',
					'rgb(103, 148, 220)',
					'rgb(103, 113, 220)',
					'rgb(128, 103, 220)',
					'rgb(163, 103, 220)',
					'rgb(199, 103, 220)',
					'rgb(220, 103, 206)',
					'rgb(220, 103, 171)',

				],
				dataLabels: {
					enabled: false
				},
			},
		}
	},
	computed: {},
	methods: {
		// updateCategories() { //need to do this manually as data is nested
		// 	var oldxaxis = this.chartoptions.xaxis;
		// 	oldxaxis.categories = this.categories;
		// 	this.chartoptions = {...this.chartoptions, ...{
		// 			xaxis: oldxaxis
		// 		}};
		// },
	},
	watch: {
		categories() {
			// this.updateCategories();
		},
	},
	mounted() {
		// this.updateCategories();
	}
}
</script>

<style scoped>

</style>