<template>
	<div @click="$emit('clicked')" class="info" :class="cls">
		<i class="mdi mdi-information-variant" :title="title"></i>
	</div>
</template>

<script>

export default {
	name: "InfoIcon",
	components: {},
	props: {
		title: String,
		addclasses: String,
	},
	data: function () {
		return {}
	},
	computed: {
		cls() {
			if(this.addclasses && this.addclasses.length > 0) {
				return this.addclasses;
			}
			return '';
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.info {
	padding: 2px;
	cursor: pointer;
	display: inline-block;
	margin-left: 5px;
	border-radius: 50%;
	border: 1px solid #0f75a5;
	transition: all 0.2s ease-in-out;
}
.info:hover {
	background-color: #0f75a5;
	color: #FFF;
}
.info i {
	font-size: 18px;
}
.info.small {
	margin-left: 10px;
}
.info.small i {
	font-size: 13px;
}
</style>