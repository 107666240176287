<template>
	<div>
		<p style="font-weight: bold; font-size: 14px; padding: 10px 0;">{{headerText}}</p>
		<div v-if="qgroup.layout === 'SingleChoice'">
			<div v-for="q in qgroup.questions" :key="q.id">
				<div v-for="opt in q.options" :key="opt.id" style="width: 800px; border-bottom: 1px solid #DDD; padding: 3px; position: relative; display: flex;">
					<div style="width: 400px; display: flex; justify-content: space-between; align-items: center;">
						<span style="font-size: 12px;">{{opt.label}}</span>
						<div>
							<span style="width: 50px; display: inline-block; text-align:right; font-size: 12px;">{{opt.count}}</span>
							<span style="width: 50px; display: inline-block; text-align:right; font-size: 12px; padding-right: 10px;">{{opt.pct}}%</span>
						</div>
					</div>
					<div style="width: 400px; background-color: #E8E8E8; position: relative;">
						<div style="background-color: #553AA9; width: 50%; left:0; top: 0; height: 100%; position: absolute;" :style="'width:'+opt.pct+'%'"></div>
					</div>
					<div style="width: 300px; padding-left: 10px;">
						<span v-for="(hval, index) in opt.history" :key="index">{{hval}}, </span>
						<span>{{opt.pct}}</span>
					</div>
				</div>
			</div>
		</div>

		<div v-if="qgroup.layout === 'MultiChoice'">
			<div v-for="q in qgroup.questions" :key="q.id" style="width: 800px; border-bottom: 1px solid #DDD; padding: 3px; position: relative;">
				<div v-for="opt in q.options" :key="opt.id" style="position: relative; display: flex;">
					<div v-if="opt.id > 0" style="width: 400px; display: flex; justify-content: space-between; align-items: center;">
						<span style="font-size: 12px;">{{q.qtext}}</span>
						<div>
							<span style="width: 50px; display: inline-block; text-align:right; font-size: 12px;">{{opt.count}}</span>
							<span style="width: 50px; display: inline-block; text-align:right; font-size: 12px; padding-right: 10px;">{{opt.pct}}%</span>
						</div>
					</div>
					<div style="width: 400px; background-color: #E8E8E8; position: relative;">
						<div style="background-color: #553AA9; width: 50%; left:0; top: 0; height: 100%; position: absolute;" :style="'width:'+opt.pct+'%'"></div>
					</div>
					<div v-if="opt.id > 0" style="width: 300px; padding-left: 10px;">
						<span v-for="(hval, index) in opt.history" :key="index">{{hval}}, </span>
						<span>{{opt.pct}}</span>
					</div>
				</div>
			</div>
		</div>

		<div v-if="qgroup.layout === 'SingleChoiceGrid' || qgroup.layout === 'RankOrderGrid'">
			<div v-for="q in qgroup.questions" :key="q.id" style="width: 1000px; border-bottom: 1px solid #DDD; padding: 5px; position: relative; display:flex;">
				<div style="width: 400px; display:flex; align-items: center;">
						<span style="font-size: 12px;">
							{{q.qtext}}
						</span>
				</div>
				<div style="width: 700px;">
					<div v-for="opt in q.options" :key="opt.id" style="position: relative; width: 100%; border-bottom: 1px solid #DDD; display: flex;">
						<div v-if="opt.id > 0" style="display: flex; width: 300px; justify-content: space-between; align-items: center;">
							<span style="font-size: 12px;">{{opt.label}}</span>
							<div>
								<span style="width: 50px; display: inline-block; text-align:right; font-size: 12px;">{{opt.count}}</span>
								<span style="width: 50px; display: inline-block; text-align:right; font-size: 12px; padding-right: 10px;">{{opt.pct}}%</span>
							</div>
						</div>
						<div style="width: 400px; background-color: #E8E8E8; position: relative;">
							<div style="background-color: #553AA9; width: 50%; left:0; top: 0; height: 100%; position: absolute;" :style="'width:'+opt.pct+'%'"></div>
						</div>
						<div v-if="opt.id > 0" style="width: 300px; padding-left: 10px;">
							<span v-for="(hval, index) in opt.history" :key="index">{{hval}}, </span>
							<span>{{opt.pct}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="qgroup.layout === 'MultiChoiceGrid'">
			<div v-for="q in qgroup.questions" :key="q.id" style="width: 1300px; display: flex; border-bottom: 1px solid #DDD; padding: 5px; position: relative;">
				<div style="width: 600px; display:flex; align-items: center; justify-content: space-between;">
						<span style="font-size: 12px;">
							{{q.parent_coltext}} - {{q.qtext}}
						</span>
					<div v-for="opt in q.options" :key="opt.id">
						<div v-if="opt.id > 0" style="display: flex; justify-content: space-between; align-items: center;">
							<div>
								<span style="width: 50px; display: inline-block; text-align:right; font-size: 12px;">{{opt.count}}</span>
								<span style="width: 50px; display: inline-block; text-align:right; font-size: 12px; padding-right: 10px;">{{opt.pct}}%</span>
							</div>
						</div>
					</div>
				</div>
				<div style="width: 400px; background-color: #E8E8E8; position: relative;">
					<div v-for="opt in q.options" :key="opt.id">
						<div v-if="opt.id > 0">
							<div style="background-color: #553AA9; width: 50%; left:0; top: 0; height: 100%; position: absolute;" :style="'width:'+opt.pct+'%'"></div>
						</div>
					</div>
				</div>
				<div v-for="opt in q.options" :key="opt.id" style="width: 300px; padding-left: 10px;">
					<div v-if="opt.id > 0">
						<span v-for="(hval, index) in opt.history" :key="index">{{hval}}, </span>
						<span>{{opt.pct}}</span>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>

export default {
	name: 'BrandTrackChartSingle',
	components: {},
	data() {
		return {
			sparktestvals: [35,38,56,45,22],
			sparktestlabels: ['1','2','3','4','5'],
		}
	},
	computed: {
		headerText() {
			return this.qgroup.qtext+" (n="+this.qgroup.sampleSize+")";
		}
	},
	methods: {

	},
	watch: {},
	props: {
		qgroup: Object,
	},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>

</style>
