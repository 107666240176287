import { reactive } from 'vue'

// let streamingApiUrl = 'https://str.arnconnect.com.au/';
let msalcallbackurl = 'https://arnconnect.com.au/sso-callback';
if(window.location.origin.indexOf("localhost") > -1 || window.location.origin.indexOf("loc.") > -1) {
	// streamingApiUrl = 'http://arnstreaming.test/';
	msalcallbackurl = 'http://localhost:8080/sso-callback';
}

let freqApiUrl = 'https://yourwavelength.com/central/post-freq-vue/';
// if (window.location.origin.indexOf('localhost') > -1) {
// 	freqApiUrl = 'http://wavelength.test/central/post-freq-vue/';
// }

export const store = reactive({
	kalert: {
		shown: false,
		text: "",
		type: 'danger',
	},
	initialObjectsReceived: false,
	natCumesLoaded: false,
	natCumeDateToUse: null,
	user: {
		id: 0,
		email: '',
		token: '',
		permissions: [],
	},
	mouseClickInFilter: false,
	mouseClickAnywhere: false,
	// streamingApiUrl: streamingApiUrl,
	chartColourGroup: [
		'#3498DB','#27AE60','#D4AC0D','#D35400','#616A6B','#F1948A','#222222','#D16d77','#226622'
	],
	chartColourGroupARNFirst: [
		'#553AA9','#3498DB','#27AE60','#D4AC0D','#D35400','#616A6B','#F1948A'
	],
	chartColourGroupGradient: [
		'#7bb7db', '#7295d9', '#6c74d9',
		'#7f6bda', '#9d6bda', '#bf6ddb',
		'#d16dcd', '#d06caa', '#d16d77',
		'#cf6b87', '#d18c6b', '#d8af71',
		'#85de70'
	],
	netcolors: [
		{key: 'arn_k', col: '#e50076'},
		{key: 'kiis', col: '#e50076'}, //gfk arnport key
		{key: 'arn', col: '#e50076'},
		{key: 'arn_p', col: '#EDCA62'},
		{key: 'pg', col: '#EDCA62'}, //gfk arnport key
		{key: 'sca_h', col: '#1bcfc9'},
		{key: 'hit', col: '#1bcfc9'}, //gfk arnport key
		{key: 'sca', col: '#1bcfc9'},
		{key: 'sca_m', col: '#fc6402'},
		{key: 'm', col: '#fc6402'}, //gfk arnport key
		{key: 'abc_loc', col: '#000000'},
		{key: 'abc_j', col: '#f33e2a'},
		{key: 'nine', col: '#092e52'},
		{key: 'nova', col: '#eb1c24'},
		{key: 'novaent', col: '#eb1c24'},
		{key: 'nova_n', col: '#eb1c24'},
		{key: 'nova_s', col: '#005094'},
		{key: 'smooth', col: '#005094'}, //gfk arnport key
		{key: 'ace', col: '#0055a5'},
		{key: 'spotify', col: '#1ed760'},
		{key: 'cada', col: '#718fff'},
	],
	defaultColors: ['#003f5c','#42d534','#2f4b7c','#665191','#a05195','#d45087','#f95d6a','#ff7c43','#ffa600','#a62166'],
	femaleMaleColors:  ['#e50076','#0055a5'],

	msalcallbackurl: msalcallbackurl,
	totcumes: { //initialise zero values.  total will be metro + gfkreg + xtrareg (the amfm & dab are just splits - do not add)
		owned10: {metro: 0, gfkreg: 0, xtrareg: 0, metroamfm: 0, metrodab: 0},
		repped10: {metro: 0, gfkreg: 0, xtrareg: 0, metroamfm: 0, metrodab: 0},
		latestSurveys: {metro: null, gc: null, newc: null, cbr: null, xtra: null},
		owned10_530: {metro: 0, gfkreg: 0, xtrareg: 0, metroamfm: 0, metrodab: 0},
		repped10_530: {metro: 0, gfkreg: 0, xtrareg: 0, metroamfm: 0, metrodab: 0},
	},

	//audology api stuff
	logosRoot: 'https://platform.audology.app/public-assets/',
	audologyAPIUrl: 'https://platform.audology.app/api/',
	audologyDashId: 'arn-gdl3sdgfdq-9dlgzfbve',
	audologyAPICreds: {
		api_key: 'bd9akdsl3adfladfad',
		client_secret: 'moadsf03da83raf',
	},
	gfkMktIds: [110,111,112,113,114,14,19,72],
	gfkMetroMktIds: [110,111,112,113,114],
	markets: [],
	surveys: [],
	stations: [],
	stationcombos: [],
	demos: [],
	statistics: [],
	tvaggmkts: [],
	qtrHrObs: [],
	arnComboIds: [
		'c_2029', //ARN KIIS network
		'c_2030', //ARN KIIS network excl DAB
		'c_110', //ARN Total Network
		// 'c_2198', //ARN Total Network (internal market only - Xtra)
		'c_2028', //ARN Total DAB Only
		'c_2013', //ARN Network excl DAB
		'c_2031', //ARN PG network
		'c_2032', //ARN PG network excl DAB
		'c_227', //ARN Representation
		// 'c_2199', //ARN Representation (internal market only - Xtra)
		'c_2016', //ARN Representation excl DAB
		'c_2162', //ARN Representation DAB only
	],
	//end audology api stuff

	//freq api stuff
	freqApiUrl: freqApiUrl,
	freqUserOb: {
		id: 1483,
		token: '4LcARGoz',
		email: 'arndash@arn.com.au',
	},
	freqCacheData: [],
	//end freq api stuff

	//sales campaigns
	sales_campaign: {
		id: 0,
		campaign_name: '',
		client_name: '',
		camp_start_date: '',
		camp_end_date: '',
		budget_estimate: null,
		demographic_text: '',
		demographic_id: null,
		station_rows: [],
		totalSpots: null,
		totalValue: null,
		totalCost: null,
	},
	//end sales campaigns
})