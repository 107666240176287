<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0" width="99%" height="100%" type="line"
			:options="chartoptions" :series="chartdata"></apexchart>
		<ChartLoaderInsert v-if="fetching"></ChartLoaderInsert>
	</div>
</template>

<script>
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ApexFreqLineTrendMultiAudology",
	mixins: [globalMixin],
	components: {
		ChartLoaderInsert
	},
	props: {
		fetching: Boolean,

		chosenstatistic: String,
		chosendemo: Number,
		chosendaypart: Number,
		chosenradiotype: {
			type: Number,
			default: 0
		},
		basedata: Array,
		chartstationlist: Array,
		surveylist: Array,
	},
	data: function () {
		return {
			store: store,

			chartoptions: {
				chart: {
					id: "ratings",
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					}
				},
				xaxis: {
					type: "category",
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 1,
					min: 0,
					forceNiceScale: true,
				},
				// colors: [],
				stroke: {
					curve: "straight",
					width: 3,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
			},
		}
	},
	computed: {
		chartdata() {
			let rows = []
			for(let st of this.chartstationlist) {
				let myrow = []
				myrow = {
					name: st.name,
					data: [],
				}
				for(let surv of this.surveylist) {
					myrow.data.push({x: surv.nicename, y: this.getCellDataAud(st.id, surv.id, this.basedata, this.chosenstatistic, this.chosendaypart, this.chosendemo, this.chosenradiotype)})
				}
				myrow.data.reverse()
				rows.push(myrow)
			}
			return rows
		},
		chartcolors() {
			if(!this.getChartColorForStationAudology) return this.store.defaultColors
			let defaultColorCount = 0
			let colors = []
			for(let st of this.chartstationlist) {
				let arr = this.getChartColorForStationAudology(st, defaultColorCount)
				colors.push(arr[0])
				defaultColorCount = arr[1]
			}
			return colors
		},
	},
	methods: {
		updateColours(isFirstLoad = false) {
			let outcols = this.store.defaultColors;
			if(this.chartcolors && this.chartcolors.length > 0) {
				outcols = this.chartcolors;
			}
			if(isFirstLoad) {
				this.chartoptions.colors = outcols;
			}
			else { //only for subsequent loads/updates - otherwise chart is duplicated
				this.chartoptions = {
					...this.chartoptions, ...{
						colors: outcols
					}
				};
			}
		},
	},
	watch: {
		chartcolors() {
			this.updateColours()
		}
	},
	mounted() {
		this.updateColours(true)
	}
}
</script>

<style scoped>

</style>