<template>
    <div class="filter-container" style="margin-right: 10px;" @click="handleInternalClick">
		<i v-if="icon_only && icon_only === true" class="mdi mdi-filter" @click.stop="toggle_items"></i>
        <div v-else class="filter-placeholder" @click="toggle_items">
			<span>{{label}}</span>
			<i v-if="!show_items" class="mdi mdi-chevron-down"></i>
			<i v-if="show_items" class="mdi mdi-chevron-up"></i>
        </div>
        <div v-if="show_items" class="filter-list">
			<div v-if="has_search" class="filter-search">
				<input type="text" v-model="textsearch" placeholder="Search" />
				<i class="mdi mdi-close" @click.stop="textsearch = ''"></i>
			</div>
			<slot name="items"></slot>
        </div>
        <div class="filter-selections">
			<slot name="selecteds"></slot>
        </div>
    </div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
    name: "FilterItem",
	mixins: [globalMixin],
    props: {
        label: {
            type: String,
            required: true,
        },
		has_search: Boolean,
		icon_only: Boolean,
    },
    data() {
        return {
			store: store,
            show_items: false,
			textsearch: '',
			internalClick: false,
		}
    },
    computed: {

    },
    methods: {
		handleInternalClick() {
			this.internalClick = true
			setTimeout(function() {
				this.internalClick = false
			}.bind(this), 100)
		},
        toggle_items() { //handles clicks from this item
            this.show_items = !this.show_items
        },
        remove_item(item) {
            let index = this.selected.findIndex((s) => s === item)
            this.selected.splice(index, 1)
        }
    },
	watch: {
		textsearch() {
			this.$emit('searchval', this.textsearch)
		},
		'store.mouseClickAnywhere'() {
			//catch clicks from elsewhere on page
			if(this.internalClick === false) {
				this.show_items = false
			}
		}
	}
}
</script>

<style scoped>
.filter-placeholder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f1f2f6;
    border: 1px solid #DDD;
    padding: 10px 12px;
    cursor: pointer;
}
.filter-placeholder i {
    font-size: 18px;
    color: #888;
    margin-left: 10px;
}
.filter-list {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	max-width: 300px;
    max-height: 200px;
    overflow-y: auto;
    padding: 5px 10px;
    z-index: 1;
}

</style>
