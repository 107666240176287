<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">
<!--			<div style="">-->
<!--				<p style="font-size: 22px; font-weight: bold;">Streaming now: {{liveListenNowNum}}</p>-->
<!--				<p style="font-size: 12px; color: #BBB;">{{latest_time_utc}} UTC</p>-->
<!--			</div>-->
			<div style="margin-top: 20px;">
				<div v-for="r in latestVals" :key="r.name" style="display: flex;">
					<div>
						<p style="font-size: 22px; font-weight: bold;">{{r.name}}: {{numdisplay(r.value * 1000)}}</p>
						<p style="font-size: 12px; color: #BBB;" v-if="r.source && r.source.length > 0">{{r.source}}</p>
					</div>
				</div>
			</div>
			<div class="chart-rows">
				<ChartHolderDash
						charttitle="Streaming Weekly Unique Listeners" :height="420"
						:source="streamChartsSource" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="streamApexDataUniques.streamstntrnd" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash
						charttitle="Streaming Weekly Total Hours (000s)" :height="420"
						:source="streamChartsSource" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="streamApexData.streamstntrnd" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash
						charttitle="Streaming Monthly Unique Listeners" :height="420"
						:source="streamChartsSource" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="streamApexDataUniquesMth.streamstntrnd" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash
						charttitle="Streaming Monthly Total Hours (000s)" :height="420"
						:source="streamChartsSource" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="streamApexDataMth.streamstntrnd" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash
						charttitle="Website Weekly Sessions" :height="420"
						:source="webChartsSource.w_sessions" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="webApexData.w_sessions" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash
						charttitle="Website Weekly Users" :height="420"
						:source="webChartsSource.w_users" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="webApexData.w_users" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash
						charttitle="Website Monthly Sessions" :height="420"
						:source="webChartsSourceMth.m_sessions" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="webApexDataMth.m_sessions" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash
						charttitle="Website Monthly Users" :height="420"
						:source="webChartsSourceMth.m_users" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="webApexDataMth.m_users" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash
						charttitle="Facebook Weekly Impressions" :height="420"
						:source="fbChartsSource.w_impressions" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="fbApexData.w_impressions" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash
						charttitle="Facebook Monthly Impressions" :height="420"
						:source="fbChartsSourceMth.m_impressions" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="fbApexDataMth.m_impressions" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash
						charttitle="Instagram Weekly Impressions" :height="420"
						:source="instaChartsSource.w_impressions" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="instaApexData.w_impressions" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="18-29 Cume DAB SMBAP" :height="420" source="GFK Metro Radio Ratings" :addclasses="'half'">
					<template v-slot:default><ApexLineTrend :chartdata="gfk1829" :fetching="getGFKFetching"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash charttitle="10+ Cume DAB Sydney" :height="220" source="GFK Metro Radio Ratings" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="gfkData[0]" :fetching="getGFKFetching"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="10+ TSL DAB Sydney" :height="220" source="GFK Metro Radio Ratings" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="gfkData[5]" :fetching="getGFKFetching"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="10+ Cume DAB Melbourne" :height="220" source="GFK Metro Radio Ratings" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="gfkData[1]" :fetching="getGFKFetching"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="10+ Cume DAB Brisbane" :height="220" source="GFK Metro Radio Ratings" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="gfkData[2]" :fetching="getGFKFetching"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash charttitle="10+ Cume DAB Adelaide" :height="220" source="GFK Metro Radio Ratings" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="gfkData[3]" :fetching="getGFKFetching"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="10+ Cume DAB Perth" :height="220" source="GFK Metro Radio Ratings" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="gfkData[4]" :fetching="getGFKFetching"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="14+ CMV Claimed Reach" :height="220" source="Nielsen CMV" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="cmv14" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="18-29 CMV Claimed Reach" :height="220" source="Nielsen CMV" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="cmv1829" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>
<!--			<div class="chart-rows">-->
<!--				<ChartHolderDash v-if="!getPodcastFetching"-->
<!--						charttitle="Podcasts - Flex & Froomes Unique Listeners" :height="220"-->
<!--						source="Triton" :addclasses="'quarter'">-->
<!--					<template v-slot:default><ApexLineTrend :chartdata="apexdata[0].triton_show_uniques" :fetching="getPodcastFetching"></ApexLineTrend></template>-->
<!--				</ChartHolderDash>-->
<!--				<ChartHolderDash v-if="!getPodcastFetching"-->
<!--						charttitle="Podcasts - Kian & Yaz with Avneesha Unique Listeners" :height="220"-->
<!--						source="Triton" :addclasses="'quarter'">-->
<!--					<template v-slot:default><ApexLineTrend :chartdata="apexdata[1].triton_show_uniques" :fetching="getPodcastFetching"></ApexLineTrend></template>-->
<!--				</ChartHolderDash>-->
<!--				<ChartHolderDash v-if="!getPodcastFetching"-->
<!--						charttitle="Podcasts - Back It Up Unique Listeners" :height="220"-->
<!--						source="Triton" :addclasses="'quarter'">-->
<!--					<template v-slot:default><ApexLineTrend :chartdata="apexdata[2].triton_show_uniques" :fetching="getPodcastFetching"></ApexLineTrend></template>-->
<!--				</ChartHolderDash>-->
<!--			</div>-->
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from 'axios'
import PermissionChecker from "@/components/PermissionChecker";
import ChartHolderDash from "@/components/ChartHolderDash";
import ApexLineTrend from "@/components/charts/ApexLineTrend";
export default {
	name: "CADAMain",
	components: {
		PermissionChecker,
		ChartHolderDash,
		ApexLineTrend,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			viewPerms: ['elt','connections','cada'],
			// stationParams: [
			// 	{name: 'CADA AEST & 96.1 FM', streamstnid: 3},
			// 	// {name: 'CADA QLD', streamstnid: 32},
			// 	// {name: 'CADA ADL', streamstnid: 33},
			// 	// {name: 'CADA NT', streamstnid: 34},
			// 	// {name: 'CADA WA', streamstnid: 35},
			// ],

			streamChartsSource: 'ARN Triton/AdsWizz Logs - updated monthly',
			streamApexData: {},
			streamApexDataUniques: {},
			streamApexDataMth: {},
			streamApexDataUniquesMth: {},
			fetchedStreams: 0,
			liveListenNowNum: 0,
			latest_time_utc: null,

			webApexData: {},
			webChartsSource: {},
			webApexDataMth: {},
			webChartsSourceMth: {},

			fbApexData: {},
			fbChartsSource: {},
			fbApexDataMth: {},
			fbChartsSourceMth: {},

			instaApexData: {},
			instaChartsSource: {},

			gfkData: [],
			fetchedGfks: 0,

			chartsSource: [],
			apexdata: [],
			fetchedLocal: 0,

			latestCMV: null,
			cmv14: [],
			cmv1829: [],
			gfk1829: [],
		}
	},
	computed: {
		endOfLastMonthYmd() {
			let date = new Date()
			date.setDate(0); //remember month index starts at 0 so need to +1 below to get 9=sep eg
			let m = (date.getMonth()+1).toString()
			if(m.length === 1) m = '0'+m
			return date.getFullYear().toString() + '-' + m + '-' + date.getDate().toString()
		},
		endOfLastWeek() {
			let date = new Date();
			let currDayOfWeek = date.getDay(); //1=Mon, 2=Tue etc
			if(currDayOfWeek < 3) { //we probably don't have last week's figures yet (prior to Wed), roll back one week
				date.setDate(date.getDate() - 7 - date.getDay());
			}
			else {
				date.setDate(date.getDate() - date.getDay());
			}
			return date.getFullYear().toString() + '-' + (date.getMonth()+1).toString() + '-' + date.getDate().toString()
		},
		twoDaysAgo() {
			let date = new Date();
			date.setDate(date.getDate() - 2);
			return date.getDate().toString() + '-' + (date.getMonth()+1).toString() + '-' + date.getFullYear().toString();
		},
		getChartsBtnWorking() {
			return false;
		},
		getGFKFetching() {
			if(this.fetchedGfks >= 5) return false;
			return true;
		},
		getPodcastFetching() {
			if(this.fetchedLocal >= 3) return false;
			return true;
		},
		latestVals() {
			let r = []
			if(this.latestCMV) r.push({name: 'CMV Weekly Reach (96.1)', value: this.latestCMV.reach, source: this.latestCMV.survey})
			return r
		},
	},
	methods: {
		prepare() {
			//report ids 1 TLH, 2 AAS, 3 cume (IHR method), 4 totSess, 5 totCumeIAB
			this.getStreamingData('tlh', 'w', 'streamApexData');
			this.getStreamingData('cume', 'w', 'streamApexDataUniques');

			this.getStreamingData('tlh', 'm', 'streamApexDataMth');
			this.getStreamingData('cume', 'm', 'streamApexDataUniquesMth');

			this.getGFKTempSMBAP()

			this.getGFKDataAud(0,'cume',48,1,['g_201026'], 110); //syd
			this.getGFKDataAud(1,'cume',48,1,['g_301027'], 111); //mel
			this.getGFKDataAud(2,'cume',48,1,['g_701019'], 112); //bne
			this.getGFKDataAud(3,'cume',48,1,['g_801018'], 113); //adl
			this.getGFKDataAud(4,'cume',48,1,['g_901034'], 114); //per
			this.getGFKDataAud(5,'tslHrsDecimal',48,1,['g_201026'], 110); //syd

			this.getFBData('w');
			this.getFBData('m');
			this.getInstaData();

			this.getPodcastData(3174, 0); //flex & froomes
			this.getPodcastData(4014, 1); //kian, yaz & avneesha
			this.getPodcastData(3172, 2); //back it up

			this.getCMVData()
		},
		getCMVData() {
			let self = this;
			let targ = "/api/cmv/get-latest.php?station=CADA&demo="+encodeURIComponent("14+");
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					self.latestCMV = ret.cmv
					self.cmv14 = ret.cmv_chart_0
					self.cmv1829 = ret.cmv_chart_1
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getPodcastData(showId, dataIndexId) {
			let self = this;
			let targ = "/api/podcast-get-chart.php?showid="+showId;
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					if (ret.apexdata != null) self.apexdata[dataIndexId] = ret.apexdata;
					if (ret.chartsSource != null) self.chartsSource = ret.chartsSource;
					self.fetchedLocal++;
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getStreamingData(statistic, cadence, targobname) {
			let endday = this.endOfLastWeek;
			let startday = "2022-01-03";
			if(cadence === 'm') { //monthly cume
				startday = "2022-01-01";
				endday = this.endOfLastMonthYmd
			}
			let str = "statistic="+statistic+"&stncode=CADA|CADA NT|CADA QLD|CADA SA|CADA WA&daypart=overall&ptype=" + cadence + "&grouptogether=1"
			str += "&startdate=" + startday + "&enddate=" + endday + "&dist=all"
			let self = this
			let targ = "/api/streaming-get-gb.php?"+str
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
					return [];
				}
				else {
					self.fetchedStreams++;
					self[targobname] = ret.chartsData;
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getWebData(cadencekey) {
			let self = this;
			let targ = "/api/web-get-analytics.php?site=cada&period="+cadencekey+"&filter=all";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.webdata != null) {
						if(cadencekey === 'w') {
							self.webApexData = ret.webdata.apexdata;
							self.webChartsSource = ret.webdata.chartsSource;
						}
						else if(cadencekey === 'm') {
							self.webApexDataMth = ret.webdata.apexdata;
							self.webChartsSourceMth = ret.webdata.chartsSource;
						}
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getFBData(cadencekey) {
			let self = this;
			let fbpageid = '333474063572';
			let targ = "/api/fb-get-analytics.php?pageid="+fbpageid+"&period="+cadencekey+"&metric=impressions";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.fbdata != null) {
						if(cadencekey === 'w') {
							self.fbApexData = ret.fbdata.apexdata;
							self.fbChartsSource = ret.fbdata.chartsSource;
						}
						else if(cadencekey === 'm') {
							self.fbApexDataMth = ret.fbdata.apexdata;
							self.fbChartsSourceMth = ret.fbdata.chartsSource;
						}
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getInstaData() {
			let self = this;
			let fbpageid = '17841401691482550';
			let targ = "/api/insta-get-analytics.php?pageid="+fbpageid+"&period=w&metric=impressions";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.fbdata != null) {
						self.instaApexData = ret.fbdata.apexdata;
						self.instaChartsSource = ret.fbdata.chartsSource;
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getGFKDataAud(indexid, statistic,demoid,daypart,stns,gfkmktid) {
			let self = this
			let sets = []
			let type = 'standard'
			let surveylistobs = this.getSurveyObsForMarket(gfkmktid)
			let surveylistIds = this.getSurveyIdsFromSurveyObs(surveylistobs)
			surveylistIds = surveylistIds.slice(0, 10)
			if(gfkmktid === 900) type = 'smbap'
			sets.push({
				type: type,
				dayparts: [daypart],
				demos: [demoid],
				statistics: [statistic],
				surveylist: surveylistIds,
				stationIds: stns,
				market: gfkmktid,
				audioTypeIds: [0],
			})
			this.doGetCacheDataAudology(sets).then(function(ret) {
				if(ret.error === 1) console.log(ret)
				else {
					self.gfkData[indexid] = self.prepareLineFromAudData(sets[0], ret.returnedData)
				}
				self.fetchedGfks++;
			})
		},
		getGFKTempSMBAP() {
			let self = this;
			let targ = "/api/cada-get-smbap.php";
			axios.get(targ).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.cada_gfk_temp != null) {
						self.gfk1829 = ret.cada_gfk_temp
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		'store.initialObjectsReceived': {
			handler() {
				if(this.store.initialObjectsReceived === true) this.prepare()
			},
		},
	},
	mounted() {
		document.title = 'CADA Dashboard';
		if(this.store.initialObjectsReceived === true) this.prepare()
		// this.getRealtime();
		this.getWebData('w');
		this.getWebData('m');
	}
}
</script>

<style scoped>

</style>