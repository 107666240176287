<template>
	<div>
		<div>
			<div v-if="store.user.id === 1 || store.user.id === 2">
				<input type="text" class="filterbox" v-model="store.natCumeDateToUse" />
				<div class="primary-btn" style="display: inline; margin-left: 10px; padding: 8px 20px 7px; font-size: 12px;" @click="getAudologyNationalCumes()">Update</div>
			</div>
			<br/><br/>
			<p class="heading">10+ Represented Cume Mon-Sun 12am-12am</p>
			<table>
				<thead>
					<tr>
						<td style="width: 180px;"></td>
						<td class="rt">ARN Metro (SMBAP)</td>
						<td class="rt">ARN Regional (GFK)</td>
						<td class="rt">ARN Regional (Xtra)</td>
						<td class="rt">ARN Regional (Total)</td>
						<td class="rt">ARN Total</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="bold">AM/FM/DAB+</td>
						<td class="rt">{{ifReady(cumedata.repped10.metro)}}</td>
						<td class="rt">{{ifReady(cumedata.repped10.gfkreg)}}</td>
						<td class="rt">{{ifReady(cumedata.repped10.xtrareg)}}</td>
						<td class="rt">{{ifReady(cumedata.repped10.xtrareg + cumedata.repped10.gfkreg)}}</td>
						<td class="rt">{{ifReady(cumedata.repped10.metro + cumedata.repped10.xtrareg + cumedata.repped10.gfkreg)}}</td>
					</tr>
					<tr>
						<td class="bold">AM/FM Only</td>
						<td class="rt">{{ifReady(cumedata.repped10.metroamfm)}}</td>
						<td class="rt">{{ifReady(cumedata.repped10.gfkreg)}}</td>
						<td class="rt">{{ifReady(cumedata.repped10.xtrareg)}}</td>
						<td class="rt">{{ifReady(cumedata.repped10.xtrareg + cumedata.repped10.gfkreg)}}</td>
						<td class="rt">{{ifReady(cumedata.repped10.metroamfm + cumedata.repped10.xtrareg + cumedata.repped10.gfkreg)}}</td>
					</tr>
					<tr>
						<td class="bold">DAB Metro Only</td>
						<td class="rt">{{ifReady(cumedata.repped10.metrodab)}}</td>
						<td class="rt"></td>
						<td class="rt"></td>
						<td class="rt"></td>
						<td class="rt">{{ifReady(cumedata.repped10.metrodab)}}</td>
					</tr>
				</tbody>
			</table>

			<br/><br/><br/>

			<p class="heading">10+ Represented Cume Mon-Sun 5.30am-12am</p>
			<table>
				<thead>
				<tr>
					<td style="width: 180px;"></td>
					<td class="rt">ARN Metro (SMBAP)</td>
					<td class="rt">ARN Regional (GFK)</td>
					<td class="rt">ARN Regional (Xtra)</td>
					<td class="rt">ARN Regional (Total)</td>
					<td class="rt">ARN Total</td>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td class="bold">AM/FM/DAB+</td>
					<td class="rt">{{ifReady(cumedata.repped10_530.metro)}}</td>
					<td class="rt">{{ifReady(cumedata.repped10_530.gfkreg)}}</td>
					<td class="rt">{{ifReady(cumedata.repped10_530.xtrareg)}}</td>
					<td class="rt">{{ifReady(cumedata.repped10_530.xtrareg + cumedata.repped10_530.gfkreg)}}</td>
					<td class="rt">{{ifReady(cumedata.repped10_530.metro + cumedata.repped10_530.xtrareg + cumedata.repped10_530.gfkreg)}}</td>
				</tr>
				<tr>
					<td class="bold">AM/FM Only</td>
					<td class="rt">{{ifReady(cumedata.repped10_530.metroamfm)}}</td>
					<td class="rt">{{ifReady(cumedata.repped10_530.gfkreg)}}</td>
					<td class="rt">{{ifReady(cumedata.repped10_530.xtrareg)}}</td>
					<td class="rt">{{ifReady(cumedata.repped10_530.xtrareg + cumedata.repped10_530.gfkreg)}}</td>
					<td class="rt">{{ifReady(cumedata.repped10_530.metroamfm + cumedata.repped10_530.xtrareg + cumedata.repped10_530.gfkreg)}}</td>
				</tr>
				<tr>
					<td class="bold">DAB Metro Only</td>
					<td class="rt">{{ifReady(cumedata.repped10_530.metrodab)}}</td>
					<td class="rt"></td>
					<td class="rt"></td>
					<td class="rt"></td>
					<td class="rt">{{ifReady(cumedata.repped10_530.metrodab)}}</td>
				</tr>
				</tbody>
			</table>

			<br/><br/><br/>

			<p class="heading">10+ Represented Cume Mon-Sun 12am-12am by state</p>
			<table>
				<thead>
					<tr>
						<td class="bold">State</td>
						<td class="bold rt">10+</td>
						<td class="bold rt">25-54</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="st in states" :key="st">
						<td class="bold">{{st}}</td>
						<td class="rt">{{numdisplay(stateCumes.cume10[st])}}</td>
						<td class="rt">{{numdisplay(stateCumes.cume2554[st])}}</td>
					</tr>
				</tbody>
			</table>

			<br/><br/>
			<p class="bold">Sources (latest surveys)</p>
			<p class="sc"><span class="bold">Metro:</span> {{cumedata.latestSurveys.metro}}</p>
			<p class="sc"><span class="bold">Gold Coast:</span> {{cumedata.latestSurveys.gc}}</p>
			<p class="sc"><span class="bold">Canberra:</span> {{cumedata.latestSurveys.cbr}}</p>
			<p class="sc"><span class="bold">Newcastle:</span> {{cumedata.latestSurveys.newc}}</p>
			<p class="sc"><span class="bold">Xtra Regional:</span> {{cumedata.latestSurveys.xtra}}</p>

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "NationalCume",
	components: {},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			states: ['NSW','VIC','QLD','WA','SA','TAS','NT','ACT'],
		}
	},
	computed: {
		cumedata() {
			return this.store.totcumes;
		},
		stateCumes() {
			let mkts = this.store.markets
			let ob10 = {NSW: 0, VIC: 0, QLD: 0, SA: 0, TAS: 0, NT: 0, WA: 0, ACT: 0}
			let ob25 = {NSW: 0, VIC: 0, QLD: 0, SA: 0, TAS: 0, NT: 0, WA: 0, ACT: 0}
			if(mkts && mkts.length > 0) {
				for(let st of this.states) {
					let mm = mkts.filter(item => item.state === st)
					for(let m of mm) {
						if(m.cume10) {
							ob10[st] += parseInt(m.cume10)
						}
						if(m.cume2554) {
							ob25[st] += m.cume2554
						}
					}
				}
			}
			return {cume10: ob10, cume2554: ob25}
		}
	},
	methods: {
		ifReady(dataItem) {
			if(this.store.natCumesLoaded === true) {
				return this.numdisplay(dataItem);
			}
			return '';
		},
	},
	watch: {
		'store.initialObjectsReceived'() {
			// if(this.store.initialObjectsReceived === true && this.store.totcumes.owned10.metro === 0) {
			// 	this.getFreqNationalCumes()
			// 	this.getGFKNationalCumes()
			// }
		},
	},
	mounted() {
		// if(this.store.initialObjectsReceived === true && this.store.totcumes.owned10.metro === 0) {
		// 	this.getFreqNationalCumes()
		// 	this.getGFKNationalCumes()
		// }
	}
}
</script>

<style scoped>
table thead {
	font-weight: bold;
}
table td {
	border: 1px solid #DDD;
	padding: 5px 10px;
}
td.rt {
	text-align: right;
	width: 160px;
}
p.sc {
	font-size: 12px;
}
.filterbox {
	border: 1px solid #DDD;
	padding: 6px;
	width: 250px;
}
</style>