<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">
			<div class="show-header" style="display: flex; align-items: center; padding-top: 10px; padding-bottom: 10px;">
				<div class="show-icon-holder" style="height: 160px; width: 160px; background-color: #EFEFEF;">
					<img v-if="showObject.showIconUrl" :src="showObject.showIconUrl" style="object-fit: cover; width: 100%; height: 100%;" />
				</div>
				<div class="show-title-holder" style="margin-left: 20px;">
					<p style="font-size: 40px; color: #553AA9;">{{showObject.title}}</p>
					<p v-if="headline" style="font-size: 24px; color: #553AA9; margin-bottom: 3px;">{{headline}}</p>
					<p v-if="headlineSource" style="font-size: 12px;">{{headlineSource}}</p>
				</div>
			</div>

			<div class="chart-rows">
				<ChartHolderDash v-if="chartsTitle.triton_show_uniques"
								:charttitle="'Australian Monthly Downloads vs Listeners - '+chartsTitle.triton_show_uniques"
								:source="chartsSource.triton_show_uniques" :height="500">
					<template v-slot:headerRight>
						<div style="width: 300px; display: flex; justify-content: flex-end; align-items: center">
							<Autocompleter :searchlist="chartsOptions.shows" :incomingval="showSelected"
								idfldname="id" textfldname="nice" v-on:update="updateSelectedId($event)"
							></Autocompleter>
						</div>
					</template>
					<template v-slot:default>
						<apexchart v-if="apexdata.triton_show_uniques" width="99%" height="100%" type="line"
							:options="chartoptions" :series="apexdata.triton_show_uniques"></apexchart>
						<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
					</template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash v-if="chartsTitle.triton_show_ranks"
					:charttitle="'Triton Podcast Ranker Rank - '+chartsTitle.triton_show_ranks"
					:source="chartsSource.triton_show_uniques" :height="500">
					<template v-slot:default>
						<apexchart v-if="apexdata.triton_show_ranks" width="99%" height="100%" type="line"
							:options="chartoptions" :series="apexdata.triton_show_ranks"></apexchart>
						<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
					</template>
				</ChartHolderDash>
			</div>
			<div>
				<div class="chart-rows">
					<ChartHolderDash charttitle="Gender Splits (% Listeners)" :height="500" addclasses="half" source="Triton Demos+">
						<template v-slot:default>
							<ApexPie :labels="dpGenderPieData.labels" :chartdata="dpGenderPieData.values" ></ApexPie>
						</template>
					</ChartHolderDash>
					<ChartHolderDash charttitle="Age Splits (% Listeners)" :height="500" addclasses="half" source="Triton Demos+">
						<template v-slot:default>
							<ApexBarTrendCategories :chartdata="dpAgeBarData" :chartoptions="chartoptions" ></ApexBarTrendCategories>
						</template>
					</ChartHolderDash>
				</div>
				<div class="chart-rows">
					<ChartHolderDash charttitle="Household Income (% Listeners)" :height="500" addclasses="half" source="Triton Demos+">
						<template v-slot:default>
							<ApexBarTrendCategories :chartdata="dpHHIBarData" :chartoptions="chartoptions" ></ApexBarTrendCategories>
						</template>
					</ChartHolderDash>
					<ChartHolderDash charttitle="Other Demographics (% Listeners)" :height="500" addclasses="half" source="Triton Demos+">
						<template v-slot:default>
							<ApexBarTrendCategories :chartdata="dpOtherBarData" :chartoptions="chartoptions" ></ApexBarTrendCategories>
						</template>
					</ChartHolderDash>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import Autocompleter from "@/components/Autocompleter";
import PermissionChecker from "@/components/PermissionChecker";
import ApexPie from "@/components/charts/ApexPie.vue";
import ApexBarTrendCategories from "@/components/charts/ApexBarTrendCategories.vue";

export default {
	name: 'DashPodcastShowDetail',
	components: {
		ApexBarTrendCategories,
		ApexPie,
		PermissionChecker,
		Autocompleter,
		ChartLoaderInsert,
		ChartHolderDash,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','connections','content-metro','podcast-admin','podcast'],
			showSelected: null,
			chartsSource: [],
			getChartsBtnWorking: false,
			chartsOptions: [],
			chartsTitle: [],
			showIdFromGet: 0,
			showObject: {},
			headline: '',
			headlineSource: '',
			updating: false,

			apexdata: {},
			chartoptionsWithRank: {
				chart: {
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					},
					type: 'line',
				},
				fill: {

				},
				xaxis: {
					type: "datetime",
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: [
					{
						decimalsInFloat: 0,
						min: 0,
						forceNiceScale: true,
						labels: {
							formatter: (value) => {
								if(value === null) return null
								return value.toLocaleString()
							},
						},
					},
					{
						seriesName: 'Podcast Ranker Ranking',
						opposite: true,
						decimalsInFloat: 0,
						min: 0,
						forceNiceScale: true,
						show: false,
					}
				],
				colors: [],
				stroke: {
					curve: "straight",
					width: 2.5,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
				tooltip: {
					x: {
						format: 'MMMM yyyy'
					}
				},
			},
			chartoptions: {
				chart: {
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					},
					type: 'line',
				},
				fill: {

				},
				xaxis: {
					type: "datetime",
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 0,
					min: 0,
					forceNiceScale: true,
					labels: {
						formatter: (value) => {
							if(value === null) return null
							return value.toLocaleString()
						},
					},
				},
				colors: [],
				stroke: {
					curve: "straight",
					width: 2.5,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
				tooltip: {
					x: {
						format: 'MMMM yyyy'
					}
				},
			},

			demosPlusData: [],
		}
	},
	computed: {
		dpShowData() {
			return this.demosPlusData.filter(item => item.podcast_id === this.showSelected)
		},
		dpGenderPieData() {
			let outer = {
				labels: [],
				values: [],
			}
			let dems = [17,34]
			if(this.demosPlusData.length > 0) {
				let demdata = this.dpShowData.filter(item => dems.includes(item.demo_id))
				for(let dd of demdata) {
					outer.labels.push(dd.demo_name)
					outer.values.push(dd.pct)
				}
			}
			return outer
		},
		dpAgeBarData() {
			let out = {name: 'Pct', data: []}
			let dems = [2,6,9,13,14,16]
			if(this.demosPlusData.length > 0) {
				let demdata = this.dpShowData.filter(item => dems.includes(item.demo_id))
				for(let dd of demdata) {
					out.data.push({x: dd.demo_name, y: dd.pct})
				}
			}
			return [out]
		},
		dpHHIBarData() {
			let out = {name: 'Pct', data: []}
			let dems = [53,54,55,56,57,58,59]
			if(this.demosPlusData.length > 0) {
				let demdata = this.dpShowData.filter(item => dems.includes(item.demo_id))
				for(let dd of demdata) {
					out.data.push({x: dd.demo_name, y: dd.pct})
				}
			}
			return [out]
		},
		dpOtherBarData() {
			let out = {name: 'Pct', data: []}
			let dems = [
				51, //parents
				52, //mums
				62, //full time employment
				155, //non radio listener pm
			]
			if(this.demosPlusData.length > 0) {
				let demdata = this.dpShowData.filter(item => dems.includes(item.demo_id))
				for(let dd of demdata) {
					out.data.push({x: dd.demo_name, y: dd.pct})
				}
			}
			return [out]
		},
	},
	methods: {
		updateSelectedId(selob) {
			if(selob.id > 0) {
				this.showSelected = selob.id;
			}
		},
		getCharts() {
			this.getChartsBtnWorking = true;
			this.updating = true;
			var self = this;
			var targbase = "/api/podcast-show-detail.php";
			var targ = "";
			if(this.showIdFromGet > 0) { //called from the URL?
				targ = targbase + "?showid=" + this.showIdFromGet;
			}
			if(this.incomingshowidrequest !== undefined) {
				targ = targbase + "?showid=" + this.incomingshowidrequest;
			}
			if(this.showSelected != null) {
				targ = targbase + "?showid=" + this.showSelected;
			}
			if(targ === "") {
				targ = targbase;
			}
			this.apexdata = {}; //reset prior to retrieval
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					if(ret.apexdata) {
						self.apexdata = ret.apexdata;
					}
					if (ret.chartsSource != null) {
						self.chartsSource = ret.chartsSource;
					}
					if (ret.chartsOptions != null) {
						self.chartsOptions = ret.chartsOptions;
					}
					if (ret.chartsTitle != null) {
						self.chartsTitle = ret.chartsTitle;
						document.title = "ARN Podcasts - "+ret.chartsTitle.triton_show_uniques;
					}
					if(ret.demosPlus) self.demosPlusData = ret.demosPlus
					if(ret.showObj != null) {
						self.showObject = ret.showObj;
					}
					if (ret.headline != null) { self.headline = ret.headline; }
					if (ret.headlineSource != null) { self.headlineSource = ret.headlineSource; }
					self.showSelected = ret.selectedShowId;
					if (ret.h != null) {
						for (var key in ret.h) { //this updates any that exist, adds new if needed, but does not delete existing
							self.slotprops[key] = ret.h[key];
						}
					}
					//custom callback functions here if required (using pageaction as filter)
				}
				self.getChartsBtnWorking = false;
				setTimeout(function() {
					self.updating = false;
				}.bind(self), 1000);
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		showSelected() {
			if(this.updating === false) {
				this.getCharts();
			}
		},
	},
	props: {
		incomingshowidrequest: Number,
	},
	mounted() {
		this.chartoptions.colors = this.store.chartColourGroupARNFirst
		this.chartoptionsWithRank.colors = this.store.chartColourGroupARNFirst
	},
	created() {
		let urlparams = this.$route.query;
		if(urlparams.showid !== undefined) {
			this.showIdFromGet = urlparams.showid;
		}
		this.getCharts();
		document.title = "Podcast Show Detail";
	},
}
</script>

<style scoped>

</style>
