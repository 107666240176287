<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0" width="99%" height="100%" type="area"
			:options="chartoptions" :series="chartdata"></apexchart>
		<ChartLoaderInsert v-if="fetching"></ChartLoaderInsert>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
export default {
	name: "ApexLineTrend",
	components: {
		ChartLoaderInsert
	},
	props: {
		fetching: Boolean,
		categories: Array,
		chartdata: Array,
		filename: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			chartoptions: {
				chart: {
					id: this.filename,
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					},
					type: 'area',
				},
				fill: {
					gradient: {
						opacityFrom: 0.4,
						opacityTo: 0.2,
					},
				},
				xaxis: {
					type: "category",
					labels: {
						show: false,
						hideOverlappingLabels: true,
						trim: true,
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 0,
					min: 0,
					forceNiceScale: true,
					labels: {
						formatter: (value) => {
							if(value === null) return null
							return value.toLocaleString()
						},
					},
				},
				colors: ['#553AA9'],
				stroke: {
					curve: "straight",
					width: 2,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
				tooltip: {
					x: {
						format: 'dd MMMM yyyy'
					}
				},
			},
		}
	},
	computed: {},
	methods: {
		// updateCategories() { //need to do this manually as data is nested
		// 	var oldxaxis = this.chartoptions.xaxis;
		// 	oldxaxis.categories = this.categories;
		// 	this.chartoptions = {...this.chartoptions, ...{
		// 			xaxis: oldxaxis
		// 		}};
		// },
	},
	watch: {
		categories() {
			// this.updateCategories();
		},
	},
	mounted() {
		// this.updateCategories();
	}
}
</script>

<style scoped>

</style>