<template>
	<div>
		<apexchart type="line" :height="height" :options="chartOptions" :series="series"></apexchart>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "SparkChart",
	components: {},
	props: {
		series: Array,
		height: Number,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			chartOptions: {
				chart: {
					height: 350,
					type: 'line',
					zoom: {
						enabled: false
					},
					toolbar: {
						show: false
					},
					animations: {
						enabled: false
					},
					// sparkline: {
					// 	enabled: true
					// }
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'straight',
					width: 2,
					colors: ['#4E39A3'],
				},
				markers: {
					size: 3,
					colors: ['#4E39A3'],
				},
				grid: {
					show: false,
				},
				xaxis: {
					labels: {
						show: false,
					}
				},
				yaxis: {
					decimalsInFloat: 0,
					forceNiceScale: true,
				},
			},
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>