<template>
	<div v-if="rowInfo" class="airtimerow">
		<div class="cell wider">
			<select v-model="rowInfo.daypartName">
				<option value="Bonus ROS">Bonus ROS</option>
				<option value="Bonus BTA">Bonus BTA</option>
			</select>
		</div>
		<div class="cell wider">
			<div v-if="rowInfo.daypartName">
				<select v-model="rowInfo.weekdayset">
					<option v-for="opt in weekdaysetsForStationDaypart" :key="opt" :value="opt">{{opt}}</option>
				</select>
			</div>
		</div>
		<div class="airtimerow-sub">
			<div class="cell"><span v-if="dpobj">{{dpobj.display_time}}</span></div>
			<div class="cell">
				<select v-model="rowInfo.spotlength">
					<option v-for="opt in durationOptions" :key="opt" :value="opt">{{opt}} sec</option>
				</select>
			</div>
			<div class="cell">{{numdisplay(rowInfo.spotValue,2)}}</div>
			<div class="cell"><input type="number" v-model="rowInfo.discountPct" /></div>
			<div class="cell">{{numdisplay(rowInfo.spotCost,2)}}</div>
			<div class="cell"><input v-model="rowInfo.nSpots" type="number" /></div>
			<div class="cell">{{numdisplay(rowInfo.totalSpotsValue,2)}}</div>
			<div class="cell">{{numdisplay(rowInfo.totalSpotsCost,2)}}</div>
			<div class="cell">
				<span class="remover" @click="store.sales_campaign.station_rows[stationindex].addValueRows.splice(rowindex,1)">
					<i class="mdi mdi-close"></i>
				</span>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: 'SalesCampaignAddedValueRow',
	components: {

	},
	props: {
		stations: {
			type: Array,
			default: () => []
		},
		chosenstation: {
			type: Object,
			default: null
		},
		ratesforstation: {
			type: Object,
			default: () => {}
		},
		commercialdayparts: {
			type: Array,
			default: () => []
		},
		rowindex: {
			type: Number,
			default: 0
		},
		stationindex: {
			type: Number,
			default: 0
		},
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

			durationOptions: [30,15],
		}
	},
	computed: {
		rowInfo: {
			get() {
				if(this.store.sales_campaign.station_rows[this.stationindex]) {
					return this.store.sales_campaign.station_rows[this.stationindex].addValueRows[this.rowindex]
				}
				return null
			},
			set(newval) {
				this.store.sales_campaign.station_rows[this.stationindex].addValueRows[this.rowindex] = newval
			}
		},
		weekdaysetsForStationDaypart() {
			if (this.rowInfo.daypartName && this.chosenstation) {
				let ws = []
				for(let wd in this.ratesforstation['Commercial'][this.rowInfo.daypartName]) {
					ws.push(wd)
				}
				return ws
			}
			return []
		},
		rateItem() {
			if (this.rowInfo && this.rowInfo.daypartName && this.chosenstation && this.rowInfo.weekdayset) {
				return this.ratesforstation['Commercial'][this.rowInfo.daypartName][this.rowInfo.weekdayset]
			}
			return null
		},
		dpobj() {
			if (this.rateItem) {
				return this.rateItem.daypartObject
			}
			return null
		},
	},
	methods: {},
	watch: {
		'rowInfo': {
			handler() {
				if (this.dpobj) {
					if(this.rowInfo.spotlength) {
						if(this.rowInfo.spotlength <= 15) this.rowInfo.spotValue = this.rateItem.rate * 0.75
						else if(this.rowInfo.spotlength === 30) this.rowInfo.spotValue = this.rateItem.rate
						else {
							this.rowInfo.spotValue = this.rateItem.rate * (this.rowInfo.spotlength / 30)
						}
					}
					if (this.rowInfo.spotValue) {
						this.rowInfo.spotCost = this.rowInfo.spotValue * (1 - (this.rowInfo.discountPct/100))
					}
					else this.rowInfo.spotCost = null
					if (this.rowInfo.spotValue) {
						this.rowInfo.totalSpotsValue = this.rowInfo.spotValue * this.rowInfo.nSpots
					}
					else this.rowInfo.totalSpotsValue = null
					if (this.rowInfo.spotCost) {
						this.rowInfo.totalSpotsCost = this.rowInfo.spotCost * this.rowInfo.nSpots
					}
					else this.rowInfo.totalSpotsCost = null
				}
			},
			deep: true
		},
	},

	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>

</style>
