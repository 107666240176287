<template>
	<div class="" style="margin-top: 20px; margin-bottom: 30px;">
		<div style="display: flex; align-items: center;">
			<div style="width: 300px;">
				<Autocompleter :searchlist="demosList" :incomingval="chosenDemoId"
					idfldname="id" textfldname="name" v-model="chosenDemoId"
					placeholder="Choose a demo" v-on:update="setDemoSelection($event)"
				></Autocompleter>
			</div>
			<div style="display: flex; align-items: center;">
				<p>Australian Index Ranker, podcasts with minimum monthly downloads
					<input style="width: 100px;" class="select-styled" type="number" v-model="minDownloads" /> </p>
					<div style="margin: 10px 0 10px 10px;" class="primary-btn" @click="fetchData">Update</div>
			</div>
		</div>
		<div style="margin: 20px 0;" v-if="results.length > 0">
			<table class="altbl noborderv" id='podcast-indexes'>
				<thead>
					<tr>
						<td class="headingtext" data-f-bold="true"><span>Rank</span></td>
						<td class="headingtext" data-f-bold="true">Podcast</td>
						<td class="headingtext" data-f-bold="true">Index</td>
						<td class="headingtext" data-f-bold="true">Listeners (3mth Avg)</td>
						<td class="headingtext" data-f-bold="true">Downloads (3mth Avg)</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(result, index) in results" :key="index">
						<td>{{index+1}}</td>
						<td>{{result.progname}}</td>
						<td>{{result.indpod}}</td>
						<td class="rt">{{numdisplayNoZeroes(result.listeners)}}</td>
						<td class="rt">{{numdisplayNoZeroes(result.downloads)}}</td>
					</tr>
				</tbody>
			</table>
			<div style="margin: 10px 0;" class="primary-btn" @click="exportToExcel('podcast-indexes','PodcastIndex')">Export</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import Autocompleter from "@/components/Autocompleter.vue";
import TableToExcel from "@linways/table-to-excel";
export default {
	name: "PodcastDemosIndexes",
	components: {
		Autocompleter

	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			chosenDemoId: null,
			demosList: [],
			results: [],
			minDownloads: 20000,
		}
	},
	computed: {

	},
	methods: {
		exportToExcel(tblid, filename = 'export') {
			if(this.excelfilename && this.excelfilename.length > 0) {
				filename = this.excelfilename;
			}
			let t = document.getElementById(tblid)
			let table2 = t.cloneNode(true)
			table2.innerHTML = table2.innerHTML.replace(/,/g,'')
			TableToExcel.convert(table2, {
				name: filename+".xlsx",
				sheet: {
					name: filename
				}
			});
		},
		setDemoSelection(dob) {
			this.chosenDemoId = dob.id
			this.fetchData()
		},
		fetchData() {
			let self = this
			axios.get(
				"/api/podcast/podcast-demos-indexes.php?demoId="+self.chosenDemoId+"&downloadsMin="+self.minDownloads
			).then(function (response) {
				let ret = response.data //response.data is returned info
				if(ret.demosList) self.demosList = ret.demosList
				if(ret.results) self.results = ret.results
				if(ret.demoId) self.chosenDemoId = ret.demoId
			}).catch(function (error) {
				console.log(error)
			});
		},
	},
	watch: {

	},
	mounted() {
		this.fetchData()
	}
}
</script>

<style scoped>

</style>