<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>

		<div v-if="userHasPermission(viewPerms)">
			<div style="display: flex; justify-content: flex-start; gap: 15px;">
				<div style="display: flex; justify-content: flex-start; gap: 15px;">
					<div>
						<p class="above-select-label">Month starting</p>
						<select class="select-styled" v-model="startdayselect">
							<option v-for="opt in monthstarts" :key="opt" :value="opt">{{opt}}</option>
						</select>
					</div>
<!--					<div>-->
<!--						<p class="above-select-label">Min Session</p>-->
<!--						<select class="select-styled" v-model="chosenMinSess">-->
<!--							<option v-for="opt in minSessOptions" :key="opt.id" :value="opt.id">{{opt.name}}</option>-->
<!--						</select>-->
<!--					</div>-->
				</div>
				<div style="display: flex; align-items: flex-end;">
					<span class="primary-btn" @click="getData">Fetch</span>
				</div>
			</div>

			<div style="margin-top: 20px;">
				<table>
					<thead>
						<tr>
							<td style="width: 180px;"></td>
							<td class="rt">ARN Total</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="bold">Total Hours</td>
							<td class="rt">{{numdisplay(reghours)}}</td>
						</tr>
						<tr>
							<td class="bold">Cume</td>
							<td class="rt">{{numdisplay(regcume)}}</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="chart-rows">

				<ChartHolderDash :charttitle="'Hours by source'" source="Triton/Adswizz" :addclasses="'half'">
					<template v-slot:default>
						<ApexPie :chartdata="apexdata.stream_hours_bysource" :labels="apexlabels.stream_hours_bysource" filename="HoursBySource" :fetching="getChartsBtnWorking"></ApexPie>
					</template>
				</ChartHolderDash>

				<ChartHolderDash :charttitle="'Sessions by source'" source="Triton/Adswizz" :addclasses="'half'">
					<template v-slot:default>
						<ApexPie :chartdata="apexdata.stream_sess_bysource" :labels="apexlabels.stream_sess_bysource" filename="SessionsBySource" :fetching="getChartsBtnWorking"></ApexPie>
					</template>
				</ChartHolderDash>

<!--				<ChartHolderDash-->
<!--						:charttitle="'Hours by station (All Metro Sources)'"-->
<!--						:source="chartsSource.stream_hours_bystation" :addclasses="'half'">-->
<!--					<template v-slot:default>-->
<!--						<ApexPie :chartdata="apexdata.stream_hours_bystation" :labels="apexlabels.stream_hours_bystation" filename="DownloadsByState" :fetching="getChartsBtnWorking"></ApexPie>-->
<!--					</template>-->
<!--				</ChartHolderDash>-->

<!--				<ChartHolderDash-->
<!--						:charttitle="'Sessions by station (All Metro Sources)'"-->
<!--						:source="chartsSource.stream_sess_bystation" :addclasses="'half'">-->
<!--					<template v-slot:default>-->
<!--						<ApexPie :chartdata="apexdata.stream_sess_bystation" :labels="apexlabels.stream_sess_bystation" filename="DownloadsByState" :fetching="getChartsBtnWorking"></ApexPie>-->
<!--					</template>-->
<!--				</ChartHolderDash>-->

<!--				<ChartHolderDash-->
<!--						:charttitle="'Total Hours by Station x Source (metro)'"-->
<!--						:source="chartsSource.stream_source_stn_table" :addclasses="'half'" :height="1200">-->
<!--					<div class="b-table-holder">-->
<!--						<ALTable tblclasses="noborderv" :excel="true" excelfilename="TLH"-->
<!--							:cols="[-->
<!--								{title: 'Station', sortable: true, fldid: 'cat'},-->
<!--								{title: 'IHR incl Web', sortable: true, fldid: 'ihr', align: 'right', numtype: 'number'},-->
<!--								{title: 'Non IHR', sortable: true, fldid: 'nonihr', align: 'right', numtype: 'number'},-->
<!--								{title: 'Total', sortable: true, fldid: 'totalhours', align: 'right', numtype: 'number'},-->
<!--							]"-->
<!--							:rowdata="chartsData.stream_source_stn_table"-->
<!--						></ALTable>-->
<!--					</div>-->
<!--					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>-->
<!--				</ChartHolderDash>-->

<!--				<ChartHolderDash-->
<!--						:charttitle="'Total Cume/Uniques by Station x Source (metro)'"-->
<!--						:source="chartsSource.stream_source_stn_table_cume" :addclasses="'half'" :height="1200">-->
<!--					<div class="b-table-holder">-->
<!--						<ALTable tblclasses="noborderv" :excel="true" excelfilename="Cume"-->
<!--							:cols="[-->
<!--								{title: 'Station', sortable: true, fldid: 'cat'},-->
<!--								{title: 'IHR (ProfileId)', sortable: true, fldid: 'ihr', align: 'right', numtype: 'number'},-->
<!--								{title: 'IHR (IAB)', sortable: true, fldid: 'ihriab', align: 'right', numtype: 'number'},-->
<!--								{title: 'Non IHR (IAB)', sortable: true, fldid: 'nonihr', align: 'right', numtype: 'number'},-->
<!--								{title: 'Total (IAB)', sortable: true, fldid: 'totalhours', align: 'right', numtype: 'number'},-->
<!--							]"-->
<!--							:rowdata="chartsData.stream_source_stn_table_cume"-->
<!--						></ALTable>-->
<!--					</div>-->
<!--					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>-->
<!--				</ChartHolderDash>-->

			</div>

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
// import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import ApexPie from "@/components/charts/ApexPie";
import PermissionChecker from "@/components/PermissionChecker";
// import ALTable from "@/components/ALTable";
// import StreamLogBugNote from "@/components/bits/StreamLogBugNote";

export default {
	name: 'StreamingMonthlyDashboard',
	components: {
		// StreamLogBugNote,
		// ALTable,
		PermissionChecker,
		ApexPie,
		ChartHolderDash,
		// ChartLoaderInsert,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','content-metro','connections','content-reg'],
			chartId: 0,
			// chartsData: {},
			// chartsSource: [],
			// chartsOptions: [],
			getChartsBtnWorking: false,

			startdayselect: '',
			searchbox: '',
			updating: false,
			monthstarts: [],

			// minSessOptions: [
			// 	{id: 0, name: '1 sec'},
			// 	{id: 30, name: '30 sec'},
			// 	{id: 60, name: '60 sec'},
			// ],
			chosenMinSess: 30,

			apexdata: {},
			apexlabels: {},

			regcume: '',
			reghours: '',
		}
	},
	computed: {

	},
	methods: {
		// resetVals() {
		// 	this.regcume = '';
		// 	this.reghours = '';
		// },
		// getCharts() {
		// 	this.getChartsBtnWorking = true;
		// 	this.resetVals();
		// 	this.getRegionalData();
		// 	this.updating = true;
		// 	var self = this;
		// 	var targ = this.store.streamingApiUrl + "api/arnstats/get-monthly-dashboard.php?monthselect="+this.startdayselect+"&minsess="+this.chosenMinSess;
		// 	axios.get(targ).then(function (response) {
		// 		var ret = response.data; //response.data is returned info
		// 		if (ret.error === 1) {
		// 			console.log(ret);
		// 		}
		// 		else {
		// 			// if(ret.apexdata) {
		// 			// 	self.apexdata = ret.apexdata;
		// 			// }
		// 			// if(ret.apexlabels) {
		// 			// 	self.apexlabels = ret.apexlabels;
		// 			// }
		// 			// if (ret.chartsData != null) {
		// 			// 	self.chartsData = ret.chartsData;
		// 			// }
		// 			// if (ret.chartsSource != null) {
		// 			// 	self.chartsSource = ret.chartsSource;
		// 			// }
		// 			// if (ret.chartsOptions != null) {
		// 			// 	self.chartsOptions = ret.chartsOptions;
		// 			// }
		// 			// if(ret.selectedMonth != null) {
		// 			// 	self.selectedMonth = ret.selectedMonth;
		// 			// }
		// 			// if(ret.tlh) self.metrohours = ret.tlh;
		// 			// if(ret.cume) self.metrocume = ret.cume;
		// 			// if (ret.h != null) {
		// 			// 	for (var key in ret.h) { //this updates any that exist, adds new if needed, but does not delete existing
		// 			// 		self.slotprops[key] = ret.h[key];
		// 			// 	}
		// 			// }
		// 		}
		// 		self.getChartsBtnWorking = false;
		// 		setTimeout(function() {
		// 			self.updating = false;
		// 		}.bind(self), 1000);
		// 	}).catch(function (error) {
		// 		console.log(error);
		// 	});
		// },
		getData() {
			var targ = "/api/streaming-get-gb-tot.php?monthselect="+this.startdayselect;
			var self = this;
			//this.chartsData = [];
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.tlh != null) self.reghours = ret.tlh
					if (ret.cume != null) self.regcume = ret.cume
					if(ret.apexdata) {
						self.apexdata = ret.apexdata;
					}
					if(ret.apexlabels) {
						self.apexlabels = ret.apexlabels;
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getMeta() {
			var self = this;
			var targ = "/api/streaming-get-meta.php";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.appinfo != null) {
						var a = ret.appinfo;
						self.monthstarts = a['streamingMonthlySnapMonths'];
						self.startdayselect = a['streamingDefaultSnapMonth'];
						self.getData();
					}
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		sourcename() {
			if(this.updating === false) {
				this.getData();
			}
		},
		selectedMonth() {
			if(this.updating === false) {
				this.getData();
			}
		}
	},
	props: {
		mychartdata: Object
	},
	mounted() {

	},
	created() {
		this.getMeta();
		document.title = "ARN Streaming Monthly Snapshot";
	},
}
</script>

<style scoped>
table thead {
	font-weight: bold;
}
table td {
	border: 1px solid #DDD;
	padding: 5px 10px;
}
td.rt {
	text-align: right;
	width: 160px;
}
</style>
