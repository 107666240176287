<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">

			<div v-if="pageView === 'table'">
				<ChartHolderDash
					:charttitle="'On-Demand Podcasts - Australian Downloads/Listeners'" :source="tableSource" :height="660">
					<div class="b-table-holder">
						<ALTable tblclasses="noborderv" :excel="true" excelfilename="Top ARN On-Demand Podcasts"
							:cols="[
							{title: 'Rank', sortable: true, fldid: 'rank'},
							{title: 'Podcast/Show', sortable: true, fldid: 'cat'},
							{title: 'Pacing/KPI', sortable: true, fldid: 'pacingpct', numtype: 'pct', highlight: true, highlightzero: 100 },
							{title: 'Target Listeners', sortable: true, fldid: 'listener_target', numtype: 'number'},
							{title: 'Current Listeners', sortable: true, fldid: 'mthlist', numtype: 'number'},
							{title: 'Prev Listeners', sortable: true, fldid: 'mthprvlist', numtype: 'number'},
							{title: 'MoM Change Listeners', sortable: true, fldid: 'pctdifflist', numtype: 'pct', highlight: true},
							{title: 'Current Downloads', sortable: true, fldid: 'mthdl', numtype: 'number'},
							{title: 'Prev Downloads', sortable: true, fldid: 'mthprvdl', numtype: 'number'},
							{title: 'MoM Change Downloads', sortable: true, fldid: 'pctdiff', numtype: 'pct', highlight: true},
						]"
							:rowdata="tableData"
						></ALTable>
					</div>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</ChartHolderDash>
			</div>

			<div v-else-if="pageView === 'charts'">

				<div>
					<span class="netshortcut" @click="resetToTable">
						<i class="mdi mdi-chevron-left"></i>
						Back
					</span>
				</div>

				<div class="show-header" style="display: flex; align-items: center; padding-top: 10px; padding-bottom: 10px;">
					<div class="show-icon-holder" style="height: 160px; width: 160px; background-color: #EFEFEF;">
						<img v-if="showObject.showIconUrl" :src="showObject.showIconUrl" style="object-fit: cover; width: 100%; height: 100%;" />
					</div>
					<div class="show-title-holder" style="margin-left: 20px;">
						<p style="font-size: 40px; color: #553AA9;">{{showObject.title}}</p>
					</div>
				</div>

				<div v-if="userHasPermission(['podcast-admin','connections']) && apexdata.kpis && apexdata.kpis[0] && apexdata.kpis[0].data && apexdata.kpis[0].data.length > 0" class="chart-rows">
					<ChartHolderDash charttitle="Listener KPI Tracker" :height="420" source="Triton">
						<template v-slot:default>
							<ApexBarTrendDateMonthlyLineKPI :chartdata="apexdata.kpis" :fetching="getChartsBtnWorking"></ApexBarTrendDateMonthlyLineKPI>
						</template>
					</ChartHolderDash>
				</div>

				<div class="chart-rows">
					<ChartHolderDash v-if="chartsTitle.pdrnk_show_list"
							:charttitle="'CRA Podcast Ranker Australian Monthly Listeners - '+chartsTitle.pdrnk_show_list+' vs competitors'"
							:source="chartsSource.pdrnk_show_list" :height="500">
						<template v-slot:headerRight v-if="chartsTitle.meg_show !== 'undefined'">
							<div style="width: 300px; display: flex; align-items: center; justify-content: flex-end;">
								<Autocompleter :searchlist="chartsOptions.shows" :incomingval="showSelected"
									idfldname="id" textfldname="nice" v-on:update="updateSelectedId($event)"
								></Autocompleter>
							</div>
						</template>
						<template v-slot:default>
							<apexchart v-if="apexdata.pdrnk_show_list" width="99%" height="100%" type="line"
								:options="chartoptionsWithHero" :series="apexdata.pdrnk_show_list"></apexchart>
							<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
						</template>
					</ChartHolderDash>
				</div>

				<div class="chart-rows">
					<ChartHolderDash v-if="chartsTitle.pdrnk_show"
									:charttitle="'CRA Podcast Ranker Australian Monthly Downloads - '+chartsTitle.pdrnk_show+' vs competitors'"
									:source="chartsSource.pdrnk_show" :height="500">
						<template v-slot:default>
							<apexchart v-if="apexdata.pdrnk_show" width="99%" height="100%" type="line"
								:options="chartoptionsWithHero" :series="apexdata.pdrnk_show"></apexchart>
							<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
						</template>
					</ChartHolderDash>
				</div>

				<div class="chart-rows">
					<ChartHolderDash v-if="chartsTitle.triton_show_uniques"
									:charttitle="'Triton Australian Monthly Downloads vs Listeners - '+chartsTitle.triton_show_uniques"
									:source="chartsSource.triton_show_uniques" :height="500">

						<template v-slot:default>
							<apexchart v-if="apexdata.triton_show_uniques" width="99%" height="100%" type="line"
								:options="chartoptions" :series="apexdata.triton_show_uniques"></apexchart>
							<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
						</template>
					</ChartHolderDash>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import Autocompleter from "@/components/Autocompleter";
import PermissionChecker from "@/components/PermissionChecker";
import ApexBarTrendDateMonthlyLineKPI from "@/components/charts/ApexBarTrendDateMonthlyLineKPI.vue";
import ALTable from "@/components/ALTable.vue";

export default {
	name: 'DashPodcastCatchupShow',
	components: {
		ALTable,
		ApexBarTrendDateMonthlyLineKPI,
		PermissionChecker,
		Autocompleter,
		ChartLoaderInsert,
		ChartHolderDash,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','content-metro','connections','podcast','podcast-admin'],
			showSelected: null,
			chartsSource: [],
			getChartsBtnWorking: false,
			chartsOptions: [],
			chartsTitle: [],
			showIdFromGet: 0,
			showObject: {},
			headline: '',
			headlineSource: '',
			updating: false,
			pageView: 'table', //table or charts
			tableData: [],
			tableSource: '',

			apexdata: {},
			chartoptionsWithHero: {
				chart: {
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					},
					type: 'line',
				},
				fill: {

				},
				xaxis: {
					type: "datetime",
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 0,
					min: 0,
					forceNiceScale: true,
					labels: {
						formatter: (value) => {
							if(value !== null) return value.toLocaleString()
						},
					},
				},
				colors: [],
				stroke: {
					curve: "straight",
					width: [3.5,2,2,2,2,2,2,2,2],
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
				tooltip: {
					x: {
						format: 'MMMM yyyy'
					}
				},
			},
			chartoptions: {
				chart: {
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					},
					type: 'line',
				},
				fill: {

				},
				xaxis: {
					type: "datetime",
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 0,
					min: 0,
					forceNiceScale: true,
					labels: {
						formatter: (value) => {
							if(value !== null) return value.toLocaleString()
						},
					},
				},
				colors: [],
				stroke: {
					curve: "straight",
					width: 2.5,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
				tooltip: {
					x: {
						format: 'MMMM yyyy'
					}
				},
			},
		}
	},
	computed: {

	},
	methods: {
		resetToTable() {
			this.pageView = 'table'
			this.showSelected = null
			this.getTable()
		},
		updateSelectedId(selob) {
			if(selob.id > 0) {
				this.showSelected = selob.id;
			}
		},
		getTable() {
			this.getChartsBtnWorking = true
			let self = this
			let targ = "/api/podcast/table-catchup.php"
			axios.get(targ).then(function (response) {
				let ret = response.data //response.data is returned info
				if (ret.error === 1) console.log(ret)
				else {
					if (ret.tableData != null) self.tableData = ret.tableData
					self.tableSource = "Triton: Current Month"
					if(ret.latestDate != null) {
						self.tableSource = "Triton: Current Month "+ret.latestDate
					}
					//handle click-through table links for On-Demand Radio shows
					let ct = 0
					for(let s of self.tableData) {
						s.cat = '<a href="/podcasts/on-demand-radio?showid='+s.showid+'" class="link-text">'+s.cat+'</a>'
						self.tableData[ct] = s
						ct++
					}
				}
				self.getChartsBtnWorking = false;
			}).catch(function (error) {
				console.log(error);
			});
		},
		getCharts() {
			this.getChartsBtnWorking = true
			this.updating = true
			let self = this
			let targbase = "/api/podcast-show-catchup-detail.php"
			let targ = ""
			if(this.showIdFromGet > 0) { //called from the URL?
				targ = targbase + "?showid=" + this.showIdFromGet;
			}
			if(this.incomingshowidrequest !== undefined) {
				targ = targbase + "?showid=" + this.incomingshowidrequest;
			}
			if(this.showSelected != null) {
				targ = targbase + "?showid=" + this.showSelected;
			}
			if(targ === "") {
				targ = targbase;
			}
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					if(ret.apexdata) {
						self.apexdata = ret.apexdata;
					}
					if (ret.chartsSource != null) {
						self.chartsSource = ret.chartsSource;
					}
					if (ret.chartsOptions != null) {
						self.chartsOptions = ret.chartsOptions;
					}
					if (ret.chartsTitle != null) {
						self.chartsTitle = ret.chartsTitle;
						document.title = "ARN On-Demand Radio Podcasts - "+ret.chartsTitle.pdrnk_show;
					}
					if(ret.showObj != null) {
						self.showObject = ret.showObj;
					}
					self.showSelected = ret.selectedShowId;

				}
				self.getChartsBtnWorking = false;
				setTimeout(function() {
					self.updating = false;
				}.bind(self), 1000);
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		showSelected() {
			if(this.updating === false) {
				this.getCharts();
			}
		},
		$route(){
			let urlparams = this.$route.query
			if(urlparams.showid !== undefined) {
				this.showIdFromGet = parseInt(urlparams.showid)
				this.showSelected = parseInt(urlparams.showid)
				this.pageView = 'charts'
				this.getCharts()
			}
			else {
				this.pageView = 'table'
				this.getTable()
			}
		},
	},
	props: {
		incomingshowidrequest: Number,
	},
	mounted() {
		this.chartoptions.colors = this.store.chartColourGroupARNFirst
		this.chartoptionsWithHero.colors = this.store.chartColourGroupARNFirst
	},
	created() {
		let urlparams = this.$route.query
		if(urlparams.showid !== undefined) {
			this.showIdFromGet = urlparams.showid
		}
		if(this.showIdFromGet > 0) {
			this.pageView = 'charts'
			this.getCharts()
		}
		else {
			this.pageView = 'table'
			this.getTable()
		}
		document.title = "ARN On-Demand Radio Podcasts";
	},
}
</script>

<style scoped>
.netshortcut {
	display: inline-block;
	border: 1px solid #DDD;
	background: #EAEAEA;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	margin: 0 10px 10px 0;
	padding: 6px 8px;
	border-radius: 5px;
	font-size: 11px;
}
.netshortcut:hover {
	background-color: #DDD;
}
</style>
