<template>
	<div class="item-logo">
		<img v-if="logourl" :src="logourl" :style="'width:'+width+';height:'+height+';'" :class="{nopad : nopad}" />
		<p v-else>{{str.toUpperCase()}}</p>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "OwnershipLogo",
	components: {},
	mixins: [globalMixin],
	props: {
		type: String,
		str: String,
		wid: String,
		ht: String,
		nopad: {
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		logourl() {
			if(this.type === 'own' || this.type === 'reps') { //looking for a network value
				return this.store.logosRoot + "station-logos/"+this.str+".png?v=2";
			}
			return false;
		},
		width() {
			if(this.wid) return this.wid;
			return "50px";
		},
		height() {
			if(this.ht) return this.ht;
			return "40px";
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.item-logo {
	display: inline-block;
}
.item-logo img {
	width: 50px;
	height: 40px;
	object-fit: contain;
	margin-right: 15px;
	margin-bottom: 15px;
}
.item-logo img.nopad {
	margin-right: 0;
	margin-bottom: 0;
}
</style>