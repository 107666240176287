<template>
	<div>
		<div>
			<div style="padding-top: 10px; display: flex; align-items: flex-end;">
				<div style="margin-right: 10px; z-index: 1">
					<p class="above-select-label">Station</p>
					<AutocompleterStringOnly v-if="allStations" :searchlist="allStations" :incomingval="stationLookupText"
							idfldname="id" textfldname="name" v-on:update="chosenStation = $event"
					></AutocompleterStringOnly>
				</div>
				<div style="margin-right: 20px; width: 200px;">
					<p class="above-select-label">Log Date</p>
					<date-picker class="dater-tr" style="width: 200px;" v-model:value="startdate" valueType="format" format="YYYY-MM-DD"></date-picker>
				</div>
				<div><span class="primary-btn" @click="fetchData(false)">View</span></div>
			</div>

			<div style="margin-top: 20px; ">
				<div v-if="!isFetching" style="padding: 20px 0;">
					<div v-if="spotplays.length === 0" style="padding: 0 10px;"><p>Nothing to show</p></div>
					<div v-else>
						<ALTable :cols="cols" :rowdata="spotplays" :excel="true" :paginatehour="true" :searchbox="true"></ALTable>
					</div>
				</div>
				<div v-else style="padding: 20px 0; height: 300px; width: 500px;">
					<ChartLoaderInsert style="z-index: 9;"></ChartLoaderInsert>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import AutocompleterStringOnly from "@/components/AutocompleterStringOnly";
import ALTable from "@/components/ALTable";
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';
import ChartLoaderInsert from "@/components/ChartLoaderInsert";

export default {
	name: "PostCampaignAdLog",
	components: {
		ChartLoaderInsert,
		ALTable,
		AutocompleterStringOnly,
		DatePicker,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			// viewPerms: ['elt','pcr'],
			showStationSelectOverlay: false,
			isFetching: false,

			tabview: 'summary', //summary or spotplays

			startdate: '2022-06-29',
			chosenStation: null,
			stationLookupText: '',

			stations: [],
			spotplays: [],

			cols: [
				{title: 'Aired Date', sortable: true, fldid: 'aired_date'},
				{title: 'Aired Time', sortable: true, fldid: 'aired_time'},
				{title: 'Station', sortable: true, fldid: 'station_name'},
				{title: 'Contract ID', sortable: true, fldid: 'contract_id'},
				{title: 'Advertiser', sortable: true, fldid: 'advertiser_name'},
				{title: 'Salesperson', sortable: true, fldid: 'salesperson_name'},
				{title: 'Booked Daypart', sortable: true, fldid: 'booked_daypart'},
				{title: 'Aired Daypart', sortable: true, fldid: 'aired_daypart'},
				{title: 'Spot Duration', sortable: true, fldid: 'duration_booked'},
				{title: 'Key Number', sortable: true, fldid: 'keynumber'},
				{title: 'PIB Aired', sortable: true, fldid: 'pib_aired'},
				{title: 'Rate Paid', sortable: true, fldid: 'rate_paid'},
			],

		}
	},
	computed: {
		stationsMetro() {
			return this.stations.filter(item => item.aqdb === 'arnmetro')
		},
		stationsReg() {
			return this.stations.filter(item => item.aqdb === 'arnreg')
		},
		stationsExt() {
			return this.stations.filter(item => item.aqdb === 'external')
		},
		allStations() {
			let arr = this.stationsMetro
			arr = arr.concat(this.stationsReg)
			return arr.concat(this.stationsExt)
		},
	},
	methods: {
		getMetaLists() {
			let self = this;
			let targ = "/api/post-campaign/get-station-list.php";
			this.isFetching = true
			axios.get(targ).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.error === 1) console.log(ret)
				else if(ret.stations) {
					self.stations = ret.stations
				}
				self.isFetching = false
			}).catch(function (error) {
				console.log(error);
				self.isFetching = false
			});
		},
		fetchData() {
			let self = this;
			let targ = "/api/post-campaign/fetch-log.php";
			this.showStationSelectOverlay = false
			this.isFetching = true
			axios.post(targ, JSON.stringify({
					startdate: self.startdate,
					station: self.chosenStation.name,
				})
			).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.spotplays) self.spotplays = ret.spotplays
				else self.spotplays = []
				self.isFetching = false
			}).catch(function () {
				self.isFetching = false
			});
		},
	},
	watch: {},
	mounted() {
		document.title = 'Station Ad Log'
		this.getMetaLists()
		let d = new Date()
		d.setDate(d.getDate() - 5)
		this.startdate = d.getFullYear()+'-'+this.ldzero(d.getMonth()+1)+"-"+this.ldzero(d.getDate())
	}
}
</script>

<style scoped>

</style>