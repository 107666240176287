<template>
	<div>
		<div>
			<div style="padding-top: 10px; display: flex; align-items: flex-end;">
				<FilterItem label="Year">
					<template v-slot:items>
						<div class="filter-item" v-for="item in years" :key="item">
							<label><input v-model="selections.years" type="checkbox" :value="item" />{{item}}</label>
						</div>
					</template>
				</FilterItem>
				<FilterItem label="Month">
					<template v-slot:items>
						<div class="filter-item" v-for="item in months" :key="item.id">
							<label><input v-model="selections.months" type="checkbox" :value="item.id" />{{item.name}}</label>
						</div>
					</template>
				</FilterItem>
				<FilterItem label="Market" :has_search="true" v-on:searchval="filterMarkets($event)">
					<template v-slot:items>
						<div class="filter-item" v-for="item in marketsFiltered" :key="item">
							<label><input v-model="selections.markets" type="checkbox" :value="item" />{{item}}</label>
						</div>
					</template>
				</FilterItem>
				<FilterItem label="Category" :has_search="true" v-on:searchval="filterCategories($event)">
					<template v-slot:items>
						<div class="filter-item" v-for="item in categoriesFiltered" :key="item">
							<label><input v-model="selections.categories" type="checkbox" :value="item" />{{item}}</label>
						</div>
					</template>
				</FilterItem>
				<FilterItem label="Advertiser" :has_search="true" v-on:searchval="filterAdvertisers($event)">
					<template v-slot:items>
						<div class="filter-item" v-for="item in advertisersFiltered" :key="item">
							<label><input v-model="selections.advertisers" type="checkbox" :value="item" />{{item}}</label>
						</div>
					</template>
				</FilterItem>
				<FilterItem label="Network" :has_search="true" v-on:searchval="filterNetworks($event)">
					<template v-slot:items>
						<div class="filter-item" v-for="item in networksFiltered" :key="item">
							<label><input v-model="selections.networks" type="checkbox" :value="item" />{{item}}</label>
						</div>
					</template>
				</FilterItem>
				<FilterItem label="Media Outlet" :has_search="true" v-on:searchval="filterStations($event)">
					<template v-slot:items>
						<div class="filter-item" v-for="(item, index) in stationsFiltered" :key="index">
							<label><input v-model="selections.stations" type="checkbox" :value="item.station" />{{item.station}}</label>
						</div>
					</template>
				</FilterItem>

				<FilterItem label="Daypart" :has_search="true" v-on:searchval="filterDayparts($event)">
					<template v-slot:items>
						<div class="filter-item" v-for="item in daypartsFiltered" :key="item">
							<label><input v-model="selections.dayparts" type="checkbox" :value="item" />{{item}}</label>
						</div>
					</template>
				</FilterItem>
				<div><span class="primary-btn" @click="fetchData">View</span></div>
			</div>

<!--			<div style="margin-top: 10px;">-->
<!--				<p v-if="selectionInfo.length === 0">No selections made</p>-->
<!--				<div v-else>-->
<!--					<span v-for="(item, index) in selectionInfo" :key="index" style="margin-right: 10px;">-->
<!--						<span class="bold">{{ucfirst(item)}}: </span>-->
<!--						<span>{{selections[item].join(", ")}}</span>-->
<!--					</span>-->
<!--				</div>-->
<!--			</div>-->

			<div style="margin-top: 0; ">
				<div v-if="!isFetching" style="padding: 10px 0;">
					<div v-if="rowdata.length === 0" style="padding: 0 10px;"><p>Nothing to show</p></div>
					<div v-else>
						<div style="display: flex; justify-content: flex-start; padding: 5px 0;">
							<span @click="exportToExcel('aircheck')" class="excel-link">
								<i class="mdi mdi-file-excel-outline"></i>
							</span>
						</div>
						<table v-if="rowdata && rowdata.length > 0" class="altbl" id="aircheck">
							<thead>
								<tr v-for="(hr, index) in headerrowdata" :key="index">
									<td v-for="(col, ind) in hr" :key="ind" class="headingtext" data-f-bold="true">
										<span>{{col}}</span>
									</td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(rowitem, rowindex) in rowdata" :key="rowindex">
									<td v-for="(cell, ind) in rowitem" :key="ind" :data-t="getCellType(ind)" :class="getCellClass(ind)">
										<span>{{cell}}</span>
									</td>
								</tr>
							</tbody>
						</table>
<!--						<div v-if="this.paginatedRows.length > 1" style="display: flex; padding: 5px 0;">-->
<!--							<div v-if="this.paginatedRows.length > 1" style="margin: 15px 0; display: flex; align-items: center;">-->
<!--								<ALTablePaginator-->
<!--										:paginationpage="paginationPage"-->
<!--										:paginatedrowscount="paginatedRows.length"-->
<!--										v-on:nextpage="nextpage" v-on:prevpage="prevpage"-->
<!--										v-on:setpage="paginationPage = $event"-->
<!--								></ALTablePaginator>-->
<!--							</div>-->
<!--						</div>-->
					</div>
				</div>
				<div v-if="isFetching" style="padding: 20px 0; height: 300px; width: 500px;">
					<ChartLoaderInsert style="z-index: 9;"></ChartLoaderInsert>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import 'vue-datepicker-next/index.css';
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import FilterItem from "@/components/FilterItem.vue";
// import ALTablePaginator from "@/components/ALTablePaginator.vue";
import TableToExcel from "@linways/table-to-excel";

export default {
	name: "AircheckClients",
	components: {
		// ALTablePaginator,
		FilterItem,
		ChartLoaderInsert,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			// viewPerms: ['elt','pcr'],
			showStationSelectOverlay: false,

			isFetching: false,

			//returned from indexes
			advertisers: [],
			categories: [],
			dayparts: [],
			markets: [],
			months: [],
			networks: [],
			stations: [],
			years: [],

			advertisersFiltered: [],
			categoriesFiltered: [],
			daypartsFiltered: [],
			marketsFiltered: [],
			networksFiltered: [],
			stationsFiltered: [],

			selections: {
				advertisers: [],
				categories: [],
				dayparts: [],
				markets: [],
				months: [],
				networks: [],
				stations: [],
				years: [],
			},

			stationSearchTextVal: '',

			headerrowdata: [],
			rowdata: [],
			// paginationPage: 1,
			// pagination: 50,

		}
	},
	computed: {
		// selectionInfo() {
		// 	let selectionsDone = []
		// 	if(this.selections.advertisers.length > 0) selectionsDone.push('advertisers')
		// 	if(this.selections.categories.length > 0) selectionsDone.push('categories')
		// 	if(this.selections.dayparts.length > 0) selectionsDone.push('dayparts')
		// 	if(this.selections.months.length > 0) selectionsDone.push('months')
		// 	if(this.selections.years.length > 0) selectionsDone.push('years')
		// 	if(this.selections.networks.length > 0) selectionsDone.push('networks')
		// 	if(this.selections.markets.length > 0) selectionsDone.push('markets')
		// 	if(this.selections.stations.length > 0) selectionsDone.push('stations')
		// 	return selectionsDone
		// },
		// paginatedRows() {
		// 	let tempArr = []
		// 	let tempSubArr = []
		// 	for(let it of this.rowdata) {
		// 		tempSubArr.push(it)
		// 		if(tempSubArr.length === this.pagination) {
		// 			tempArr.push(tempSubArr)
		// 			tempSubArr = []
		// 		}
		// 	}
		// 	if(tempSubArr.length > 0) tempArr.push(tempSubArr)
		// 	return tempArr
		// },
		// rowsForPage() {
		// 	return this.paginatedRows[this.paginationPage-1]
		// },
	},
	methods: {
		getCellType(colind) {
			if(colind > 1) return 'n'
			return 's'
		},
		getCellClass(colind) {
			if(colind > 1) return 'aright'
			return ''
		},
		exportToExcel(tblid, filename = 'export') {
			if(this.excelfilename && this.excelfilename.length > 0) {
				filename = this.excelfilename;
			}
			TableToExcel.convert(document.getElementById(tblid), {
				name: filename+".xlsx",
				sheet: {
					name: 'Sheet 1'
				}
			});
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		filterNetworks(searchval) {
			if(searchval.length === 0) this.networksFiltered = this.networks
			this.networksFiltered = this.networks.filter(item => this.selections.networks.includes(item) || item.toLowerCase().includes(searchval.toLowerCase()))
		},
		filterAdvertisers(searchval) {
			if(searchval.length === 0) this.advertisersFiltered = this.advertisers
			this.advertisersFiltered = this.advertisers.filter(item => this.selections.advertisers.includes(item) || item.toLowerCase().includes(searchval.toLowerCase()))
		},
		filterCategories(searchval) {
			if(searchval.length === 0) this.categoriesFiltered = this.categories
			this.categoriesFiltered = this.categories.filter(item => this.selections.categories.includes(item) || item.toLowerCase().includes(searchval.toLowerCase()))
		},
		filterDayparts(searchval) {
			if(searchval.length === 0) this.daypartsFiltered = this.dayparts
			this.daypartsFiltered = this.dayparts.filter(item => this.selections.dayparts.includes(item) || item.toLowerCase().includes(searchval.toLowerCase()))
		},
		filterMarkets(searchval) {
			if(searchval.length === 0) this.marketsFiltered = this.markets
			this.marketsFiltered = this.markets.filter(item => this.selections.markets.includes(item) || item.toLowerCase().includes(searchval.toLowerCase()))
		},
		filterStations(searchval) {
			this.stationSearchTextVal = searchval
			let s = this.stations
			if(this.selections.networks.length > 0) s = s.filter(item => this.selections.networks.includes(item.network))
			if(this.selections.markets.length > 0) s = s.filter(item => this.selections.markets.includes(item.market))
			if(searchval.length === 0) this.stationsFiltered = s
			this.stationsFiltered = s.filter(item => this.selections.stations.includes(item.station) || item.station.toLowerCase().includes(searchval.toLowerCase()))
		},
		getMetaLists() {
			let self = this;
			let targ = "/api/aircheck-clients/get-meta-lists.php";
			this.isFetching = true
			axios.get(targ).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.error === 1) console.log(ret)
				else if(ret.advertisers) {
					self.advertisers = ret.advertisers
					self.categories = ret.categories
					self.dayparts = ret.dayparts
					self.markets = ret.markets
					self.months = ret.months
					self.networks = ret.networks
					self.stations = ret.stations
					self.years = ret.years

					self.advertisersFiltered = ret.advertisers
					self.categoriesFiltered = ret.categories
					self.daypartsFiltered = ret.dayparts
					self.marketsFiltered = ret.markets
					self.monthsFiltered = ret.months
					self.networksFiltered = ret.networks
					self.stationsFiltered = ret.stations
					self.yearsFiltered = ret.years
				}
				self.isFetching = false
			}).catch(function (error) {
				console.log(error);
				self.isFetching = false
			});
		},
		fetchData() {
			let self = this;
			let targ = "/api/aircheck-clients/fetch-summary.php";
			this.isFetching = true
			axios.post(targ, JSON.stringify({
				selections: self.selections,
			})).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.rows_main) self.rowdata = ret.rows_main
				if (ret.rows_header) self.headerrowdata = ret.rows_header
				self.isFetching = false
			}).catch(function () {
				self.isFetching = false
			});
		},
	},
	watch: {
		selections: {
			deep: true,
			handler() {
				this.filterStations(this.stationSearchTextVal)
			}
		},
		networksFiltered() {
			this.filterStations(this.stationSearchTextVal)
		},
		marketsFiltered() {
			this.filterStations(this.stationSearchTextVal)
		},
	},
	mounted() {
		document.title = 'Aircheck Client Lookup'
		this.getMetaLists()
	}
}
</script>

<style scoped>
.excel-link {
	cursor: pointer;
}
.excel-link:hover {
	opacity: 0.8;
}
.aright {
	text-align: right;
}
</style>