<template>
	<div class="chart-holder" :class="addclasses" :style="'height: '+this.holderHeight+'px;'">
		<div class="headsection">
			<p class="chtitle">{{charttitle}}</p>
			<slot name="headerRight"></slot>
		</div>
		<div class="chart-container" :style="'height: '+this.chartHeight+'px;'">
			<div style="width: 100%; height: 100%;">
				<slot name="default"></slot>
			</div>
		</div>
		<div v-if="!hidesource" style="padding: 4px 10px 10px;"><p style="font-size: 10px; color: #888;">Source: {{source}}</p></div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: 'ChartHolderDash',
	components: {},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

		}
	},
	computed: {
		chartHeight() {
			if(this.height) return this.height;
			else return 400;
		},
		holderHeight() {
			if(this.height) return this.height + 86;
			else return 486;
		}
	},
	methods: {

	},
	watch: {},
	props: {
		charttitle: String,
		source: String,
		addclasses: String,
		height: Number,
		hidesource: {
			type: Boolean,
			default: false,
		}
	},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>
.headsection {
	height: 60px;
	padding: 0 20px;
	border-bottom: 1px solid #DDD;
	background-color: #FAFAFA;
	display: flex;
	justify-content: space-between;
}
.headsection .chtitle {
	line-height: 1;
	color: #444;
	font-weight: bold;
	font-size: 15px;
	align-items: center;
	display: flex;
}
.chart-container {
	padding: 20px 0 0;
	justify-content:center;
	position: relative;
	display: flex;
	width: 100%;
}
.quarter .headsection {
	height: 40px;
}
.quarter .headsection .chtitle {
	font-size: 13px;
}
.quarter .chart-container {
	padding-top: 10px;
}
</style>
