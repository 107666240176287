<template>
	<div>
		<div v-if="excelatbottom !== true && ((searchbox && searchbox === true) || (excel && excel === true))" style="display: flex; align-items: center; justify-content: space-between; padding: 5px 0;">
			<div v-if="searchbox && searchbox === true" class="" style="width: 300px; margin: 5px 0;">
				<input type="text" class="text-field-input" v-model="searchboxval" placeholder="Search" />
			</div>
			<div v-if="paginatehour === true" class="hours">
				<div v-for="h in 24" :key="h" @click="paginationHour = h-1" class="hour" :class="{active: h-1 === paginationHour}">{{h-1}}</div>
			</div>
			<slot name="topfilters"></slot>
<!--			<div @click="resetSorts">Reset</div>-->
			<div v-if="excel && excel === true">
				<span @click="exportToExcel(tblRandId)" class="excel-link">
					<i class="mdi mdi-file-excel-outline"></i>
				</span>
			</div>
		</div>
		<table v-if="cols && cols.length > 0 && rowdata && rowdata.length > 0" class="altbl" :class="tblClassesComputed" :id="tblRandId">
			<thead>
				<tr>
					<td v-for="col in cols" :key="col.title" class="headingtext" :class="getColClasses(col)" data-f-bold="true">
						<span>
							<span @click="sortColumn(col.fldid)">
								<i v-if="col.sortable && col.fldid === sortColName" class="mdi mdi-sort-ascending" :class="{ 'mdi-sort-descending': sortColOrder === 'asc' }"></i>
								{{col.title}}
							</span>
							<FilterItem v-if="col.filter && col.filter === true" :icon_only="true" label="Filter" :has_search="false" style="display: inline-block; margin-left: 5px;">
								<template v-slot:items>
									<div class="filter-item" v-for="item in filterables[col.fldid]" :key="item">
										<label><input v-model="filterSelections[col.fldid]" type="checkbox" :value="item" />{{item}}</label>
									</div>
								</template>
							</FilterItem>
						</span>
					</td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(rowitem, rowindex) in rowsForPage" :key="rowindex" :class="{subtotalrow : rowitem.isTotalRow}">
					<td v-for="c in cols" :key="c.title" :class="getColClasses(c, true, getCellVal(rowitem, c))">
						<span v-html="getCellVal(rowitem ,c)"></span>
					</td>
				</tr>
			</tbody>
		</table>
		<div v-if="(pagination && this.paginatedRows.length > 1) || (excelatbottom === true && excel && excel === true)" style="display: flex; padding: 5px 0;">
			<div v-if="pagination && this.paginatedRows.length > 1" style="margin: 15px 0; display: flex; align-items: center;">
				<ALTablePaginator
						:paginationpage="paginationPage"
						:paginatedrowscount="paginatedRows.length"
						v-on:nextpage="nextpage" v-on:prevpage="prevpage"
						v-on:setpage="paginationPage = $event"
				></ALTablePaginator>
			</div>
			<div v-if="excelatbottom === true && excel && excel === true" style="display: flex; justify-content: flex-end; padding: 5px 0;">
				<span @click="exportToExcel(tblRandId)" class="excel-link">
					<i class="mdi mdi-file-excel-outline"></i>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
// import TableToExcel from "@linways/table-to-excel";
import zipcelx from 'zipcelx';
import ALTablePaginator from "@/components/ALTablePaginator";
import FilterItem from "@/components/FilterItem.vue";
export default {
	name: "ALTable",
	components: {
		ALTablePaginator,
		FilterItem,
	},
	props: {
		rowdata: Array,
		cols: Array,
		tblclasses: String,
		excel: Boolean,
		excelfilename: String,
		excelatbottom: Boolean,
		searchbox: Boolean,
		pagination: Number,
		condensed: Boolean,
		paginatehour: {
			type: Boolean,
			default: false
		},
		paginatehourfield: {
			type: String,
			default: 'hour'
		},
		searchablefields: Array,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			sortColName: null,
			sortColOrder: 'desc',
			tblRandId: null,
			searchboxval: '',
			paginationPage: 1,
			paginationHour: 0,

			rowdataRawIn: [],
			filterables: {},
			filterSelections: {},
			firstDataLoadDone: false,
		}
	},
	computed: {
		rows() {
			let rows = this.rowdata
			if(this.searchboxval.length > 0) { //add any filter by text input here
				let newer = [];
				let sboxval = this.searchboxval.toLowerCase();
				for (let r of rows) {
					for (let fld in r) {
						if(this.searchablefields && this.searchablefields.length > 0) {
							if(this.searchablefields.indexOf(fld) === -1) continue;
						}
						if(r[fld] && (isNaN(r[fld]) || parseFloat(r[fld]) != r[fld])) {
							if (r[fld].toLowerCase().indexOf(sboxval) > -1) {
								newer.push(r);
								break;
							}
						}
					}
				}
				rows = newer;
			}
			//handle any filters
			for(let fld in this.filterSelections) {
				if(this.filterSelections[fld].length > 0) {
					rows = rows.filter((r) => {
						if(this.filterSelections[fld].indexOf(r[fld]) > -1) return true
						else return false
					})
				}
			}
			if(this.sortColName) {
				let useStrings = false;
				if(isNaN(rows[0][this.sortColName]) || parseFloat(rows[0][this.sortColName]) != rows[0][this.sortColName]) useStrings = true;
				if(this.sortColOrder === 'asc') {
					if(useStrings === true) {
						rows = rows.sort((a, b) => (a[this.sortColName].toLowerCase() > b[this.sortColName].toLowerCase() ? 1 : -1))
					}
					else rows = rows.sort((a, b) => (a[this.sortColName] > b[this.sortColName]) ? 1 : -1)
				}
				else {
					if(useStrings === true) rows = rows.sort((a, b) => (a[this.sortColName].toLowerCase() > b[this.sortColName].toLowerCase() ? -1 : 1))
					else rows = rows.sort((a, b) => (a[this.sortColName] > b[this.sortColName]) ? -1 : 1)
				}
			}

			return rows;
		},
		paginatedRows() {
			let myrows = this.rows
			if(this.paginatehour === true) {
				myrows = this.rows.filter(item => item[this.paginatehourfield] === this.paginationHour)
			}
			if(!this.pagination) return [myrows]
			else {
				let tempArr = []
				let tempSubArr = []
				for(let it of myrows) {
					tempSubArr.push(it)
					if(tempSubArr.length === this.pagination) {
						tempArr.push(tempSubArr)
						tempSubArr = []
					}
				}
				if(tempSubArr.length > 0) tempArr.push(tempSubArr)
				return tempArr
			}
		},
		rowsForPage() {
			return this.paginatedRows[this.paginationPage-1]
		},
		tblClassesComputed() {
			let c = '';
			if(this.tblclasses && this.tblclasses.length > 0) c += this.tblclasses + ' ';
			if(this.condensed && this.condensed === true) c += 'condensed ';
			return c;
		},
	},
	methods: {
		resetSorts() {
			this.sortColName = this.cols[0].fldid //default to first column [0
			this.sortColOrder = 'desc'
		},
		prevpage() {
			if(this.paginationPage > 1) this.paginationPage--
		},
		nextpage() {
			if(this.paginationPage < this.paginatedRows.length) this.paginationPage++
		},
		exportToExcel(tblid, filename = 'export') {
			if(this.excelfilename && this.excelfilename.length > 0) {
				filename = this.excelfilename;
			}
			let excelData = []
			let excelRowData = []
			for(let col of this.cols) {
				excelRowData.push({value: col.title, type: 'string'})
			}
			excelData.push(excelRowData)
			for(let row of this.rows) {
				excelRowData = []
				for(let col of this.cols) {
					excelRowData.push({value: row[col.fldid], type: 'string'})
				}
				excelData.push(excelRowData)
			}
			const config = {
				filename: filename,
				sheet: {
					data: excelData
				}
			};
			zipcelx(config)
		},
		getCellVal(rowitem, colitem) {
			let fldid = colitem.fldid;
			let val = rowitem[fldid];
			if(val == 'null') val = ''
			if(colitem.numtype && colitem.numtype === 'number') val = this.numdisplay(val);
			else if(colitem.numtype && colitem.numtype === 'pct') {
				if(val != '') val = this.numdisplay(val)+"%";
			}
			else if(colitem.numtype && colitem.numtype === 'dollars') {
				if(val != '') val = "$"+this.numdisplay(val);
			}
			else if(colitem.numtype && (colitem.numtype === 'pctbubble' || colitem.numtype === 'numbubble')) {
				let h = '';
				if(val !== '' && val !== 0) {
					h += '<span class="bubble';
					if(val < 0) h += ' down';
					h += '">';
					if(val > 0) h += '+';
					if(val != '') h += this.numdisplay(val);
					if(colitem.numtype === 'pctbubble') h += "%";
					h += '</span>';
				}
				return h;
			}
			return val;
		},
		getColClasses(col, isCell = false, val = null) {
			let c = '';
			if(col.align === 'right' || col.numtype && ['number','pct','pctbubble','dollars'].indexOf(col.numtype) > -1) c += 'rt ';
			if(['center','centre','ctr'].includes(col.align)) c += 'ctr ';
			c += ' ' + val+ ' '
			if(isCell === true && val !== null) {
				if(col.highlight && col.highlight === true) {
					let highlightzero = 0
					if(col.highlightzero) highlightzero = col.highlightzero
					if(col.numtype === 'pct') {
						let myval = parseFloat(val)
						if(myval > highlightzero) c += 'highlight '
						else if(myval < highlightzero) c += 'lowlight '
					}
				}
			}
			return c;
		},
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		prepareFilters() {
			let f = {}
			let flds = []
			if(this.rowdata && this.rowdata.length > 0) {
				for (let c of this.cols) {
					if (c.filter && c.filter === true) {
						this.filterSelections[c.fldid] = []
						f[c.fldid] = []
						flds.push(c.fldid)
					}
				}
				for (let r of this.rows) {
					for (let fld of flds) {
						if (f[fld].indexOf(r[fld]) === -1) f[fld].push(r[fld])
						if (this.filterSelections[fld].indexOf(r[fld]) === -1) {
							this.filterSelections[fld].push(r[fld])
						}
					}
				}
				for (let fld in f) {
					f[fld].sort()
				}
				this.filterables = f
				this.firstDataLoadDone = true
			}
		},
	},
	watch: {
		rowdata() {
			this.paginationPage = 1 //return to Page 1 when data changes
			if(this.firstDataLoadDone === false) {
				this.prepareFilters()
			}
		},
	},
	created() {
		if(this.tblRandId === null) {
			this.tblRandId = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8);
		}
	},
	mounted() {
		window.addEventListener('click', event => {
			let target = event.target
			if (target && target.href) {
				if(target.href.indexOf(window.location.origin) === -1) return //if external link, continue to handle normally
				else { //parse and handle as internal link
					event.preventDefault()
					const url = target.href.replace(window.location.origin, '').replace('#', '')
					this.$router.push(url)
				}
			}
		})
		this.prepareFilters()
	}
}
</script>

<style scoped>
.altbl {
	width: 100%;
}
.altbl thead td {
	cursor: pointer;
}
.altbl tr.subtotalrow {
	font-weight: bold;
}
.altbl.condensed tr td {
	font-size: 12px;
	padding: 5px 8px;
}
.altbl.condensed thead tr td {
	padding: 5px 8px;
}
.excel-link {
	cursor: pointer;
	padding: 4px 0;
}
.excel-link i {
	font-size: 20px;
}
.highlight {
	background: #b5f5b9;
}
.lowlight {
	background: #f79c9c;
}
.ctr {
	text-align: center;
}
.hours {
	display: flex;
	gap: 5px;
}
.hour {
	border: 1px solid #ccc;
	padding: 5px;
	font-size: 11px;
	cursor: pointer;
}
.hour.active, .hour:hover {
	background: #EEE;
}
</style>