<template>
	<div>
		<div style="display: flex; justify-content: flex-start; gap: 15px;">
			<div class="market-summary">

				<div style="display: flex; justify-content: flex-start; align-items: flex-end; gap: 15px; margin: 10px 0 20px;">
					<div>
						<p class="above-select-label">Asset Type</p>
						<select class="select-styled" v-model="chosenType">
							<option v-for="opt in typesAllowed" :key="opt.id" :value="opt.id">{{opt.name}}</option>
						</select>
					</div>
					<div>
						<p class="above-select-label">Metric</p>
						<select class="select-styled" v-model="chosenMetric">
							<option v-for="opt in metricsAllowed" :key="opt.id" :value="opt.id">{{opt.name}}</option>
						</select>
					</div>
					<div>
						<p class="above-select-label">Page/Site/Asset</p>
						<AutocompleterStringOnly v-if="pagenamelist.length > 0" :searchlist="pagenamelist" :incomingval="chosenPage"
							idfldname="id" textfldname="name" v-on:update="updateChosenPageId($event)"
						></AutocompleterStringOnly>
					</div>
					<div v-if="chosenMetric !== 'followers'">
						<p class="above-select-label">Period</p>
						<select class="select-styled" v-model="chosenPeriod">
							<option v-for="opt in periodsAllowed" :key="opt.id" :value="opt.id">{{opt.name}}</option>
						</select>
					</div>
					<div><span class="primary-btn" @click="getChartData">Fetch</span></div>
				</div>

				<div class="chart-rows">
					<ChartHolderDash :charttitle="chartTitle" :source="chartSource" :height="600">
						<template v-slot:default>
							<ApexLineWeeklyTrend :chartdata="retData" filename="WebMetrics" :fetching="fetchingCharts"></ApexLineWeeklyTrend>
						</template>
					</ChartHolderDash>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import AutocompleterStringOnly from "@/components/AutocompleterStringOnly";
import ApexLineWeeklyTrend from "@/components/charts/ApexLineWeeklyTrend";

export default {
	name: "WebExplorer",
	components: {
		ApexLineWeeklyTrend,
		AutocompleterStringOnly,
		ChartHolderDash,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			typesAllowed: [
				{id: 'webga', name: 'Web (GA4)'},
				{id: 'facebook_sprout', name: 'Facebook'},
				{id: 'fb_instagram_account_sprout', name: 'Instagram'},
				{id: 'twitter_sprout', name: 'Twitter'},
				{id: 'tiktok_sprout', name: 'TikTok'},
				{id: 'youtube_sprout', name: 'YouTube'},
				{id: 'fb', name: 'Facebook (Legacy)'},
				{id: 'insta', name: 'Instagram (Legacy)'},
				{id: 'web', name: 'Web (Legacy UA)'},
				// {id: 'twitter', name: 'Twitter (Legacy)'},
			],
			fetchingCharts: false,
			retData: [],

			chosenType: 'webga', //web, fb or insta
			chosenMetric: 'sessions',
			chosenPage: '',
			chosenPeriod: 'w',

			weblist: [],
			fblist: [],
			instalist: [],
			// twitterlist: [],

			fbsproutlist: [],
			instasproutlist: [],
			twittersproutlist: [],
			tiktoksproutlist: [],
			youtubesproutlist: [],

			sproutMetricsByNetwork: {},

			chartSource: "ARN Google Analytics, Facebook/Instagram/Twitter Insights API, Sprout API",
		}
	},
	computed: {
		pagenamelist() {
			if(this.chosenType === 'web' || this.chosenType === 'webga') return this.weblist;
			else if(this.chosenType === 'fb') return this.fblist;
			else if(this.chosenType === 'insta') return this.instalist;
			// else if(this.chosenType === 'twitter') return this.twitterlist;
			else if(this.chosenType === 'facebook_sprout') return this.fbsproutlist;
			else if(this.chosenType === 'fb_instagram_account_sprout') return this.instasproutlist;
			else if(this.chosenType === 'twitter_sprout') return this.twittersproutlist;
			else if(this.chosenType === 'tiktok_sprout') return this.tiktoksproutlist;
			else if(this.chosenType === 'youtube_sprout') return this.youtubesproutlist;
			return [];
		},
		metricsAllowed() {
			if(this.chosenType === 'web') {
				return [
					{id: 'sessions', name: 'Sessions'},
					{id: 'users', name: 'Unique Users'},
					{id: 'pageviews', name: 'Page Views / Impressions'},
					{id: 'avgSessionDuration', name: 'Avg Session (sec)'},
					{id: 'sessions_aus', name: 'Sessions (Aus)'},
					{id: 'users_aus', name: 'Unique Users (Aus)'},
					{id: 'pageviews_aus', name: 'Page Views / Impressions (Aus)'},
					{id: 'avgSessionDuration_aus', name: 'Avg Session (sec) (Aus)'},
				];
			}
			if(this.chosenType === 'webga') {
				return [
					{id: 'sessions', name: 'Sessions'},
					{id: 'totalUsers', name: 'Unique Users'},
					{id: 'screenPageViews', name: 'Page Views / Impressions'},
					{id: 'averageSessionDuration', name: 'Avg Session (sec)'},
				];
			}
			else if(this.chosenType === 'fb') {
				return [
					{id: 'impressions', name: 'Impressions'},
					{id: 'page_fans', name: 'Fans / Likes'},
					{id: 'engagement', name: 'Engagement'},
					{id: 'eng_users', name: 'Engaged Users'},
					{id: 'vid_views', name: 'Video Views'},
				];
			}
			else if(this.chosenType === 'insta') {
				return [
					{id: 'impressions', name: 'Impressions'},
					{id: 'impressions_unique', name: 'Unique Users'},
					{id: 'followers', name: 'Followers'},
				];
			}
			// else if(this.chosenType === 'twitter') {
			// 	return [
			// 		{id: 'followers', name: 'Followers'},
			// 	];
			// }
			else if(this.chosenType === 'facebook_sprout') return this.sproutMetricsByNetwork['facebook']
			else if(this.chosenType === 'fb_instagram_account_sprout') return this.sproutMetricsByNetwork['fb_instagram_account']
			else if(this.chosenType === 'twitter_sprout') return this.sproutMetricsByNetwork['twitter']
			else if(this.chosenType === 'tiktok_sprout') return this.sproutMetricsByNetwork['tiktok']
			else if(this.chosenType === 'youtube_sprout') return this.sproutMetricsByNetwork['youtube']
			return [];
		},
		periodsAllowed() {
			let p = [
				{id: 'w', name: 'Weekly'},
			];
			let allowMonthly = true
			if(this.chosenType === 'insta' && this.chosenMetric === 'followers') allowMonthly = false
			if(allowMonthly === true) {
				p.push({id: 'm', name: 'Monthly'});
			}
			if(this.chosenType.indexOf('_sprout') !== -1) {
				p = [{id: 'd', name: 'Daily'}]
				let allows = ['impressions','video_views','post_link_clicks','video_views_total','profile_views']
				if(allows.indexOf(this.chosenMetric) > -1) {
					p.push({id: 'm', name: 'Monthly'});
					p.push({id: 'w', name: 'Weekly'});
				}
			}
			return p;
		},
		chartTitle() {
			return '';
		},
		cdatalength() {
			return this.chartdata.length;
		},
	},
	methods: {
		updateChosenPageId(ob) {
			this.chosenPage = ob.id;
		},
		getLists() {
			let self = this;
			axios.get("/api/web-get-lists.php",).then(function (response) {
				let ret = response.data; //response.data is returned info
				if(ret.webs) self.weblist = ret.webs;
				if(ret.fbs) self.fblist = ret.fbs;
				if(ret.instas) self.instalist = ret.instas;
				if(ret.twitters) self.twitterlist = ret.twitters;
				let sprouts = ret.sprouts
				if(sprouts.facebook_sprout) self.fbsproutlist = sprouts.facebook_sprout;
				if(sprouts.fb_instagram_account_sprout) self.instasproutlist = sprouts.fb_instagram_account_sprout;
				if(sprouts.twitter_sprout) self.twittersproutlist = sprouts.twitter_sprout;
				if(sprouts.tiktok_sprout) self.tiktoksproutlist = sprouts.tiktok_sprout;
				if(sprouts.youtube_sprout) self.youtubesproutlist = sprouts.youtube_sprout;
				self.sproutMetricsByNetwork = ret.sproutMetricsByNetwork
				if (ret.error === 1) {
					self.form1working = false;
					self.showKalert(ret.errob);
				}
				if(self.chosenPage === '') {
					if(self.chosenType === 'webga' || self.chosenType === 'web') self.chosenPage = self.weblist[1].id //skip 4kq
					else self.chosenPage = self.pagenamelist[0].id;
					self.getChartData();
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getChartData() { //for cache lookup
			if(this.fetchingCharts !== true && this.chosenPage.length > 0 && this.chosenMetric.length > 0 && this.chosenType.length > 0) {
				this.fetchingCharts = true;
				let self = this;
				let targ = "/api/web-get-chart.php";
				let gaFilter = 'all'
				this.retData = []
				let mymetric = this.chosenMetric
				if(this.chosenType !== 'webga') mymetric = this.chosenMetric.toLowerCase()
				if(mymetric.indexOf('aus') > -1) {
					gaFilter = 'aus'
					mymetric = mymetric.replace('_aus','')
				}
				axios.post(targ, JSON.stringify({
					type: self.chosenType,
					pageAsset: self.chosenPage,
					metric: mymetric,
					cadence: self.chosenPeriod,
					gaFilter: gaFilter
				})).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.data && ret.data.length > 0) { //normal report type
						self.retData = ret.data;
					}
					else self.retData = [];
					self.fetchingCharts = false;
				}).catch(function () {
					self.fetchingCharts = false;
				});
			}
		},
	},
	watch: {
		chosenPage() { },
		chosenMetric() {
			if(this.chosenType.indexOf('_sprout') !== -1) {
				if(this.periodsAllowed.indexOf(this.chosenPeriod) === -1) {
					this.chosenPeriod = 'd'
				}
			}
		},
		chosenType() {
			if(this.chosenType === 'web') this.chosenMetric = 'sessions';
			else if(this.chosenType === 'fb' || this.chosenType === 'insta') this.chosenMetric = 'impressions';
			else this.chosenMetric = this.sproutMetricsByNetwork[this.chosenType.replace("_sprout","")][0].id;
			this.chosenPage = this.pagenamelist[0].id;
			if(this.chosenType === 'twitter') this.chosenPeriod = 'm';
			else if(this.chosenType.indexOf('_sprout') !== -1) this.chosenPeriod = 'd';
			else this.chosenPeriod = 'w';
		},
	},
	mounted() {
		this.getLists();
		document.title = 'ARN Web Explorer';
	}
}
</script>

<style scoped>
.market-summary {
	display: block;
	overflow-y: auto;
	height: 100%;
	width: 100%;
}
.content-block p {
	line-height: 1.6;
}

@media screen and (max-width: 1350px) {

}
</style>