<template>
	<div>
		<div>
			<div style="padding-top: 10px; display: flex; align-items: flex-end;">
				<div style="margin-right: 10px; z-index: 1">
					<p class="above-select-label">Lookup Type</p>
					<select class="select-styled" v-model="reportType">
						<option value="postcode">Postcode List</option>
						<option value="suburbstate">Suburb & State</option>
						<option value="lga">Local Government Area</option>
						<option value="lap">License/Market Postcodes</option>
					</select>
				</div>
				<div><span class="primary-btn" @click="fetchData(false)">Submit</span></div>
			</div>

			<div style="margin-top: 20px; ">
				<div v-if="pageview === 'dataEntry'">
					<textarea v-if="reportType !== 'lap'" v-model="pastedata" :placeholder="placey" style="width: 400px; height: 300px;"></textarea>
					<select v-if="reportType === 'lap'" class="select-styled" v-model="selectedLapname" style="max-width: 400px;">
						<option :value="null"></option>
						<option v-for="m in marketNamesFromTbl" :key="m" :value="m">{{m}}</option>
					</select>
				</div>
				<div v-if="pageview === 'results'">
					<div>
						<span class="text-link" @click="pageview = 'dataEntry'" style="cursor: pointer; margin-right: 10px;"> &lt; Back</span>
						<span class="netshortcut" @click="exportToExcel(reportType+'_results', ucfirst(reportType))">Export</span>
					</div>
					<div v-if="!isFetching" style="padding: 20px 0;">
						<div v-if="reportType === 'postcode'">
							<table class="tbl" id="postcode_results">
								<thead>
									<tr><td>Postcode</td><td>Region</td><td>State</td><td>LAP</td><td>LAP 10+ Pop</td><td>Stations</td><td>Rep / Info</td></tr>
								</thead>
								<tbody>
									<tr v-for="(r, ind) in tbldata" :key="ind">
										<td>{{r.postcode}}</td>
										<td>{{r.region}}</td>
										<td>{{r.state}}</td>
										<td>{{r.lapname}}</td>
										<td v-if="r.mktid > 0" style="text-align: right;">{{getIndPop(r.mktid)}}</td>
										<td v-if="r.mktid > 0" style="text-align: right;">{{getStationsForMarket(r.mktid)}}</td>
										<td>
											<div style="display: flex; align-items: center">
												{{getOwnershipForMarket(r.mktid)}}
												<div>
													<InfoIcon v-if="r.mktid > 0" @click="showMarketSummary(r.mktid)" title="Click to view market information and recent survey results in popup"></InfoIcon>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div v-if="reportType === 'suburbstate'">
							<table class="tbl" id="suburbstate_results">
								<thead>
								<tr><td>Suburb</td><td>State</td><td>LAP</td><td>LAP 10+ Pop</td><td>Stations</td><td>Rep / Info</td><td>Postcodes</td></tr>
								</thead>
								<tbody>
								<tr v-for="(r, ind) in tbldata" :key="ind">
									<td>{{r.suburb}}</td>
									<td>{{r.state}}</td>
									<td>{{r.lapname}}</td>
									<td v-if="r.mktid > 0" style="text-align: right;">{{getIndPop(r.mktid)}}</td>
									<td v-if="r.mktid > 0" style="text-align: right;">{{getStationsForMarket(r.mktid)}}</td>
									<td>
										<div style="display: flex; align-items: center">
											{{getOwnershipForMarket(r.mktid)}}
											<div>
												<InfoIcon v-if="r.mktid > 0" @click="showMarketSummary(r.mktid)" title="Click to view market information and recent survey results in popup"></InfoIcon>
											</div>
										</div>
									</td>
									<td>
										{{r.postcodes}}
									</td>
								</tr>
								</tbody>
							</table>
						</div>
						<div v-if="reportType === 'lga'">
							<table class="tbl" id="suburbstate_results">
								<thead>
								<tr><td>LGA</td><td>State</td><td>LAP</td><td>LAP 10+ Pop</td><td>Stations</td><td>Rep / Info</td></tr>
								</thead>
								<tbody>
								<tr v-for="(r, ind) in tbldata" :key="ind">
									<td>{{r.lga}}</td>
									<td>{{r.state}}</td>
									<td>{{r.lapname}}</td>
									<td v-if="r.mktid > 0" style="text-align: right;">{{getIndPop(r.mktid)}}</td>
									<td v-if="r.mktid > 0" style="text-align: right;">{{getStationsForMarket(r.mktid)}}</td>
									<td>
										<div style="display: flex; align-items: center">
											{{getOwnershipForMarket(r.mktid)}}
											<div>
												<InfoIcon v-if="r.mktid > 0" @click="showMarketSummary(r.mktid)" title="Click to view market information and recent survey results in popup"></InfoIcon>
											</div>
										</div>
									</td>
								</tr>
								</tbody>
							</table>
						</div>
						<div v-if="reportType === 'lap'">
							<table class="tbl" id="lappc_results">
								<thead>
									<tr><td>Postcode</td></tr>
								</thead>
								<tbody>
									<tr v-for="(r, ind) in tbldata" :key="ind">
										<td>{{r.postcode}}</td>
									</tr>
								</tbody>
							</table>
						</div>

					</div>
					<div v-else>Fetching...please wait</div>
				</div>
			</div>
		</div>

		<div v-if="showSummaryView" class="loader-overlay">
			<div class="market-summary-view">
				<MarketSummary v-if="summaryMarketOb" :aspopup="true" :mktob="summaryMarketOb" v-on:close="showSummaryView = false"></MarketSummary>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import InfoIcon from "@/components/arnmarkets/InfoIcon";
import MarketSummary from "@/components/arnmarkets/MarketSummary";
import TableToExcel from "@linways/table-to-excel";

export default {
	name: "SalesToolsGeoLookup",
	components: {
		MarketSummary,
		InfoIcon

	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			pageview: 'dataEntry',
			reportType: 'postcode', //suburbstate, lga, lap
			pastedata: '', //paste from excel

			tbldata: [],
			marketNamesFromTbl: [],

			selectedLapname: null,

			isFetching: false,
			summaryMarketOb: null,
			showSummaryView: false,
		}
	},
	computed: {
		placey() {
			if(this.reportType === 'postcode') {
				return 'Paste a column of postcodes from Excel (one postcode per row).  Single column.'
			}
			else if(this.reportType === 'suburbstate') {
				return 'Paste two columns from Excel (suburb, state - one suburb per row).'
			}
			else if(this.reportType === 'lga') {
				return 'Paste two columns from Excel (LGA name, state - one LGA per row).'
			}
			return ''
		},
	},
	methods: {
		exportToExcel(tblid, filename = 'export') {
			TableToExcel.convert(document.getElementById(tblid), {
				name: filename+".xlsx",
				sheet: {
					name: 'Sheet 1'
				}
			});
		},
		getStationsForMarket(mktid) {
			let names = []
			let obs = this.store.stations.filter(item => item.mktid === parseInt(mktid))
			for(let ob of obs) {
				if(ob.isCommercial === true && ob.outsidemkt === false && ob.community === false) {
					names.push(ob.name)
				}
			}
			return names.join(", ")
		},
		getOwnershipForMarket(mktid) {
			let names = []
			let ob = this.getMarketById(mktid)
			if(ob) {
				for(let rep of ob.ownership.reps) {
					names.push(rep.toUpperCase())
				}
			}
			return names.join(", ")
		},
		showMarketSummary(mktid) {
			this.summaryMarketOb = this.getMarketById(mktid)
			this.showSummaryView = true
		},
		getMarketById(mid) {
			mid = parseInt(mid)
			return this.store.markets.find(item => item.id === mid)
		},
		getIndPop(mktid) {
			let mkt = this.getMarketById(mktid)
			if(mkt && mkt.indpop) return this.numdisplay(mkt.indpop)
			return ''
		},
		fetchData() {
			let self = this;
			let targ = "/api/sales-tools/geo-lookup.php";
			this.isFetching = true
			axios.post(targ, JSON.stringify({
				reportType: self.reportType,
				pasteData: self.pastedata,
				selectedLapname: self.selectedLapname
			})).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.tbldata) self.tbldata = ret.tbldata
				else self.tbldata = []
				self.isFetching = false
				self.pageview = 'results'
			}).catch(function () {
				self.isFetching = false
			});
		},
		getMarketNameList() {
			let self = this;
			let targ = "/api/sales-tools/get-market-names-from-geotbl.php";
			this.isFetching = true
			axios.post(targ, JSON.stringify({

			})).then(function (response) {
				let ret = response.data
				if (ret.marketNamesFromTbl) self.marketNamesFromTbl = ret.marketNamesFromTbl
				else self.marketNamesFromTbl = []
				self.isFetching = false
			}).catch(function () {
				self.isFetching = false
			});
		},
	},
	watch: {
		reportType() {
			this.tbldata = []
			this.pastedata = ''
			this.pageview = 'dataEntry'
		},
	},
	mounted() {
		document.title = 'Postcode & Suburb Lookup Tool'
		this.getMarketNameList()
	}
}
</script>

<style scoped>
.tbl thead {
	font-weight: bold;
}
.tbl tr {
	border-bottom: 1px solid #DDD;
}
.tbl td {
	padding: 5px 10px;
	vertical-align: middle;
}
.market-summary-view {
	width: 90%;
	height: 90%;
	position: fixed;
	top: 5%;
	left: 5%;
}
.netshortcut {
	display: inline-block;
	border: 1px solid #DDD;
	background: #EAEAEA;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	margin: 0 10px 10px 0;
	padding: 6px 8px;
	border-radius: 5px;
	font-size: 11px;
}
.netshortcut:hover {
	background-color: #DDD;
}
</style>