<template>
	<div class="kalert-top" v-if="store.kalert.shown === true" :class="obj.type" style="">
		<div>{{obj.text}}</div>
		<div @click="closeAlert" class="icon-click">
			<i class='mdi mdi-close'></i>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: 'AlertTop',
	props: {
		obj: Object,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
		}
	},
	computed: {
		compiledText() {
			return {
				template: '<span>'+this.obj.text+'</span>',
				methods: {

				},
			}
		},
	},
	methods: {
		closeAlert() {
			this.closeKalert()
		},
	},
}
</script>

<style scoped>
.kalert-top {
	width: 80%;
	margin: 0 auto;
	z-index: 998;
	position: fixed;
	left: 10%;
	top: 16px;
	border: 1px solid #bee5eb;
	background-color: #d1ecf1;
	color: #0c5460;
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.kalert-top.error, .kalert-top.danger {
	border: 1px solid #f5c6cb;
	background-color: #f8d7da;
	color: #721c24;
}
.kalert-top.success {
	border: 1px solid #c3e6cb;
	background-color: #d4edda;
	color: #155724;
}
.icon-click {
	cursor: pointer;
	padding: 10px;
}
.icon-click i {
	transition: all 0.3s ease-in-out;
}
.icon-click:hover i {
	color: #BBB;
}
</style>
