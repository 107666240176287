<template>
	<div class="bgpage loginpage" style="padding-top: 30px; min-height: 100vh;">
		<div id="app-container">
			<div id="logo-holder">
				<a href="https://arnconnect.com.au">
					<img src="/public-files/arn-logo.svg" style="height: 80px;" />
				</a>
			</div>

			<div>

				<div class="header-notes-login">
					<p>If you have an arn.com.au email address or have an account in the ARN Active Directory (eg using ARN's webmail, OneDrive etc) please login below with your ARN credentials.
						If you're not already logged in a popup window will appear for you to do so (make sure your browser allows popups for this site).<br/><br/>
					If you do not have an ARN email address and you've been advised that you have specific account for the ARN Connections Dashboard please use the email/password login option.<br/><br/>
						If you have problems, please contact your manager or email Alan Logan at ARN Connections/Content.
					</p>
				</div>

				<div style="padding: 10px 20px 0;">
					<input style="font-size: 16px;" type="checkbox" v-model="hasARNEmail" id="hasem" />
					<label style="font-size: 16px; margin-left: 10px;" for="hasem">I have an ARN email/ActiveDirectory account</label>
				</div>

				<div v-if="hasARNEmail" class="primary-btn" v-on:click="doArnAdLogin" v-bind:class="{'button-is-working':(doingADLogin === true)}">
					<span>Click Here To Log In With Your ARN AD Credentials</span>
					<WorkingButtonInsert v-if="doingADLogin === true"></WorkingButtonInsert>
				</div>

				<div v-if="!hasARNEmail" id="form-wrapper">
					<div>
						<h2>Login with Non-ARN Email Account</h2>
						<div>
							<div class="form-input-group">
								<input type='email' v-model="email" placeholder="Email Address" />
								<div class="form-input-fail" :class="{shown : emailState === false}">{{emailInvalidFeedback}}</div>
							</div>
							<div class="form-input-group">
								<input type='password' v-model="pass" placeholder="Password" @keyup.enter="submitForm" />
								<div class="form-input-fail" :class="{shown : passState === false}">{{passInvalidFeedback}}</div>
							</div>
							<div class="primary-btn" style="margin-top: 5px;" v-on:click="submitForm" v-bind:class="{'button-is-working':(form1working === true)}">
								<span>Submit</span>
								<WorkingButtonInsert v-if="form1working === true"></WorkingButtonInsert>
							</div>
						</div>
					</div>

				</div>

			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import WorkingButtonInsert from "@/components/WorkingButtonInsert";
import * as msal from "@azure/msal-browser"; // ESM

export default {
	name: 'LoginPage',
	components: {
		WorkingButtonInsert
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			headerNotes: "",
			requestedLink: "", //for use after successful login

			hasARNEmail: true,

			email: '',
			pass: '',

			checksTriggered: false,
			form1working: false,

			refererurl: '',
			accessToken: null,
			adEmail: null,
			doingADLogin: false,
		}
	},
	computed: {
		emailState() {
			if(this.checksTriggered === false && this.email.length >= 0 && /.+@.+\..+/.test(this.email) === false) {
				return null;
			}
			else if(this.email.includes("@arn.com.au")) {
				return false;
			}
			else if(this.email.length >= 0 && /.+@.+\..+/.test(this.email)) {
				return true;
			}
			return false;
		},
		emailInvalidFeedback() {
			if(this.email.includes("@arn.com.au")) {
				return 'It looks like you have an ARN email address - please use the Active Directory login option instead';
			}
			return 'Please enter a valid email address.'
		},
		passState() {
			if(this.checksTriggered === false && this.pass.length === 0) {
				return null;
			}
			else {
				if(this.pass.length < 6) return false;
				if(/[a-zA-Z]/g.test(this.pass) === false) return false;
				// if(/\d/.test(this.pass) === false) return false;
				// if(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g.test(this.pass) === false) return false;
			}
			return true;
		},
		passInvalidFeedback() {
			return 'Please enter your password.'
		},
	},
	methods: {
		doArnAdLogin() {
			if(!this.doingADLogin) {
				let msalConfig = {
					auth: {
						clientId: 'a7026297-3651-4f32-abe6-0927029ee871',
						authority: "https://login.microsoftonline.com/91d5bc47-4df3-4ad0-a5ae-3d3249eceaf1",
						redirectUri: this.store.msalcallbackurl,
					}
				};
				this.doingADLogin = true;
				let self = this;
				const msalInstance = new msal.PublicClientApplication(msalConfig);
				msalInstance.acquireTokenPopup({
					scopes: ["User.Read"] // shorthand for https://graph.microsoft.com/User.Read
				}).then((response) => {
					if(response.accessToken) {
						self.accessToken = response.accessToken;
					}
					if(response.account && response.account.username) {
						self.adEmail = response.account.username;
					}
					self.finishArnLogin();
				}).catch((error) => {
					console.log(error); // handle errors
				});
			}
		},
		finishArnLogin() { //check from our server via MS Graph that person is who they say they are
			if(this.doingADLogin === true && this.accessToken && this.adEmail) {
				let form = {
					accessToken: this.accessToken,
					email: this.adEmail
				};
				let self = this;
				axios.post(
					"/post/fin-ad-login.php",
					JSON.stringify({
						data: form,
					})
				).then(function(response) {
					var ret = response.data; //response.data is returned info
					self.doingADLogin = false;
					if(ret.error === 1) {
						self.form1working = false;
						self.showKalert(ret);
					}
					else if(ret.success === 1) {
						if(ret.user) {
							self.setUser(ret.user);
						}
						let nextpage = "/home";
						if(self.refererurl.length > 2) {
							nextpage = self.refererurl;
						}
						window.location = nextpage;
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
		submitForm() {
			if(this.form1working === false) { //only proceed if not already waiting on callback
				this.checksTriggered = true; //check for validations
				var checkitems = [this.emailState, this.passState];
				for(var i=0; i<checkitems.length; i++) {
					if(checkitems[i] === false) return false;
				}
				this.form1working = true; //make button working class after pulse effect is finished
				var form = {};
				form.email = this.email;
				form.pass = this.pass;
				var self = this;
				axios.post(
					"/post/do-login.php",
					JSON.stringify({
						data: form,
						redir: self.requestedLink,
					})
				).then(function(response) {
					var ret = response.data; //response.data is returned info
					if(ret.error === 1) {
						self.form1working = false;
						self.showKalert(ret);
					}
					else if(ret.success === 1) {
						if(ret.user) {
							self.setUser(ret.user);
						}
						let nextpage = "/home";
						if(self.refererurl.length > 2) {
							nextpage = self.refererurl;
						}
						window.location = nextpage;
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {
		document.title = "ARN Connect Dashboard - Login";
		if(this.store.user.email.length > 0) {
			// window.location = "/home";
			this.$router.push("/home");
		}
	},
	created() {
		//handle incoming GET parameters
		let urlparams = this.$route.query;
		if(urlparams.ref !== undefined && urlparams.ref !== "/") {
			this.refererurl = urlparams.ref.replace("https://arnconnect.com.au", "");
		}
		// if(urlparams.activated !== undefined && parseInt(urlparams.activated) === 1) {
		// 	this.headerNotes = "Thanks for activating your account - you can now log in!";
		// }
		// if(urlparams.passreset !== undefined && parseInt(urlparams.passreset) === 1) {
		// 	this.headerNotes = "Your password has been changed - you can now log in!";
		// }
		// if(urlparams.emailchanged !== undefined && parseInt(urlparams.emailchanged) === 1) {
		// 	this.headerNotes = "Your email address has been changed - you can now log in!";
		// }
	},
}
</script>
<style>
.loginpage .form-group label {
	margin: 20px 0 10px;
}
</style>
<style scoped>
.bgpage {
	background-size: cover;
}
#app-container {
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
}
h2 {
	font-size: 18px;
}
#form-wrapper {
	background: #FFF;
	padding: 30px 50px;
	margin-top: 40px;
	margin-bottom: 80px;
	box-shadow: 10px 0px 40px 6px rgba(0,0,0,.08);
}
.header-notes-login {
	margin: 20px 0;
	padding: 20px;
	border: 1px solid #CCC;
	background-color: #DDD;
	color: #444;
}
.loginpage .primary-btn {
	width: 100%;
	margin-top: 30px;
	text-align: center;
}
#form-wrapper input {
	border: 1px solid #BBB;
	width: calc(100% - 20px);
	display: block;
	padding: 10px;
	font-size: 18px;
}
.form-input-fail {
	padding: 5px 0;
	visibility: hidden;
}
.form-input-fail.shown {
	visibility: visible;
}

@media screen and (max-width: 720px) {
	#logo-holder img {
		height: 30px;
		margin-left: 50px;
	}
	#app-container {
		width: 100%;
		max-width: 100%;
		margin: 0;
	}
	#form-wrapper {
		border-radius: 0;
		padding: 50px;
	}
}
@media screen and (max-width: 400px) {
	#logo-holder img {
		margin-left: 30px;
	}
	#form-wrapper {
		padding: 30px;
	}
}
</style>
