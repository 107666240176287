import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import VueApexCharts from 'vue3-apexcharts'

import ErrorPage from "./components/pageviews/ErrorPage"
import HomePage from "./components/pageviews/Home"
import PodcastDashboard from "./components/pageviews/PodcastDashboard"
import DashPodcastCategories from "./components/pageviews/DashPodcastCategories"
import DashPodcastRankerPublishers from "./components/pageviews/DashPodcastRankerPublishers"
import DashPodcastRankerTotal from "./components/pageviews/DashPodcastRankerTotal"
import DashPodcastCatchupShow from "./components/pageviews/DashPodcastCatchupShow"
import DashPodcastShowDetail from "./components/pageviews/DashPodcastShowDetail"
import DashPodcastRankVisual from "./components/pageviews/DashPodcastRankVisual"
import DashPodcastMovers from "@/components/pageviews/DashPodcastMovers";
import PodcastShowHome from "@/components/pageviews/PodcastShowHome.vue";
import PodcastShowListOmny from "@/components/pageviews/PodcastShowListOmny.vue";
import PodcastIndexing from "@/components/pageviews/PodcastIndexing.vue";
import PodcastGenreIndexing from "@/components/pageviews/PodcastGenreIndexing.vue";
import RatingsCharts from "./components/pageviews/RatingsCharts"
import RatingsChartsAud from "@/components/pageviews/RatingsChartsAud.vue";
import RatingsHighlights from "./components/pageviews/RatingsHighlights"
import StreamingMonthlyDashboard from "./components/pageviews/StreamingMonthlyDashboard"
// import RatingsExecLinks from "@/components/pageviews/RatingsExecLinks";
// import StreamingStationTrending from "./components/pageviews/StreamingStationTrending"
// import StreamingMonthlyTrending from "./components/pageviews/StreamingMonthlyTrending"
// import StreamingRealtime from "@/components/pageviews/StreamingRealtime";
import StreamingRealtimeTriton from "@/components/pageviews/StreamingRealtimeTriton";
import StreamingRealtimeTrend from "@/components/pageviews/StreamingRealtimeTrend.vue";
import StreamingRegional from "@/components/pageviews/StreamingRegional";
import StreamingIHRTable from "@/components/pageviews/StreamingIHRTable";
import BrandTrackingStudyList from "./components/pageviews/BrandTrackingStudyList"
import BrandTrackingStudySummary from "./components/pageviews/BrandTrackingStudySummary"
import BrandTrackingMarketDashboard from "@/components/pageviews/BrandTrackingMarketDashboard";
import BrandTrackingIheartDashboard from "@/components/pageviews/BrandTrackingIheartDashboard";
import TradeMarketing from "@/components/pageviews/TradeMarketing";
import TradeMarketingPersonas from "@/components/pageviews/TradeMarketingPersonas";
import TradeMarketingChannels from "@/components/pageviews/TradeMarketingChannels";
import BrandHealthDash from "@/components/pageviews/BrandHealthDash";
import FreqChartExplorer from "@/components/pageviews/FreqChartExplorer";
// import PodcastAdminDash from "@/components/pageviews/PodcastAdminDash";
import MarketsPage from "@/components/arnmarkets/Markets";
import MarketSingle from "@/components/arnmarkets/MarketSingle";
import NationalCume from "@/components/arnmarkets/NationalCume";
import StationMusicLog from "@/components/pageviews/StationMusicLog.vue";
import WebRegionalDashboard from "@/components/pageviews/WebRegionalDashboard";
import WebExplorer from "@/components/pageviews/WebExplorer";
import WebMetroDashboard from "@/components/pageviews/WebMetroDashboard";
import WebNationalDashboard from "@/components/pageviews/WebNationalDashboard.vue";
import WebPageDetails from "@/components/pageviews/WebPageDetails.vue";
// import WebMetroTable from "@/components/pageviews/WebMetroTable";
// import SlideShow from "@/components/SlideShow";
import IHRLand from "@/components/pageviews/IHRLand";
import IHRChartExplorer from "@/components/pageviews/IHRChartExplorer";
import IHRDash from "@/components/pageviews/IHRDash";
import IHRPlaylistDash from "@/components/pageviews/IHRPlaylistDash.vue";
import IHRNationalKPITable from "@/components/pageviews/IHRNationalKPITable";
import IHRRegionalKPITable from "@/components/pageviews/IHRRegionalKPITable";
import CADAMain from "@/components/pageviews/CADAMain";
import MarketingPage from "@/components/pageviews/Marketing";
import ShareOfVoice from "@/components/pageviews/ShareOfVoice";
import PostCampaignReporting from "@/components/pageviews/PostCampaignReporting";
import PostCampaignAdLog from "@/components/pageviews/PostCampaignAdLog";
import PostCampaignRYReports from "@/components/pageviews/PostCampaignRYReports";
import PostCampaignRYCharts from "@/components/pageviews/PostCampaignRYCharts.vue";
import SalesToolsLand from "@/components/pageviews/SalesToolsLand";
import SalesToolGeoLookup from "@/components/pageviews/SalesToolGeoLookup";
import SalesCampaignPlanner from "@/components/pageviews/SalesCampaignPlanner.vue";
import RegionalRevenueReports from "@/components/pageviews/RegionalRevenueReports.vue";
import AircheckClients from "@/components/pageviews/AircheckClients.vue";
import KIXDashboard from "@/components/pageviews/KIXDashboard";
import LoginPage from "@/components/Login";
import LogoutPage from "@/components/Logout";

import '@/includes/style.css'

//routing
const routes = [
	{path: '/error', component: ErrorPage},
	{path: '/home', component: HomePage},
	{path: '/', component: HomePage},
	{path: '/login', component: LoginPage},
	{path: '/logout', component: LogoutPage},
	// {path: '/slideshow', component: SlideShow},
	// {path: '/slideshow/:showname', component: SlideShow},

	{path: '/podcasts/dashboard', component: PodcastDashboard},
	{path: '/podcasts/categories', component: DashPodcastCategories},
	{path: '/podcasts/podcast-ranker-publishers', component: DashPodcastRankerPublishers},
	{path: '/podcasts/podcast-ranker', component: DashPodcastRankerTotal},
	{path: '/podcasts/on-demand-radio', component: DashPodcastCatchupShow},
	{path: '/podcasts/shows', component: PodcastShowListOmny},
	{path: '/podcasts/shows/:showslug', component: PodcastShowHome},
	{path: '/podcasts/show-detail', component: DashPodcastShowDetail},
	{path: '/podcasts/show-visual-ranker', component: DashPodcastRankVisual},
	{path: '/podcasts/top-movers', component: DashPodcastMovers},
	// {path: '/podcasts/admin-dashboard', component: PodcastAdminDash},
	{path: '/podcasts/profile-indexes', component: PodcastIndexing},
	{path: '/podcasts/genre-indexes', component: PodcastGenreIndexing},
	{path: '/gfk-ratings/charts-old', component: RatingsCharts},
	{path: '/gfk-ratings/charts', component: RatingsChartsAud},
	{path: '/gfk-ratings/highlights', component: RatingsHighlights},
	// {path: '/gfk-ratings/exec-summaries', component: RatingsExecLinks},
	{path: '/xtra-ratings', component: FreqChartExplorer},
	// {path: '/streaming/realtime', component: StreamingRealtime},
	{path: '/streaming/realtime', component: StreamingRealtimeTriton},
	{path: '/streaming/realtime-trend', component: StreamingRealtimeTrend},
	// {path: '/streaming/station-trending', component: StreamingStationTrending},
	// {path: '/streaming/streaming-monthly-trends', component: StreamingMonthlyTrending},
	{path: '/streaming/monthly-dashboard', component: StreamingMonthlyDashboard},
	{path: '/streaming/trending', component: StreamingRegional},
	{path: '/streaming/ihr-stations', component: StreamingIHRTable},
	{path: '/brand-tracking/studies', component: BrandTrackingStudyList},
	{path: '/brand-tracking/study-summary', component: BrandTrackingStudySummary},
	{path: '/brand-tracking/market-dashboard', component: BrandTrackingMarketDashboard},
	{path: '/brand-tracking/iheart-dashboard', component: BrandTrackingIheartDashboard},
	{path: '/marketing', component: MarketingPage},
	{path: '/marketing/share-of-voice', component: ShareOfVoice},
	{path: '/trade-marketing', component: TradeMarketing},
	{path: '/trade-marketing/personas', component: TradeMarketingPersonas},
	{path: '/trade-marketing/channels', component: TradeMarketingChannels},
	{path: '/brand-health/:brandname', component: BrandHealthDash},
	{path: '/arn-markets', component: MarketsPage},
	{path: '/arn-markets/stations', component: MarketsPage},
	{path: '/arn-markets/national-cume', component: NationalCume},
	{path: '/arn-markets/station-music-log', component: StationMusicLog},
	{path: '/arn-markets/:marketname', component: MarketSingle},
	{path: '/web/regional-dashboard', component: WebRegionalDashboard},
	{path: '/web/chart-explorer', component: WebExplorer},
	{path: '/web/metro-dashboard', component: WebMetroDashboard},
	{path: '/web/national-dashboard', component: WebNationalDashboard},
	// {path: '/web/metro-monthly-snapshot', component: WebMetroTable},
	{path: '/web/top-pages', component: WebPageDetails},
	{path: '/iheart-landing', component: IHRLand},
	{path: '/iheart/chart-explorer', component: IHRChartExplorer},
	{path: '/iheart/dashboard', component: IHRDash},
	{path: '/iheart/playlists-dashboard', component: IHRPlaylistDash},
	{path: '/iheart/national-kpi', component: IHRNationalKPITable},
	{path: '/iheart/regional-summary', component: IHRRegionalKPITable},
	{path: '/cada-dashboard', component: CADAMain},
	{path: '/post-campaign-reporting', component: PostCampaignReporting},
	{path: '/post-campaign-reporting/ad-log', component: PostCampaignAdLog},
	{path: '/post-campaign-reporting/ry-reports', component: PostCampaignRYReports},
	{path: '/post-campaign-reporting/revenue-charts', component: PostCampaignRYCharts},
	{path: '/sales-tools', component: SalesToolsLand},
	{path: '/sales-tools/geo-lookup', component: SalesToolGeoLookup},
	{path: '/sales-tools/aircheck-clients', component: AircheckClients},
	{path: '/sales-tools/regional-revenue-reports', component: RegionalRevenueReports},
	{path: '/sales-tools/campaign-planner', component: SalesCampaignPlanner},
	{path: '/kix-country', component: KIXDashboard},
]

window.routesOutsideApp = [
	'/register', '/browser-not-supported', '/password-reset', '/password-reset-update',
	'/registration-success', '/register-verify-email', '/login', '/slideshow'
];

const router = createRouter({
	hashbang: false,
	routes: routes,
	history: createWebHistory(),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		return {x: 0, y: 0};
	},
});

const app = createApp(App)
app.use(router);
app.use(VueApexCharts);
app.mount('#app')