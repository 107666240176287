<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">
			<div style="display: flex; justify-content: space-between; align-items: center;">
				<div>
					<div class="link-btn" style="margin-right: 5px;" :class="{'active' : activeDatasetGroupId === 'SYD'}" @click="activeDatasetGroupId = 'SYD'">
						<p>Sydney</p>
					</div>
					<div class="link-btn" style="margin-right: 5px;" :class="{'active' : activeDatasetGroupId === 'MEL'}" @click="activeDatasetGroupId = 'MEL'">
						<p>Melbourne</p>
					</div>
					<div class="link-btn" style="margin-right: 5px;" :class="{'active' : activeDatasetGroupId === 'BNE'}" @click="activeDatasetGroupId = 'BNE'">
						<p>Brisbane</p>
					</div>
					<div class="link-btn" style="margin-right: 5px;" :class="{'active' : activeDatasetGroupId === 'ADL'}" @click="activeDatasetGroupId = 'ADL'">
						<p>Adelaide</p>
					</div>
					<div class="link-btn" style="margin-right: 5px;" :class="{'active' : activeDatasetGroupId === 'PER'}" @click="activeDatasetGroupId = 'PER'">
						<p>Perth</p>
					</div>
				</div>

<!--				<div>-->
<!--					<a v-if="activeDatasetGroupId > 0 && getChartsBtnWorking === false" style="padding-left: 10px;" @click="getMarketDash(activeDatasetGroupId)">Fetch</a>-->
<!--				</div>-->
			</div>

			<div v-if="rawdata.SYD">
				<p style="padding: 10px 0 0; font-weight: bold;">P1 = Listens to station most (favourite station)</p>
				<div class="chart-rows">
					<ChartHolderDash charttitle="Reported Listening % (P18-59)" :source="chartsSource.reportedlistening" :height="500" :addclasses="'half'">
						<template v-slot:default><ApexBarTrend :chartdata="apexdata.reportedlistening" :fetching="getChartsBtnWorking"></ApexBarTrend></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Which of the following radio stations is your #1 favourite choice? % (P18-59)" :source="chartsSource.number1choice" :height="500" :addclasses="'half'">
						<template v-slot:default><ApexBarTrend :chartdata="apexdata.number1choice" :fetching="getChartsBtnWorking"></ApexBarTrend></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Unaided Awareness % (P18-59)" :source="chartsSource.station_unaided_awareness" :height="500" :addclasses="'half'">
						<template v-slot:default><ApexBarTrend :chartdata="apexdata.station_unaided_awareness" :fetching="getChartsBtnWorking"></ApexBarTrend></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Aided Awareness % (P18-59)" :source="chartsSource.station_aided_awareness" :height="500" :addclasses="'half'">
						<template v-slot:default><ApexBarTrend :chartdata="apexdata.station_aided_awareness" :fetching="getChartsBtnWorking"></ApexBarTrend></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Talent Unaided Awareness % (P18-59)" :source="chartsSource.talent_unaided_awareness" :height="500" :addclasses="'half'">
						<template v-slot:default><ApexBarTrend :chartdata="apexdata.talent_unaided_awareness" :fetching="getChartsBtnWorking"></ApexBarTrend></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Talent Aided Awareness % (P18-59)" :source="chartsSource.talent_aided_awareness" :height="500" :addclasses="'half'">
						<template v-slot:default><ApexBarTrend :chartdata="apexdata.talent_aided_awareness" :fetching="getChartsBtnWorking"></ApexBarTrend></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="NPS Likely Recommend (P18-59)" :source="chartsSource.likely_recommend" :height="500" :addclasses="'half'">
						<template v-slot:default><ApexBarTrend :chartdata="apexdata.likely_recommend" :fetching="getChartsBtnWorking"></ApexBarTrend></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Talent Unaided Awareness - Others (P18-59)" :source="chartsSource.talent_unaided_awareness_others" :height="500">
						<template v-slot:default><ApexBarTrend :chartdata="apexdata.talent_unaided_awareness_others" :fetching="getChartsBtnWorking"></ApexBarTrend></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Show / Station Matching (P18-59)" :source="chartsSource.show_station_mapping" :height="500">
						<template v-slot:default><ApexBarTrend :chartdata="apexdata.show_station_mapping" :fetching="getChartsBtnWorking"></ApexBarTrend></template>
					</ChartHolderDash>

					<div style="display: block; width: 100%; flex-shrink: 0; margin-bottom: 20px;">
						<label><input type="checkbox" v-model="forceRadarsTo100" /><span>Force Radar Charts to 100</span></label>
					</div>

					<ChartHolderDash charttitle="Station Attributes - P18-59 (current wave)" :source="chartsSource.talent_unaided_awareness_others" :addclasses="'half'" :height="700">
						<template v-slot:default><ApexRadar :chartdata="apexdata.station_attributes_1" :fetching="getChartsBtnWorking" :force100="forceRadarsTo100"></ApexRadar></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Attributes - P18-59 (previous wave)" :source="chartsSource.station_attributes_2" :addclasses="'half'" :height="700">
						<template v-slot:default><ApexRadar :chartdata="apexdata.station_attributes_2" :fetching="getChartsBtnWorking" :force100="forceRadarsTo100"></ApexRadar></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Attributes - By station P1 (current wave)" :source="chartsSource.station_attributes_p1_1" :addclasses="'half'" :height="700">
						<template v-slot:default><ApexRadar :chartdata="apexdata.station_attributes_p1_1" :fetching="getChartsBtnWorking" :force100="forceRadarsTo100"></ApexRadar></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Attributes - By station P1 (previous wave)" :source="chartsSource.station_attributes_p1_2" :addclasses="'half'" :height="700">
						<template v-slot:default><ApexRadar :chartdata="apexdata.station_attributes_p1_2" :fetching="getChartsBtnWorking" :force100="forceRadarsTo100"></ApexRadar></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Attributes (Music) - P18-59 (current wave)" :source="chartsSource.station_attributes_music_1" :addclasses="'half'" :height="700">
						<template v-slot:default><ApexRadar :chartdata="apexdata.station_attributes_music_1" :fetching="getChartsBtnWorking" :force100="forceRadarsTo100"></ApexRadar></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Attributes (Music) - P18-59 (previous wave)" :source="chartsSource.station_attributes_music_2" :addclasses="'half'" :height="700">
						<template v-slot:default><ApexRadar :chartdata="apexdata.station_attributes_music_2" :fetching="getChartsBtnWorking" :force100="forceRadarsTo100"></ApexRadar></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Attributes (Music) - By station P1 (current wave)" :source="chartsSource.station_attributes_music_p1_1" :addclasses="'half'" :height="700">
						<template v-slot:default><ApexRadar :chartdata="apexdata.station_attributes_music_p1_1" :fetching="getChartsBtnWorking" :force100="forceRadarsTo100"></ApexRadar></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Attributes (Music) - By station P1 (previous wave)" :source="chartsSource.station_attributes_music_p1_2" :addclasses="'half'" :height="700">
						<template v-slot:default><ApexRadar :chartdata="apexdata.station_attributes_music_p1_2" :fetching="getChartsBtnWorking" :force100="forceRadarsTo100"></ApexRadar></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Attributes (Shows) - P18-59 (current wave)" :source="chartsSource.station_attributes_shows_1" :addclasses="'half'" :height="700">
						<template v-slot:default><ApexRadar :chartdata="apexdata.station_attributes_shows_1" :fetching="getChartsBtnWorking" :force100="forceRadarsTo100"></ApexRadar></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Attributes (Shows) - P18-59 (previous wave)" :source="chartsSource.station_attributes_shows_2" :addclasses="'half'" :height="700">
						<template v-slot:default><ApexRadar :chartdata="apexdata.station_attributes_shows_2" :fetching="getChartsBtnWorking" :force100="forceRadarsTo100"></ApexRadar></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Attributes (Shows) - By station P1 (current wave)" :source="chartsSource.station_attributes_shows_p1_1" :addclasses="'half'" :height="700">
						<template v-slot:default><ApexRadar :chartdata="apexdata.station_attributes_shows_p1_1" :fetching="getChartsBtnWorking" :force100="forceRadarsTo100"></ApexRadar></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Attributes (Shows) - By station P1 (previous wave)" :source="chartsSource.station_attributes_shows_p1_2" :addclasses="'half'" :height="700">
						<template v-slot:default><ApexRadar :chartdata="apexdata.station_attributes_shows_p1_2" :fetching="getChartsBtnWorking" :force100="forceRadarsTo100"></ApexRadar></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Attributes (Overall Image) - P18-59 (current wave)" :source="chartsSource.station_attributes_overallimage_1" :addclasses="'half'" :height="700">
						<template v-slot:default><ApexRadar :chartdata="apexdata.station_attributes_overallimage_1" :fetching="getChartsBtnWorking" :force100="forceRadarsTo100"></ApexRadar></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Attributes (Overall Image) - P18-59 (previous wave)" :source="chartsSource.station_attributes_overallimage_2" :addclasses="'half'" :height="700">
						<template v-slot:default><ApexRadar :chartdata="apexdata.station_attributes_overallimage_2" :fetching="getChartsBtnWorking" :force100="forceRadarsTo100"></ApexRadar></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Attributes (Overall Image) - By station P1 (current wave)" :source="chartsSource.station_attributes_overallimage_p1_1" :addclasses="'half'" :height="700">
						<template v-slot:default><ApexRadar :chartdata="apexdata.station_attributes_overallimage_p1_1" :fetching="getChartsBtnWorking" :force100="forceRadarsTo100"></ApexRadar></template>
					</ChartHolderDash>

					<ChartHolderDash charttitle="Station Attributes (Overall Image) - By station P1 (previous wave)" :source="chartsSource.station_attributes_overallimage_p1_2" :addclasses="'half'" :height="700">
						<template v-slot:default><ApexRadar :chartdata="apexdata.station_attributes_overallimage_p1_2" :fetching="getChartsBtnWorking" :force100="forceRadarsTo100"></ApexRadar></template>
					</ChartHolderDash>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import PermissionChecker from "@/components/PermissionChecker";
import ApexBarTrend from "@/components/charts/ApexBarTrend";
import ApexRadar from "@/components/charts/ApexRadar";

export default {
	name: 'BrandTrackingMarketDashboard',
	components: {
		ApexRadar,
		ApexBarTrend,
		ChartHolderDash,
		PermissionChecker,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','connections','content-metro'],
			activeDatasetGroupId: 'SYD',

			chartsData: {},
			chartsSource: [],
			getChartsBtnWorking: false,
			// apexdata: {},

			rawdata: {},
			forceRadarsTo100: true,
		}
	},
	computed: {
		apexdata() {
			return this.rawdata[this.activeDatasetGroupId]
		}
	},
	methods: {
		getMarketDash() {
			this.getChartsBtnWorking = true
			let self = this
			let targ = "/api/brand-tracking-get-json-for-dash.php"
			axios.get(targ).then(function (response) {
				self.rawdata = response.data
				setTimeout(function() {
					self.getChartsBtnWorking = false;
				}.bind(self), 300);
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {

	},
	created() {
		document.title = "ARN Brand Tracking Dashboards";
		this.getMarketDash();
	},
}
</script>

<style scoped>

</style>
