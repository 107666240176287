<template>
	<div>
		<PodcastCRAMenu></PodcastCRAMenu>

		<div class="chart-rows">
			<PodListenerWarn></PodListenerWarn>
			<ChartHolderDash :charttitle="'Podcast Ranker - '+sourcename"	:source="chartsSource.podrnk" :height="6200">

				<template v-slot:headerRight>
					<div style="width: 300px; display: flex; align-items: center;">
						<select class="select-styled" v-model="sourcename" style="width: 250px;">
							<option v-for="opt in sourceoptions" :key="opt" :value="opt">{{opt}}</option>
						</select>
					</div>
					<div style="width: 300px; display: flex; align-items: center;">
						<select class="select-styled" v-model="selectedMonth" style="width: 250px;">
							<option v-for="opt in chartsOptions.months" :key="opt.val" :value="opt.val">{{opt.nice}}</option>
						</select>
					</div>
				</template>

				<template v-slot:default>
					<div class="b-table-holder">
						<ALTable tblclasses="noborderv" :excel="true" excelfilename="Podcast Ranker" :searchbox="true"
							:cols="[
								{title: 'Rank', sortable: true, fldid: 'rank'},
								{title: 'Change', sortable: false, fldid: 'rankchng', numtype: 'numbubble'},
								{title: 'Title', sortable: true, fldid: 'title'},
								{title: 'Publisher', sortable: true, fldid: 'publisher'},
								{title: 'Sales', sortable: true, fldid: 'sales'},
								{title: 'Listeners', sortable: true, fldid: 'listeners', numtype: 'number'},
								{title: 'Downloads', sortable: true, fldid: 'downloads', numtype: 'number'},
								{title: '# Episodes', sortable: true, fldid: 'numeps', numtype: 'number'},
								// {title: 'Avg dl / Ep', sortable: true, fldid: 'avgdlperep', numtype: 'number'},
							]"
							:rowdata="chartsData.podrnk"
						></ALTable>
					</div>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</template>

			</ChartHolderDash>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import PodListenerWarn from "@/components/PodListenerWarn";
import ALTable from "@/components/ALTable";
import PodcastCRAMenu from "@/components/PodcastCRAMenu.vue";

export default {
	name: 'DashPodcastRankerTotal',
	components: {
		PodcastCRAMenu,
		ALTable,
		PodListenerWarn,
		ChartLoaderInsert,
		ChartHolderDash,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			sourcename: 'Total',
			sourceoptions: ['Total','All Australian'],
			chartId: 0,
			chartsData: {},
			chartsSource: [],
			chartsOptions: [],
			getChartsBtnWorking: false,

			selectedMonth: '',
			updating: false,
		}
	},
	computed: {
		// headers() {
		// 	return [
		// 		{label: 'Rank', sortable: true, key: 'rank'},
		// 		{label: 'Change', sortable: false, key: 'rankchng'},
		// 		{label: 'Title', sortable: true, key: 'title'},
		// 		{label: 'Publisher', sortable: true, key: 'publisher'},
		// 		{label: 'Sales', sortable: true, key: 'sales'},
		// 		{label: 'Listeners', sortable: true, key: 'listeners', thClass: 'text-right'},
		// 		{label: 'Downloads', sortable: true, key: 'downloads', thClass: 'text-right'},
		// 		{label: '# Episodes', sortable: true, key: 'numeps', thClass: 'text-right'},
		// 		{label: 'Avg dl / Ep', sortable: true, key: 'avgdlperep', thClass: 'text-right'},
		// 	];
		// },
	},
	methods: {
		jumpToPodcastShowDetail(showid) {
			this.$router.push("/podcasts/show-detail?showid="+showid);
		},
		getCharts() {
			this.getChartsBtnWorking = true;
			this.updating = true;
			var self = this;
			var targ = "/api/podcast-ranker-table.php?type="+this.sourcename+"&mth="+this.selectedMonth;
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					if (ret.chartsData != null) {
						self.chartsData = ret.chartsData;
					}
					if (ret.chartsSource != null) {
						self.chartsSource = ret.chartsSource;
					}
					if (ret.chartsOptions != null) {
						self.chartsOptions = ret.chartsOptions;
					}
					if(ret.selectedMonth != null) {
						self.selectedMonth = ret.selectedMonth;
					}
					if (ret.h != null) {
						for (var key in ret.h) { //this updates any that exist, adds new if needed, but does not delete existing
							self.slotprops[key] = ret.h[key];
						}
					}
					//custom callback functions here if required (using pageaction as filter)
				}
				self.getChartsBtnWorking = false;
				setTimeout(function() {
					self.updating = false;
				}.bind(self), 1000);
			}).catch(function (error) {
				console.log(error);
			});
		},
		updateTitles(path) {
			this.sourcename = "Total";
			if(path.indexOf('australia') > 0) {
				this.sourcename = "All Australian";
			}
			document.title = "Podcast Ranker "+this.sourcename;
		},
	},
	watch: {
		sourcename() {
			if(this.updating === false) {
				this.getCharts();
			}
		},
		selectedMonth() {
			if(this.updating === false) {
				this.getCharts();
			}
		},
		$route (to){
			this.updateTitles(to.path);
		}
	},
	props: {

	},
	mounted() {
		this.updateTitles(this.$router.currentRoute.value.path);
		this.getCharts();
	},
	created() {

	},
}
</script>

<style scoped>
.bubble {
	padding: 5px 10px;
	border-radius: 15px;
	background-color: #553AA9;
	color: #FFF;
}
.bubble.down {
	background-color: #BB0000;
}
</style>
