<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0" width="99%" height="100%" type="radar"
			:options="chartoptions" :series="chartdata"></apexchart>
		<ChartLoaderInsert v-if="fetching"></ChartLoaderInsert>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
export default {
	name: "ApexRadar",
	components: {
		ChartLoaderInsert
	},
	props: {
		fetching: Boolean,
		categories: Array,
		chartdata: Array,
		filename: String,
		force100: Boolean,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			chartoptions: {
				chart: {
					id: this.filename,
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					},
					type: 'radar',
					dropShadow: {
						enabled: false,
					}
				},
				plotOptions: {
					radar: {
						fill: {
							colors: 'transparent',
						}
					},
				},
				colors: [],
				fill: {
					colors: ['transparent'],
					opacity: 0,
				},
				xaxis: {
					labels: {
						show: true,
						hideOverlappingLabels: false,
						trim: true,
						formatter: (value) => {
							var len = value.length;
							return len > 15 ? value.substring(0,20) + "..." : value;
						}
					},
				},
				yaxis: {
					max: 100,
					min: 0,
					forceNiceScale: false,
				},
				dataLabels: {
					enabled: false
				},
			},
		}
	},
	computed: {},
	methods: {
		updateYAxis() { //need to do this manually as data is nested
			let oldaxis = this.chartoptions.yaxis
			if(this.force100 && this.force100 === true) {
				oldaxis = {max: 100, min: 0, forceNiceScale: false}
				this.chartoptions = {
					...this.chartoptions, ...{
						yaxis: oldaxis
					}
				}
			}
			else {
				oldaxis = {min: 0, forceNiceScale: true}
				this.chartoptions = {
					...this.chartoptions, ...{
						yaxis: oldaxis
					}
				}
			}
		},
	},
	watch: {
		force100() {
			this.updateYAxis()
		},
	},
	mounted() {
		this.chartoptions.colors = this.store.chartColourGroup
	}
}
</script>

<style scoped>

</style>