<template>
	<div style="max-width: 1000px; margin: 0 auto;">
		<div style="display: flex; justify-content: flex-start; gap: 15px; margin-bottom: 20px;">
			<div>
				<select class="select-styled" v-model="chosenStation">
					<option v-for="opt in mystations" :key="opt.stid" :value="opt">{{opt.name}}</option>
				</select>
			</div>
			<div><span class="primary-btn" @click="getData">Fetch</span></div>
		</div>

		<div class="highlights-holder-parent">
			<p>{{retsurvname}}</p>
			<div id="highlights-holder" v-html="highlightshtml"></div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";

export default {
	name: 'RatingsHighlights',
	components: {

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			getChartsBtnWorking: false,
			highlightshtml: '',

			// stations: [	{id: 482, name: 'SMBAP Network (excl. DAB)'},
			// 	{id: 241, name: 'KIIS1065 Sydney'},
			// 	{id: 186, name: 'WSFM Sydney'},
			// 	{id: 245, name: 'KIIS1011 Melbourne'},
			// 	{id: 132, name: 'Gold1043 Melbourne'},
			// 	{id: 87, name: 'KIIS973 Brisbane'},
			// 	{id: 85, name: '4KQ Brisbane'},
			// 	{id: 45, name: 'Mix102.3 Adelaide'},
			// 	{id: 227, name: 'Cruise1323 Adelaide'},
			// 	{id: 5, name: '96FM Perth'},
			// 	// {id: 536, name: '2UE Sydney'},
			// 	// {id: 130, name: '3MP Melbourne'},
			// 	// {id: 129, name: 'Magic1278 Melbourne'},
			// 	// {id: 1467, name: '4BH Brisbane'},
			// 	{id: 1512, name: 'Hot Tomato Gold Coast'},
			// ],
			chosenStation: null,
			
			mystations: [
				{mktid: 900, stid: 'c_2013', name: 'ARN SMBAP excl DAB'},
				{mktid: 110, stid: 'g_201009', name: 'KIIS1065'},
				{mktid: 110, stid: 'g_2207', name: 'WSFM'},
				{mktid: 111, stid: 'g_3207', name: 'Gold104.3'},
				{mktid: 111, stid: 'g_301056', name: 'KIIS1011'},
				{mktid: 112, stid: 'g_7203', name: 'KIIS973'},
				{mktid: 113, stid: 'g_8203', name: 'Mix102.3'},
				{mktid: 113, stid: 'g_8214', name: 'Cruise1323'},
				{mktid: 114, stid: 'g_8905', name: '96FM'},
				{mktid: 14, stid: 'g_4817', name: 'Hot Tomato'},
				{mktid: 72, stid: 'g_4001', name: '2CA'},
				{mktid: 72, stid: 'g_4002', name: '2CC'},
				{mktid: 19, stid: 'g_4301', name: '2HD'},
				{mktid: 19, stid: 'g_4304', name: 'New FM'},
			],
			retsurvname: '',
		}
	},
	computed: {

	},
	methods: {
		getData() {
			this.getChartsBtnWorking = true;
			// var str = "station="+this.chosenStation+"&run=1";
			// var self = this;
			// var targ = "https://gfk.arnconnect.com.au/post/get-highlights-by-station.php?"+str;
			// axios.get(targ).then(function (response) {
			// 	var ret = response.data; //response.data is returned info
			// 	if (ret.error === 1) { console.log(ret); }
			// 	else {
			// 		self.highlightshtml = ret;
			// 	}
			// 	self.getChartsBtnWorking = false;
			// }).catch(function (error) {
			// 	console.log(error);
			// });

			let self = this
			let postjson = JSON.stringify({
				apicreds: this.store.audologyAPICreds,
				stationId: this.chosenStation.stid,
				marketId: this.chosenStation.mktid,
			})
			let api = this.store.audologyAPIUrl
			axios.post(api+"get-station-highlights", postjson).then(function (response) {
				self.highlightshtml = response.data.outHTML
				self.retsurvname = response.data.latestSurvIdNice

			}).catch(function (error) {
				console.log(error)
			});
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {
		this.chosenStation = this.mystations[0]
		this.getData()
	},
	created() {
		document.title = "ARN Ratings Highlights"
	},
}
</script>

<style scoped>

</style>
