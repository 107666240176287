<template>
	<div>

		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms) && rawdata.IHR">
			<div class="chart-rows">
				<!-- Unaided Awareness -->
				<ChartHolderDash charttitle="Unaided Awareness - National (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.unaided_streaming99" :fetching="getChartsBtnWorking"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="Unaided Awareness - Sydney (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.unaided_streaming1"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="Unaided Awareness - Melbourne (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.unaided_streaming2"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="Unaided Awareness - Brisbane (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.unaided_streaming3"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="Unaided Awareness - Adelaide (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.unaided_streaming4"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="Unaided Awareness - Perth (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.unaided_streaming5"></ApexBarTrend></template>
				</ChartHolderDash>

				<!-- Aided Awareness -->
				<ChartHolderDash charttitle="Aided Awareness - National (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.aided_streaming99"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="Aided Awareness - Sydney (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.aided_streaming1"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="Aided Awareness - Melbourne (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.aided_streaming2" :categories="chartsCategories.aided_streaming2"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="Aided Awareness - Brisbane (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.aided_streaming3" :categories="chartsCategories.aided_streaming3"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="Aided Awareness - Adelaide (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.aided_streaming4" :categories="chartsCategories.aided_streaming4"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="Aided Awareness - Perth (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.aided_streaming5" :categories="chartsCategories.aided_streaming5"></ApexBarTrend></template>
				</ChartHolderDash>

				<!-- Intention to use IHR -->
				<ChartHolderDash charttitle="Intention To Use IHR (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.ihr_intent"></ApexBarTrend></template>
				</ChartHolderDash>

				<!-- IHR Feature Awareness -->
				<ChartHolderDash charttitle="IHR Feature Awareness - National (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.ihr_feature_awareness99"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="IHR Feature Awareness - Sydney (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.ihr_feature_awareness1"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="IHR Feature Awareness - Melbourne (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.ihr_feature_awareness2"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="IHR Feature Awareness - Brisbane (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.ihr_feature_awareness3"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="IHR Feature Awareness - Adelaide (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.ihr_feature_awareness4"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="IHR Feature Awareness - Perth (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.ihr_feature_awareness5"></ApexBarTrend></template>
				</ChartHolderDash>

				<!-- IHR Function Use -->
				<ChartHolderDash charttitle="IHR Function Usage Intent - National (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.ihr_feature_use99"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="IHR Function Usage Intent - Sydney (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.ihr_feature_use1"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="IHR Function Usage Intent - Melbourne (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.ihr_feature_use2"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="IHR Function Usage Intent - Brisbane (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.ihr_feature_use3"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="IHR Function Usage Intent - Adelaide (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.ihr_feature_use4"></ApexBarTrend></template>
				</ChartHolderDash>

				<ChartHolderDash charttitle="IHR Function Usage Intent - Perth (%)" :source="newsource" :height="600">
					<template v-slot:default><ApexBarTrend :chartdata="chartsData.ihr_feature_use5"></ApexBarTrend></template>
				</ChartHolderDash>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ApexBarTrend from "@/components/charts/ApexBarTrend";
import PermissionChecker from "@/components/PermissionChecker";

export default {
	name: 'BrandTrackingIheartDashboard',
	components: {
		ApexBarTrend,
		ChartHolderDash,
		PermissionChecker,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','connections','content-metro'],
			// chartsData: {
			//
			// },
			chartsCategories: {

			},
			chartsSource: {

			},
			newsource: 'ARN Brand Tracking',
			getChartsBtnWorking: false,

			rawdata: {},

		}
	},
	computed: {
		chartsData() {
			return this.rawdata['IHR']
		}
	},
	methods: {
		getMarketDash() {
			this.getChartsBtnWorking = true
			let self = this
			let targ = "/api/brand-tracking-get-json-for-dash.php"
			axios.get(targ).then(function (response) {
				self.rawdata = response.data
				setTimeout(function() {
					self.getChartsBtnWorking = false;
				}.bind(self), 300);
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {
		this.getMarketDash();
	},
	created() {
		document.title = "ARN Brand Tracking Dashboard";
	},
}
</script>

<style scoped>

</style>
