<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div>
			<div style="padding-top: 10px; display: flex; align-items: flex-end;">
				<div style="margin-right: 10px; z-index: 1">
					<p class="above-select-label">Report Type</p>
					<select class="select-styled" v-model="reportType">
						<option value="daypartSummary">Daypart Summary</option>
						<option value="spotClass">Spot Class Summary</option>
						<option value="inventRev">Inventory vs Revenue</option>
					</select>
				</div>
				<div v-if="daypartChooserShown" style="margin-right: 10px; z-index: 1">
					<p class="above-select-label">Dayparts</p>
					<select class="select-styled" v-model="daypartsChosen">
						<option value="BMAD">BMAD</option>
						<option value="All">All</option>
						<option value="Breakfast">Breakfast</option>
						<option value="Morning">Morning</option>
						<option value="Afternoon">Afternoon</option>
						<option value="Drive">Drive</option>
					</select>
				</div>
				<div v-if="daysofweekChooserShown" style="margin-right: 10px; z-index: 1">
					<p class="above-select-label">Days Of Week</p>
					<select class="select-styled" v-model="daysOfWeek">
						<option value="Mon-Fri">Mon-Fri</option>
						<option value="Mon-Sun">Mon-Sun</option>
					</select>
				</div>
				<div style="margin-right: 10px; z-index: 1">
					<p class="above-select-label">Station</p>
					<AutocompleterStringOnly v-if="allStations" :searchlist="allStations" :incomingval="stationLookupText"
							idfldname="name" textfldname="name" v-on:update="chosenStation = $event"
					></AutocompleterStringOnly>
				</div>
				<div style="margin-right: 20px; width: 200px;">
					<p class="above-select-label">Start Date</p>
					<date-picker class="dater-tr" style="width: 200px;" v-model:value="startdate" valueType="format" format="YYYY-MM-DD"></date-picker>
				</div>
				<div style="margin-right: 20px; width: 200px;">
					<p class="above-select-label">End Date</p>
					<date-picker class="dater-tr" style="width: 200px;" v-model:value="enddate" valueType="format" format="YYYY-MM-DD"></date-picker>
				</div>
				<div><span class="primary-btn" @click="fetchData(false)">View</span></div>
			</div>

			<div style="margin-top: 20px; ">
				<div v-if="!isFetching" style="padding: 20px 0;">
					<div v-if="tbldata.length === 0 && cols.length > 0" style="padding: 0 10px;"><p>Nothing to show</p></div>
					<div v-else>
						<ALTable :cols="cols" :rowdata="tbldata" :pagination="50"></ALTable>
					</div>
				</div>
				<div v-else style="padding: 20px 0; height: 300px; width: 500px;">
					<ChartLoaderInsert style="z-index: 9;"></ChartLoaderInsert>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import AutocompleterStringOnly from "@/components/AutocompleterStringOnly";
import ALTable from "@/components/ALTable";
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';
import PermissionChecker from "@/components/PermissionChecker";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";

export default {
	name: "PostCampaignRYReports",
	components: {
		ChartLoaderInsert,
		PermissionChecker,
		ALTable,
		AutocompleterStringOnly,
		DatePicker,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			viewPerms: ['elt','pcr-randy'],
			showStationSelectOverlay: false,

			startdate: '2022-06-29',
			enddate: '2022-06-29',
			chosenStation: null,
			reportType: 'daypartSummary',
			daypartsChosen: 'BMAD',
			daysOfWeek: 'Mon-Fri',
			stationLookupText: '',

			stations: [],
			tbldata: [],

			isFetching: false,

		}
	},
	computed: {
		cols() {
			if(this.reportType === 'daypartSummary') {
				return [
					{title: 'Daypart', sortable: true, fldid: 'aired_daypart'},
					{title: 'N Spots', sortable: true, fldid: 'nspots', numtype: 'number'},
					{title: '15s', sortable: true, fldid: 'n15s', numtype: 'number'},
					{title: '15s %', sortable: true, fldid: 'pct15s', numtype: 'pct'},
					{title: '30s', sortable: true, fldid: 'n30s', numtype: 'number'},
					{title: '30s %', sortable: true, fldid: 'pct30s', numtype: 'pct'},
					{title: 'FOC spots', sortable: true, fldid: 'free', numtype: 'number'},
					{title: 'FOC %', sortable: true, fldid: 'pctfree', numtype: 'pct'},
					{title: 'Revenue', sortable: true, fldid: 'revenue', numtype: 'dollars'},
					{title: 'AUR / 30s', sortable: true, fldid: 'aur30s', numtype: 'dollars'},
				]
			}
			else if(this.reportType === 'spotClass') {
				return [
					{title: 'Daypart', sortable: true, fldid: 'aired_daypart'},
					{title: 'Spot Classification', sortable: true, fldid: 'spot_class'},
					{title: 'N Spots', sortable: true, fldid: 'nspots', numtype: 'number'},
					{title: 'FOC spots', sortable: true, fldid: 'free', numtype: 'number'},
					{title: 'FOC %', sortable: true, fldid: 'pctfree', numtype: 'pct'},
					{title: 'Revenue', sortable: true, fldid: 'revenue', numtype: 'dollars'},
					{title: 'AUR / 30s', sortable: true, fldid: 'aur30s', numtype: 'dollars'},
				]
			}
			else if(this.reportType === 'inventRev') {
				return [
					{title: 'Rep', sortable: true, fldid: 'salesperson_name'},
					{title: 'Revenue', sortable: true, fldid: 'revenue', numtype: 'dollars'},
					{title: 'AUR / 30s', sortable: true, fldid: 'aur30s', numtype: 'dollars'},
					{title: 'N Spots', sortable: true, fldid: 'nspots', numtype: 'number'},
					{title: 'Pct Airtime', sortable: true, fldid: 'pcttime',  align: 'right'},
					{title: 'Pct Revenue', sortable: true, fldid: 'pctrev', align: 'right'},
				]
			}
			return []
		},
		daypartChooserShown() {
			if(this.reportType === 'daypartSummary') return false
			if(this.reportType === 'spotClass') return false
			return true
		},
		daysofweekChooserShown() {
			// if(this.reportType === 'daypartSummary') return false
			return true
		},
		stationsMetro() {
			return this.stations.filter(item => item.aqdb === 'arnmetro')
		},
		stationsReg() {
			return this.stations.filter(item => item.aqdb === 'arnreg')
		},
		allStations() {
			let arr = this.stationsMetro
			return arr.concat(this.stationsReg)
		},
	},
	methods: {
		getMetaLists() {
			let self = this;
			let targ = "/api/post-campaign/get-station-list.php";
			this.isFetching = true
			axios.get(targ).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.error === 1) console.log(ret)
				else if(ret.stations) {
					self.stations = ret.stations
				}
				self.isFetching = false
			}).catch(function (error) {
				self.isFetching = false
				console.log(error);
			});
		},
		fetchData() {
			let self = this;
			let targ = "/api/post-campaign/fetch-randy-report.php";
			this.isFetching = true
			axios.post(targ, JSON.stringify({
				startdate: self.startdate,
				enddate: self.enddate,
				station: self.chosenStation.name,
				reportType: self.reportType,
				dayparts: self.daypartsChosen,
				daysofweek: self.daysOfWeek,
			})).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.tbldata) self.tbldata = ret.tbldata
				else self.tbldata = []
				self.isFetching = false
			}).catch(function () {
				self.isFetching = false
			});
		},
	},
	watch: {
		reportType() {
			this.tbldata = []
		},
	},
	mounted() {
		document.title = 'R&Y Reports'
		this.getMetaLists()
		let d = new Date()
		this.enddate = d.getFullYear()+'-'+this.ldzero(d.getMonth()+1)+"-"+this.ldzero(d.getDate())
	}
}
</script>

<style scoped>

</style>