<template>
	<div>
		<div>
			<div style="padding-top: 10px; display: flex; align-items: flex-end;">
				<div>
					<select v-model="reportType" class="select-styled">
						<option value="summary">Sales Team R&Y Summary</option>
						<option value="clientreport">Market R&Y By Client Report</option>
						<option value="salesrep">Sales Group / Rep R&Y Report</option>
						<option value="clientbymonth">Client Revenue By Month</option>
					</select>
				</div>
				<div style="margin: 0 10px;">
					<select v-model="chosenMarket" class="select-styled">
						<option v-for="m in markets" :key="m" :value="m">{{m}}</option>
					</select>
				</div>

				<FilterItem label="Months">
					<template v-slot:items>
						<div class="filter-item" v-for="item in monthOptions" :key="item.id">
							<label><input v-model="selections.months" type="checkbox" :value="item.id" />{{item.name}}</label>
						</div>
					</template>
				</FilterItem>
				<FilterItem label="Dayparts">
					<template v-slot:items>
						<div class="filter-item" v-for="item in daypartOptions" :key="item">
							<label><input v-model="selections.dayparts" type="checkbox" :value="item" />{{item}}</label>
						</div>
					</template>
				</FilterItem>
				<FilterItem label="Stations">
					<template v-slot:items>
						<div class="filter-item" v-for="item in stationsForMarket" :key="item.station">
							<label><input v-model="selections.stations" type="checkbox" :value="item.station" />{{item.station}}</label>
						</div>
					</template>
				</FilterItem>
				<div><span class="primary-btn" @click="fetchData">View</span></div>
			</div>

			<div style="margin-top: 0; ">
				<div v-if="!isFetching" style="padding: 10px 0;">
					<div v-if="rawdata.length === 0" style="padding: 0 10px;"><p>Nothing to show</p></div>
					<div v-else>
						<ALTable :rowdata="rows" :cols="cols" :excel="true"
							excelfilename="Regional Revenue Report"
							:searchbox="true" :pagination="50" :condensed="true"
						></ALTable>
					</div>
				</div>
				<div v-if="isFetching" style="padding: 20px 0; height: 300px; width: 500px;">
					<ChartLoaderInsert style="z-index: 9;"></ChartLoaderInsert>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import 'vue-datepicker-next/index.css';
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import FilterItem from "@/components/FilterItem.vue";
import ALTable from "@/components/ALTable.vue";

export default {
	name: "RegionalRevenueReports",
	components: {
		ALTable,
		FilterItem,
		ChartLoaderInsert,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			// viewPerms: ['elt','pcr'],
			showStationSelectOverlay: false,

			isFetching: false,

			reportType: 'summary', //summary, clientreport, salesrep, clientbymonth
			monthOptions: [
				{id: 1, name: 'January'},
				{id: 2, name: 'February'},
				{id: 3, name: 'March'},
				{id: 4, name: 'April'},
				{id: 5, name: 'May'},
				{id: 6, name: 'June'},
				{id: 7, name: 'July'},
				{id: 8, name: 'August'},
				{id: 9, name: 'September'},
				{id: 10, name: 'October'},
				{id: 11, name: 'November'},
				{id: 12, name: 'December'},
			],
			daypartOptions: [
				'BMAD M-F',
				'BMAD Sat',
				'BMAD Sun',
				'Evening M-F',
				'Evening Sat',
				'Evening Sun',
				'Middawn M-F',
				'Middawn Sat',
				'Middawn Sun',
			],

			//returned from indexes
			markets: [],
			stations: [],

			chosenMarket: null,
			selections: {
				dayparts: ['BMAD M-F'],
				months: [1,2,3,4,5,6,7,8,9,10,11,12],
				stations: [],
			},

			rawdata: [],

			allSalesOffices: [],

		}
	},
	computed: {
		cols() {
			if(this.reportType === 'summary') {
				return [
					{title: 'Sales Group', fldid: 'salesoffice', align: 'left', numtype: null},
					{title: 'Station', fldid: 'station', align: 'left', numtype: null},
					{title: 'TY Revenue', fldid: 'tyrev', align: 'right', numtype: 'dollars'},
					{title: 'TY Units', fldid: 'tyunits', align: 'right', numtype: 'number'},
					{title: 'LY Revenue', fldid: 'lyrev', align: 'right', numtype: 'dollars'},
					{title: '$ YoY', fldid: 'yoyrev', align: 'right', numtype: 'dollars'},
					{title: '% YoY', fldid: 'yoyrevpct', align: 'right', numtype: 'pct'},
					{title: 'TY Rate', fldid: 'tyrate', align: 'right', numtype: 'dollars'},
					{title: 'LY Rate', fldid: 'lyrate', align: 'right', numtype: 'dollars'},
					{title: 'Rate Gain', fldid: 'yoyratepct', align: 'right', numtype: 'pct'},
					{title: 'TY Bonus', fldid: 'tybonusratio', align: 'right', numtype: 'pct'},
					{title: 'LY Bonus', fldid: 'lybonusratio', align: 'right', numtype: 'pct'},
				]
			}
			else if(this.reportType === 'clientreport') {
				return [
					{title: 'Advertiser', fldid: 'advertiser', align: 'left', numtype: null},
					{title: 'TY Revenue', fldid: 'tyrev', align: 'right', numtype: 'dollars'},
					{title: 'TY Units', fldid: 'tyunits', align: 'right', numtype: 'number'},
					{title: 'LY Revenue', fldid: 'lyrev', align: 'right', numtype: 'dollars'},
					{title: '$ YoY', fldid: 'yoyrev', align: 'right', numtype: 'dollars'},
					{title: '% YoY', fldid: 'yoyrevpct', align: 'right', numtype: 'pct'},
					{title: 'TY Rate', fldid: 'tyrate', align: 'right', numtype: 'dollars'},
					{title: 'LY Rate', fldid: 'lyrate', align: 'right', numtype: 'dollars'},
					{title: 'Rate Gain', fldid: 'yoyratepct', align: 'right', numtype: 'pct'},
					{title: 'TY Bonus', fldid: 'tybonusratio', align: 'right', numtype: 'pct'},
					{title: 'LY Bonus', fldid: 'lybonusratio', align: 'right', numtype: 'pct'},
				]
			}
			else if(this.reportType === 'salesrep') {
				return [
					{title: 'Sales Rep', fldid: 'salesrep', align: 'left', numtype: null},
					{title: 'Advertiser', fldid: 'advertiser', align: 'left', numtype: null},
					{title: 'TY Revenue', fldid: 'tyrev', align: 'right', numtype: 'dollars'},
					{title: 'TY Units', fldid: 'tyunits', align: 'right', numtype: 'number'},
					{title: 'LY Revenue', fldid: 'lyrev', align: 'right', numtype: 'dollars'},
					{title: '$ YoY', fldid: 'yoyrev', align: 'right', numtype: 'dollars'},
					{title: '% YoY', fldid: 'yoyrevpct', align: 'right', numtype: 'pct'},
					{title: 'TY Rate', fldid: 'tyrate', align: 'right', numtype: 'dollars'},
					{title: 'LY Rate', fldid: 'lyrate', align: 'right', numtype: 'dollars'},
					{title: 'Rate Gain', fldid: 'yoyratepct', align: 'right', numtype: 'pct'},
					{title: 'TY Bonus', fldid: 'tybonusratio', align: 'right', numtype: 'pct'},
					{title: 'LY Bonus', fldid: 'lybonusratio', align: 'right', numtype: 'pct'},
				]
			}
			else if(this.reportType === 'clientbymonth') {
				return [
					{title: 'Advertiser', fldid: 'advertiser', align: 'left', numtype: null},
					{title: 'January', fldid: 'January', align: 'right', numtype: 'dollars'},
					{title: 'February', fldid: 'February', align: 'right', numtype: 'dollars'},
					{title: 'March', fldid: 'March', align: 'right', numtype: 'dollars'},
					{title: 'April', fldid: 'April', align: 'right', numtype: 'dollars'},
					{title: 'May', fldid: 'May', align: 'right', numtype: 'dollars'},
					{title: 'June', fldid: 'June', align: 'right', numtype: 'dollars'},
					{title: 'July', fldid: 'July', align: 'right', numtype: 'dollars'},
					{title: 'August', fldid: 'August', align: 'right', numtype: 'dollars'},
					{title: 'September', fldid: 'September', align: 'right', numtype: 'dollars'},
					{title: 'October', fldid: 'October', align: 'right', numtype: 'dollars'},
					{title: 'November', fldid: 'November', align: 'right', numtype: 'dollars'},
					{title: 'December', fldid: 'December', align: 'right', numtype: 'dollars'},
					{title: 'Total', fldid: 'Total', align: 'right', numtype: 'dollars'},
				]
			}
			return []
		},
		rows() {
			let out = []
			if(this.reportType === 'summary') {
				for(let row of this.rawdata) {
					let isTotalRow = false
					if(row.station && row.station === 'All Stations') isTotalRow = true
					out.push({
						salesoffice: row.salesoffice,
						station: row.station,
						tyrev: this.getVal(row, 'TYrev'),
						tyunits: this.getVal(row, 'TYunits'),
						lyrev: this.getVal(row, 'LYrev'),
						yoyrev: this.getVal(row, 'YoYrev'),
						yoyrevpct: this.getVal(row, 'YoYrevPct'),
						tyrate: this.getVal(row, 'TYRate'),
						lyrate: this.getVal(row, 'LYRate'),
						yoyratepct: this.getVal(row, 'YoYRatePct'),
						tybonusratio: this.getVal(row, 'TYBonusRatio'),
						lybonusratio: this.getVal(row, 'LYBonusRatio'),
						isTotalRow: isTotalRow,
					})
				}
			}
			else if(this.reportType === 'clientreport') {
				for (let row of this.rawdata) {
					out.push({
						advertiser: row.advertiser,
						tyrev: this.getVal(row, 'TYrev'),
						tyunits: this.getVal(row, 'TYunits'),
						lyrev: this.getVal(row, 'LYrev'),
						yoyrev: this.getVal(row, 'YoYrev'),
						yoyrevpct: this.getVal(row, 'YoYrevPct'),
						tyrate: this.getVal(row, 'TYRate'),
						lyrate: this.getVal(row, 'LYRate'),
						yoyratepct: this.getVal(row, 'YoYRatePct'),
						tybonusratio: this.getVal(row, 'TYBonusRatio'),
						lybonusratio: this.getVal(row, 'LYBonusRatio'),
					})
				}
			}
			else if(this.reportType === 'salesrep') {
				for (let row of this.rawdata) {
					let isTotalRow = false
					if (row.advertiser && row.advertiser === 'All Clients') isTotalRow = true
					out.push({
						salesrep: row.salesrep,
						advertiser: row.advertiser,
						tyrev: this.getVal(row, 'TYrev'),
						tyunits: this.getVal(row, 'TYunits'),
						lyrev: this.getVal(row, 'LYrev'),
						yoyrev: this.getVal(row, 'YoYrev'),
						yoyrevpct: this.getVal(row, 'YoYrevPct'),
						tyrate: this.getVal(row, 'TYRate'),
						lyrate: this.getVal(row, 'LYRate'),
						yoyratepct: this.getVal(row, 'YoYRatePct'),
						tybonusratio: this.getVal(row, 'TYBonusRatio'),
						lybonusratio: this.getVal(row, 'LYBonusRatio'),
						isTotalRow: isTotalRow,
					})
				}
			}
			else if(this.reportType === 'clientbymonth') {
				for(let row of this.rawdata) {
					out.push({
						advertiser: row.advertiser,
						January: this.getVal(row, 'January'),
						February: this.getVal(row, 'February'),
						March: this.getVal(row, 'March'),
						April: this.getVal(row, 'April'),
						May: this.getVal(row, 'May'),
						June: this.getVal(row, 'June'),
						July: this.getVal(row, 'July'),
						August: this.getVal(row, 'August'),
						September: this.getVal(row, 'September'),
						October: this.getVal(row, 'October'),
						November: this.getVal(row, 'November'),
						December: this.getVal(row, 'December'),
						Total: this.getVal(row, 'Total'),
					})
				}
			}
			return out
		},
		stationsForMarket() {
			return this.stations.filter(item => item.market === this.chosenMarket)
		},
	},
	methods: {
		getVal(row, lookup) {
			if(['summary','clientreport','salesrep'].includes(this.reportType)) {
				if(lookup === 'TYrev' && row.TY && row.TY.total && row.TY.total.rev) return row.TY.total.rev
				if(lookup === 'TYunits' && row.TY && row.TY.total && row.TY.total.units) return row.TY.total.units
				if(lookup === 'LYrev' && row.LY && row.LY.total && row.LY.total.rev) return row.LY.total.rev
				if(lookup === 'YoYrev' &&  row.YoY && row.YoY.total && row.YoY.total.rev) return row.YoY.total.rev
				if(lookup === 'YoYrevPct' &&  row.YoY && row.YoY.total && row.YoY.total.revPctDiff) return row.YoY.total.revPctDiff
				if(lookup === 'TYRate' && row.TY && row.TY.effective && row.TY.effective.rate30s) return row.TY.effective.rate30s
				if(lookup === 'LYRate' && row.LY && row.LY.effective && row.LY.effective.rate30s) return row.LY.effective.rate30s
				if(lookup === 'YoYRatePct' && row.YoY && row.YoY.effective && row.YoY.effective.ratePctDiff) return row.YoY.effective.ratePctDiff
				if(lookup === 'TYBonusRatio' && row.TY && row.TY.bonusRatio) return row.TY.bonusRatio
				if(lookup === 'LYBonusRatio' && row.LY && row.LY.bonusRatio) return row.LY.bonusRatio
			}
			else if(this.reportType === 'clientbymonth') {
				if(row[lookup] && row[lookup].total && row[lookup].total.rev) return row[lookup].total.rev
			}
			return 0
		},
		getMetaLists() {
			let self = this;
			let prependTarg = 'https://arnconnect.com.au/'
			let targ = prependTarg + "/api/sales-tools/regional-revenue/get-meta-lists.php"
			this.isFetching = true
			axios.get(targ).then(function (response) {
				let ret = response.data //response.data is returned info
				if (ret.error === 1) console.log(ret)
				else if(ret.markets) {
					self.markets = ret.markets
					self.stations = ret.stations
					setTimeout(function() {
						self.chosenMarket = self.markets[0]
					}.bind(this), 100)
				}
				self.isFetching = false
			}).catch(function (error) {
				console.log(error)
				self.isFetching = false
			});
		},
		fetchData() {
			let self = this
			let prependTarg = 'https://arnconnect.com.au/'
			let targ = prependTarg + "/api/sales-tools/regional-revenue/get-report.php"
			this.isFetching = true
			axios.post(targ, JSON.stringify({
				selections: self.selections,
				reportType: self.reportType
			})).then(function (response) {
				let ret = response.data //response.data is returned info
				console.log(ret)
				if(ret.rawdata) self.rawdata = ret.rawdata
				if(ret.allSalesOffices) self.allSalesOffices = ret.allSalesOffices
				self.isFetching = false
			}).catch(function () {
				self.isFetching = false
			});
		},
	},
	watch: {
		chosenMarket() {
			this.selections.stations = []
		},
		stationsForMarket() {
			setTimeout(function() {
				this.selections.stations = this.stationsForMarket.map(item => item.station)
			}.bind(this), 100)
		},
		reportType() {
			this.rawdata = []
		}
	},
	mounted() {
		document.title = 'Regional Revenue Reporting'
		this.getMetaLists()
	}
}
</script>

<style scoped>

</style>