<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0" width="99%" height="100%" type="bar"
			:options="chartoptions" :series="chartdata"></apexchart>
		<ChartLoaderInsert v-if="fetching"></ChartLoaderInsert>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
export default {
	name: "ApexCropCircles",
	components: {
		ChartLoaderInsert
	},
	props: {
		chartdata: Array,
		chartcolors: Array,
		fetching: Boolean,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			chartoptions: {
				chart: {
					id: "ratings",
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					},
					type: 'bubble',
				},
				plotOptions: {
					bubble: {
						minBubbleRadius: 10,
						maxBubbleRadius: 70,
					}
				},
				xaxis: {
					type: "numeric",
					min: 20,
					max: 80,
					decimalsInFloat: 0,
					categories: [],
					tickAmount: 10,
					forceNiceScale: true,
					labels: {
						show: true,
					},
					axisTicks: {
						show: true
					},
					title: {
						text: 'Percent Female Cume',
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 0,
					min: 10,
					max: 70,
					forceNiceScale: true,
					title: {
						text: 'Average Age',
					}
				},
				// colors: [],
				dataLabels: {
					enabled: false
				},
				tooltip: {
					// eslint-disable-next-line no-unused-vars
					custom: function({series, seriesIndex, dataPointIndex, w}) {
						// console.log(w); //cheat way to figure out what is in w seeing as docs are terrible
						return '<div class="wc-tooltip">' +
							'<div class="wc-tooltip-top boldme">'+w.globals.seriesNames[seriesIndex]+'</div>'+
							'<div class="wc-tooltip-body">' +
							'<div class="wc-tooltip-line">' +
							'<p class="">Pct Female: '+w.config.series[seriesIndex].data[0].x+'%</p>' +
							'<p class="">Avg Age: '+series[seriesIndex]+'</p>' +
							'</div>'+
							'</div>'+
							'</div>'
					}.bind(this),
				}
			},
		}
	},
	computed: {
		chartcols() {
			if(this.chartcolors && this.chartcolors.length > 0) return this.chartcolors;
			return this.store.defaultColors;
		},
	},
	methods: {
		updateColours() {
			let outcols = this.store.defaultColors;
			if(this.chartcolors && this.chartcolors.length > 0) {
				outcols = this.chartcolors;
			}
			setTimeout(function() {
				this.chartoptions = {...this.chartoptions, ...{
						colors: outcols
					}};
			}.bind(this), 10)
		},
	},
	watch: {
		chartcolors() {
			this.updateColours();
		}
	},
	mounted() {
		this.updateColours();
	}
}
</script>

<style scoped>

</style>