<template>
	<div>
		<p class="headingtext">
			<span class="bold">How to read the data: </span>
			Consider 'indexing' as a way to gauge how a particular iHeart podcast's audience stacks up against the norm for podcasts in the same genre. It's like comparing apples to apples but figuring out which apple is juicier when it comes to listener characteristics.
			For instance, if we say the index for Podcast X is 147, it means that the folks tuning into Podcast X are 47% more inclined to plan a domestic vacation in the upcoming year compared to the average listener in that genre.
			This insight helps you understand not just who's listening, but how they're potentially different (and more engaged) in certain activities or interests than the broader audience pool.
		</p>
		<div v-if="shows.length > 0" class="filters-area" style="padding-top: 10px; display: flex; align-items: flex-end;">
			<div style="padding-top: 10px; display: flex; align-items: flex-end;">
				<div style="margin-right: 10px;">
					<select v-model="selections.datatype" class="select-styled">
						<option v-for="item in availableDatatypes" :key="item.id" :value="item.id">{{item.name}}</option>
					</select>
				</div>
				<div style="width: 300px; display: flex; justify-content: flex-end; align-items: center">
					<Autocompleter :searchlist="showsFiltered" :incomingval="selections.show"
						idfldname="id" textfldname="name" v-on:update="selectShow($event)"
					></Autocompleter>
				</div>
				<FilterItem :label="'Genre ('+selections.genres.length+')'">
					<template v-slot:items>
						<div class="filter-item">
							<label class="aller" @click="toggleAllGenres">All / Clear</label>
						</div>
						<div class="filter-item" v-for="item in genres" :key="item">
							<label><input v-model="selections.genres" type="checkbox" :value="item" />{{item}}</label>
						</div>
					</template>
				</FilterItem>
				<FilterItem :label="'Demo ('+countSelectedDemosMain+')'">
					<template v-slot:items>
						<div class="filter-item">
							<label class="aller" @click="toggleAllDemosMain">All / Clear</label>
						</div>
						<div class="filter-item" v-for="item in demosMain" :key="item">
							<label><input v-model="selections.demos" type="checkbox" :value="item.id" />{{item.name}}</label>
						</div>
					</template>
				</FilterItem>
				<FilterItem :label="'Advanced ('+countSelectedDemosAdvanced+')'">
					<template v-slot:items>
						<div class="filter-item">
							<label class="aller" @click="toggleAllDemosAdvanced">All / Clear</label>
						</div>
						<div class="filter-item" v-for="item in demosAdvanced" :key="item">
							<label><input v-model="selections.demos" type="checkbox" :value="item.id" />{{item.name}}</label>
						</div>
					</template>
				</FilterItem>
				<FilterItem :label="'Purch Int ('+countSelectedDemosPurchInt+')'">
					<template v-slot:items>
						<div class="filter-item">
							<label class="aller" @click="toggleAllDemosPurchInt">All / Clear</label>
						</div>
						<div class="filter-item" v-for="item in demosPurchInt" :key="item">
							<label><input v-model="selections.demos" type="checkbox" :value="item.id" />{{item.name}}</label>
						</div>
					</template>
				</FilterItem>
				<FilterItem :label="'Media ('+countSelectedDemosMedia+')'">
					<template v-slot:items>
						<div class="filter-item">
							<label class="aller" @click="toggleAllDemosMedia">All / Clear</label>
						</div>
						<div class="filter-item" v-for="item in demosMedia" :key="item">
							<label><input v-model="selections.demos" type="checkbox" :value="item.id" />{{item.name}}</label>
						</div>
					</template>
				</FilterItem>
				<div><span class="primary-btn" @click="fetchData">Fetch</span></div>
			</div>
		</div>
		<div class="results-area">
			<div style="display: flex; justify-content: flex-start; padding: 5px 0;">
				<span @click="exportToExcel('genre_indexes')" class="excel-link">
					<i class="mdi mdi-file-excel-outline"></i>
				</span>
			</div>
			<table v-if="progs_used.length > 0" class="altbl" id="genre_indexes">
				<thead>
					<tr>
						<td v-for="(col, ind) in headerrowdata" :key="ind" class="headingtext sortable" @click="sortColumn(ind)" data-f-bold="true">
							<span>{{col}}</span>
						</td>
					</tr>
				</thead>
				<tbody>
				<tr v-for="(row, rowindex) in rows" :key="rowindex">
<!--					<td data-t="s">{{row[0]}}</td>-->
<!--					<td data-t="s">{{row[1]}}</td>-->
<!--					<td data-t="n" class="aright">{{numdisplayNoZeroes(row[2])}}</td>-->
<!--					<td data-t="n" class="aright">{{numdisplayNoZeroes(row[3])}}</td>-->
					<td v-for="ind in nDataCols" :key="ind" data-t="n" :class="getCellClass(ind, row[ind])">
						<span>{{this.numdisplay(row[ind], 0)}}</span>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import FilterItem from "@/components/FilterItem.vue";
import axios from "axios";
import Autocompleter from "@/components/Autocompleter.vue";
import TableToExcel from "@linways/table-to-excel";

export default {
	name: 'PodcastGenreIndexing',
	components: {
		Autocompleter,
		FilterItem

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			isFetching: false,

			genres: [],
			shows: [],
			demos: [],

			selections: {
				genres: [], //empty is all
				show: 0, //0 is all
				demos: [1,3,8,10,17,34], //empty is all
				datatype: 'pct', //percent, indexaus, indexpod, vsgenreidx
			},

			availableDatatypes: [
				{id: 'pct', name: 'Percent'},
				{id: 'indaus', name: 'Index Aus Popn'},
				{id: 'indpod', name: 'Index Podcast Popn'},
				{id: 'vsgenreidx', name: 'vs Genre Index'},
			],

			returnedData: {},
			progs_used: [],
			demos_used: [],
			listeners: {},
			downloads: {},

			sortColIndex: null,
			sortColOrder: 'desc',
		}
	},
	computed: {
		rows() {
			let rows = []
			for(let progId of this.progs_used) {
				let myrow = [
					this.getShowName(progId),
					this.getShowGenre(progId),
					this.getListeners(progId),
					this.getDownloads(progId),
				]
				for(let demoId of this.demos_used) {
					myrow.push(this.getCellVal(progId, demoId))
				}
				rows.push(myrow)
			}
			if(this.sortColIndex) {
				let useStrings = false
				if(isNaN(rows[0][this.sortColIndex]) || parseFloat(rows[0][this.sortColIndex]) != rows[0][this.sortColIndex]) useStrings = true;
				if(this.sortColOrder === 'asc') {
					if(useStrings === true) {
						rows = rows.sort((a, b) => (a[this.sortColIndex].toLowerCase() > b[this.sortColIndex].toLowerCase() ? 1 : -1))
					}
					else rows = rows.sort((a, b) => (a[this.sortColIndex] > b[this.sortColIndex]) ? 1 : -1)
				}
				else {
					if(useStrings === true) rows = rows.sort((a, b) => (a[this.sortColIndex].toLowerCase() > b[this.sortColIndex].toLowerCase() ? -1 : 1))
					else rows = rows.sort((a, b) => (a[this.sortColIndex] > b[this.sortColIndex]) ? -1 : 1)
				}
			}
			return rows;
		},
		nDataCols() {
			if(this.rows && this.rows[0]) {
				let r = []
				let count = 0
				while(count < this.rows[0].length) {
					r.push(count)
					count++
				}
				return r
			}
			return []
		},
		demosMain() {
			return this.demos.filter(item => item.cat === 'demo')
		},
		demosAdvanced() {
			return this.demos.filter(item => item.cat === 'advanced')
		},
		demosPurchInt() {
			return this.demos.filter(item => item.cat === 'purchint')
		},
		demosMedia() {
			return this.demos.filter(item => item.cat === 'media')
		},
		showsFiltered() {
			if(this.selections.genres.length === 0) { //return all shows
				return this.shows
			}
			else { //return shows that have same genre as selected
				let aller = [this.shows[0]]
				let remainder = this.shows.filter(item => this.selections.genres.includes(item.genre))
				return aller.concat(remainder)
			}
		},
		headerrowdata() {
			let h = ['Program','Genre','Listeners (3m avg)','Downloads (3m avg)']
			for(let demoId of this.demos_used) {
				let myob = this.demos.find(item => item.id === demoId)
				if(myob) h.push(myob.name)
			}
			return h
		},
		countSelectedDemosMain() {
			let c = this.getCommonIds(this.selections.demos, this.demosMain.map(item => item.id))
			return c.length
		},
		countSelectedDemosAdvanced() {
			let c = this.getCommonIds(this.selections.demos, this.demosAdvanced.map(item => item.id))
			return c.length
		},
		countSelectedDemosPurchInt() {
			let c = this.getCommonIds(this.selections.demos, this.demosPurchInt.map(item => item.id))
			return c.length
		},
		countSelectedDemosMedia() {
			let c = this.getCommonIds(this.selections.demos, this.demosMedia.map(item => item.id))
			return c.length
		},
	},
	methods: {
		sortColumn(colind) {
			if(this.sortColIndex === colind) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColIndex = colind; //either no column yet chosen or a different column, sort by that col
		},
		getCommonIds(arr1, arr2) {
			let com = []
			for(let id of arr1) {
				if(arr2.find(item => item === id)) {
					com.push(id)
				}
			}
			return com
		},
		exportToExcel(tblid, filename = 'export') {
			if(this.excelfilename && this.excelfilename.length > 0) {
				filename = this.excelfilename;
			}
			TableToExcel.convert(document.getElementById(tblid), {
				name: filename+".xlsx",
				sheet: {
					name: 'Sheet 1'
				}
			});
		},
		getCellClass(colind, val) {
			let classes = []
			if(colind > 1) classes.push('aright')
			if(val > 100 && colind > 3) classes.push('highlight')
			return classes.join(' ')
		},
		getShowName(progId) {
			let myob = this.shows.find(item => item.id === progId)
			if(myob) return myob.name
			return ''
		},
		getShowGenre(progId) {
			let myob = this.shows.find(item => item.id === progId)
			if(myob) return myob.genre
			return ''
		},
		getCellVal(progId, demoId) {
			let myval = this.returnedData[progId][demoId]
			if(myval) return myval
			return ''
		},
		getListeners(progId) {
			if(this.listeners[progId]) return this.listeners[progId]
			return 0
		},
		getDownloads(progId) {
			if(this.downloads[progId]) return this.downloads[progId]
			return 0
		},
		selectShow(showOb) {
			this.selections.show = showOb.id
			if(showOb.id > 0) {
				let myob = this.shows.find(item => item.id === showOb.id)
				if(myob && myob.genre) {
					this.selections.genres = [myob.genre]
				}
			}
		},
		toggleAllGenres() {
			if(this.selections.genres.length === this.genres.length) this.selections.genres = []
			else this.selections.genres = this.genres
		},
		toggleAllDemosMain() {
			if(this.haveCommonItems(this.selections.demos, this.demosMain.map(item => item.id))) {
				this.selections.demos = this.removeFromArray(this.selections.demos, this.demosMain.map(item => item.id))
			}
			else this.selections.demos = this.selections.demos.concat(this.demosMain.map(item => item.id))
		},
		toggleAllDemosAdvanced() {
			if(this.haveCommonItems(this.selections.demos, this.demosAdvanced.map(item => item.id))) {
				this.selections.demos = this.removeFromArray(this.selections.demos, this.demosAdvanced.map(item => item.id))
			}
			else this.selections.demos = this.selections.demos.concat(this.demosAdvanced.map(item => item.id))
		},
		toggleAllDemosPurchInt() {
			if(this.haveCommonItems(this.selections.demos, this.demosPurchInt.map(item => item.id))) {
				this.selections.demos = this.removeFromArray(this.selections.demos, this.demosPurchInt.map(item => item.id))
			}
			else this.selections.demos = this.selections.demos.concat(this.demosPurchInt.map(item => item.id))
		},
		toggleAllDemosMedia() {
			if(this.haveCommonItems(this.selections.demos, this.demosMedia.map(item => item.id))) {
				this.selections.demos = this.removeFromArray(this.selections.demos, this.demosMedia.map(item => item.id))
			}
			else this.selections.demos = this.selections.demos.concat(this.demosMedia.map(item => item.id))
		},
		getMeta() {
			let self = this
			let targ = "/api/podcast/genre-meta.php"
			this.isFetching = true
			axios.get(targ).then(function (response) {
				let ret = response.data //response.data is returned info
				if (ret.error === 1) console.log(ret)
				else {
					if(ret.genres) self.genres = ret.genres
					if(ret.shows) {
						self.shows = ret.shows
						self.selections.show = 0
					}
					if(ret.demos) self.demos = ret.demos
				}
				self.isFetching = false
			}).catch(function (error) {
				console.log(error)
				self.isFetching = false
			});
		},
		haveCommonItems(arr1, arr2) {
			return arr1.some(item => arr2.includes(item))
		},
		removeFromArray(arr, arrayToRemove) {
			for(let i=0; i<arrayToRemove.length; i++) {
				let index = arr.indexOf(arrayToRemove[i])
				if (index > -1) arr.splice(index, 1)
			}
			return arr
		},
		fetchData() {
			let self = this;
			let targ = "/api/podcast/fetch-genre-index-table.php";
			this.isFetching = true
			axios.post(targ, JSON.stringify({
				selections: self.selections,
			})).then(function (response) {
				let ret = response.data //response.data is returned info
				if (ret.table) self.returnedData = ret.table
				if (ret.progs_used) self.progs_used = ret.progs_used
				if (ret.demos_used) self.demos_used = ret.demos_used
				if (ret.downloads) self.downloads = ret.downloads
				if (ret.listeners) self.listeners = ret.listeners
				self.isFetching = false
			}).catch(function () {
				self.isFetching = false
			});
		},
	},
	watch: {

	},
	props: {

	},
	mounted() {
		this.getMeta()
	},
	created() {
		document.title = "Genre Indexes & Comparisons"
	},
}
</script>

<style scoped>
.excel-link {
	cursor: pointer;
}
.excel-link:hover {
	opacity: 0.8;
}
.aright {
	text-align: right;
}
.aller {
	cursor: pointer;
	margin: 5px 0;
	display: block;
}
.aller:hover {
	text-decoration: underline;
}
.highlight {
	background: #b5f5b9;
}
.lowlight {
	background: #f79c9c;
}
.sortable {
	cursor: pointer;
}
</style>
