<template>
	<div>
		<div style="padding-top: 20px; display: flex; flex-wrap: wrap;">
			<div class="large-tile-button" @click="$router.push('/post-campaign-reporting')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-sign-direction"></i></div>
					<p>Post Campaign Reporting</p>
				</div>
			</div>
			<div class="large-tile-button" @click="$router.push('/sales-tools/geo-lookup')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-map-marker-outline"></i></div>
					<p>Postcode & Suburb Tool</p>
				</div>
			</div>
			<div class="large-tile-button" @click="$router.push('/sales-tools/aircheck-clients')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-currency-usd"></i></div>
					<p>Aircheck Client Reports</p>
				</div>
			</div>
			<div v-if="canViewRevenue === true" class="large-tile-button" @click="$router.push('/sales-tools/regional-revenue-reports')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-currency-usd"></i></div>
					<p>Regional Revenue Reports</p>
				</div>
			</div>
			<div v-if="store.user.id === 1" class="large-tile-button" @click="$router.push('/sales-tools/campaign-planner')">
				<div class="tile-inside">
					<div class="tile-icon-hold"><i class="mdi mdi-currency-usd"></i></div>
					<p>Campaign Planner</p>
				</div>
			</div>
<!--			<div v-if="userHasPermission(['elt','connections','content-metro'])" class="large-tile-button" @click="$router.push('/iheart/national-kpi')">-->
<!--				<div class="tile-inside">-->
<!--					<div class="tile-icon-hold"><i class="mdi mdi-chart-timeline"></i></div>-->
<!--					<p>iHeartRadio National KPIs</p>-->
<!--				</div>-->
<!--			</div>-->
<!--			<div v-if="userHasPermission(['elt','connections','content-reg'])" class="large-tile-button" @click="$router.push('/iheart/regional-summary')">-->
<!--				<div class="tile-inside">-->
<!--					<div class="tile-icon-hold"><i class="mdi mdi-chart-bell-curve-cumulative"></i></div>-->
<!--					<p>iHeartRadio Regional KPIs</p>-->
<!--				</div>-->
<!--			</div>-->
<!--			<div v-if="userHasPermission(['elt','connections','content-metro'])" class="large-tile-button" @click="$router.push('/iheart/chart-explorer')">-->
<!--				<div class="tile-inside">-->
<!--					<div class="tile-icon-hold"><i class="mdi mdi-chart-line"></i></div>-->
<!--					<p>iHeartRadio Chart Explorer</p>-->
<!--				</div>-->
<!--			</div>-->
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'SalesToolsLand',
	components: {},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			errorId: 0,
			heading: '',
		}
	},
	computed: {
		transformed() {
			return {
				template: '<div>' + this.content + '</div>',
				props: this.$options.props
			}
		},
		canViewRevenue() {
			let uemail = this.store.user.email.toLowerCase()
			let allowed = ['alanlogan','benturley','johnalegria']
			for(let allow of allowed) {
				if (uemail.includes(allow)) {
					return true
				}
			}
			return false
		}
	},
	methods: {},
	watch: {},
	props: {
		errorPageHeading: String,
		errorPageText: String,
	},
	mounted() {

	},
	created() {
		let urlparams = this.$route.query;
		this.heading = "ARN Connections Dashboard";
		if (urlparams.errorId !== undefined) {
			this.errorId = parseInt(urlparams.errorId);
		}
		document.title = this.heading;
	},
}
</script>

<style scoped>
.header-notes {
	margin: 20px 0;
	padding: 20px;
	border: 1px solid #CCC;
	background-color: #DDD;
	color: #444;
	font-size: 15px;
}
</style>
