<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">
			<div>
				<router-link to="/trade-marketing">
					<span class="primary-btn">Back</span>
				</router-link>
			</div>
			<div style="padding-top: 30px; display: flex; flex-wrap: wrap; justify-content: space-between">
				<TradeMarketingPersona title="Co-Ordinator" :itemnames="['adnews','mumbrella','mediaweek']"></TradeMarketingPersona>
				<TradeMarketingPersona title="Buyer/Trader" :itemnames="['adnews','mumbrella','mi3']"></TradeMarketingPersona>
				<TradeMarketingPersona title="Planner/Client Service" :itemnames="['adnews','mumbrella','mi3']"></TradeMarketingPersona>
				<TradeMarketingPersona title="Strategy" :itemnames="['mumbrella','adnews','mi3']"></TradeMarketingPersona>
				<TradeMarketingPersona title="Digital" :itemnames="['adnews','mumbrella','mi3']"></TradeMarketingPersona>
				<TradeMarketingPersona title="Management" :itemnames="['adnews','mumbrella','mediaweek']"></TradeMarketingPersona>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import TradeMarketingPersona from "@/components/TradeMarketingPersona";
import PermissionChecker from "@/components/PermissionChecker";
export default {
	name: "TradeMarketingPersonas",
	components: {PermissionChecker, TradeMarketingPersona},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			viewPerms: ['elt','connections'],
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>