<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">
			<div class="chart-rows">
				<ChartHolderDash charttitle="Cumulative Reach" :height="420" :source="chartsSource.cume" :addclasses="'half'">
					<template v-slot:default>
						<ApexBarTrendDateMonthlyLineKPI :chartdata="chartsData.cume" :fetching="getChartsBtnWorking"></ApexBarTrendDateMonthlyLineKPI>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Total Listening Hours" :height="420" :source="chartsSource.tlh" :addclasses="'half'">
					<template v-slot:default>
						<ApexBarTrendDateMonthlyLineKPI :chartdata="chartsData.tlh" :fetching="getChartsBtnWorking"></ApexBarTrendDateMonthlyLineKPI>
					</template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash charttitle="Registered Users %" :height="420" :source="chartsSource.regpct" :addclasses="'half'">
					<template v-slot:default>
						<ApexBarTrendDateMonthlyLineKPI :chartdata="chartsData.regpct" :fetching="getChartsBtnWorking"></ApexBarTrendDateMonthlyLineKPI>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Cume: Metro ARN Stations" :height="420" :source="chartsSource.cume_metro" :addclasses="'half'">
					<template v-slot:default>
						<ApexBarTrendDateMonthlyLineKPI :chartdata="chartsData.cume_metro" :fetching="getChartsBtnWorking"></ApexBarTrendDateMonthlyLineKPI>
					</template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash charttitle="Cume: Regional ARN Stations" :height="420" :source="chartsSource.cume_reg" :addclasses="'half'">
					<template v-slot:default>
						<ApexBarTrendDateMonthlyLineKPI :chartdata="chartsData.cume_reg" :fetching="getChartsBtnWorking"></ApexBarTrendDateMonthlyLineKPI>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Cume: IHR Stations Australia" :height="420" :source="chartsSource.cume_ihrstn" :addclasses="'half'">
					<template v-slot:default>
						<ApexBarTrendDateMonthlyLineKPI :chartdata="chartsData.cume_ihrstn" :fetching="getChartsBtnWorking"></ApexBarTrendDateMonthlyLineKPI>
					</template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash charttitle="Cume: Partnership Stations" :height="420" :source="chartsSource.cume_partners" :addclasses="'half'">
					<template v-slot:default>
						<ApexBarTrendDateMonthlyLineKPI :chartdata="chartsData.cume_partners" :fetching="getChartsBtnWorking"></ApexBarTrendDateMonthlyLineKPI>
					</template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="Cume: Artist/Faves Radio" :height="420" :source="chartsSource.cume_artistfave" :addclasses="'half'">
					<template v-slot:default>
						<ApexBarTrendDateMonthlyLineKPI :chartdata="chartsData.cume_artistfave" :fetching="getChartsBtnWorking"></ApexBarTrendDateMonthlyLineKPI>
					</template>
				</ChartHolderDash>
			</div>
			<div class="chart-rows">
				<ChartHolderDash charttitle="Cume: Podcasts" :height="420" :source="chartsSource.cume_podcasts" :addclasses="'half'">
					<template v-slot:default>
						<ApexBarTrendDateMonthlyLineKPI :chartdata="chartsData.cume_podcasts" :fetching="getChartsBtnWorking"></ApexBarTrendDateMonthlyLineKPI>
					</template>
				</ChartHolderDash>

			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from 'axios'
import PermissionChecker from "@/components/PermissionChecker";
import ChartHolderDash from "@/components/ChartHolderDash";
import ApexBarTrendDateMonthlyLineKPI from "@/components/charts/ApexBarTrendDateMonthlyLineKPI";
export default {
	name: "IHRDash",
	components: {
		ApexBarTrendDateMonthlyLineKPI,
		PermissionChecker,
		ChartHolderDash,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			viewPerms: ['elt','connections','iheart'],
			getChartsBtnWorking: true,

			chartsData: [],
			chartsSource: [],

		}
	},
	computed: {
		// endOfLastMonth() {
		// 	let date = new Date();
		// 	date.setDate(0); //remember month index starts at 0 so need to +1 below to get 9=sep eg
		// 	return date.getDate().toString() + '-' + (date.getMonth()+1).toString() + '-' + date.getFullYear().toString();
		// },
		// endOfLastWeek() {
		// 	let date = new Date();
		// 	let currDayOfWeek = date.getDay(); //1=Mon, 2=Tue etc
		// 	if(currDayOfWeek < 3) { //we probably don't have last week's figures yet (prior to Wed), roll back one week
		// 		date.setDate(date.getDate() - 7 - date.getDay());
		// 	}
		// 	else {
		// 		date.setDate(date.getDate() - date.getDay());
		// 	}
		// 	return date.getDate().toString() + '-' + (date.getMonth()+1).toString() + '-' + date.getFullYear().toString();
		// },
		// twoDaysAgo() {
		// 	let date = new Date();
		// 	date.setDate(date.getDate() - 2);
		// 	return date.getDate().toString() + '-' + (date.getMonth()+1).toString() + '-' + date.getFullYear().toString();
		// },
	},
	methods: {
		getData() {
			let self = this;
			let targ = "/api/iheart/get-dashboard.php";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					if (ret.chartsData) self.chartsData = ret.chartsData
					if (ret.chartsSource) self.chartsSource = ret.chartsSource
					self.getChartsBtnWorking = false
				}
			}).catch(function (error) {
				console.log(error);
			});
		},

	},
	watch: {},
	mounted() {
		document.title = 'iHeartRadio Dashboard'
		this.getData()
	}
}
</script>

<style scoped>

</style>