<template>
	<div class="autocompleter">
		<div class="inputholder">
			<input class="myinputtext" type="text" style="cursor: pointer" v-model="selectedText" @focus="dropdownshown = true" @keyup="textEntered" @blur="sendupdate" />
			<div @click="toggleShown" class="dropicon" style="position: absolute; right: 5px; background-color: #FFF; top: 11px;">
				<i class="mdi mdi-chevron-down" style="font-size: 20px;"></i>
			</div>
		</div>
		<div class="dropdown" v-if="dropdownshown" :class="{hidden : cssDropdownShown === false}">
			<div class="dropdown-item" v-for="s in dropdownoptions" :key="s[idfldname]" @click.stop="selectme(s)" :class="{act : s[idfldname] === selectionJustMade}">{{s[textfldname]}}</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "AutocompleterStringOnly",
	props: {
		searchlist: Array,
		incomingval: String,
		idfldname: String,
		textfldname: String,
	},
	mixins: [globalMixin],
	data: function() {
		return {
			store: store,
			selectedId: '',
			selectedText: '',
			dropdownshown: false,
			cssDropdownShown: false,
			textManuallyEntered: false,

			selectionJustMade: null,
		}
	},
	computed: {
		dropdownoptions() {
			if(this.textManuallyEntered === false) { //show all in list
				return this.searchlist;
			}
			return this.searchlist.filter(item => item[this.textfldname].toLowerCase().includes(this.selectedText.toLowerCase()));
		},
	},
	methods: {
		toggleShown() {
			if(this.dropdownshown === true) this.dropdownshown = false;
			else this.dropdownshown = true;
		},
		textEntered() {
			this.textManuallyEntered = true;
		},
		selectme(item) {
			this.selectedId = item[this.idfldname];
			this.selectedText = item[this.textfldname];
			this.selectionJustMade = item[this.idfldname]
			this.sendupdate();
			setTimeout(function() {
				this.textManuallyEntered = false
				this.selectionJustMade = null
				this.cssDropdownShown = false
			}.bind(this), 100)
		},
		sendupdate() {
			var ob = {};
			ob[this.idfldname] = this.selectedId;
			ob[this.textfldname] = this.selectedText;
			this.$emit('update', ob);
			setTimeout(function() {
				this.dropdownshown = false;
			}.bind(this), 500);
		},
		doPrepare() {
			if(this.incomingval && this.incomingval.length > 0) {
				this.selectedId = this.incomingval;
				var myob = this.searchlist.find(item => item[this.idfldname] === this.incomingval);
				if(myob) this.selectedText = myob[this.textfldname];
			}
		},
	},
	watch: {
		selectedText() {
			if(this.selectedText.length < 2) { //clear selection
				this.selectedId = '';
			}
		},
		incomingval() {
			this.doPrepare();
		},
		dropdownshown() {
			this.cssDropdownShown = this.dropdownshown
		},
	},
	mounted() {
		this.doPrepare();
	},
}
</script>

<style scoped>
.autocompleter {
	width: 100%;
}
.inputholder {
	margin-right: 10px;
	position: relative;
	cursor: pointer;
}
.myinputtext {
	padding: 10px 8px 10px;
	border: 1px solid #BBB;
	width: 290px;
	border-radius: 5px;
	font-size: 16px;
}
.dropdown {
	background-color: #FAFAFA;
	border: 1px solid #DDD;
	position: absolute;
	min-width: 300px;
	z-index: 1002;
}
.dropdown-item {
	padding: 10px 10px;
	font-size: 14px;
	border-bottom: 1px solid #DDD;
	cursor: pointer;
	transition: background-color 0.3s ease-out;
}
.dropdown-item.act {
	background-color: rgba(85, 58, 169, 0.5);
}
.hidden {
	display: none;
}
</style>