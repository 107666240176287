<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>

		<div v-if="userHasPermission(viewPerms)">

			<div>
				<div class="link-btn" @click="activeTable = 'tlh'" :class="{active : activeTable === 'tlh'}" style="margin-right: 5px;">IHR Streaming TLH</div>
				<div class="link-btn" @click="activeTable = 'cume'" :class="{active : activeTable === 'cume'}" style="margin-right: 5px;">IHR Streaming Cume</div>
			</div>
			<div style="margin-top: 10px; padding: 15px 0 10px; border-top: 1px solid #DDD;">
				<input type="checkbox" v-model="commasInNumbers" id="comnum" /> <label for="comnum" style="margin-right: 20px; font-size: 11px;">Show commas in numbers</label>
				<input type="checkbox" v-model="hideLY" id="hideLYcheck" /> <label for="hideLYcheck" style="margin-right: 20px; font-size: 11px;">Hide last year</label>
				<span class="netshortcut" v-if="activeTable === 'tlh'" @click="exportToExcel('ihrtblTLH', 'IHR_TLH')">Export</span>
				<span class="netshortcut" v-if="activeTable === 'cume'" @click="exportToExcel('ihrtblCume', 'IHR_Cume')">Export</span>
			</div>

			<div :class="{hidden : activeTable !== 'tlh'}">

				<table v-if="tlh && tlh[0] && tlh[0].data" class="altbl" id="ihrtblTLH" :class="{hideLY : hideLY}">
					<thead>
						<tr>
							<td class="headingtext"></td>
							<td class="headingtext rt" v-for="(t, ind) in tlh[0]['data']" :key="ind" data-f-bold="true">{{t.x}}</td>
						</tr>
					</thead>
					<tbody>
<!--						<tr v-for="stnid in amfms" :key="stnid">-->
<!--							<td data-f-bold="true">{{tlh[stnid].name}}</td>-->
<!--							<td v-for="(mth, ind) in months" :key="ind" class="rt">{{formatNumbers(getMyVal(tlh, stnid, mth))}}</td>-->
<!--						</tr>-->
<!--						<tr class="subtitlerow">-->
<!--							<td data-f-bold="true">SubTotal AM/FM</td>-->
<!--							<td v-for="(t, ind) in amfmsubtotalTLH" :key="ind" data-f-bold="true" class="rt">{{formatNumbers(t)}}</td>-->
<!--						</tr>-->
<!--						<tr v-for="stnid in otherMetros" :key="stnid">-->
<!--							<td data-f-bold="true">{{tlh[stnid].name}}</td>-->
<!--							<td v-for="(mth, ind) in months" :key="ind" class="rt">{{formatNumbers(getMyVal(tlh, stnid, mth))}}</td>-->
<!--						</tr>-->
<!--						<tr class="subtitlerow">-->
<!--							<td data-f-bold="true">SubTotal</td>-->
<!--							<td v-for="(t, ind) in nonAmfmsubtotalTLH" :key="ind" class="rt" data-f-bold="true">{{formatNumbers(t)}}</td>-->
<!--						</tr>-->
<!--						<tr class="subtitlerow">-->
<!--							<td data-f-bold="true">Total ARN Metro (Assigned)</td>-->
<!--							<td v-for="(mth, ind) in months" :key="ind" class="rt" data-f-bold="true">{{formatNumbers(getMyVal(tlh, 9999, mth))}}</td>-->
<!--						</tr>-->
						<tr v-for="stn in regstncodes" :key="stn.id">
							<td data-f-bold="true">{{stn.nice}}</td>
							<td v-for="(mth, ind) in months" :key="ind" class="rt">{{formatNumbers(getMyVal(tlh_regstn, stn.id, mth))}}</td>
						</tr>
						<tr class="subtitlerow">
							<td data-f-bold="true">ARN Total</td>
							<td v-for="(mth, ind) in months" :key="ind" class="rt" data-f-bold="true">{{formatNumbers(getMyVal(tlh, 9998, mth))}}</td>
						</tr>
<!--						<tr class="bold subtitlerow">-->
<!--							<td data-f-bold="true">ARN Grand Total</td>-->
<!--							<td v-for="(mth, ind) in months" :key="ind" class="rt" data-f-bold="true">{{grandTotalsTLH[ind]}}</td>-->
<!--						</tr>-->
					</tbody>
				</table>
			</div>

			<div :class="{hidden : activeTable !== 'cume'}">
				<table v-if="cumes && cumes[0] && cumes[0].data" class="altbl" id="ihrtblCume" :class="{hideLY : hideLY}">
					<thead>
					<tr>
						<td class="headingtext"></td>
						<td class="headingtext rt" v-for="(t, ind) in cumes[0]['data']" :key="ind" data-f-bold="true">{{t.x}}</td>
					</tr>
					</thead>
					<tbody>
<!--					<tr v-for="stnid in amfms" :key="stnid">-->
<!--						<td data-f-bold="true">{{cumes[stnid].name}}</td>-->
<!--						<td v-for="(mth, ind) in months" :key="ind" class="rt">{{formatNumbers(getMyVal(cumes, stnid, mth))}}</td>-->
<!--					</tr>-->
<!--					<tr v-for="stnid in otherMetros" :key="stnid">-->
<!--						<td data-f-bold="true">{{tlh[stnid].name}}</td>-->
<!--						<td v-for="(mth, ind) in months" :key="ind" class="rt">{{formatNumbers(getMyVal(cumes, stnid, mth))}}</td>-->
<!--					</tr>-->
<!--					<tr class="subtitlerow">-->
<!--						<td data-f-bold="true">Total ARN Metro (Assigned / Deduped)</td>-->
<!--						<td v-for="(mth, ind) in months" :key="ind" data-f-bold="true" class="rt">{{formatNumbers(getMyVal(cumes, 9999, mth))}}</td>-->
<!--					</tr>-->
					<tr v-for="stn in regstncodes" :key="stn.id">
						<td data-f-bold="true">{{stn.nice}}</td>
						<td v-for="(mth, ind) in months" :key="ind" class="rt">{{formatNumbers(getMyVal(cumes_regstn, stn.id, mth))}}</td>
					</tr>
					<tr class="subtitlerow">
						<td data-f-bold="true">ARN Total</td>
						<td v-for="(mth, ind) in months" data-f-bold="true" :key="ind" class="rt">{{formatNumbers(getMyVal(cumes, 9998, mth))}}</td>
					</tr>
<!--					<tr class="bold subtitlerow">-->
<!--						<td data-f-bold="true">ARN Metro + Regional</td>-->
<!--						<td v-for="(mth, ind) in months" data-f-bold="true" :key="ind" class="rt">{{grandTotalsCume[ind]}}</td>-->
<!--					</tr>-->
					</tbody>
				</table>
			</div>

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import PermissionChecker from "@/components/PermissionChecker";
import TableToExcel from "@linways/table-to-excel";

export default {
	name: 'StreamingIHRTable',
	components: {
		PermissionChecker,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','content-metro','connections','content-reg'],

			getChartsBtnWorking: false,
			activeTable: 'tlh', //tlh or cume

			tlh: [],
			amfmsubtotalTLH: [],
			nonAmfmsubtotalTLH: [],
			cumes: [],
			amfmsubtotalCume: [],
			nonAmfmsubtotalCume: [],
			months: [],
			commasInNumbers: true,
			hideLY: true,
			lastDateWithDataMetro: 0,

			tlh_regstn: [],
			cumes_regstn: [],
			regstncodes: [],

			amfms: [1,2,3,4,5,6,7,8,9,10,11,12],

			grandTotalsTLH: [],
			grandTotalsCume: [],

			calcValsMetro: [],
			calcValsReg: [],

		}
	},
	computed: {
		otherMetros() {
			let x = [];
			for(let t of this.tlh) {
				let stnid = parseInt(t.stnid);
				if(stnid > 0 && stnid < 9000 && !this.amfms.includes(stnid)) {
					x.push(stnid);
				}
			}
			return x;
		},
		endOfLastMonth() {
			let date = new Date();
			date.setDate(0); //remember month index starts at 0 so need to +1 below to get 9=sep eg
			return date.getFullYear().toString() + '-' + (date.getMonth()+1).toString() + '-' + date.getDate().toString()
		},
	},
	methods: {
		exportToExcel(tblid, filename = 'export') {
			TableToExcel.convert(document.getElementById(tblid), {
				name: filename+".xlsx",
				sheet: {
					name: 'Sheet 1'
				}
			});
		},
		getMyVal(arr, stnid, mth) {
			let ob = arr.find(item => item.stnid === stnid);
			if(ob) {
				let obb = ob.data.find(item => item.x === mth);
				if(obb) {
					return obb.y;
				}
			}
			return 0;
		},
		formatNumbers(num) {
			if(num != undefined) {
				if (this.commasInNumbers === true) {
					return num.toLocaleString();
				}
			}
			return num;
		},
		// getChartDataOld() {
		// 	this.getChartsBtnWorking = true;
		// 	var targ = this.store.streamingApiUrl + "api/arnstats/get-ihr-tables.php";
		// 	var self = this;
		// 	this.chartsData = [];
		// 	axios.get(targ).then(function (response) {
		// 		var ret = response.data; //response.data is returned info
		// 		if (ret.error === 1) { console.log(ret); }
		// 		else {
		// 			if (ret.tlh != null) self.tlh = ret.tlh;
		// 			if (ret.amfmsubtotalTLH != null) self.amfmsubtotalTLH = ret.amfmsubtotalTLH;
		// 			if (ret.nonAmfmsubtotalTLH != null) self.nonAmfmsubtotalTLH = ret.nonAmfmsubtotalTLH;
		// 			if (ret.cumes != null) self.cumes = ret.cumes;
		// 			if (ret.amfmsubtotalCume != null) self.amfmsubtotalCume = ret.amfmsubtotalCume;
		// 			if (ret.nonAmfmsubtotalCume != null) self.nonAmfmsubtotalCume = ret.nonAmfmsubtotalCume;
		// 			if (ret.months != null) self.months = ret.months;
		// 			self.lastDateWithDataMetro = ret.lastDate; //need this to make sure regionals don't get newer data than metros
		// 			self.calcValsMetro = ret.calcValsMetro;
		// 			self.getRegionalData();
		// 		}
		// 		self.getChartsBtnWorking = false;
		// 	}).catch(function (error) {
		// 		console.log(error);
		// 	});
		// },
		getChartData() {
			this.getChartsBtnWorking = true;
			let targ = "/api/streaming-get-gb-ihrtbls.php?until="+this.endOfLastMonth
			let self = this;
			this.chartsData = [];
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.tlh != null) {
						self.tlh.push(ret.tlh);
						let mths = []
						for(let m of ret.tlh.data) {
							mths.push(m.x)
						}
						self.months = mths
					}
					if (ret.cumes != null) self.cumes.push(ret.cumes);
					if (ret.regstncodes != null) self.regstncodes = ret.stations;
					if (ret.tlh_stn != null) self.tlh_regstn = ret.tlh_stn;
					if (ret.cumes_stn != null) self.cumes_regstn = ret.cumes_stn;
					self.calcValsReg = ret.calcValsReg;
				}
				// //handle grand totals for TLH
				// let gt = [];
				// let dataForMetro = self.tlh.find(item => item.stnid === 9999);
				// let metroIndex = self.tlh.findIndex(item => item.stnid === 9999);
				// let dataForReg = self.tlh.find(item => item.stnid === 9998);
				// for(let mthind=0; mthind<self.tlh[metroIndex].data.length-4; mthind++) {
				// 	let myval = dataForMetro.data[mthind].y;
				// 	let regrow = dataForReg.data.find(item => item.x === dataForMetro.data[mthind].x);
				// 	if(regrow) myval += regrow.y;
				// 	gt.push(self.formatNumbers(myval));
				// }
				// //now do percentages for TLH
				// let m = self.calcValsMetro;
				// let r = self.calcValsReg;
				// let latestMonth = m.tlhLatestMonth + r.tlhLatestMonth;
				// let janMonth = m.tlhJanMonth + r.tlhJanMonth;
				// gt.push(Math.round((latestMonth - janMonth) / janMonth * 100)+"%");
				// let mthLastYear = m.tlhLYMonth + r.tlhLYMonth;
				// gt.push(Math.round((latestMonth - mthLastYear) / mthLastYear * 100)+"%");
				// let prvMth = m.tlhPrevMth + r.tlhPrevMth;
				// gt.push(Math.round((latestMonth - prvMth) / prvMth * 100)+"%");
				// let thisYTD = m.tlhThisYTD + r.tlhThisYTD;
				// let prevYTD = m.tlhPrevYTD + r.tlhPrevYTD;
				// gt.push(Math.round((thisYTD - prevYTD) / prevYTD * 100)+"%");
				// self.grandTotalsTLH = gt;
				//
				// //handle grand totals for Cume
				// gt = [];
				// dataForMetro = self.cumes.find(item => item.stnid === 9999);
				// metroIndex = self.cumes.findIndex(item => item.stnid === 9999);
				// dataForReg = self.cumes.find(item => item.stnid === 9998);
				// for(let mthind=0; mthind<self.cumes[metroIndex].data.length-4; mthind++) {
				// 	let myval = dataForMetro.data[mthind].y;
				// 	let regrow = dataForReg.data.find(item => item.x === dataForMetro.data[mthind].x);
				// 	if(regrow) myval += regrow.y;
				// 	gt.push(self.formatNumbers(myval));
				// }
				// //now do percentages for Cume
				// latestMonth = m.cumeLatestMonth + r.cumeLatestMonth;
				// janMonth = m.cumeJanMonth + r.cumeJanMonth;
				// gt.push(Math.round((latestMonth - janMonth) / janMonth * 100)+"%");
				// mthLastYear = m.cumeLYMonth + r.cumeLYMonth;
				// gt.push(Math.round((latestMonth - mthLastYear) / mthLastYear * 100)+"%");
				// prvMth = m.cumePrevMth + r.cumePrevMth;
				// gt.push(Math.round((latestMonth - prvMth) / prvMth * 100)+"%");
				// thisYTD = m.cumeThisYTD + r.cumeThisYTD;
				// prevYTD = m.cumePrevYTD + r.cumePrevYTD;
				// gt.push(Math.round((thisYTD - prevYTD) / prevYTD * 100)+"%");
				// self.grandTotalsCume = gt;

				self.getChartsBtnWorking = false;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {

	},
	mounted() {

	},
	created() {
		this.getChartData();
		document.title = "ARN IHR Streaming by Station";
	},
}
</script>

<style scoped>
.hidden {
	display: none;
}
.netshortcut {
	display: inline-block;
	border: 1px solid #DDD;
	background: #EAEAEA;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	margin: 0 10px 10px 0;
	padding: 6px 8px;
	border-radius: 5px;
	font-size: 11px;
}
.netshortcut:hover {
	background-color: #DDD;
}
table.hideLY tr td:nth-child(n+2):nth-child(-n+13) {
	display: none;
}
</style>
