<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div style="margin-bottom: 0px;"><p>Shaded columns denote future month targets.</p></div>
		<div v-if="userHasPermission(viewPerms)">
			<div v-if="summaryData.length > 0 && months.length > 0">
				<IHRRegionalTable :months="months" :summary_data="summaryData" title="IHR Regional Summary"></IHRRegionalTable>
			</div>

			<div v-if="stnGroupData.length > 0 && months.length > 0">
				<div v-for="(stater, ind) in stnGroupData" :key="ind">
					<IHRRegionalTable :months="months" :summary_data="stater.stationData" :title="stater.ob.state"></IHRRegionalTable>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import PermissionChecker from "@/components/PermissionChecker";
import IHRRegionalTable from "@/components/bits/IHRRegionalTable";

export default {
	name: 'IHRRegionalKPITable',
	components: {
		IHRRegionalTable,
		PermissionChecker,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','content-reg','connections','iheart'],

			getChartsBtnWorking: false,

			summaryData: [],
			stnGroupData: [],

			months: [],
			commasInNumbers: true,

		}
	},
	computed: {

	},
	methods: {
		getData() {
			this.getChartsBtnWorking = true;
			let targ = "/api/iheart/get-regional-summary.php";
			let self = this;
			this.summaryData = [];
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.summaryData != null) self.summaryData = ret.summaryData;
					if (ret.months != null) self.months = ret.months;
					if (ret.stnGroupData != null) self.stnGroupData = ret.stnGroupData;
				}
				self.getChartsBtnWorking = false;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {

	},
	mounted() {
		this.getData()
	},
	created() {
		document.title = "IHR Regional KPI Tables";
	},
}
</script>

<style scoped>

</style>
