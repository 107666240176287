<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">
			<div style="display: flex; justify-content: flex-start; gap: 15px;">
				<div>
					<select class="select-styled" v-model="chosenType">
						<option value="radio">Radio</option>
						<option value="stream">Streaming</option>
						<option value="all">Everything</option>
					</select>
				</div>
				<div>
					<select class="select-styled" v-model="cadence">
						<option value="mth">Monthly</option>
						<option value="qtr">Quarterly</option>
						<option value="yr">Yearly</option>
					</select>
				</div>
				<div>
					<select class="select-styled" v-model="showAsPercentage">
						<option :value="false">Spend ($ 000s)</option>
						<option :value="true">Percentages</option>
					</select>
				</div>
				<div v-if="chosenType === 'radio'">
					<select class="select-styled" v-model="chosenMarket">
						<option v-for="opt in markets" :key="opt.id" :value="opt.id">{{opt.name}}</option>
					</select>
				</div>
<!--				<div><span class="primary-btn" @click="getChartData">Fetch</span></div>-->
			</div>
			<div style="margin-top: 15px;">
				<div style="display: inline-block; margin-bottom: 8px;">
					<span class="netshortcut" @click="toggleMediaTypes">All Media</span>
				</div>
				<div v-for="mt in mediaTypes" :key="mt" style="display: inline-block; margin-bottom: 8px;">
					<input style="margin-right: 5px;" type="checkbox" v-model="selectedMediaTypes" :id="'mt'+mt" :value="mt" />
					<label style="margin-right: 15px;" :for="'mt'+mt">{{mt}}</label>
				</div>
			</div>

			<!-- all except for SMBAP radio -->
			<div v-if="chosenType !== 'radio' || chosenMarket !== 21" style="margin-top: 0;">
				<div style="display: inline-block; margin-bottom: 0;">
					<span class="netshortcut" @click="toggleAllStations">All Brands</span>
				</div>
				<div v-for="mt in availableStationSelections" :key="mt.stnname" style="display: inline-block; margin-bottom: 8px;">
					<input style="margin-right: 5px;" type="checkbox" v-model="selectedStations" :id="'mt'+mt.stnname" :value="mt.stnname" />
					<label style="margin-right: 15px;" :for="'mt'+mt.stnname">{{mt.stnname.toUpperCase()}}</label>
				</div>
			</div>
			<div v-else style="margin-top: 0;"> <!-- SMBAP radio only -->
				<div style="display: inline-block; margin-bottom: 0;">
					<span class="netshortcut" @click="toggleAllNetworks">All Networks</span>
				</div>
				<div v-for="mt in availableSMBAPRadioNetworks" :key="mt.stnname" style="display: inline-block; margin-bottom: 8px;">
					<input style="margin-right: 5px;" type="checkbox" v-model="selectedStations" :id="'mt'+mt.stnname" :value="mt.stnname" />
					<label style="margin-right: 15px;" :for="'mt'+mt.stnname">{{mt.stnname.toUpperCase()}}</label>
				</div>
				<br/>
				<div style="display: inline-block; margin-bottom: 0;">
					<span class="netshortcut" @click="toggleAllSubNetworks">All Sub-Networks</span>
				</div>
				<div v-for="mt in availableSMBAPRadioSubNetworks" :key="mt.stnname" style="display: inline-block; margin-bottom: 8px;">
					<input style="margin-right: 5px;" type="checkbox" v-model="selectedStations" :id="'mt'+mt.stnname" :value="mt.stnname" />
					<label style="margin-right: 15px;" :for="'mt'+mt.stnname">{{mt.stnname.toUpperCase()}}</label>
				</div>
			</div>

			<div class="chart-rows">
				<ChartHolderDash :charttitle="mainChartTitle" source="AdEx" :height="500">
					<template v-slot:default>
						<apexchart v-if="sovApexDataFiltered.length > 0" width="99%" height="100%" type="line"
							:options="chartoptions" :series="sovApexDataFiltered"></apexchart>
						<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
					</template>
				</ChartHolderDash>
			</div>

			<div class="chart-rows">
				<ChartHolderDash :charttitle="pieLatestTitle" source="AdEx" :addclasses="'half'">
					<template v-slot:default>
						<ApexPie :chartdata="pieLatestDataFiltered" :labels="pieLatestLabelsFiltered" :usedefaultcolors="true" filename="LatestPeriod" :fetching="getChartsBtnWorking"></ApexPie>
					</template>
				</ChartHolderDash>
				<ChartHolderDash :charttitle="piePrevTitle" source="AdEx" :addclasses="'half'">
					<template v-slot:default>
						<ApexPie :chartdata="piePrevDataFiltered" :labels="pieLatestLabelsFiltered" :usedefaultcolors="true" filename="PrevPeriod" :fetching="getChartsBtnWorking"></ApexPie>
					</template>
				</ChartHolderDash>
			</div>

			<div style="display: flex; justify-content: flex-start; gap: 15px;">
				<div>
					<select class="select-styled" v-if="sovApexDataFiltered.length > 0" v-model="selectedStation">
						<option v-for="(stn, index) in sovApexDataFiltered" :key="index" :value="stn">{{stn.name}}</option>
					</select>
				</div>
			</div>

			<div class="chart-rows">
				<ChartHolderDash :charttitle="stationChartTitle" source="AdEx" :height="500">
					<template v-slot:default>
						<apexchart v-if="stnApexData.length > 0" width="99%" height="100%" type="bar"
								:options="chartoptionsStn" :series="stnApexData"></apexchart>
						<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
					</template>
				</ChartHolderDash>
			</div>

			<div v-if="showRatingsChart" class="chart-rows">
				<ChartHolderDash :charttitle="ratingsChartTitle" source="AdEx / GFK" :height="500">
					<template v-slot:default>
						<apexchart v-if="ratingsApexData.length > 0" width="99%" height="100%" type="line"
							:options="ratingsChartoptionsStn" :series="ratingsApexData"></apexchart>
						<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
					</template>
				</ChartHolderDash>
			</div>

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import PermissionChecker from "@/components/PermissionChecker";
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import ApexPie from "@/components/charts/ApexPie";
export default {
	name: "ShareOfVoice",
	components: {
		ApexPie,
		PermissionChecker,
		ChartLoaderInsert,
		ChartHolderDash,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			viewPerms: ['elt','connections'],
			getChartsBtnWorking: false,

			chosenMarket: 5,
			markets: [
				{id: 5, name: 'Sydney'},
				{id: 4, name: 'Melbourne'},
				{id: 3, name: 'Brisbane'},
				{id: 2, name: 'Adelaide'},
				{id: 1, name: 'Perth'},
				{id: 23, name: 'Gold Coast'},
				{id: 21, name: 'SMBAPGC'},
			],
			cadence: 'qtr', //mth, qtr, yr
			chosenType: 'radio', //radio, streaming, all
			mediaTypes: [],
			selectedMediaTypes: [],
			allMediaSelected: true,
			showAsPercentage: false,
			selectedStations: [],

			sovApexData: [],
			mainChartTitle: 'Advertising Spend',
			chartoptions: {
				chart: {
					id: "share_of_voice",
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					}
				},
				xaxis: {
					type: "category",
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 1,
					min: 0,
				},
				colors: [],
				stroke: {
					curve: "straight",
					width: 3,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
			},

			pieLatestData: [],
			pieLatestLabels: [],
			piePrevData: [],
			pieLatestTitle: '',
			piePrevTitle: '',

			//secondary chart - single station/network by spend type
			selectedStation: null,
			stnApexData: [],
			stationChartTitle: 'Spend by Media Type',
			chartoptionsStn: {
				chart: {
					id: "spend_by_media",
					stacked: true,
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					}
				},
				xaxis: {
					type: "category",
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 1,
					min: 0,
				},
				colors: [],
				stroke: {
					curve: "straight",
					// width: 3,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
			},

			//single station + gfk overlay chart
			ratingsApexData: [],
			ratingsChartTitle: 'Spend vs GFK 10+ Cume',
			ratingsChartoptionsStn: {
				chart: {
					id: "spend_vs_ratings",
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					}
				},
				xaxis: {
					type: "category",
					categories: [],
					tickAmount: 10,
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: [
					{title: {text: 'Spend'}, decimalsInFloat: 0, min: 0},
					{opposite: true, title: {text: '10+ Cume 000s'}, decimalsInFloat: 0, min: 0},
				],
				colors: [],
				stroke: {
					curve: "straight",
					width: 3,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
			},

			radio_stations: [],
			stream_stations: [],
			everything_stations: [],
		}
	},
	computed: {
		initialObjectsReceived() {
			return this.store.initialObjectsReceived;
		},
		stnlookupval() {
			if(this.chosenType === 'radio') {
				if (this.chosenMarket === 21) return this.selectedStation.subnet;
				else return this.selectedStation.stnid;
			}
			else return this.selectedStation.network;
		},
		showRatingsChart() {
			if(this.chosenType === 'radio' && this.chosenMarket !== 21) return true;
			return false;
		},
		availableStationSelections() {
			if(this.chosenType === 'radio') {
				return this.radio_stations.filter(item => item.mktid === this.chosenMarket);
			}
			else if(this.chosenType === 'stream') {
				return this.stream_stations;
			}
			else { //everything
				return this.everything_stations;
			}
		},
		availableSMBAPRadioNetworks() {
			if(this.chosenType === 'radio' && this.chosenMarket === 21) {
				return this.radio_stations.filter(item => item.mktid === this.chosenMarket && item.type === 'network');
			}
			return [];
		},
		availableSMBAPRadioSubNetworks() {
			if(this.chosenType === 'radio' && this.chosenMarket === 21) {
				return this.radio_stations.filter(item => item.mktid === this.chosenMarket && item.type === 'subnet');
			}
			return [];
		},
		sovApexDataFiltered() {
			let t = [];
			for(let ob of this.sovApexData) {
				if(this.chosenType === 'radio' && this.chosenMarket !== 21) {
					if (this.selectedStations.includes(ob.name)) t.push(ob);
				}
				else {
					if (this.selectedStations.includes(ob.name.toLowerCase())) t.push(ob);
				}
			}
			return t;
		},
		chosenStationIndex() {
			return this.sovApexData.findIndex(item => item.stnid === this.selectedStation.stnid);
		},
		selectedStationIndexes() {
			let inds = []
			let counter = 0
			for(let lab of this.pieLatestLabels) {
				if (this.selectedStations.includes(lab) || this.selectedStations.includes(lab.toLowerCase())) { //labels
					inds.push(counter)
				}
				counter++
			}
			return inds
		},
		pieLatestDataFiltered() {
			return this.pieLatestData.filter((item, index) => this.selectedStationIndexes.includes(index))
		},
		pieLatestLabelsFiltered() {
			return this.pieLatestLabels.filter((item, index) => this.selectedStationIndexes.includes(index))
		},
		piePrevDataFiltered() {
			return this.piePrevData.filter((item, index) => this.selectedStationIndexes.includes(index))
		},
	},
	methods: {
		toggleMediaTypes() {
			if(this.allMediaSelected === true) { //toggle to false
				this.selectedMediaTypes = [];
				this.allMediaSelected = false;
			}
			else {
				this.selectedMediaTypes = this.mediaTypes;
				this.allMediaSelected = true;
			}
		},
		toggleAllStations() {
			if(this.selectedStations.length === this.availableStationSelections.length) this.selectedStations = [];
			else this.putAllMarketStationsIntoSelectedStations();
		},
		toggleAllNetworks() {
			if(this.selectedStations.length === this.availableSMBAPRadioNetworks.length) this.selectedStations = [];
			else this.putAllMarketStationsIntoSelectedStations('network');
		},
		toggleAllSubNetworks() {
			if(this.selectedStations.length === this.availableSMBAPRadioSubNetworks.length) this.selectedStations = [];
			else this.putAllMarketStationsIntoSelectedStations('subnet');
		},
		putAllMarketStationsIntoSelectedStations(filtertype = 'network') {
			let tempstns = [];
			let avails = this.availableStationSelections;
			if(filtertype === 'network' && this.chosenMarket === 21 && this.chosenType === 'radio') avails = this.availableSMBAPRadioNetworks;
			else if(filtertype === 'subnet') avails = this.availableSMBAPRadioSubNetworks;
			for(let st of avails) {
				tempstns.push(st.stnname);
			}
			this.selectedStations = tempstns;
		},
		getMeta() {
			let self = this;
			let targ = "/api/share-of-voice/get-meta.php";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.mediaTypes != null) {
						self.mediaTypes = ret.mediaTypes;
						self.selectedMediaTypes = ret.mediaTypes;
						self.radio_stations = ret.radio_stations;
						self.stream_stations = ret.stream_stations;
						self.everything_stations = ret.networks_everything;
						if(self.selectedStations.length === 0) {
							self.putAllMarketStationsIntoSelectedStations();
						}
					}
				}
				self.getChartData();
			}).catch(function (error) {
				console.log(error);
			});
		},
		getChartData() {
			if(this.getChartsBtnWorking === false) {
				this.getChartsBtnWorking = true;
				let self = this;
				let targ = "/api/share-of-voice/share-of-voice-mkt.php?";
				targ += "cadence="+this.cadence+"&mktid="+this.chosenMarket+"&type="+this.chosenType+"&mediaTypes="+this.selectedMediaTypes+"&showPercent="+this.showAsPercentage;
				this.chartsData = [];
				axios.get(targ).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.error === 1) { console.log(ret); }
					else {
						if (ret.chartsData != null) {
							self.sovApexData = ret.chartsData;
							let myitemIndex = 0;
							if (!self.selectedStation || ret.chartsData.findIndex(item => item.name === self.selectedStation.name) === -1) {
								myitemIndex = 0;
							}
							else {
								myitemIndex = ret.chartsData.findIndex(item => item.name === self.selectedStation.name);
							}
							self.selectedStation = ret.chartsData[myitemIndex];
							self.ratingsApexData = [ret.chartsData[myitemIndex]];
						}
						if (ret.chartTitle != null) self.mainChartTitle = ret.chartTitle;
						self.pieLatestLabels = ret.pieLabels;
						self.pieLatestData = ret.pieLatestData;
						self.piePrevData = ret.piePrevData;
						self.pieLatestTitle = ret.pieLatestTitle;
						self.piePrevTitle = ret.piePrevTitle;
					}
					self.getChartsBtnWorking = false;
					self.getStationChartData();
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		getStationChartData() {
			if(this.getChartsBtnWorking === false) {
				this.getChartsBtnWorking = true;
				let self = this;
				let targ = "/api/share-of-voice/station-single.php?";
				targ += "namelook="+this.stnlookupval+"&cadence="+this.cadence+"&mktid="+this.chosenMarket+"&type="+this.chosenType+"&mediaTypes="+this.selectedMediaTypes+"&showPercent="+this.showAsPercentage;
				this.chartsData = [];
				axios.get(targ).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.error === 1) { console.log(ret); }
					else {
						if (ret.chartsData != null) self.stnApexData = ret.chartsData;
						if (ret.chartTitle != null) self.stationChartTitle = ret.chartTitle;
					}
					self.getChartsBtnWorking = false;
					if(self.showRatingsChart === true) self.getRatingsData();
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		getRatingsData() {
			if(this.getChartsBtnWorking === false) {
				let oldstnid = this.selectedStation.stnid;
				let newstnob = this.radio_stations.find(item => item.stnid === oldstnid)
				if(newstnob) {
					let stnid = newstnob.audstnid;
					let self = this;
					let sets = []
					let surveylistobs = this.getSurveyObsForMarket(newstnob.audmktid)
					let surveylistIds = this.getSurveyIdsFromSurveyObs(surveylistobs)
					sets.push({
						type: 'standard',
						dayparts: [1],
						demos: [48],
						statistics: ['cume'],
						surveylist: surveylistIds,
						stationIds: [stnid],
						market: newstnob.audmktid,
						audioTypeIds: [0],
					})
					let mktcode = ''
					if(newstnob.audmktid === 110) mktcode = 'SY'
					else if(newstnob.audmktid === 111) mktcode = 'ME'
					else if(newstnob.audmktid === 112) mktcode = 'BR'
					else if(newstnob.audmktid === 113) mktcode = 'AD'
					else if(newstnob.audmktid === 114) mktcode = 'PE'
					else if(newstnob.audmktid === 427) mktcode = 'GT'
					this.doGetCacheDataAudology(sets).then(function (ret) {
						if (ret.error === 1) {
							console.log(ret);
						}
						else if (ret.returnedData) {
							let tempdata = [];
							let olddata = [self.sovApexData[self.chosenStationIndex]];
							for (let s = 0; s < self.ratingsApexData[0].data.length; s++) {
								let lookupsurvarr = self.ratingsApexData[0].data[s].survmap.split('s');
								let lookupsurvey = 's' + lookupsurvarr[1] + ' ' + lookupsurvarr[0].replace('y', '');
								let myitem = self.getMostRecentSurveyPriorTo(lookupsurvey, ret.returnedData, mktcode);
								if (myitem && myitem.data && myitem.data[0]) {
									tempdata.push({x: self.ratingsApexData[0].data[s].x, y: myitem.data[0].val});
								}
							}
							self.ratingsApexData = [
								{name: olddata[0].name + ' Marketing spend', data: olddata[0].data},
								{name: 'GFK Cume', data: tempdata}
							];
						}
						self.getChartsBtnWorking = false;
					});
				}
			}
		},
		getMostRecentSurveyPriorTo(lookupsurvey, dataset, mktcode) {
			for(let i=0; i<8; i++) {
				let myitem = dataset.find(item => item.survcode === mktcode+lookupsurvey);
				if(myitem) {
					return myitem;
				}
				else {
					let la = lookupsurvey.split(" ");
					let yr = parseInt(la[1]);
					let surv = parseInt(la[0].replace('s',''));
					if(surv === 1) {
						yr = yr - 1;
						lookupsurvey = yr+"08";
					}
					else {
						surv = surv - 1;
						lookupsurvey = yr+"0"+surv
					}
				}
			}
		},
	},
	watch: {
		initialObjectsReceived() {
			if(this.initialObjectsReceived === true) {
				this.getMeta();
			}
		},
		selectedStation() {
			this.getStationChartData();
		},
		chosenType() {
			this.putAllMarketStationsIntoSelectedStations(); //change to active market
			this.getChartData();
		},
		cadence() {
			this.getChartData();
		},
		showAsPercentage() {
			this.getChartData();
		},
		chosenMarket() {
			this.putAllMarketStationsIntoSelectedStations(); //change to active market
			this.getChartData();
		},
		selectedMediaTypes() {
			if(this.selectedMediaTypes.length > 0) {
				this.getChartData();
			}
		},
	},
	mounted() {
		document.title = 'Marketing Share of Voice';
		if(this.initialObjectsReceived) {
			this.getMeta();
		}
		this.chartoptions.colors = this.store.defaultColors
		this.chartoptionsStn.colors = this.store.defaultColors
		this.ratingsChartoptionsStn.colors = this.store.defaultColors
	}
}
</script>

<style scoped>
.netshortcut {
	display: inline-block;
	border: 1px solid #DDD;
	background: #EAEAEA;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	margin: 0 10px 10px 0;
	padding: 7px 10px;
	border-radius: 5px;
}
.netshortcut:hover {
	background-color: #DDD;
}
</style>