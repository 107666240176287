<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">
			<div class="chart-rows">
				<PodListenerWarn></PodListenerWarn>
				<ChartHolderDash
						:charttitle="'Category Summary'"
						:source="chartsSource.mthtbl" :height="440">
					<div class="b-table-holder">
						<ALTable tblclasses="noborderv" :excel="true" excelfilename="Podcast Categories"
							:cols="[
								{title: 'Category', sortable: true, fldid: 'cat'},
								{title: 'Current Downloads', sortable: true, fldid: 'mthdl', numtype: 'number'},
								{title: 'Prev Downloads', sortable: true, fldid: 'mthprvdl', numtype: 'number'},
								{title: 'Downloads MoM', sortable: true, fldid: 'pctdiff', numtype: 'pctbubble'},
								{title: 'Downloads YoY Change', sortable: true, fldid: 'pctdiffyoy', numtype: 'pctbubble'},
								{title: 'Current Listeners', sortable: true, fldid: 'mthlist', numtype: 'number'},
								{title: 'Prev Listeners', sortable: true, fldid: 'mthprvlist', numtype: 'number'},
								{title: 'Listeners MoM', sortable: true, fldid: 'pctdifflist', numtype: 'pctbubble'},
								{title: 'Listeners YoY Change', sortable: true, fldid: 'pctdiffyoylist', numtype: 'pctbubble'},
							]"
							:rowdata="chartsData.mthtbl"
						></ALTable>
					</div>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</ChartHolderDash>

				<ChartHolderDash
						:charttitle="'Category Listeners - latest month'"
						:source="chartsSource.monthly_listeners_by_cat" :addclasses="'half'">
					<apexchart v-if="apexdata.monthly_listeners_by_cat" width="99%" height="100%" type="pie"
						:options="pieChartOptions" :series="apexdata.monthly_listeners_by_cat"></apexchart>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</ChartHolderDash>

				<ChartHolderDash
						:charttitle="'Category Downloads - latest month'"
						:source="chartsSource.monthly_downloads_by_cat" :addclasses="'half'">
					<apexchart v-if="apexdata.monthly_downloads_by_cat" width="99%" height="100%" type="pie"
						:options="pieChartOptions" :series="apexdata.monthly_downloads_by_cat"></apexchart>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</ChartHolderDash>

				<ChartHolderDash
						:charttitle="'Category: Arts - Monthly Trending '"
						:source="chartsSource.weekly_cat_dl_Arts" :addclasses="'half'">
					<apexchart v-if="apexdata.weekly_cat_dl_Arts" width="99%" height="100%" type="line"
						:options="chartoptions" :series="apexdata.weekly_cat_dl_Arts"></apexchart>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</ChartHolderDash>

				<ChartHolderDash
						:charttitle="'Category: Business - Monthly Trending '"
						:source="chartsSource.weekly_cat_dl_Business" :addclasses="'half'">
					<apexchart v-if="apexdata.weekly_cat_dl_Business" width="99%" height="100%" type="line"
						:options="chartoptions" :series="apexdata.weekly_cat_dl_Business"></apexchart>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</ChartHolderDash>

				<ChartHolderDash
						:charttitle="'Category: On-Demand Radio - Monthly Trending '"
						:source="chartsSource.weekly_cat_dl_OnDemand" :addclasses="'half'">
					<apexchart v-if="apexdata.weekly_cat_dl_OnDemand" width="99%" height="100%" type="line"
						:options="chartoptions" :series="apexdata.weekly_cat_dl_OnDemand"></apexchart>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</ChartHolderDash>

				<ChartHolderDash
						:charttitle="'Category: Comedy - Monthly Trending '"
						:source="chartsSource.weekly_cat_dl_Comedy" :addclasses="'half'">
					<apexchart v-if="apexdata.weekly_cat_dl_Comedy" width="99%" height="100%" type="line"
						:options="chartoptions" :series="apexdata.weekly_cat_dl_Comedy"></apexchart>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</ChartHolderDash>

				<ChartHolderDash
						:charttitle="'Category: Education - Monthly Trending '"
						:source="chartsSource.weekly_cat_dl_Education" :addclasses="'half'">
					<apexchart v-if="apexdata.weekly_cat_dl_Education" width="99%" height="100%" type="line"
						:options="chartoptions" :series="apexdata.weekly_cat_dl_Education"></apexchart>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</ChartHolderDash>

				<ChartHolderDash
						:charttitle="'Category: Health - Monthly Trending '"
						:source="chartsSource.weekly_cat_dl_Health" :addclasses="'half'">
					<apexchart v-if="apexdata.weekly_cat_dl_Health" width="99%" height="100%" type="line"
						:options="chartoptions" :series="apexdata.weekly_cat_dl_Health"></apexchart>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</ChartHolderDash>

				<ChartHolderDash
						:charttitle="'Category: News - Monthly Trending '"
						:source="chartsSource.weekly_cat_dl_News" :addclasses="'half'">
					<apexchart v-if="apexdata.weekly_cat_dl_News" width="99%" height="100%" type="line"
						:options="chartoptions" :series="apexdata.weekly_cat_dl_News"></apexchart>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</ChartHolderDash>

				<ChartHolderDash
						:charttitle="'Category: Sports - Monthly Trending '"
						:source="chartsSource.weekly_cat_dl_Sports" :addclasses="'half'">
					<apexchart v-if="apexdata.weekly_cat_dl_Sports" width="99%" height="100%" type="line"
						:options="chartoptions" :series="apexdata.weekly_cat_dl_Sports"></apexchart>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</ChartHolderDash>

				<ChartHolderDash
						:charttitle="'Category: True Crime - Monthly Trending '"
						:source="chartsSource.weekly_cat_dl_True" :addclasses="'half'">
					<apexchart v-if="apexdata.weekly_cat_dl_True" width="99%" height="100%" type="line"
						:options="chartoptions" :series="apexdata.weekly_cat_dl_True"></apexchart>
					<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
				</ChartHolderDash>

			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import PodListenerWarn from "@/components/PodListenerWarn";
import PermissionChecker from "@/components/PermissionChecker";
import ALTable from "@/components/ALTable";

export default {
	name: 'DashPodcastCategories',
	components: {
		ALTable,
		PermissionChecker,
		PodListenerWarn,
		ChartLoaderInsert,
		ChartHolderDash,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','content-metro','connections','podcast'],
			chartId: 0,
			chartsData: {},
			chartsSource: [],
			getChartsBtnWorking: false,

			apexdata: {},
			chartoptions: {
				chart: {
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					},
					type: 'line',
				},
				fill: {
					gradient: {
						opacityFrom: 0.4,
						opacityTo: 0.2,
					},
				},
				xaxis: {
					type: "datetime",
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 0,
					min: 0,
					forceNiceScale: true,
					labels: {
						formatter: (value) => {
							if(value === null) return null
							return value.toLocaleString()
						},
					},
				},
				colors: [],
				stroke: {
					curve: "straight",
					width: 2,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 3,
				},
				tooltip: {
					x: {
						format: 'MMMM yyyy'
					}
				},
			},

			pieChartOptions: {
				chart: {
					width: 380,
					type: 'pie',
				},
				colors: [],
				labels: [],
				responsive: [{
					breakpoint: 480,
					options: {
						chart: {
							width: 200
						},
						legend: {
							position: 'bottom'
						}
					}
				}]
			},
		}
	},
	computed: {

	},
	methods: {
		getCharts() {
			this.getChartsBtnWorking = true;
			var self = this;
			var targ = "/api/podcast-category-charts.php";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					if (ret.chartsData != null) {
						self.chartsData = ret.chartsData;
					}
					if(ret.apexdata) {
						self.apexdata = ret.apexdata;
					}
					if(ret.apexlabels) {
						self.pieChartOptions.labels = ret.apexlabels.monthly_downloads_by_cat;
					}
					if (ret.chartsSource != null) {
						self.chartsSource = ret.chartsSource;
					}
					if (ret.h != null) {
						for (var key in ret.h) { //this updates any that exist, adds new if needed, but does not delete existing
							self.slotprops[key] = ret.h[key];
						}
					}
					//custom callback functions here if required (using pageaction as filter)
				}
				self.getChartsBtnWorking = false;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {},
	props: {

	},
	mounted() {
		this.getCharts();
		document.title = "Podcast Categories";
		this.chartoptions.colors = this.store.chartColourGroupARNFirst
		this.pieChartOptions.colors = this.store.chartColourGroupGradient
	},
	created() {

	},
}
</script>

<style scoped>

</style>
