<template>
	<div v-if="collatedMarketData.length > 0" style="width: 100%; height: 100%;">
		<ApexMFBar :horizontal="true" :showpctstacked="false" :chartdata="chartdata10mfsplit" :chartcolors="store.femaleMaleColors" :fetching="fetchingcharts"></ApexMFBar>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ApexMFBar from "@/components/arnmarkets/ApexMFBar";
export default {
	name: "PctCumeFemale",
	components: {
		ApexMFBar,
	},
	props: {
		source: String, //gfk or xtra
		indata: Array,
		stnids: Array,
		fetchingcharts: Boolean,
		mktob: Object,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			collatedMarketData: [], //xtra data

			chartdata10mfsplit: [],
		}
	},
	computed: {
		latestSurvOb() {
			return this.store.surveys.find(item => item.mktid === this.mktob.id);
		},
	},
	methods: {
		loadData() {
			this.collatedMarketData = this.indata;
			if(this.collatedMarketData.length > 0) this.prepareMFSplits();
		},
		prepareMFSplits() {
			let cdata = [];
			let demos = [50,49]; //female, male
			let dnames = ['Female','Male'];
			if(this.latestSurvOb) {
				for (let d = 0; d < demos.length; d++) {
					let cseries = {};
					cseries.name = dnames[d];
					cseries.data = [];
					for (let s = 0; s < this.stnids.length; s++) {
						let stid = this.stnids[s]
						let stnob = this.store.stations.find(item => item.id === stid);
						let ob = this.collatedMarketData.find(item => item.survcode === this.latestSurvOb.id && item.demoId === demos[d] && item.daypartId === 1 && item.statistic === 'cume');
						if (ob) {
							let cell = ob.data.find(item => item.stid === stid)
							if (cell && cell.val) {
								cseries.data.push({x: stnob.name, y: cell.val});
							}
						}
					}
					cdata.push(cseries);
				}
			}
			this.chartdata10mfsplit = cdata;
		},
	},
	watch: {
		indata() {
			this.loadData();
		}
	},
	mounted() {
		this.loadData();
	}
}
</script>

<style scoped>

</style>