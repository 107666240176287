<template>
	<div style="width: 100%; height: 100%;">
		<apexchart v-if="chartdata && chartdata.length > 0 && chartoptions.labels.length > 0" width="99%" height="100%" type="pie"
			:options="chartoptions" :series="chartdata"></apexchart>
		<ChartLoaderInsert v-if="fetching"></ChartLoaderInsert>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
export default {
	name: "ApexPie",
	components: {
		ChartLoaderInsert
	},
	props: {
		fetching: Boolean,
		categories: Array,
		chartdata: Array,
		filename: String,
		labels: Array,
		usedefaultcolors: Boolean,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			colours: [],
			chartoptions: {
				chart: {
					type: 'pie',
					animations: {
						enabled: false
					},
					toolbar: {
						show: true
					},
				},
				dataLabels: {
					enabled: true,
					distributed: true,
					formatter: function(val, opts) {
						const name = opts.w.globals.labels[opts.seriesIndex]
						return [name, val.toFixed(1) + '%']
					}
				},
				colors: this.colours,
				labels: [],
			}
		}
	},
	computed: {

	},
	methods: {
		updateLabels() { //need to do this manually as data is nested
			// var oldxaxis = this.chartoptions.xaxis;
			// oldxaxis.categories = this.categories;
			this.chartoptions = {...this.chartoptions, ...{
					labels: this.labels,
				}};
		},
	},
	watch: {
		labels() {
			this.updateLabels();
		},
	},
	mounted() {
		if(this.usedefaultcolors === true) this.chartoptions.colors = this.store.defaultColors;
		else this.chartoptions.colors = this.store.chartColourGroupGradient;
		this.updateLabels();
	}
}
</script>

<style scoped>

</style>