<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div>
			<div style="padding-top: 10px; display: flex; align-items: flex-end;">
				<div style="margin-right: 10px; z-index: 1">
					<p class="above-select-label">View Type</p>
					<select class="select-styled" v-model="viewType">
						<option value="charts">Charts</option>
						<option value="tables">Tables</option>
					</select>
				</div>
				<div v-if="viewType === 'tables'" style="margin-right: 10px; z-index: 1">
					<p class="above-select-label">Report Type</p>
					<select class="select-styled" v-model="reportType">
						<option value="revByStation">Revenue By Station</option>
					</select>
				</div>
				<div v-if="viewType === 'charts'" style="margin-right: 10px; z-index: 1">
					<p class="above-select-label">Chart Type</p>
					<select class="select-styled" v-model="chartType">
						<option value="revenue">Revenue Trend</option>
						<option value="revenue_normalised">Normalised Revenue Trend</option>
						<option value="rate">Rate Trend (30s Com)</option>
						<option value="foc_spot_pct">Free of Charts Spots Trend (30s Com)</option>
					</select>
				</div>
				<div style="margin-right: 10px; z-index: 1">
					<p class="above-select-label">Daypart</p>
					<select class="select-styled" v-model="daypartsChosen">
						<option value="Total">Total</option>
						<option value="BMAD">BMAD</option>
						<option value="Breakfast">Breakfast</option>
						<option value="Morning">Morning</option>
						<option value="Afternoon">Afternoon</option>
						<option value="Drive">Drive</option>
					</select>
				</div>
				<div v-if="viewType === 'tables'" style="margin-right: 10px; z-index: 1">
					<p class="above-select-label">Month</p>
					<select class="select-styled" v-model="chosenMonth">
						<option v-for="m in months" :key="m" :value="m">{{m}}</option>
					</select>
				</div>
				<div v-if="viewType === 'charts'" style="margin-right: 10px; z-index: 1">
					<p class="above-select-label">Station</p>
					<AutocompleterStringOnly v-if="stations.length > 0" :searchlist="stations" :incomingval="stationLookupText"
							idfldname="name" textfldname="name" v-on:update="chosenStation = $event"
					></AutocompleterStringOnly>
				</div>
				<div><span class="primary-btn" @click="fetchData(false)">View</span></div>
			</div>

			<div v-if="viewType === 'tables'" style="margin-top: 20px; ">
				<div v-if="!isFetching" style="padding: 20px 0;">
					<div v-if="tbldata.length === 0 && cols.length > 0" style="padding: 0 10px;"><p>Nothing to show</p></div>
					<div v-else>
						<ALTable :cols="cols" :rowdata="tbldata" :pagination="50"></ALTable>
					</div>
				</div>
				<div v-else style="padding: 20px 0; height: 300px; width: 500px;">
					<ChartLoaderInsert style="z-index: 9;"></ChartLoaderInsert>
				</div>
			</div>

			<div v-if="viewType === 'charts'" class="chart-rows" style="margin-top: 20px; ">
				<ChartHolderDash charttitle="" source="Aquira reconciled logs" :height="600">
					<template v-slot:default>
						<ApexBarTrendDateMonthlySingleSeries :chartdata="chartdata" filename="RandY" :fetching="isFetching"></ApexBarTrendDateMonthlySingleSeries>
					</template>
				</ChartHolderDash>
			</div>

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import AutocompleterStringOnly from "@/components/AutocompleterStringOnly";
import ALTable from "@/components/ALTable";
import PermissionChecker from "@/components/PermissionChecker";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import ChartHolderDash from "@/components/ChartHolderDash.vue";
import ApexBarTrendDateMonthlySingleSeries from "@/components/charts/ApexBarTrendDateMonthlySingleSeries.vue";

export default {
	name: "PostCampaignRYCharts",
	components: {
		ApexBarTrendDateMonthlySingleSeries,
		ChartHolderDash,
		ChartLoaderInsert,
		PermissionChecker,
		ALTable,
		AutocompleterStringOnly,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			viewPerms: ['elt','pcr-randy'],
			showStationSelectOverlay: false,

			viewType: 'charts', // 'tables', 'charts
			reportType: 'revByStation',
			chartType: 'revenue',
			chosenStation: null,
			chosenMonth: null,

			daypartsChosen: 'Total',
			stationLookupText: '',

			months: [],
			stations: [],
			tbldata: [],
			chartdata: [],

			isFetching: false,

		}
	},
	computed: {
		cols() {
			if(this.reportType === 'revByStation') {
				return [
					{title: 'Station', sortable: true, fldid: 'station'},
					{title: 'Revenue', sortable: true, fldid: 'revenue', numtype: 'dollars'},
					{title: 'N Spots', sortable: true, fldid: 'spots', numtype: 'number'},
					{title: 'N Spots (30s Com)', sortable: true, fldid: 'spots_30com', numtype: 'number'},
					{title: 'AUR / 30s', sortable: true, fldid: 'rate', numtype: 'dollars'},
					{title: 'Ratecard / 30s', sortable: true, fldid: 'ratecard_rate', numtype: 'dollars'},
					// {title: 'Avg mins/hr', sortable: true, fldid: 'avg_mins', numtype: 'number'},
				]
			}
			return []
		},
	},
	methods: {
		getMetaLists() {
			let self = this;
			let targ = "/api/post-campaign/get-summary-meta.php";
			this.isFetching = true
			axios.get(targ).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.error === 1) console.log(ret)
				else {
					if(ret.stations) {
						self.stations = ret.stations
						self.stationLookupText = self.stations[0].name
						self.chosenStation = self.stations[0]
					}
					if(ret.months) {
						self.months = ret.months.reverse()
						self.chosenMonth = self.months[0]
					}
				}
				self.isFetching = false
			}).catch(function (error) {
				self.isFetching = false
				console.log(error);
			});
		},
		fetchData() {
			let stnchoice = null
			if(this.chosenStation) stnchoice = this.chosenStation.name
			if(this.viewType === 'charts' && stnchoice === null) return false
			if(this.viewType === 'tables' && this.chosenMonth === null) return false
			let self = this
			let targ = "/api/post-campaign/rev-fetch-report.php"
			this.isFetching = true
			axios.post(targ, JSON.stringify({
				viewType: self.viewType,
				chartType: self.chartType,
				reportType: self.reportType,
				monthdate: self.chosenMonth,
				station: stnchoice,
				daypart: self.daypartsChosen,
			})).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.tbldata) self.tbldata = ret.tbldata
				else self.tbldata = []
				if (ret.chartdata) self.chartdata = ret.chartdata
				else self.chartdata = []
				self.isFetching = false
			}).catch(function () {
				self.isFetching = false
			});
		},
	},
	watch: {
		reportType() {
			this.fetchData()
		},
		chosenStation() {
			this.fetchData()
		},
		chosenMonth() {
			this.fetchData()
		},
		daypartsChosen() {
			this.fetchData()
		},
		viewType() {
			this.fetchData()
		},
		chartType() {
			this.fetchData()
		},
	},
	mounted() {
		document.title = 'R&Y Reports'
		this.getMetaLists()
		let d = new Date()
		this.enddate = d.getFullYear()+'-'+this.ldzero(d.getMonth()+1)+"-"+this.ldzero(d.getDate())
	}
}
</script>

<style scoped>

</style>