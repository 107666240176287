<template>
	<div>
		<div style="display: flex; justify-content: flex-start; gap: 15px;">
			<div class="market-summary">

				<div style="display: flex; justify-content: flex-start; align-items: flex-end; gap: 15px; margin: 10px 0 20px;">
					<div>
						<p class="above-select-label">Month</p>
						<select class="select-styled" v-model="chosenMonth">
							<option v-for="opt in months" :key="opt.id" :value="opt.id">{{opt.name}}</option>
						</select>
					</div>
					<div>
						<p class="above-select-label">Page</p>
						<AutocompleterStringOnly v-if="weblist.length > 0" :searchlist="weblist" :incomingval="chosenPage"
							idfldname="id" textfldname="name" v-on:update="updateChosenPageId($event)"
						></AutocompleterStringOnly>
					</div>


					<div><span class="primary-btn" @click="getChartData">Fetch</span></div>
				</div>

				<div class="chart-rows">
					<ALTable tblclasses="noborderv" :excel="true" excelfilename="WebPages"
						:searchbox="true" :pagination="100" :rowdata="retData"
						:cols="[
							{title: 'Page', sortable: true, fldid: 'pagepath'},
							{title: 'Views', sortable: true, fldid: 'pageviews', align: 'right', numtype: 'number'},
							{title: 'Unique Viewers', sortable: true, fldid: 'uniqueUsers', align: 'right', numtype: 'number'},						]"
					>
					</ALTable>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import AutocompleterStringOnly from "@/components/AutocompleterStringOnly";
import ALTable from "@/components/ALTable.vue";

export default {
	name: "WebPageDetails",
	components: {
		ALTable,
		AutocompleterStringOnly,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			fetchingCharts: false,
			retData: [],

			chosenPage: '',
			chosenMonth: '',

			weblist: [],
			months: [],

			chartSource: "ARN Google Analytics",
		}
	},
	computed: {
		chartTitle() {
			return '';
		},
	},
	methods: {
		updateChosenPageId(ob) {
			this.chosenPage = ob.id;
		},
		getLists() {
			let self = this;
			axios.get("/api/web-get-pagemeta.php",).then(function (response) {
				let ret = response.data; //response.data is returned info
				if(ret.webs) self.weblist = ret.webs
				if(ret.months) self.months = ret.months
				if (ret.error === 1) {
					self.form1working = false;
					self.showKalert(ret.errob);
				}
				if(self.chosenPage === '') {
					self.chosenPage = self.weblist[0].id
				}
				if(self.chosenMonth === '') {
					self.chosenMonth = self.months[0].id
					self.getChartData()
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getChartData() { //for cache lookup
			if(this.fetchingCharts !== true && this.chosenPage.length > 0 && this.chosenMonth.length > 0) {
				this.fetchingCharts = true;
				let self = this;
				let targ = "/api/web-get-pagedetail.php";
				axios.post(targ, JSON.stringify({
					pageAsset: self.chosenPage,
					cadence: 'm',
					monthstart: self.chosenMonth,
				})).then(function (response) {
					let ret = response.data; //response.data is returned info
					if (ret.rowdata && ret.rowdata.length > 0) { //normal report type
						self.retData = ret.rowdata
					}
					else self.retData = []
					self.fetchingCharts = false
				}).catch(function () {
					self.fetchingCharts = false
				});
			}
		},
	},
	watch: {

	},
	mounted() {
		this.getLists()
		document.title = 'ARN Web Page Detail';
	}
}
</script>

<style scoped>
.market-summary {
	display: block;
	overflow-y: auto;
	height: 100%;
	width: 100%;
}
.content-block p {
	line-height: 1.6;
}

@media screen and (max-width: 1350px) {

}
</style>