<template>
	<div class="admin-stats-section">
		<div v-if="stats.nsessdaily" class="charthold">
			<p class="chartheadline">Sessions by day</p>
			<SparkChart :series="stats.nsessdaily" :height="250"></SparkChart>
		</div>
		<div v-if="stats.nusersdaily" class="charthold">
			<p class="chartheadline">Unique users by day</p>
			<SparkChart :series="stats.nusersdaily" :height="250"></SparkChart>
		</div>
		<div v-if="stats.nsessweekly" class="charthold">
			<p class="chartheadline">Sessions by week</p>
			<SparkChart :series="stats.nsessweekly" :height="250"></SparkChart>
		</div>
		<div v-if="stats.nusersweekly" class="charthold">
			<p class="chartheadline">Unique users by week</p>
			<SparkChart :series="stats.nusersweekly" :height="250"></SparkChart>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import SparkChart from "@/components/charts/SparkChart";

export default {
	name: "AdminStats",
	components: {
		SparkChart
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			stats: [],
		}
	},
	computed: {

	},
	methods: {},
	watch: {},
	mounted() {
		let self = this;
		axios.get(
			"/api/usage-data.php"
		).then(function (response) {
			let ret = response.data; //response.data is returned info
			self.stats = ret;
		}).catch(function (error) {
			console.log(error);
		});
	}
}
</script>

<style scoped>
.admin-stats-section {
	display: flex;
	width: 100%;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 20px;
	padding: 20px 0;
}
.charthold {
	width: 380px;
	text-align: center;
	background-color: #EFEFEF;
	height: 290px;
}
.chartheadline {
	padding: 20px 0 0;
	font-weight: bold;
}
</style>