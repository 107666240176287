<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">
			<div class="show-header" style="display: flex; align-items: center; padding-top: 10px; padding-bottom: 10px;">
				<div class="show-icon-holder" style="height: 160px; width: 160px; background-color: #EFEFEF;">
					<img v-if="showObject.artwork_url" :src="showObject.artwork_url" style="object-fit: cover; width: 100%; height: 100%;" />
				</div>
				<div class="show-title-holder" style="margin-left: 20px;">
					<p style="font-size: 40px; color: #553AA9;">{{showObject.name}}</p>
					<p v-if="showObject.author" style="font-size: 24px; color: #553AA9; margin-bottom: 3px;">{{showObject.author}}</p>
					<p v-if="showObject.network" style="font-size: 12px;">{{showObject.network}}</p>
					<p v-if="showObject.category" style="font-size: 12px;">{{showObject.category}}</p>
				</div>
			</div>

			<div>
				<p>{{showObject.description}}</p>
				<p><a class="link-text" :href="showObject.social_web" target="_blank">{{showObject.social_web}}</a></p>
			</div>

			<div class="chart-rows">
				<ChartHolderDash v-if="chartsTitle.triton_show_uniques"
						:charttitle="'Australian Monthly Downloads vs Listeners - '+chartsTitle.triton_show_uniques"
						:source="chartsSource.triton_show_uniques" :height="500">
					<template v-slot:default>
						<apexchart v-if="apexdata.triton_show_uniques" width="99%" height="100%" type="line"
								:options="chartoptions" :series="apexdata.triton_show_uniques"></apexchart>
						<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
					</template>
				</ChartHolderDash>
			</div>

			<div>
				<div class="">
					<table class="altbl noborderv" id="clips">
						<thead>
							<tr>
								<td class="headingtext" data-f-bold="true"><span>Title</span></td>
								<td class="headingtext" data-f-bold="true"><span>Visibility</span></td>
								<td class="headingtext" data-f-bold="true"><span>Publish Date</span></td>
								<td class="headingtext" data-f-bold="true"><span>Audio URL</span></td>
								<td class="headingtext" data-f-bold="true"><span>Omny URL</span></td>
							</tr>
						</thead>
						<tbody>
						<tr v-for="clip in showObject.clips" :key="clip.id">
							<td :title="clip.description">{{clip.title}}</td>
							<td>{{clip.visibility}}</td>
							<td>{{clip.pubdate}}</td>
							<td><span><a class="link-text" :href="clip.audio_url" target="_blank">Play</a></span></td>
							<td><span><a class="link-text" :href="clip.publish_url" target="_blank">Omny Page</a></span></td>
						</tr>
						</tbody>
					</table>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import PermissionChecker from "@/components/PermissionChecker";

export default {
	name: 'PodcastShowHome',
	components: {
		PermissionChecker,
		ChartLoaderInsert,
		ChartHolderDash,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			viewPerms: ['elt','connections','content-metro','podcast'],
			showSelectedSlug: null,

			showObject: {},
			updating: false,

			chartsSource: [],
			getChartsBtnWorking: false,
			chartsTitle: [],
			apexdata: {},
			chartoptions: {
				chart: {
					toolbar: {
						show: true
					},
					animations: {
						enabled: false
					},
					type: 'line',
				},
				fill: {

				},
				xaxis: {
					type: "datetime",
					labels: {
						show: true,
						hideOverlappingLabels: true
					},
					axisTicks: {
						show: true
					}
				},
				toolbar: {
					autoSelected: "zoom"
				},
				yaxis: {
					decimalsInFloat: 0,
					min: 0,
					forceNiceScale: true,
					labels: {
						formatter: (value) => {
							if(value === null) return null
							return value.toLocaleString()
						},
					},
				},
				colors: [],
				stroke: {
					curve: "straight",
					width: 2.5,
				},
				dataLabels: {
					enabled: false
				},
				markers: {
					size: 4
				},
				tooltip: {
					x: {
						format: 'MMMM yyyy'
					}
				},
			},
		}
	},
	computed: {

	},
	methods: {
		getData() {
			this.getOmnyData()
		},
		getOmnyData() {
			if(this.showSelectedSlug && this.showSelectedSlug.length > 0 && this.updating === false) {
				this.updating = true
				let self = this
				let targ = "/api/podcast/omny-show-detail.php?showslug=" + this.showSelectedSlug
				axios.get(targ).then(function (response) {
					let ret = response.data; //response.data is returned info
					self.showObject = ret.show
					document.title = ret.show.name
					self.getCharts()
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		getCharts() {
			this.getChartsBtnWorking = true;
			this.updating = true;
			let self = this;
			let targ = "/api/podcast-show-detail.php?omny=1&tritonid="+this.showObject.triton_prog_id+"&showslug="+this.showObject.slug+"&showname="+this.showObject.name;
			this.apexdata = {}; //reset prior to retrieval
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					if (ret.apexdata) self.apexdata = ret.apexdata;
					if (ret.chartsSource != null) self.chartsSource = ret.chartsSource
					if (ret.chartsOptions != null) self.chartsOptions = ret.chartsOptions
					if (ret.chartsTitle != null) self.chartsTitle = ret.chartsTitle
				}
				self.getChartsBtnWorking = false
				setTimeout(function () {
					self.updating = false;
				}.bind(self), 1000);
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		showSelectedSlug() {
			if(this.updating === false) {
				this.getData()
			}
		},
	},
	mounted() {
		this.chartoptions.colors = this.store.chartColourGroupARNFirst
	},
	created() {
		let urlparams = this.$route.params
		if(urlparams.showslug) {
			this.showSelectedSlug = urlparams.showslug
		}
		document.title = "Podcast Show Detail"
	},
}
</script>

<style scoped>

</style>
