import axios from "axios";

export const globalMixin = {
	methods: {
		numdisplay: function (num, dp = 0) {
			if(!num) return num
			else if(isNaN(num)) return num
			return num.toLocaleString('en-US', {minimumFractionDigits: dp, maximumFractionDigits: dp});
		},
		ucfirst: function (str) {
			return str[0].toUpperCase() + str.substring(1);
		},
		niceDateTimeFromUnix(unix_timestamp, includeTime) {
			var a = new Date(unix_timestamp * 1000);
			var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			var year = a.getFullYear();
			var month = months[a.getMonth()];
			var date = a.getDate();

			var timestr = "";
			if (includeTime === true) {
				var hour = a.getHours();
				var min = a.getMinutes();
				if (min < 10) min = "0" + min;
				// var sec = a.getSeconds();
				timestr = ' ' + hour + ':' + min; // + ':' + sec;
			}
			return date + ' ' + month + ' ' + year + timestr;
		},
		boolToInt: function(boolin) {
			if(boolin === true) return 1;
			return 0;
		},
		trueToYes: function(str) {
			if(str === true) return "Yes";
			return '';
		},
		numdisplayNoZeroes(num) {
			if(num === 0) return '';
			else return this.numdisplay(num);
		},
		survDateToNice(yyyymm) {
			if(yyyymm === 0) return '';
			let str = yyyymm.toString();
			let y = str.substring(0,4);
			let m = str.substring(4,6);
			return y+"-"+m;
		},
		survGfkNiceName(yYYYYsS) {
			if(yYYYYsS.indexOf('s' !== false)) {
				let arr = yYYYYsS.split('s');
				return 's'+arr[1]+' '+arr[0].replace('y','');
			}
			return yYYYYsS;
		},
		competitiveNice(num) {
			if(num === 0) return '';
			else return 'Yes';
		},
		convertNetworkAlign(str) {
			if(str === 'arn_pg') return 'Pure Gold';
			else if(str === 'arn_k') return 'KIIS';
			return str;
		},
		intToYes: function (str) {
			if (str) {
				if (parseInt(str) === 1) return "Yes";
			}
			return '';
		},
		getTVAggMktName(aggmktid) {
			let m = this.store.tvaggmkts.find(item => item.id === aggmktid);
			if(m) return m.name;
			return '';
		},
		userHasPermission(permarrForItem) {
			for(let i=0; i<permarrForItem.length; i++) {
				if(this.store.user.permissions.includes(permarrForItem[i])) {
					return true;
				}
			}
			return false;
		},
		convertToUrlName(str) {
			str = str.replaceAll("/","");
			str = str.replaceAll("'","");
			str = str.replaceAll(' ','-').toLowerCase();
			str = str.replaceAll('--','-').toLowerCase();
			return str;
		},
		doGetGfkData(gfkalportmktid, demoid, statisticid, daypartid, stnids, firstsurv, lastsurv) {
			var str = "mktid=" + gfkalportmktid + "&demoid=" + demoid + "&statisticid=" + statisticid + "&daypart=" + daypartid;
			str += '&stations=' + stnids.join("|") + "&firstsurv="+firstsurv+"&lastsurv="+lastsurv;
			let targ = "https://gfk.arnconnect.com.au/post/arnstats/get-chart-data.php?" + str;
			return axios.get(targ).then(function (response) {
				return response.data; //response.data is returned info
			}).catch(function (error) {
				console.log(error);
			});
		},
		// doGetGfkQtrHrData(gfkalportmktid, statisticid, stnids, survkey) {
		// 	var str = "mktid=" + gfkalportmktid + "&statisticid=" + statisticid;
		// 	str += '&stations=' + stnids.join("|") + "&survkey="+survkey;
		// 	let targ = "https://gfk.arnconnect.com.au/post/arnstats/get-qtr6a6p.php?" + str;
		// 	return axios.get(targ).then(function (response) {
		// 		return response.data; //response.data is returned info
		// 	}).catch(function (error) {
		// 		console.log(error);
		// 	});
		// },
		doGetCacheDataAudology(requestSets) {
			this.chartdata = []
			let postjson = JSON.stringify({
				apicreds: this.store.audologyAPICreds,
				requestSets: requestSets,
			})
			let api = this.store.audologyAPIUrl
			return axios.post(api+"get-chart-data", postjson).then(function (response) {
					return response.data
				}).catch(function (error) {
					console.log(error)
			});
		},
		getChartColorForStation(stnob, existColorArray) {
			if(stnob) {
				if(stnob.name && (stnob.name.toLowerCase().includes('edge') || stnob.name.toLowerCase().includes('cada'))) {
					let l = this.store.netcolors.find(item => item.key === 'cada');
					if (l) return l.col;
				}
				else if(!stnob.stid) { //this will be from the master Freq list - most instances except ARN SMBAP GFK
					if(stnob.id === 1294) return '#092e52'; //5AA
					else if(stnob.id === 1279) return '#665191'; //Magic1278
					if(stnob.repnetgroup !== stnob.netgroupown && this.store.gfkMktIds.includes(stnob.mktid)) { //maybe an Ace or 6IX type situation
						let l = this.store.netcolors.find(item => item.key === stnob.netgroupown);
						if (l) return l.col;
					}
					let l = this.store.netcolors.find(item => item.key === stnob.repsubnet);
					if (l) return l.col;
					l = this.store.netcolors.find(item => item.key === stnob.subnetown);
					if (l) return l.col;
				}
				else { //ARN mkt SMBAP id=21 network groups
					let l = this.store.netcolors.find(item => item.key === stnob.subnetwork);
					if (l) return l.col;
				}
			}
			let defaultColorCount = 0;
			for(let c=0; c<existColorArray.length; c++) {
				let mycolor = existColorArray[c];
				if(this.store.defaultColors.includes(mycolor)) defaultColorCount++;
			}
			return this.store.defaultColors[defaultColorCount];
		},
		getChartColorByNetworkString(netnamekey, existColorArray) {
			let l = this.store.netcolors.find(item => item.key === netnamekey);
			if (l) return l.col;
			let defaultColorCount = 0;
			for(let c=0; c<existColorArray.length; c++) {
				let mycolor = existColorArray[c];
				if(this.store.defaultColors.includes(mycolor)) defaultColorCount++;
			}
			return this.store.defaultColors[defaultColorCount];
		},
		sendlogging(topath) {
			let json = {
				topage: topath,
			};
			let jsonstr = JSON.stringify(json);
			let targ = "/post/logger.php";
			axios.post(targ, jsonstr).then(function (response) {
				return response.data; //response.data is returned info
			}).catch(function (error) {
				console.log(error);
			});
		},
		stripTags(str) {
			return str.replaceAll('<br/>',' ');
		},
		getMonthName(monthint) {
			let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			return months[parseInt(monthint-1)]
		},
		ldzero(intval) {
			if(intval < 10) return '0'+intval.toString()
			return intval.toString()
		},
		//tables stuff
		tblPrepareFilteredRows(rows, filterText = '', sortColName = null, sortColOrder = 'desc') {
			if(filterText.length > 0) { //add any filter by text input here
				let newer = [];
				let sboxval = filterText.toLowerCase();
				for (let r of rows) {
					for (let fld in r) {
						if(typeof(r[fld]) === 'string' && (isNaN(r[fld]) || parseFloat(r[fld]) != r[fld])) {
							if (r[fld].toLowerCase().indexOf(sboxval) > -1) {
								newer.push(r);
								break;
							}
						}
					}
				}
				rows = newer;
			}
			if(sortColName) {
				let useStrings = false;
				if(isNaN(rows[0][sortColName]) || parseFloat(rows[0][sortColName]) != rows[0][sortColName]) useStrings = true;
				if(sortColOrder === 'asc') {
					if(useStrings === true) {
						rows = rows.sort((a, b) => (a[sortColName].toLowerCase() > b[sortColName].toLowerCase() ? 1 : -1))
					}
					else rows = rows.sort((a, b) => (a[sortColName] > b[sortColName]) ? 1 : -1)
				}
				else {
					if(useStrings === true) rows = rows.sort((a, b) => (a[sortColName].toLowerCase() > b[sortColName].toLowerCase() ? -1 : 1))
					else rows = rows.sort((a, b) => (a[sortColName] > b[sortColName]) ? -1 : 1)
				}
			}
			return rows;
		},
		tblGetPaginatedRows(rows, paginationRowsPerPage = 25) {
			if(!paginationRowsPerPage) return [rows]
			else {
				let tempArr = []
				let tempSubArr = []
				for(let it of rows) {
					tempSubArr.push(it)
					if(tempSubArr.length === paginationRowsPerPage) {
						tempArr.push(tempSubArr)
						tempSubArr = []
					}
				}
				if(tempSubArr.length > 0) tempArr.push(tempSubArr)
				return tempArr
			}
		},
		//end tables stuff
		showKalert(responseObject) {
			this.store.kalert.text = responseObject.message;
			if (responseObject.text !== "" && responseObject.text !== undefined) this.store.kalert.text = responseObject.text;
			if (responseObject.alerttype === "" || responseObject.alerttype === "error" || responseObject.alerttype === undefined) responseObject.alerttype = 'danger';
			this.store.kalert.type = responseObject.alerttype;
			if (responseObject.type !== "" && responseObject.type !== undefined) this.store.kalert.type = responseObject.type;
			this.store.kalert.shown = true;
		},
		closeKalert() {
			this.store.kalert.shown = false;
		},
		setUser(uobj) {
			this.store.user = uobj;
		},
		dateNow() {
			let d = new Date(),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();
			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;

			return [year, month, day].join('-');
		},
		//for National Cume and Markets pages
		getAudologyNationalCumes() {
			let self = this
			let postjson = JSON.stringify({
				apicreds: this.store.audologyAPICreds,
				comboIds: this.store.arnComboIds,
				dateToUse: this.store.natCumeDateToUse,
			})
			let api = this.store.audologyAPIUrl
			axios.post(api+"get-cumes-at-date", postjson).then(function (response) {
				let ret = response.data
				let totcumes = { //initialise zero values.  total will be metro + gfkreg + xtrareg (the amfm & dab are just splits - do not add)
					owned10: {metro: 0, gfkreg: 0, xtrareg: 0, metroamfm: 0, metrodab: 0},
					repped10: {metro: 0, gfkreg: 0, xtrareg: 0, metroamfm: 0, metrodab: 0},
					latestSurveys: {metro: null, gc: null, newc: null, cbr: null, xtra: null},
					owned10_530: {metro: 0, gfkreg: 0, xtrareg: 0, metroamfm: 0, metrodab: 0},
					repped10_530: {metro: 0, gfkreg: 0, xtrareg: 0, metroamfm: 0, metrodab: 0},
				}
				totcumes.latestSurveys = {
					xtra: ret.latestXtra.survname + " (" + ret.latestXtra.reldate+")",
					metro: ret.latestGfkMetro.survname + " (" + ret.latestGfkMetro.reldate+")",
					cbr: ret.latestGfkCA.survname + " (" + ret.latestGfkCA.reldate+")",
					newc: ret.latestGfkNE.survname + " (" + ret.latestGfkNE.reldate+")",
					gc: ret.latestGfkGC.survname + " (" + ret.latestGfkGC.reldate+")",
				};
				//handle data into station records - MS12-12 daypartid=2
				for(let i= 0; i<self.store.stations.length; i++) {
					let stnob = self.store.stations[i]
					let stnid = stnob.id
					let mktid = stnob.mktid
					let cumeob = ret.data.find(item => item.marketId === mktid && item.demoId === 48 && item.statistic === 'cume' && item.daypartId === 2);
					if(cumeob) {
						let stner = cumeob.data.find(item => item.stid === stnid);
						if(stner) stnob.cume10 = stner.val
					}
					cumeob = ret.data.find(item => item.marketId === mktid && item.demoId === 78 && item.statistic === 'cume' && item.daypartId === 2);
					if(cumeob) {
						let stner = cumeob.data.find(item => item.stid === stnid);
						if(stner) stnob.cume2554 = stner.val
					}
					self.store.stations.splice(i, 1, stnob);
				}
				//also do the market stationcombos for ARN Repped/Owned
				for(let i=0; i<self.store.markets.length; i++) {
					let mktob = self.store.markets[i];
					let mktid = mktob.id;
					//do Mon-Sun 12a-12a
					let cumeob = ret.data.find(item => item.marketId === mktid && item.demoId === 48 && item.statistic === 'cume' && item.daypartId === 2);
					if(cumeob) {
						let stner = cumeob.data.find(item => item.stid === 'c_227'); //ARN Rep
						if(stner) {
							mktob.cume10 = stner.val
							if (self.store.gfkMetroMktIds.includes(mktob.id)) totcumes.repped10.metro += stner.val
							else if (self.store.gfkMktIds.includes(mktob.id)) totcumes.repped10.gfkreg += stner.val
							else {
								totcumes.repped10.xtrareg += stner.val
								totcumes.repped10_530.xtrareg += stner.val
							}
						}
						stner = cumeob.data.find(item => item.stid === 'c_110'); //ARN Owned
						if(stner) {
							mktob.cumeown10 = stner.val
							if (self.store.gfkMetroMktIds.includes(mktob.id)) totcumes.owned10.metro += stner.val
							else if (self.store.gfkMktIds.includes(mktob.id)) totcumes.owned10.gfkreg += stner.val
							else {
								totcumes.owned10.xtrareg += stner.val
								totcumes.owned10_530.xtrareg += stner.val
							}
						}
						stner = cumeob.data.find(item => item.stid === 'c_2013'); //Owned AMFM Only
						if(stner) {
							if (self.store.gfkMetroMktIds.includes(mktob.id)) totcumes.owned10.metroamfm += stner.val
						}
						stner = cumeob.data.find(item => item.stid === 'c_2016'); //Repped AMFM Only
						if(stner) {
							if (self.store.gfkMetroMktIds.includes(mktob.id)) totcumes.repped10.metroamfm += stner.val
						}
						stner = cumeob.data.find(item => item.stid === 'c_2028'); //Owned DAB Only
						if(stner) {
							if (self.store.gfkMetroMktIds.includes(mktob.id)) totcumes.owned10.metrodab += stner.val
						}
						stner = cumeob.data.find(item => item.stid === 'c_2162'); //Repped DAB Only
						if(stner) {
							if (self.store.gfkMetroMktIds.includes(mktob.id)) totcumes.repped10.metrodab += stner.val
						}
					}
					//now do Mon-Sun 530a-12mn for 10+ only
					cumeob = ret.data.find(item => item.marketId === mktid && item.demoId === 48 && item.statistic === 'cume' && item.daypartId === 1);
					if(cumeob) {
						let stner = cumeob.data.find(item => item.stid === 'c_227'); //ARN Rep
						if(stner) {
							if (self.store.gfkMetroMktIds.includes(mktob.id)) totcumes.repped10_530.metro += stner.val
							else if (self.store.gfkMktIds.includes(mktob.id)) totcumes.repped10_530.gfkreg += stner.val
						}
						stner = cumeob.data.find(item => item.stid === 'c_110'); //ARN Owned
						if(stner) {
							if (self.store.gfkMetroMktIds.includes(mktob.id)) totcumes.owned10_530.metro += stner.val
							else if (self.store.gfkMktIds.includes(mktob.id)) totcumes.owned10_530.gfkreg += stner.val
						}
						stner = cumeob.data.find(item => item.stid === 'c_2013'); //Owned AMFM Only
						if(stner) {
							if (self.store.gfkMetroMktIds.includes(mktob.id)) totcumes.owned10_530.metroamfm += stner.val
						}
						stner = cumeob.data.find(item => item.stid === 'c_2016'); //Repped AMFM Only
						if(stner) {
							if (self.store.gfkMetroMktIds.includes(mktob.id)) totcumes.repped10_530.metroamfm += stner.val
						}
						stner = cumeob.data.find(item => item.stid === 'c_2028'); //Owned DAB Only
						if(stner) {
							if (self.store.gfkMetroMktIds.includes(mktob.id)) totcumes.owned10_530.metrodab += stner.val
						}
						stner = cumeob.data.find(item => item.stid === 'c_2162'); //Repped DAB Only
						if(stner) {
							if (self.store.gfkMetroMktIds.includes(mktob.id)) totcumes.repped10_530.metrodab += stner.val
						}
					}

					//now do 2554
					cumeob = ret.data.find(item => item.marketId === mktid && item.demoId === 78 && item.statistic === 'cume' && item.daypartId === 2);
					if(cumeob) {
						let stner = cumeob.data.find(item => item.stid === 'c_227'); //ARN Rep
						if(stner) {
							mktob.cume2554 = stner.val
						}
					}
					self.store.markets.splice(i, 1, mktob);
				}

				self.store.totcumes = totcumes
				self.store.natCumesLoaded = true;
			}).catch(function (error) {
				console.log(error)
			});
		},
		///AUDOLOGY CHARTS
		getCellDataAud(stid, survid, baseData, chosenStatistic, chosenDaypart, chosenDemo, chosenRadioType = 0, divideBy1000 = true) {
			let survitem = baseData.find(
				item => item.survcode === survid
					&& item.statistic === chosenStatistic
					&& item.daypartId === chosenDaypart
					&& item.demoId === chosenDemo
					&& item.audioType === chosenRadioType
			)
			if(survitem) {
				let valitem = survitem.data.find(item => item.stid === stid)
				if(valitem) {
					if(divideBy1000 && (chosenStatistic === 'cume' || chosenStatistic === 'avgAud')) return valitem.val / 1000
					return valitem.val
				}
			}
			return null
		},
		prepareLineFromAudData(set, retdata) {
			let mydaypart = set.dayparts[0]
			let mydemo = set.demos[0]
			let mystatistic = set.statistics[0]
			let stnobs = []
			for(let stnid of set.stationIds) {
				stnobs.push(this.store.stations.find(item => item.id === stnid))
			}
			let rows = []
			for (let st of stnobs) {
				if(st) {
					let myrow = []
					myrow = {
						name: st.name,
						data: [],
					}
					for (let survid of set.surveylist) {
						myrow.data.push({
							x: survid,
							y: this.getCellDataAud(st.id, survid, retdata, mystatistic, mydaypart, mydemo, 0)
						})
					}
					myrow.data.reverse()
					rows.push(myrow)
				}
			}
			return rows
		},
		getChartColorForStationAudology(stnob, defaultColorCount) {
			let col = ''
			if(stnob.name && (stnob.name.toLowerCase().includes('edge') || stnob.name.toLowerCase().includes('cada'))) {
				let l = this.store.netcolors.find(item => item.key === 'cada');
				if (l) col = l.col;
			}
			if(col === '') {
				let l = this.store.netcolors.find(item => item.key === stnob.repsubnet)
				if (l) col = l.col
			}
			if(col === '') {
				let l = this.store.netcolors.find(item => item.key === stnob.subnetown)
				if(l) col = l.col
			}
			if(col === '') {
				let l = this.store.netcolors.find(item => item.key === stnob.netgroupown) //catches nine etc
				if(l) col = l.col
			}
			if(col === '') {
				let str = stnob.name.toLowerCase()
				let l = null
				if(str.indexOf('kiis') !== -1) l = this.store.netcolors.find(item => item.key === 'arn_k')
				else if(str.indexOf('gold') !== -1) l = this.store.netcolors.find(item => item.key === 'arn_p')
				else if(str.indexOf('smooth') !== -1) l = this.store.netcolors.find(item => item.key === 'nova_s')
				else if(str.indexOf('nova') !== -1) l = this.store.netcolors.find(item => item.key === 'nova_n')
				else if(this.strContains(str, ['nine','3aw','4bc','6pr','2gb']) === true) l = this.store.netcolors.find(item => item.key === 'nine')
				else if(this.strContains(str, ['hit','fox','2day','b105','safm']) === true) l = this.store.netcolors.find(item => item.key === 'sca_h')
				else if(this.strContains(str, ['mmm','triple m']) === true) l = this.store.netcolors.find(item => item.key === 'sca_m')
				else if(this.strContains(str, ['jjj','triple j']) === true) l = this.store.netcolors.find(item => item.key === 'abc_j')
				else if(this.strContains(str, ['5aa','fiveaa']) === true) l = this.store.netcolors.find(item => item.key === 'nova_aa')
				else if(this.strContains(str, ['2ca','ace']) === true) l = this.store.netcolors.find(item => item.key === 'ace')
				else if(this.strContains(str, ['2cc']) === true) l = this.store.netcolors.find(item => item.key === 'nine')
				else if(this.strContains(str, ['2hd']) === true) l = this.store.netcolors.find(item => item.key === 'arn_p')
				else if(this.strContains(str, ['newfm']) === true) l = this.store.netcolors.find(item => item.key === 'arn_k')
				else if(this.strContains(str, ['arn']) === true) l = this.store.netcolors.find(item => item.key === 'arn_k')
				else if(this.strContains(str, ['sca']) === true) l = this.store.netcolors.find(item => item.key === 'sca_h')
				if(l) col = l.col
			}
			if(col === '') {
				col = this.store.defaultColors[defaultColorCount]
				defaultColorCount++;
				if (defaultColorCount >= this.store.defaultColors.length) {
					defaultColorCount = 0
				}
			}
			return [col, defaultColorCount]
		},
		strContains(string, substringArr) {
			let strlower = string.toLowerCase()
			for(let substring of substringArr) {
				substring = substring.toLowerCase()
				if(strlower.indexOf(substring) !== -1) {
					return true
				}
			}
			return false
		},
		getSurveyObsForMarket(mktid) {
			return this.store.surveys.filter(item => item.mktid === mktid)
		},
		getSurveyIdsFromSurveyObs(surveys) {
			let ss = []
			for(let su of surveys) {
				ss.push(su.id)
			}
			return ss
		},
		///END OF AUDOLOGY CHARTS
	},

}