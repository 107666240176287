<template>
	<div class="personatile" @click="$router.push('/trade-marketing/channels')">
		<p class="personatitle">{{title}}</p>
		<div style="justify-content: space-between; display: flex;">
			<div class="logo-holder" v-for="itemname in itemnames" :key="itemname">
				<TradeMarketingChannelTile :title="itemname" ></TradeMarketingChannelTile>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import TradeMarketingChannelTile from "@/components/pageviews/TradeMarketingChannelTile";
export default {
	name: "TradeMarketingPersona",
	components: {TradeMarketingChannelTile},
	props: {
		title: String,
		itemnames: Array,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.personatile {
	background: #FAFAFA;
	padding: 20px;
	margin-bottom: 30px;
	width: 600px;
	box-shadow: 0 0 10px 5px rgba(0,0,0,0.1);
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}
.personatitle {
	font-weight: bold;
	font-size: 20px;
}
.logo-holder {
	width: 100px;
	height: 100px;
	margin: 10px auto 20px;
	border: 1px solid #EEE;
	overflow: hidden;
	border-radius: 50%;
}

</style>