<template>
	<div>
		<PodcastCRAMenu></PodcastCRAMenu>
		<div class="chart-rows">
			<PodListenerWarn></PodListenerWarn>
			<ChartHolderDash
					:charttitle="'Publisher Summary'"
					:source="chartsSource.pubpodrank" :height="750">
				<div class="b-table-holder">
					<ALTable tblclasses="noborderv" :excel="true" excelfilename="Podcast Publishers" :excelatbottom="true"
						:cols="[
							{title: 'Rank', sortable: true, fldid: 'rank'},
							{title: 'Publisher', sortable: true, fldid: 'publisher'},
							{title: 'Sales', sortable: true, fldid: 'sales'},
							{title: 'Listeners', sortable: true, fldid: 'listeners', numtype: 'number'},
							{title: 'Listeners % MoM', sortable: true, fldid: 'listenersmom', numtype: 'pct'},
							{title: 'Downloads', sortable: true, fldid: 'downloads', numtype: 'number'},
							{title: 'Downloads % MoM', sortable: true, fldid: 'downloadsmom', numtype: 'pct'},
						]"
						:rowdata="chartsData.pubpodrank"
					></ALTable>
				</div>
				<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
			</ChartHolderDash>

			<ChartHolderDash :charttitle="'Publisher Downloads Trending'" :source="chartsSource.pod_rnk_pubchrt">
				<template v-slot:default>
					<ApexBarTrendCategories :chartdata="apexdata.pod_rnk_pubchrt" filename="Publishers" :fetching="getChartsBtnWorking"></ApexBarTrendCategories>
				</template>
			</ChartHolderDash>

			<ChartHolderDash :charttitle="'Publisher Listeners Trending'" :source="chartsSource.pod_rnk_pubchrt_listeners">
				<template v-slot:default>
					<ApexBarTrendCategories :chartdata="apexdata.pod_rnk_pubchrt_listeners" filename="Publishers" :fetching="getChartsBtnWorking"></ApexBarTrendCategories>
				</template>
			</ChartHolderDash>

			<ChartHolderDash
					:charttitle="'Sales Group Summary'"
					:source="chartsSource.salespodrank" :height="880">
				<div class="b-table-holder">
					<ALTable tblclasses="noborderv" :excel="true" excelfilename="Podcast Sales Groups" :excelatbottom="true"
						:cols="[
							{title: 'Rank', sortable: true, fldid: 'rank'},
							{title: 'Publisher', sortable: true, fldid: 'publisher'},
							{title: 'Sales', sortable: true, fldid: 'sales'},
							{title: 'Listeners', sortable: true, fldid: 'listeners', numtype: 'number'},
							{title: 'Listeners % MoM', sortable: true, fldid: 'listenersmom', numtype: 'pct'},
							{title: 'Downloads', sortable: true, fldid: 'downloads', numtype: 'number'},
							{title: 'Downloads % MoM', sortable: true, fldid: 'downloadsmom', numtype: 'pct'},
						]"
						:rowdata="chartsData.salespodrank"
					></ALTable>
				</div>
				<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>
			</ChartHolderDash>

			<ChartHolderDash :charttitle="'Sales Group Downloads Trending'" :source="chartsSource.pod_rnk_saleschrt">
				<template v-slot:default>
					<ApexBarTrendCategories :chartdata="apexdata.pod_rnk_saleschrt" filename="SalesGroups" :fetching="getChartsBtnWorking"></ApexBarTrendCategories>
				</template>
			</ChartHolderDash>

			<ChartHolderDash :charttitle="'Sales Group Listeners Trending'" :source="chartsSource.pod_rnk_saleschrt_listeners">
				<template v-slot:default>
					<ApexBarTrendCategories :chartdata="apexdata.pod_rnk_saleschrt_listeners" filename="SalesGroups" :fetching="getChartsBtnWorking"></ApexBarTrendCategories>
				</template>
			</ChartHolderDash>

<!--			<ChartHolderDash-->
<!--					:charttitle="'Sales Group Summary - Downloads'"-->
<!--					:source="chartsSource.salespodrank" :height="400">-->
<!--				<div class="b-table-holder">-->
<!--					<ALTable tblclasses="noborderv" :excel="true" excelfilename="Podcast Sales Groups" :excelatbottom="true"-->
<!--						:cols="[-->
<!--							{title: 'Rank', sortable: true, fldid: 'rank'},-->
<!--							{title: 'Sales', sortable: true, fldid: 'sales'},-->
<!--							// {title: 'Listeners', sortable: true, fldid: 'listeners', numtype: 'number'},-->
<!--							// {title: 'Listeners % MoM', sortable: true, fldid: 'listenersmom', numtype: 'pct'},-->
<!--							{title: 'Downloads', sortable: true, fldid: 'downloads', numtype: 'number'},-->
<!--							{title: 'Downloads % MoM', sortable: true, fldid: 'downloadsmom', numtype: 'pct'},-->
<!--						]"-->
<!--						:rowdata="chartsData.salespodrank"-->
<!--					></ALTable>-->
<!--				</div>-->
<!--				<ChartLoaderInsert v-if="getChartsBtnWorking"></ChartLoaderInsert>-->
<!--			</ChartHolderDash>-->

<!--			<ChartHolderDash :charttitle="'Sales Group Trending - Downloads'" :source="chartsSource.pod_rnk_saleschrt">-->
<!--				<template v-slot:default>-->
<!--					<ApexBarTrendCategories :chartdata="apexdata.pod_rnk_saleschrt" filename="SalesGroups" :fetching="getChartsBtnWorking"></ApexBarTrendCategories>-->
<!--				</template>-->
<!--			</ChartHolderDash>-->

<!--			<ChartHolderDash :charttitle="'Sales Group Trending - Listeners'" :source="chartsSource.pod_rnk_saleschrt_listeners">-->
<!--				<template v-slot:default>-->
<!--					<ApexBarTrendCategories :chartdata="apexdata.pod_rnk_saleschrt_listeners" filename="SalesGroups" :fetching="getChartsBtnWorking"></ApexBarTrendCategories>-->
<!--				</template>-->
<!--			</ChartHolderDash>-->

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ChartLoaderInsert from "@/components/ChartLoaderInsert";
import ApexBarTrendCategories from "@/components/charts/ApexBarTrendCategories";
import PodListenerWarn from "@/components/PodListenerWarn";
import ALTable from "@/components/ALTable";
import PodcastCRAMenu from "@/components/PodcastCRAMenu.vue";

export default {
	name: 'DashPodcastRankerPublishers',
	components: {
		PodcastCRAMenu,
		ALTable,
		PodListenerWarn,
		ApexBarTrendCategories,
		ChartLoaderInsert,
		ChartHolderDash,
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			chartId: 0,
			chartsData: {},
			chartsSource: [],
			getChartsBtnWorking: false,

			apexdata: {},
		}
	},
	computed: {

	},
	methods: {
		getCharts() {
			this.getChartsBtnWorking = true;
			var self = this;
			var targ = "/api/podcast-ranker-publishers.php";
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) {
					console.log(ret);
				}
				else {
					if(ret.apexdata) {
						self.apexdata = ret.apexdata;
					}
					if (ret.chartsData != null) {
						self.chartsData = ret.chartsData;
					}
					if (ret.chartsSource != null) {
						self.chartsSource = ret.chartsSource;
					}
					if (ret.h != null) {
						for (var key in ret.h) { //this updates any that exist, adds new if needed, but does not delete existing
							self.slotprops[key] = ret.h[key];
						}
					}
					//custom callback functions here if required (using pageaction as filter)
				}
				self.getChartsBtnWorking = false;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {},
	props: {

	},
	mounted() {

	},
	created() {
		this.getCharts();
		document.title = "Podcast Ranker Publishers";
	},
}
</script>

<style scoped>

</style>
