<template>
	<div v-if="rowInfo">
		<div class="airtimerow">
			<div class="cell wider" style="border-right: none;">
				<span style="font-size: 12px">Type</span>
			</div>
			<div class="cell wider">
				<select v-model="rowInfo.itemType">
					<option value="Live Read">Live Read</option>
					<option value="Live Cross">Live Cross</option>
					<option value="Impact Lives">Impact Lives</option>
					<option value="Blasts">Blasts</option>
				</select>
			</div>
		</div>
		<div class="airtimerow">
			<div class="cell wider">
				<select v-model="rowInfo.daypartName">
					<option v-for="opt in applicableDayparts" :key="opt" :value="opt">{{opt}}</option>
				</select>
			</div>
			<div class="cell wider">
				<div v-if="rowInfo.itemType">
					<select v-model="rowInfo.weekdayset">
						<option v-for="opt in weekdaysetsForStationDaypart" :key="opt" :value="opt">{{opt}}</option>
					</select>
				</div>
			</div>
			<div class="airtimerow-sub">
				<div class="cell"><span v-if="dpobj">{{dpobj.display_time}}</span></div>
				<div class="cell">{{rowInfo.spotlength}}</div>
				<div class="cell">{{numdisplay(rowInfo.spotValue,2)}}</div>
				<div class="cell"><input type="number" v-model="rowInfo.discountPct" /></div>
				<div class="cell">{{numdisplay(rowInfo.spotCost,2)}}</div>
				<div class="cell"><input v-model="rowInfo.nSpots" type="number" /></div>
				<div class="cell">{{numdisplay(rowInfo.totalSpotsValue,2)}}</div>
				<div class="cell">{{numdisplay(rowInfo.totalSpotsCost,2)}}</div>
				<div class="cell">
					<span class="remover" @click="store.sales_campaign.station_rows[stationindex].otherRows.splice(rowindex,1)">
						<i class="mdi mdi-close"></i>
					</span>
				</div>
			</div>

		</div>
	</div>

</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: 'SalesCampaignOtherRow',
	components: {

	},
	props: {
		stations: {
			type: Array,
			default: () => []
		},
		chosenstation: {
			type: Object,
			default: null
		},
		ratesforstation: {
			type: Object,
			default: () => {}
		},
		rowindex: {
			type: Number,
			default: 0
		},
		stationindex: {
			type: Number,
			default: 0
		},
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

			durationOptions: [30,15],
		}
	},
	computed: {
		rowInfo: {
			get() {
				if(this.store.sales_campaign.station_rows[this.stationindex]) {
					return this.store.sales_campaign.station_rows[this.stationindex].otherRows[this.rowindex]
				}
				return null
			},
			set(newval) {
				this.store.sales_campaign.station_rows[this.stationindex].otherRows[this.rowindex] = newval
			}
		},
		applicableDayparts() {
			if (this.rowInfo.itemType && this.chosenstation) {
				let ws = []
				for(let wd in this.ratesforstation[this.rowInfo.itemType]) {
					ws.push(wd)
				}
				return ws
			}
			return []
		},
		weekdaysetsForStationDaypart() {
			if (this.rowInfo.itemType && this.chosenstation && this.rowInfo.daypartName) {
				let ws = []
				for(let wd in this.ratesforstation[this.rowInfo.itemType][this.rowInfo.daypartName]) {
					ws.push(wd)
				}
				return ws
			}
			return []
		},
		rateItem() {
			if (this.rowInfo && this.rowInfo.itemType && this.rowInfo.daypartName && this.chosenstation && this.rowInfo.weekdayset) {
				return this.ratesforstation[this.rowInfo.itemType][this.rowInfo.daypartName][this.rowInfo.weekdayset]
			}
			return null
		},
		dpobj() {
			if (this.rateItem) {
				return this.rateItem.daypartObject
			}
			return null
		},
	},
	methods: {},
	watch: {
		'rowInfo.itemType': {
			handler() {
				if(this.rowInfo.itemType) {
					if(this.rowInfo.itemType === 'Live Read') this.rowInfo.spotlength = 30
					else this.rowInfo.spotlength = null
					this.rowInfo.daypartName = null
					this.rowInfo.weekdayset = null
				}
			}
		},
		'rowInfo': {
			handler() {
				if (this.dpobj) {
					if(this.rowInfo.spotlength) {
						if(this.rowInfo.spotlength <= 15) this.rowInfo.spotValue = this.rateItem.rate * 0.75
						else if(this.rowInfo.spotlength === 30) this.rowInfo.spotValue = this.rateItem.rate
						else {
							this.rowInfo.spotValue = this.rateItem.rate * (this.rowInfo.spotlength / 30)
						}
					}
					if (this.rowInfo.spotValue) {
						this.rowInfo.spotCost = this.rowInfo.spotValue * (1 - (this.rowInfo.discountPct/100))
					}
					else this.rowInfo.spotCost = null
					if (this.rowInfo.spotValue) {
						this.rowInfo.totalSpotsValue = this.rowInfo.spotValue * this.rowInfo.nSpots
					}
					else this.rowInfo.totalSpotsValue = null
					if (this.rowInfo.spotCost) {
						this.rowInfo.totalSpotsCost = this.rowInfo.spotCost * this.rowInfo.nSpots
					}
					else this.rowInfo.totalSpotsCost = null
				}
			},
			deep: true
		},
	},
	mounted() {

	},
	created() {

	},
}
</script>

<style scoped>

</style>
