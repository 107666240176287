<template>
	<div class="channel">
		<div class="channel-logo-hold">
			<TradeMarketingChannelTile :title="channeldata.name"></TradeMarketingChannelTile>
		</div>
		<p class="channel-title">{{channeldata.title}}</p>
		<div class="channel-content">
			<p>
				<a :href="channeldata.mediakiturl" target="_blank">Media Kit</a>
			</p>
			<p><span class="itemhead">Newsletter subcribers: </span>{{channeldata.newslettersub}}</p>
			<p><span class="itemhead">Monthly website sessions: </span>{{channeldata.mnthwebsess}}</p>
			<p><span class="itemhead">Website users/mth: </span>{{channeldata.mthwebusers}}</p>
			<p><span class="itemhead">Website pageviews/mth: </span>{{channeldata.mthwebviews}}</p>
			<p><span class="itemhead">Social followers: </span>{{channeldata.socialfollowers}}</p>
			<p v-if="channeldata.magazinesub.length > 0"><span class="itemhead">Magazine: </span>{{channeldata.magazinesub}}</p>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import TradeMarketingChannelTile from "@/components/pageviews/TradeMarketingChannelTile";
export default {
	name: "TradeMarketingChannelInfo",
	components: {
		TradeMarketingChannelTile
	},
	props: {
		channeldata: Object
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.channel {
	background: #FAFAFA;
	padding: 20px;
	margin-bottom: 30px;
	width: 400px;
	box-shadow: 0 0 10px 5px rgba(0,0,0,0.1);
}
.channel-logo-hold {
	width: 150px;
	height: 150px;
	border: 1px solid #EEE;
	margin: 10px auto 20px;
	overflow: hidden;
	border-radius: 50%;
}
.channel-title {
	font-weight: bold;
	font-size: 20px;
	text-align: center;
}
.channel-content {
	padding: 10px 0;
}
.itemhead {
	font-weight: bold;
}
</style>